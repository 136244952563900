export const MenuTagsData = [
	{
		menuTag: 'Gluten Free',
		abbrev: "GF"
  },
  {
    menuTag: "New",
    abbrev: "new",
  },
  {
    menuTag: "Popular",
    abbrev: 'star',
    icon: "star"
  },
	{
		menuTag: 'Vegan',
		abbrev: 'VG'
	},
  {
    menuTag: "Vegetarian",
    abbrev: 'V',
  }
];
