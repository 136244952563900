export interface StatusOption {
	value: number;
	desc: string;
	readable: string;
	selectable: boolean;
}



export class VendorStatusOptions{
	private statusOptions: StatusOption[] = [];
	private readOnly: boolean;

	constructor(statusOptions: StatusOption[]) {
		this.statusOptions = statusOptions;
	}

	private evalReadOnly(vendorStatusFlag: number): boolean{
		let readOnly: boolean = false;
		this.statusOptions.forEach((option: StatusOption) => {
			if (option.value === vendorStatusFlag){
				if (!option.selectable) {readOnly = true;}
			}
		});
		return readOnly;
	}

	public getStatusOptions(): StatusOption[]{
		return this.statusOptions;
	}

	public isReadOnly(vendorStatusFlag: number): boolean{
		return this.evalReadOnly(vendorStatusFlag);
	}


}
