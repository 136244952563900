import { Component, ViewChild,  ElementRef, Input} from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { IonSearchbar, Platform } from "@ionic/angular";
import { NavParams, ModalController, AlertController, LoadingController } from "@ionic/angular";
import { EventRepo, Event, IEventWrite, IEventRead, IEventData} from "../../../../app-domain/scheduling/index";
import { VendorService, IVendor } from "../../../../app-domain/vendor/index";
import { Persistable } from "../../../../app-domain/shared-interfaces/index";
import { IPlace, Place } from "../../../../app-domain/place/index";
import { MapComponent, VendorSelectComponent } from "../../../../app-components/shared-components/index";
import * as moment from 'moment';

/* Interfaces */
import { Mappable, RemoteResponse } from "../../../../app-interfaces/index";

/***Services***/
import { AnalyticsService, GooglePlaceService, VendorCacheService } from "../../../../app-services/index";



@Component({
	selector: 'event-builder',
	templateUrl: 'event-builder.component.html',
	styleUrls: ['event-builder.component.scss']
})
export class EventBuilderComponent {
	@Input() event: IEventWrite;
	@Input() vendor: IVendor;
	public eventForm: FormGroup;
	public mode: "Add" | "Update" = "Add";
	public formInitialized: boolean = false;
	@ViewChild(IonSearchbar ) searchElementRef: IonSearchbar;
	@ViewChild(MapComponent) map: MapComponent;
	public waitForMapRendering: boolean = false;
	private selectedDate: Date = new Date();
	//public minDate = moment(new Date().toISOString()).format("YYYY-MM-DD");
	public nonVendorInd: boolean = false;
	public largeScreen: boolean = false;

	constructor(private vendorService: VendorService, private navParams: NavParams,private modalCtrl: ModalController,
				private eventRepo: EventRepo, private googlePlaceService: GooglePlaceService, private vendorCacheService: VendorCacheService,
				private alertCtrl: AlertController, private loadCtrl: LoadingController, private analyticsService: AnalyticsService,
				private platform: Platform
				) {
		this.vendor = this.vendorService.getVendorFocus();

		if (!this.vendor) this.nonVendorInd = true;
		if (this.platform.width() > 1200) this.largeScreen = true;
	}

	async ngOnInit(){
		window.setTimeout(() => this.initAutoComplete(),1000);
		await this.handleParams();
		this.initializeForm();
	}

	private async handleParams(){
		if (this.navParams){
			if (this.navParams.get('mode')) {this.mode = this.navParams.get('mode');}

			if (this.navParams.get('event')){
				const event = structuredClone(this.navParams.get("event"));
				this.event = new Event(event.eventID, event.eventData);
				this.vendor = await this.vendorCacheService.getByID(this.event.getVendorID()); 
			}else{
				this.event = new Event();
			}

			if (this.navParams.get('selectedDate')) {this.selectedDate = this.navParams.get("selectedDate");}
		}
	}



	private initializeForm() {
		let placeName = null; 
		let address = null; 
		let title = null;
		let start = moment(new Date(this.selectedDate).toISOString()).format();
		let end = moment(new Date(this.selectedDate).toISOString()).format();
		let notes = this.nonVendorInd ? "Customer entered event" : "";
		let vendor = this.nonVendorInd ? "" : this.vendor.getName();

		if (this.navParams.get('event')) {
			placeName = this.event.getEventPlaceName();
			address = this.event.getEventPlaceAddr();
			title = this.event.getTitle();
			notes = this.event.getNotes();
			start =  moment(new Date(this.event.getStartTime()).toISOString()).format();
			end =  moment(new Date(this.event.getEndTime()).toISOString()).format();
			vendor = this.vendor.getName();
		}

		this.eventForm = new FormGroup({
		  placeName: new FormControl(placeName, Validators.required),
		  address: new FormControl(address, Validators.required),
		  title : new FormControl(title),
		  notes: new FormControl(notes),
		  start: new FormControl(start),
		  end: new FormControl(end),
		  vendor: new FormControl(vendor, Validators.required)
		});
		this.formInitialized = true;

	}

	onDismiss(stateChange?: boolean): void{
		//this.map.destroyMap();
		this.modalCtrl.dismiss(stateChange);
	}

	public async onSubmit(form: FormGroup){
		this.updateEventDetails(form);
		let remoteResponse: RemoteResponse;
		
		const loader = await this.loadCtrl.create({message: this.mode === "Update" ? "Updating Event": "Adding Event"});
		await loader.present()

		try{
			if (this.mode === "Update"){
				remoteResponse = await this.eventRepo.update(this.event as unknown as Persistable<IEventData>);
				this.analyticsService.logEvent("ss_update_event",{"vendorID": this.event.getVendorID()})
			}else{
				remoteResponse = await this.eventRepo.add(this.event as unknown as Persistable<IEventData>);
				this.analyticsService.logEvent("ss_add_event",{"vendorID": this.event.getVendorID()})
			}
			loader.dismiss();
			this.alertEventResponse(remoteResponse);
		}catch(error){
			loader.dismiss();
			const errorReponseAlert = await this.alertCtrl.create({
				header: "There was an issue with " + (this.mode === 'Add' ? "adding" : "updating") + " this event",
				message: error.message,
				buttons: ['Ok']
			})
			await errorReponseAlert.present();
		}		
	}

	public onNewPlace(place: IPlace){
		this.updateEventsPlace(place);
	}


	private async initAutoComplete(){
		console.log(this.searchElementRef);
		//Allow autocomplete search functionality to search bar
		const inputElement = await this.searchElementRef.getInputElement();

	    this.googlePlaceService.createGoogleAutocompleteListener(inputElement,(place: IPlace) => {
	    	this.updateEventsPlace(place);
			//this.map.updateMarker(place as unknown as Mappable);
		});
		this.waitForMapRendering = true;
	}


	private updateEventsPlace(place: IPlace): void{
		this.event.setNewPlace(place);
		this.map.marker = this.event;
		this.map.center = this.event;
		this.updatePlaceDetails();
	}


	private updatePlaceDetails(){
		this.eventForm.patchValue({
			placeName: this.event.getEventPlaceName(),
			address: this.event.getEventPlaceAddr(),
		});
	}

	public onStartChange(value: CustomEvent): void{
		this.eventForm.patchValue({
			end: value.detail.value
		});
	}


	private updateEventDetails(form: FormGroup){
		this.event.setTitle(form.value.title);
		this.event.setNotes(form.value.notes);
		this.event.setStartTime(new Date(form.value.start).getTime());
		this.event.setEndTime(new Date(form.value.end).getTime());
		this.event.setVendorID(this.vendor.getVendorID());
	}


	public async onSelectVendor(){
		const modal = await this.modalCtrl.create({
			component: VendorSelectComponent
		})
		modal.onDidDismiss().then((data) => {
			if (data.data){
				this.setVendor(data.data.vendor);
			}
		})
		await modal.present();

	}


	private setVendor(vendor: IVendor){
		this.vendor = vendor;
		this.eventForm.get('vendor').setValue(this.vendor.getName());
	}

	private async alertEventResponse(response: RemoteResponse){
		const alert = await this.alertCtrl.create({
			header: response.data.msg,
			buttons: [{
				"text": "OK",
				handler: () => {
					this.onDismiss(true);
				}
			}]
		})
		await alert.present();
	}

	public async displayCustomerEventInfo(){
		const alert = await this.alertCtrl.create({
			header: "Customer Added Events",
			message: "Customers can now add events for vendors that have created an account in StreatSmart. These events will be verified by StreatSmart before they are public.",
			buttons: ["OK"]
		})
		alert.present();
	}

}
