/// <reference types="google.maps"/>
import {Injectable } from '@angular/core';

/***Plugins***/
import { Geolocation, Position, PermissionStatus } from "@capacitor/geolocation";

/***Models***/
import { ILocation, Location } from "../app-domain/location/index";
import { AlertController } from '@ionic/angular';

@Injectable()
export class GeolocationService{


	constructor(private alertCtrl: AlertController){}

	public async getCurrentLocation(): Promise<ILocation>{
		const permissionStatus = await this.checkPermissions();
		if (permissionStatus.location === "granted"){
			return this.getAndTransformLocationFromDevice();
		}else if (permissionStatus.location !== "denied"){
			try{
				await this.requestPermissions();
			}catch(error){
				console.log(error.message);
			}
			return this.getAndTransformLocationFromDevice();
		}else{
			const alert = await this.alertCtrl.create({
				header: "App is missing location service permission",
				message: "The StreatSmart application will lose most of its functionality when location services are disabled.",
				buttons: ["Ok"]
			});
			alert.present();
		}
	}

	private async getAndTransformLocationFromDevice(): Promise<ILocation>{
		const position: Position = await Geolocation.getCurrentPosition();
		return this.transformPosition(position);
	}

	public async getCurrentState(): Promise<string>{
		const curLoc: ILocation = await this.getCurrentLocation();
		return this.getStateFromLoc(curLoc);
	}


	private transformPosition(position: Position): ILocation{
		return new Location(position.coords.latitude, position.coords.longitude);
	}

	public getStateFromLoc(loc: ILocation): Promise<string>{
		if (loc){
			return new Promise(function(resolve,reject){
				new google.maps.Geocoder().geocode({location: {lat: loc.lat, lng: loc.lng}},(results,status) => {
					console.log(status);
					const stateList: any[] = results.filter((s) => (s.types[0] === 'administrative_area_level_1'));
					const state: string = stateList[0].address_components[0].short_name;
					resolve(state);
				});
			});
		}else{
			return Promise.resolve("MN");//default MN if no location
		}
	}

	private checkPermissions(): Promise<PermissionStatus>{
		try{
			return Geolocation.checkPermissions();
		}catch(error){
			console.warn("Unable to retrieve user geolocation due to disabled location services");
		}
		
	
		
	}

	private requestPermissions(): Promise<PermissionStatus>{
		try{
			return Geolocation.requestPermissions();
		}catch(error){
			console.warn("Unable to retrieve user geolocation due to disabled location services");
		}
		
	}


}
