import {Injectable } from '@angular/core';

@Injectable()
export abstract class MarketService{

	constructor(
	){

	}

	public abstract openAppListing(appId: string): Promise<void>	

}
 