import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

import { Model, Persistable } from "../../app-domain/index";
import { IReviewData, IReview } from "./index";
import { AppModule } from 'src/app/app.module';
import { SubscriptionManagerService } from 'src/app/app-services/subscription-manager.service';


export class Review implements Persistable<IReviewData>, IReview{
	private vendorID: string;
	private userID: string;
	private reviewData: IReviewData;
	private reference: firebase.database.Reference;
	constructor(vendorID?: string, userID?: string, reviewData?: IReviewData){
		this.vendorID = vendorID;
		this.userID = userID;
		this.reviewData = reviewData;
	}

	public getID(): string{
		return this.userID;
	}

	public getData(): IReviewData{
		return this.reviewData;
	}


	public getReviewData(): IReviewData{
		return this.reviewData;
	}

	public getVendorID(): string{
		return this.vendorID;
	}

	public getUserID(): string{
		return this.userID;
	}

	public isFavorite(): boolean{
		return this.reviewData.favorite;
	}

	public getCreatedTime(): number{
		return this.reviewData.createdTime;
	}

	public isCensored(): boolean{
		return this.reviewData.censoredReview ? true : false;
	}

  public hasComment(): boolean{
    return this.getComment() != undefined && this.getComment()!= "" ? true : false;
	}

	public getComment(): string{
		return this.reviewData.comment;
	}

	public setComment(comment: string): void{
		this.reviewData.comment = comment;
	}

	public getVendorResponse(): string{
		return this.reviewData.vendorResponse;
	}

	public setVendorResponse(response: string): void{
		this.reviewData.vendorResponse = response;
	}

	public getRating(): number{
		return this.reviewData.rating;
	}

	public hasRating(): boolean{
		if (this.reviewData.rating){
			return true;
		}
	}

	public setRating(rating: number): void{
		this.reviewData.rating = rating;
	}

	public toggleFavorite(): void{
		if (this.reviewData.favorite){
			this.reviewData.favorite = false;
		}else{
			this.reviewData.favorite = true;
		}
	}

	public censor(): void{
		this.reviewData.censoredReview = true;
	}

	public subscribe(db: firebase.database.Reference): void{
		this.unsubscribe();
		AppModule.injector.get(SubscriptionManagerService).addSubcription(this);
		this.reference = db;
		db.on("value",(snapshot: firebase.database.DataSnapshot) => {

			this.reviewData = snapshot.val();
			console.log(this.reviewData);
		});
	}

	public unsubscribe(): void {
		if (this.reference) this.reference.off();
	}

}
