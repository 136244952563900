import { Accordion, AccordionDrawerItem } from "../accordion";
import { Cuisine } from "../../../../app-domain/vendor/index";
/*
Example Accordion Structure

{
	Country: [
		{
			name: "American",
			informative: ""
		},
		{
			name: "French",
			informative: ""
		}
	],
	Specialty: [
		{
			name: 'Gluten-friendly',
			informative: "This is a gluten friendly specialty"
		}
	]
}

*/



/*export class CuisineAccordion extends Accordion<Cuisine>{

	constructor(label, drawerData: Cuisine){
		super(label, drawerData);
	}

	createLabel(data){
		return return label;
	}

	createDrawer(data){
		const _labelObj = Object.keys(data)[0];
		return Object.keys(data[_labelObj]).map((i) => {
			return new AccordionDrawerItem(data[_labelObj][i]);
		})
	}
}*/



/*export class CuisineAccordionDrawerItem extends AccordionDrawerItem{

	constructor(data){
		super(data);
	}

	createPane(data){
		return data.name;
	}

	createInformative(data){
		return data.informative;
	}

}*/
