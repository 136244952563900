import {Injectable} from "@angular/core";

import { TopicGroupOptions, TopicGroup, TopicsConfig, TopicProps, Topic } from "./index";

import { RemoteConfigService, FavoritesService, GeolocationService } from "src/app/app-services";
import { UserService, UserRole, User } from "../user";

@Injectable({
    providedIn: 'root'
})
export class TopicGroupFactory{
	
    constructor(private remoteConfigs: RemoteConfigService, private favoritesService: FavoritesService,
        private userService: UserService, private geoService: GeolocationService
    ){}

    public async createTopicGroup(groupName: TopicGroupOptions, userTopics: string[]){
        if (groupName === TopicGroupOptions.LOCATION){
            const remoteConfigValue = this.remoteConfigs.getAvailableStatesofService();
            const curState: string = await this.geoService.getCurrentState();
            const filterBy = remoteConfigValue.map((config) => {
                return {id: config.value, display: config.readable}
            })
            //const topics: Topic[] = this.createTopicsForGroup(groupName)
            const topicGroup = new TopicGroup(groupName, userTopics, filterBy, curState.toUpperCase());
            return topicGroup;
        }else if(groupName === TopicGroupOptions.FAVORITES){
            const favorites = await this.favoritesService.getFavorites();
            const filterBy = favorites.map((vendor) => {
                return {id: vendor.getVendorID(), display: vendor.getName()}
            });
            const topicGroup = new TopicGroup(groupName,  userTopics, filterBy, favorites.length > 0 ? favorites[0].getVendorID():null);
            return topicGroup;
        }else if (groupName === TopicGroupOptions.VENDOR){
            if (this.userService.getRole() === UserRole.VENDOR || this.userService.getRole() === UserRole.ADMIN){
                const topicGroup = new TopicGroup(groupName,userTopics);
                return topicGroup;
            }
        }else if (groupName === TopicGroupOptions.ADMIN){
            if(this.userService.getRole() === UserRole.ADMIN){
                const topicGroup = new TopicGroup(groupName, userTopics)
                return topicGroup;
            }
        }
    }

    /*private createTopicsForGroup(group: TopicGroupOptions): Topic[]{
        return TopicsConfig.filter((config: TopicProps) => {return config.category === group}).map((config: TopicProps) => {
            return new Topic(config);
        });
    }*/
   
}
