import { Directive, ElementRef, Input, OnInit} from '@angular/core';
import { UserService } from "../app-domain/user/index";
import { ViewManagerService } from "../app-services/index";


@Directive({
	selector: '[permission]',
})
export class PermissionDirective implements OnInit{
	@Input() permission: string;


	constructor(private userService: UserService, private el: ElementRef,
			private viewManager: ViewManagerService
		) {

	}


	ngOnInit(){
		if (!this.userService.hasPermissionsForVendor(this.permission) || !this.viewManager.isVendorView()){
			this.el.nativeElement.style.display = "none";
		}
	}

}
