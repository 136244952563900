import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

/***Services***/
import { QueuedEvent } from '../../../app-domain/scheduling';
import { IVendor } from '../../../app-domain/vendor';
import { AdminService, VendorCacheService } from '../../../app-services';

@Component({
  selector: 'event-queue',
  templateUrl: 'event-queue.page.html',
  styleUrls: ['event-queue.page.scss']
})
export class EventQueuePage implements OnInit{
  public queuedEvents: QueuedEvent[] = [];
  private availableVendors: { [key: string]: IVendor } = {};
	public dataLoaded: boolean = false;

  constructor(
    private adminService: AdminService,
    private vendorService: VendorCacheService,
    private modalCtrl: ModalController
	){}

	ngOnInit(){
		this.dataLoaded = false;
		return this.getEventsOnQueue()
	}
	

  public async onAcceptEvent(event: QueuedEvent){
		await this.adminService.confirmQueuedEvent(event.queueID);
		this.removeEventFromLocalQueue(event.queueID);
	}
		
  public async onRejectEvent(event: QueuedEvent) {
    await this.adminService.rejectQueuedEvent(event.queueID);
		this.removeEventFromLocalQueue(event.queueID);
	}
		
  private async getEventsOnQueue() {
		this.queuedEvents = await this.adminService.getEventsOnQueue();
		await this.addToAvailableVendors(this.queuedEvents);
		this.dataLoaded = true;
	}
	
	private async addToAvailableVendors(events: QueuedEvent[]): Promise<void>{
    if (events) {
      const vendorIDs: string[] = events.map((event: QueuedEvent) => { return event.event.data.vendorId; })
      const vendors: IVendor[] = await Promise.all(vendorIDs.map((vID: string) => {
        return this.vendorService.getByID(vID);
      }))
			vendors.forEach((vendor: IVendor) => this.availableVendors[vendor.getVendorID()] = vendor);
		}
		
	}

	private removeEventFromLocalQueue(queueID: string): void{
		this.queuedEvents = this.queuedEvents.filter((event: QueuedEvent) => {return event.queueID != queueID});
	}


  public onDismiss() {
    this.modalCtrl.dismiss();
  }

  test() {
    console.log("HI");

  }

}
