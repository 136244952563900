import {Injectable } from '@angular/core';
import { Preferences } from "@capacitor/preferences";
import { LocalStorageService } from './local-storage.service.abstract.';


@Injectable()
export class CapacitorPreferencesService extends LocalStorageService{

	constructor(
	
	){
		super();
	}

	public async get(key: string): Promise<string> {
		const { value } = await Preferences.get({key: key});
		return value;
	}

	public async set(key: string, value: string): Promise<void> {
		await Preferences.set({
			key: key,
			value: value
		});
	}

	public async remove(key: string): Promise<void>{
		return Preferences.remove({key: key});
	}



   
}
 