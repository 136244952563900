import { Component, Output, Input, EventEmitter } from '@angular/core';
import { ModalController, AlertController, LoadingController } from '@ionic/angular';

/***Interfaces***/
import { Persistable } from "../../../../app-domain/index";

/***Components***/
import {EventBuilderComponent} from "../../../../app-components/index";
import { AbstractButtonComponent } from "../abstract-button";

/***Models***/
import { UserService, UserRole } from "../../../../app-domain/user/index";
import { IEventRead, IEventData, QueueEventMethod } from "../../../../app-domain/scheduling/index";

/***Services***/
import { AnalyticsService, SchedulingService } from "../../../../app-services/index";


@Component({
	selector: 'customer-event-button',
	templateUrl: './customer-event-button.component.html',
	styleUrls: ["customer-event-button.component.scss"]
})
export class CustomerEventButton extends AbstractButtonComponent {
	private _event: IEventRead;
	private _mode: QueueEventMethod;
	

	//@Output() public eventAdded = new EventEmitter<boolean>();
	

	constructor(
		private modalCtrl: ModalController,
		public user: UserService,
		private alertCtrl: AlertController,
		private schedulingService: SchedulingService,
		private loadingCtrl: LoadingController,
		private analyticsService: AnalyticsService
	){
		super();
	}
 

	//[[Event]]
	@Input() set event(event: IEventRead){
		this._event = event;
	}
	
	get event(){
		return this._event;
	}
	
	//[[Mode]]
	@Input() set mode(mode: QueueEventMethod){
		this._mode = mode;
	}
	
	get mode(){
		return this._mode;
	}

	public async onClick(){
		if (this.mode === "Add" || this.mode === "Update"){
			let modal = await this.modalCtrl.create({
				component: EventBuilderComponent,
				componentProps: {event: this.event, mode: this.mode}
			});
			await modal.present();
			//modal.onDidDismiss().then((data) => {if (data) this.eventAdded.emit(true);});
		}else{
			let alert = await this.alertCtrl.create({
				header: "Are you sure you want to delete this event?",
				buttons: [{
					text: 'Cancel',
					role: 'cancel'
				},{
					text: 'Yes',
					handler: () => {
						this.deleteEvent();
					}
				}]
			});
			await alert.present();
		}
	}

	private async deleteEvent(){
		const loader = await this.loadingCtrl.create({
			message: "Deleting Event"
		})
		await loader.present();
		
		try{
			const response = await this.schedulingService.deleteEvent(this.event as unknown as Persistable<IEventData>);
			console.log(response);
			this.analyticsService.logEvent("ss_delete_event",{"vendorID": this.event.getVendorID()})
			loader.dismiss();
			this.alertDeleteEventResponse(response.data.msg);

		}catch(error) {
			loader.dismiss();
			const errorReponseAlert = await this.alertCtrl.create({
				header: "There was an issue with deleting this event",
				message: error.message,
				buttons: ['Ok']
			})
			await errorReponseAlert.present();
		}
	}

	/*public onClick(event: IEvent){
		this.navCtrl.push(EventDetailTemplateComponent,{'event': event});
	}*/

	private async alertDeleteEventResponse(message: string){
		const alert = await this.alertCtrl.create({
			header: message,
			buttons: [{
				"text": "OK",
				handler: () => {
					//this.eventAdded.emit(true);
				}
			}]
		})
		await alert.present();
	}

	public visible(){
		return this.user.getRole() === UserRole.CUSTOMER;
	}

}