import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { IMenu, IMenuItem, IMenuItemData, ISubMenu, MenuItem, SubMenu, IMenuTypeData, MenuTypeRepo } from "./index";
import { AppModule } from 'src/app/app.module';
import { SubscriptionManagerService } from 'src/app/app-services/subscription-manager.service';
import { Model } from '../shared-interfaces';

export class Menu implements IMenu{
	private subMenus: ISubMenu[] = [];
	private menuItems: IMenuItem[] = [];
	private menuID: string; //vendorID
	private hasDummySubMenu: boolean = false;
	private reference:  firebase.database.Reference;
	constructor(menuID?: string){
		this.menuID = menuID;
		//this.reconstituteMenu(menuData);
	}


	public getAllMenuItems(): IMenuItem[]{
		return this.getSubMenus().map((subMenu: ISubMenu) => subMenu.getAllMenuItems()).flat();
	}

	public getAvailableMenuItems(): IMenuItem[]{
		return this.getSubMenus().map((subMenu: ISubMenu) => subMenu.getAvailableMenuItems()).flat();
	}

	public getSubMenus(): ISubMenu[]{
		return this.subMenus.sort((a,b) => {
			if (a.getOrder() < b.getOrder()) return -1;
			if (a.getOrder() > b.getOrder()) return 1;
			return 0;
		});
	}

	private getNonDummySubMenus(): ISubMenu[]{
		return this.getSubMenus().filter((subMenu) => {
			return subMenu.getMenuTypeID() != "-1";
		})
	}

	public getActiveSubMenus(): ISubMenu[]{
		return this.getSubMenus().filter((subMenu: ISubMenu) => subMenu.hasActiveMenuitems() && subMenu.getMenuTypeID() != "-1");
	}

	public getMenuID(): string{
		return this.menuID;
	}

	public getSubMenu(subMenuName: string): ISubMenu{
		const subMenu: ISubMenu[] = this.getSubMenus().filter((subMenu: ISubMenu) => subMenu.getName() == subMenuName);
		if (subMenu.length > 0) {return subMenu[0];}
	}

	public getSubMenuByID(id: string): ISubMenu{
		const subMenu: ISubMenu[] = this.getSubMenus().filter((subMenu: ISubMenu) => subMenu.getMenuTypeID() == id);
		if (subMenu.length > 0) {return subMenu[0];}
	}

	public getSubMenuNames(): string[]{
		return this.getNonDummySubMenus().map((subMenu: ISubMenu) => subMenu.getName());
	}

	public getSubMenuIDs(withMenuItems?: boolean): string[]{
		return this.getSubMenus().filter((subMenu: ISubMenu) => !withMenuItems || (withMenuItems && subMenu.hasMenuItems())).map((subMenu: ISubMenu) => subMenu.getMenuTypeID());
	}

	public addSubMenus(subMenus: ISubMenu[]): void{
		subMenus.forEach((subMenu: ISubMenu) => {
			subMenu.setMenu(this);
			this.subMenus.push(subMenu);
		});
	}

	public addMenuItems(menuItems: IMenuItem[]): void{
		menuItems.forEach((menuItem: IMenuItem) => {
			this.menuItems.push(menuItem);
		});
	}
	

	/*public reconciliateMenu(){
		console.log(this);
		console.log("Reconciling Menu");
		let dummyNeeded = false;
		this.menuItems.forEach((menuItem: IMenuItem) => {
			console.log(menuItem.getMenuType());
			const subMenu: ISubMenu = this.findSubMenu(menuItem.getMenuType());
			console.log(subMenu);
			if (subMenu === undefined){
				console.log("Dummy Needed");
				dummyNeeded = true;
				console.log(this.hasDummySubMenu)
				if (!this.hasDummySubMenu){
					const dummySubMenu: ISubMenu = this.createDummySubMenu("Missing Sub Menu");
					this.addSubMenus([dummySubMenu]);
					this.hasDummySubMenu = true;
				}
				const _subMenu: ISubMenu = this.findSubMenu("Missing Sub Menu");
				console.log(_subMenu);
				_subMenu.addMenuItems([menuItem]);
			}
		})
		if (!dummyNeeded){
			if (this.findSubMenu("Missing Sub Menu")){
				this.removeSubMenu(this.findSubMenu("Missing Sub Menu"));
				this.hasDummySubMenu = false;
			}
		}
		
	}*/

	public removeMenuItem(menuItem: IMenuItem): void{
		const subMenu: ISubMenu = this.findSubMenu(menuItem.getMenuType());
		subMenu.removeMenuItem(menuItem);
	}

	private resetSubMenuItems(): void{
		this.hasDummySubMenu = false;
		this.getSubMenus().forEach((subMenu: ISubMenu, idx) => {
			subMenu.resetMenuItems();
		});
		this.getSubMenus().splice(0,this.subMenus.length);
	}


	private findSubMenu(subMenuName: string): ISubMenu{
		const subMenu: ISubMenu[] = this.getSubMenus().filter((subMenu: ISubMenu) => subMenu.getName() === subMenuName);

		if (subMenu.length > 0) {return subMenu[0];}
	}

	public removeSubMenu(subMenu: ISubMenu): void{
		this.subMenus = this.subMenus.filter((_subMenu: ISubMenu) => {return subMenu.getName() != _subMenu.getName()});
	}

	private createDummySubMenu(subMenuName: string): ISubMenu{
		const order: number = this.getSubMenus().length + 1;
		return new SubMenu("-1",{name: subMenuName,order, description: "Menu items with no specified sub menu"});
	}

	public subscribe(db: firebase.database.Reference): void{
		this.unsubscribe();
		AppModule.injector.get(SubscriptionManagerService).addSubcription(this);
		this.reference = db;
		db.on("child_added",(snapshot: firebase.database.DataSnapshot) => {
			//console.log("Sub Menu Added")
			
			if (snapshot.val()){				
				const subMenu = new SubMenu(snapshot.key,snapshot.val());
				subMenu.setMenu(this);
				console.log("MENU SET")
				const ref: firebase.database.Reference = firebase.database().ref("/menuTypes/" + this.getMenuID() +'/' + snapshot.key);
				const query = firebase.database().ref("menus/" + this.getMenuID()).orderByChild('menuType').equalTo(snapshot.val().name);
				subMenu.subscribe(ref)
				subMenu.subscribeToQuery(query);
				
				this.addSubMenus([subMenu]);
				//this.reconciliateMenu();
				
			}
		});

		db.on("child_removed",(snapshot: firebase.database.DataSnapshot) => {
			//console.log("Sub Menu Removed")
			console.log(snapshot.val());
			console.log(snapshot.key)
			if (snapshot.val()){
				const existSubMenu = this.findSubMenu(snapshot.val().name);
				if (existSubMenu){
					this.removeSubMenu(existSubMenu);
					existSubMenu.destroy();
				}
				//this.reconciliateMenu();
			}
		});
	}

	/*private reconciliateMenu(snapshot: firebase.database.DataSnapshot){
		const remoteData = snapshot.val();
		const localItemLength: number = this.getAllMenuItems().length;
		const remoteItemLength: number = Object.keys(remoteData).length;

		if (localItemLength > 0 && remoteItemLength > localItemLength) {

		Object.keys(remoteData).forEach((menuItemID: string) => {
			const exists = this.getAllMenuItems().reduce((_exists, curVal, curIdx, arr) => _exists || curVal.getItemID() === menuItemID, false);
			if (!exists) {
			console.log("ADDING");
			const newMenuItem: IMenuItem = new MenuItem(remoteData[menuItemID], menuItemID);
			const ref: firebase.database.Reference = firebase.database().ref("/menus/" + this.getMenuID() + '/' + menuItemID);
			newMenuItem.subscribe(ref);
			this.addMenuItems([newMenuItem]);
			}
		});
		} else if (remoteItemLength < localItemLength) {
		console.log("REMOVING");
		this.getAllMenuItems().forEach((menuItem: IMenuItem) => {
			const exists = Object.keys(remoteData).reduce((_exists, curVal, curIdx, arr) => _exists || curVal === menuItem.getItemID(), false);
			if (!exists) {
			console.log("SUCCESS");
			this.removeMenuItem(menuItem);
			}
		});
		} else {
			console.log("Checking for menu type change");
			this.getSubMenus().forEach(subMenu => {
				console.log(subMenu);
				subMenu.getAllMenuItems().forEach(menuItem => {
				if (menuItem.getMenuType() !== subMenu.getName()) {
					subMenu.removeMenuItem(menuItem);
					const newSubMenu = this.getSubMenu(menuItem.getMenuType());
					if (newSubMenu) newSubMenu.addMenuItems([menuItem]);
				}
				})
			})
		}
	}


	private reconciliateMenuTypes(snapshot: firebase.database.DataSnapshot){
		const menuItems: IMenuItem[] = this.getAllMenuItems();
		const subMenus: ISubMenu[] = Object.keys(snapshot.val()).map((menuTypeID: string) => new SubMenu(menuTypeID, snapshot.val()[menuTypeID]));
		console.log(subMenus);
		this.resetSubMenuItems();
		this.addSubMenus(subMenus.sort((a,b) => {
			if (a.getOrder() > b.getOrder()) {return 1;}
			if (a.getOrder() < b.getOrder()) {return -1;}
			return 0;
		}));
		this.addMenuItems(menuItems);
	}*/

	/*public shuffleMenuItems(): void{
		const menuItems: IMenuItem[] = this.getAllMenuItems();

		const subMenus: ISubMenu[] = [];
		this.getSubMenus().forEach((subMenu: ISubMenu) => {
			subMenus.push(new SubMenu(subMenu.getMenuTypeID(),subMenu.getMenuTypeData()));
		})
		this.resetSubMenuItems();
		console.log(menuItems);
		console.log(subMenus)
		this.addSubMenus(subMenus);
		this.addMenuItems(menuItems);
		console.log(this);
	}*/


	

	public destroy(){
		this.unsubscribe();
		this.subMenus.forEach(s => {
			s.destroy();
		})
	}

	public unsubscribe(): void {
		if (this.reference) this.reference.off();
	}

}
