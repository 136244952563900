import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { IParentCalendar, IEventAdapter, Ionic2CalAdapter, Ionic2CalEvent, IEventRead} from "../../../../app-domain/scheduling/index";
import { CalendarConfig, CalendarDisplayMode } from "./calendar-config.interface";
import { DateUtils } from "../../../../app-utilities/date-utility";


@Component({
	selector: 'app-calendar',
	templateUrl: 'calendar.component.html',
	styleUrls: ['calendar.component.scss']
})
export class CalendarComponent implements CalendarConfig{
	@Input() public calendar: IParentCalendar;
	@Input() public template; //pass in template that should render event details
	public currentDate: Date = new Date();
	public mode: CalendarDisplayMode = CalendarDisplayMode.MONTH;
	public eventAdapter: IEventAdapter<Ionic2CalEvent> = new Ionic2CalAdapter();
	public title: string;
	//public events: Ionic2CalEvent[] = [];
	public selectedMonth: Date;

	@ViewChild("ionCal") private ionCal: any;
	@Output() selectedEvent = new EventEmitter<IEventRead[]>();
	@Output() selectedDay = new EventEmitter<Date>();
	constructor() {}

	ngOnInit(){
		//this.events = this.calendar.getAllCnvtEvents<Ionic2CalEvent>(this.eventAdapter);
		window.setTimeout(() => {
			this.ionCal.autoSelect = false; //performance issues, turning off
		},100);
		console.log(this.calendar);
	}


	public onViewTitleChange(title: string){
		this.selectedMonth = new Date(parseInt(title.split(" ")[1]),DateUtils.monthToIdx(title.split(" ")[0]));
		//this.monthlyEventCount = this.calendar.numberOfEventsInMonth(new Date(title));
		this.title = title;
	}

	public onDaySelection(events: any): void{
		const eventIDs = events.events.map((event: Ionic2CalEvent) => event.eventID);
		this.selectedEvent.emit(this.getOriginalEvents(eventIDs));
		this.selectedDay.emit(new Date(events.selectedTime));
	}

	private getOriginalEvents(eventIDs: string[]): IEventRead[]{
		return this.calendar.findEventsByID(eventIDs);
	}

	public reloadEvents(): void{
		const el = document.getElementsByClassName("monthview-selected")[0] as HTMLElement;
		if (el) {el.click();}
	}

  public slideNext() {
    this.ionCal.slideNext();
  }

  public slideBack() {
    this.ionCal.slidePrev();
  }

}
