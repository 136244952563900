import {Injectable} from '@angular/core';
import { IUserData } from 'src/app/app-domain/user';


@Injectable()
export abstract class AnalyticsService {
	
	constructor(
		
	){}
	
	public abstract logEvent(eventName: string, eventParams?: any): void;
	
    public abstract setUserID(id: string): void;

    public abstract setUserProperties(property: string, value: string): void;
	
}
