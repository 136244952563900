import {Injectable } from '@angular/core';
import { ViewManagerService } from './view-manager.service';
import { App } from './view-manager.service';
import { InAppBrowserService, AppLauncherService } from './native-services/';
import { RemoteConfigService } from './remote-configs.service';






@Injectable()
export class MediaNavigatorService{
	
	constructor(
		private viewMgrService: ViewManagerService,
        private iabService: InAppBrowserService,
        private appLauncherService: AppLauncherService,
        private remoteConfiService: RemoteConfigService
	){}


    openFacebookURL(facebookID: string){
        const {ios, android, web} = this.remoteConfiService.getFacebookURL();
        return this.launchApp("fb://",
                            "com.facebook.katana",
                            ios + facebookID,
                            android + facebookID,
                            web + facebookID
                        )
    }

    openTwitterURL(twitterHandle: string){
        const {ios, android, web} = this.remoteConfiService.getTwitterURL();
        return this.launchApp("twitter://",
                            "com.twitter.android",
                            ios + twitterHandle,
                            android + twitterHandle,
                            web + twitterHandle
                        )
    }

    openInstagramURL(instagramHandle: string){
        const {ios, android, web} = this.remoteConfiService.getInstagramURL();
        return this.launchApp("instagram://",
                            "com.instagram.android",
                            ios + instagramHandle,
                            android + instagramHandle,
                            web + instagramHandle
                        )
    }

    openWebURL(url: string){
        return this.iabService.open(url, "_system");
    }


    private launchApp(iosApp: string, androidApp: string, appUrlIOS: string, appUrlAndroid: string, webUrl: string){
		let appScheme: string;
		let appUrl: string;
		//check platform, else open webUrl
		if (this.viewMgrService.getPlatform() === App.IOS){
			appScheme = iosApp;
			appUrl = appUrlIOS;
		/*} else if (this.platform.is('android')){
			appScheme = androidApp;
			appUrl = appUrlAndroid;*/
		}else{
			this.iabService.open(webUrl,"_system");
			return;
		}

		this.appLauncherService.canOpen(appScheme).then(() => {
			//success, the app exists and we can open italics
			console.log("Success");
			const browser = this.appLauncherService.open(appUrl);
			//console.log(JSON.stringify(browser))
		}, () => {
			//error callback, the app does not exist, open web url
			const browser = this.iabService.open(webUrl,"_system");
		})
	}

	
}

