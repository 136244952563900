import { Component, Input } from '@angular/core';
import { AlertController, ModalController } from "@ionic/angular";

import { UserService } from "../../../../app-domain/user/index";
import { VendorService, IVendor } from "../../../../app-domain/vendor/index";
import { IReview, IReviewData } from "../../../../app-domain/review/index";
import { VendorReviewResponseComponent } from "../index";
import { Persistable } from '../../../../app-domain';
import { ReviewService } from '../../../../app-services';

@Component({
	selector: 'vendor-review-item',
	templateUrl: 'vendor-review-item.component.html',
	styleUrls: ['vendor-review-item.component.scss']
})
export class VendorReviewItemComponent {
	@Input() review: IReview;
	//@Input() editable: boolean = false;
	@Input() respond: boolean = false;
	constructor(private userService: UserService,
				private vendorService: VendorService,
    private modalController: ModalController,
    private reviewService: ReviewService,
        private alertCtrl: AlertController
	) {

	}

	ngOnInit(){}

	public async onReviewResponse(responseType: string){
		const modal = await this.modalController.create({
			component: VendorReviewResponseComponent,
			componentProps: {
				review: this.review,
				responseType
			}
		});
		return await modal.present();
	}

  async onFlagReview(review: IReview) {
    const alert = await this.alertCtrl.create({
      header: "Flag Review",
      message: "Clicking OK will flag this review for StreatSmart administrators to review.",
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'OK',
          handler: data => {
            this.reviewService.flagReview(this.review as unknown as Persistable<IReviewData>, { vendorID: this.review.getVendorID() })
          }
        }
      ]
    });
   await  alert.present();
  }

}
