import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

import { Preference } from "./index";
import { Model } from '../shared-interfaces';
import { VendorService } from '../vendor';
import { AppModule } from 'src/app/app.module';
import { SubscriptionManagerService } from 'src/app/app-services/subscription-manager.service';


export class PreferenceCollection implements Model {
	private preferences: Preference[] = [];
	private vendorID: string;
	private vendorService: VendorService;
	private reference: firebase.database.Reference;
	constructor(vendorID?: string, prefs?: { [key: string]: any }) {
		if (prefs) this.setSerializedPreferences(prefs);
		this.vendorID = vendorID;
		this.vendorService = AppModule.injector.get(VendorService);
	}

	public setPreferences(unserializedPrefs: Preference[]){
		this.preferences = unserializedPrefs;
	}

	public setSerializedPreferences(serializedPrefs: {[key: string]: any}){
		this.preferences = this.deserialize(serializedPrefs);
	}

	public addSerializedPreferences(serializedPrefs: {[key: string]: any}){
		this.preferences.push(...this.deserialize(serializedPrefs));
	}

	public removeSerializedPreferences(serializedPrefs: {[key: string]: any}){
		const pref = this.deserialize(serializedPrefs);
		if (pref.length > 0){
			const idx = this.preferences.findIndex(_pref => _pref.key === this.getPrefBy(pref[0]).key);
			if (idx > -1) this.preferences.splice(idx,1)
		} 
	}

	public subscribe(db: firebase.database.Reference): void{
		this.unsubscribe();
		AppModule.injector.get(SubscriptionManagerService).addSubcription(this);
		this.reference = db;
		db.on("child_removed", (snapshot: firebase.database.DataSnapshot) => {
			this.removeSerializedPreferences({ [snapshot.key]: snapshot.val() });
		});

		db.on("child_added", (snapshot: firebase.database.DataSnapshot) => {
			this.addSerializedPreferences({ [snapshot.key]: snapshot.val() });
		});

		db.on("child_changed", (snapshot: firebase.database.DataSnapshot) => {
			this.setSerializedPreferences({ [snapshot.key]: snapshot.val() });
		});
	}

	public unsubscribe(): void {
		if (this.reference) this.reference.off();
	}

	private deserialize(prefData: { [key: string]: any }): Preference[] {
		const prefs: Preference[] = [];
		if (Object.keys(prefData).length > 0){
			Object.keys(prefData).forEach(key => {
				if (typeof prefData[key] != "object") {
					prefs.push(new Preference(prefData[key], key))
				}else{
					Object.keys(prefData[key]).forEach(subKey => {
						prefs.push(new Preference(prefData[key][subKey],key, subKey))
					})
				}
			})
			return prefs;
		}else{
			return []
		}	
	}

	private serialize(): { [key: string]: any }{
		const prefs = {};
		this.preferences.forEach(pref => {
			Object.assign(prefs, pref.serialize());
		})
		return prefs;
	}

	public getAllPrefs(): Preference[]{
		return this.preferences;
	}

	public getPrefByKeys(key: string, subKey?: string): Preference{
		const prefs = this.preferences.filter(pref => {
			return pref.key === key && (subKey === undefined || subKey === pref.subKey);
		})
		if (prefs.length > 0) return prefs[0];
		return;
	}

	public getPrefBy(pref: Preference): Preference{
		const prefs = this.preferences.filter(_pref => {
			return _pref.key === pref.key && (pref.subKey === undefined || pref.subKey === _pref.subKey);
		})
		if (prefs.length > 0) return prefs[0];
		return;
	}

	

	public setPref(pref: Preference): void{
		//const pref = this.getPrefByKeys(pref.key, pref.subKey);
		//pref.value = value;
	}

}


