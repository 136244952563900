import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'calendar-search',
	templateUrl: './calendar-search.component.html',
	styleUrls: ['calendar-search.component.scss']
})
export class CalendarSearchComponent implements OnInit {
	viewTitle: string;
	dateTime: string;
	calConfigs = {
		mode: 'month',
		currentDate: new Date()
  };
  @ViewChild("ionCal") private ionCal: any;

	constructor(
		private modalCtrl: ModalController
	){}

	ngOnInit(){
		this.calConfigs.currentDate = new Date();
	}

	onDismissView(data?: string){
		if (data) {this.modalCtrl.dismiss(new Date(data));}
		if (!data) {this.modalCtrl.dismiss();}
	}

	onTimeSelected(ev) {
		this.dateTime = ev.selectedTime;
	}

	onViewTitleChanged(title) {
		this.viewTitle = title;
	}

	onSubmitSearch(){
		this.onDismissView(this.dateTime);
  }

  public slideNext() {
    this.ionCal.slideNext();
  }

  public slideBack() {
    this.ionCal.slidePrev();
  }

}
