import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
/***Models***/
import { IPlace } from 'src/app/app-domain/place';
import { EventRepo, IParentCalendar } from 'src/app/app-domain/scheduling';



@Component({
	selector: 'venue-calendar-modal',
	templateUrl: './venue-calendar-modal.component.html',
	styleUrls: ['venue-calendar-modal.component.scss']
})
export class VenueCalendarModalComponent implements OnInit {
	@Input() venue: IPlace;
	public calendar: IParentCalendar;
	
	
	constructor(private modalCtrl: ModalController, private eventRepo: EventRepo){}
	
	async ngOnInit() {
		this.calendar = await this.eventRepo.getAllEventsByPlace(this.venue);
	}

    onDismiss(){
        this.modalCtrl.dismiss();
    }
}
