import {Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
/***Models***/
import { IEventData } from "../../../app-domain/scheduling/index";
/***Interfaces */
import { Persistable } from "../../../app-domain/index";
import { RemotePayload } from "../../../app-interfaces/index";
/***Services */
import { SchedulingService } from "./scheduling.service.abstract"
import { CalendarPayload } from './scheduling.contracts';
import { HotspotEventData } from '../../../app-domain/scheduling/hotspot-event-data.interface';


@Injectable()
export class FirebaseSchedulingService extends SchedulingService{

	constructor(){
		super();
		/*if (window.location.host.includes('localhost')){
			firebase.functions().useEmulator('http://localhost',5001);
		}*/
	}


	public addEvent(event: Persistable<IEventData>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<IEventData> = this.createPayload<IEventData>(event, dataOptions);
		console.log(payload);
		const addEvent = firebase.functions().httpsCallable("service_scheduling_addEvent");
		return addEvent(payload);
	}

	public updateEvent(event: Persistable<IEventData>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<IEventData> = this.createPayload<IEventData>(event, dataOptions);
		console.log(payload);
		const updateEvent = firebase.functions().httpsCallable("service_scheduling_updateEvent");
		return updateEvent(payload);
	}

	public deleteEvent(event: Persistable<IEventData>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<IEventData> = this.createPayload<IEventData>(event, dataOptions);
		console.log(payload);
		const deleteEvent = firebase.functions().httpsCallable("service_scheduling_deleteEvent");
		return deleteEvent(payload);
	}

	public linkExternalCalendar<T>(payload: CalendarPayload<T>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		let _payload: RemotePayload<CalendarPayload<T>> = <RemotePayload<CalendarPayload<T>>>{};
		_payload.data = payload;
		{Object.keys(dataOptions).forEach((option: string) => {_payload[option] = dataOptions[option];});}
		const linkExtCalendar = firebase.functions().httpsCallable("service_scheduling_linkExtCalendar");
		return linkExtCalendar(_payload);
	}

	public unlinkExternalCalendar(dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload = {};
		{Object.keys(dataOptions).forEach((option: string) => {payload[option] = dataOptions[option];});}
		const unlinkExtCalendar = firebase.functions().httpsCallable("service_scheduling_unlinkExtCalendar");
		return unlinkExtCalendar(payload);
  }

  public addVendorToHotSpot(eventID: string, hotspot: HotspotEventData, vendorID: string) {
    const addVendorToHotSpot = firebase.functions().httpsCallable("service_scheduling_addVendorToHotSpot");
    return addVendorToHotSpot({
      id: eventID,
      data: hotspot,
      vendorID: vendorID
    })
  }
  public removeVendorFromHotSpot(eventID: string, hotspot: HotspotEventData, vendorID: string) {
    const removeVendorFromHotSpot = firebase.functions().httpsCallable("service_scheduling_removeVendorFromHotSpot");
    return removeVendorFromHotSpot({
      id: eventID,
      data: hotspot,
      vendorID: vendorID
    })
  }


	private createPayload<T>(persistable: Persistable<T>, dataOptions?: {[key: string]: any}): RemotePayload<T>{
		const payload: RemotePayload<T> = <RemotePayload<T>>{};
		payload.id = persistable.getID();
		payload.data = persistable.getData();
		if (dataOptions) {Object.keys(dataOptions).forEach((option: string) => {payload[option] = dataOptions[option];});}
		return payload;
	}

}

