import {Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
/***Models***/
import { IUserData } from 'src/app/app-domain/user';
/***Interfaces */
import { Persistable } from "../../../app-domain/index";
import { RemotePayload } from "../../../app-interfaces/index";
/***Services */
import { RemoteUserService } from './user.service.abstract';



@Injectable()
export class FirebaseUserService extends RemoteUserService{

	constructor(){
		super();
		
	}


	public updateUser(userData: Persistable<IUserData>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<IUserData> = this.createPayload<IUserData>(userData, dataOptions);
		console.log(payload)
		const updateUser = firebase.functions().httpsCallable("service_user_updateUser");
		return updateUser(payload);
	}

	public addFavorite(userData: Persistable<IUserData>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<IUserData> = this.createPayload<IUserData>(userData, dataOptions);
		const addFavorite = firebase.functions().httpsCallable("service_user_addFavorite");
		return addFavorite(payload);
	}

	public removeFavorite(userData: Persistable<IUserData>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<IUserData> = this.createPayload<IUserData>(userData, dataOptions);
		const removeFavorite = firebase.functions().httpsCallable("service_user_removeFavorite");
		return removeFavorite(payload);
	}

	
	private createPayload<T>(persistable: Persistable<T>, dataOptions: {[key: string]: any}): RemotePayload<T>{
		const payload: RemotePayload<T> = <RemotePayload<T>>{};
		payload.id = persistable.getID();
		payload.data = persistable.getData();
		if (dataOptions && Object.keys(dataOptions).length > 0) Object.keys(dataOptions).forEach((option: string) => {payload[option] = dataOptions[option];});
		return payload;
	}

}

