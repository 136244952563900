import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { IonChip, ModalController} from '@ionic/angular';
import { IVendor, VendorList, VendorRepo } from '../../../app-domain/vendor';
import { VendorMgmtCardComponent } from './vendor-mgmt-components/vendor-mgmt-card.component';

@Component({
  templateUrl: 'vendor-management.page.html',
  styleUrls: ['vendor-management.page.scss'],
})

export class VendorManagementPage implements OnInit{
  public vendors: VendorList;
  public workingVendors: IVendor[] = [];
  public loaded: boolean = false;
  @ViewChildren(IonChip) chips: QueryList<IonChip>;
  @ViewChild("chip0") allChip: IonChip;
  vendorMgmtCardComponent = VendorMgmtCardComponent;

	constructor(
    private modalCtrl: ModalController,
    private vendorRepo: VendorRepo
	){}
	
  ngOnInit() {
    window.setTimeout(() => {
      this.allChip.outline = true;
    }, 500);
    this.loadVendors();
	}

  private async loadVendors() {
    if (this.allChip) this.filterVendors(this.allChip);
    const vendors = await this.vendorRepo.getAllVendors();
    this.vendors = new VendorList(vendors);
    const requestedVendors = await this.vendorRepo.getRequestedVendors();
    if (requestedVendors) this.vendors.add(requestedVendors);
    this.workingVendors = this.vendors.getAllVendors();
    this.loaded = true;
  }

  private activateAllVendorChip() {
    this.allChip.outline = true;
  }
  /*public async onVendorClick(vendor: IVendor) {
    console.log("HEREEEEEE")
    const modal = await this.modalCtrl.create({
      component: VendorMgmtCardComponent,
      componentProps: { vendor: vendor }
    })
		await modal.present();
    modal.onDidDismiss().then((data: any) => {
      console.log(data);
      if (data === "Updated") this.loadVendors();
		})
  }*/

  filterVendors(chip: IonChip) {
    switch (<string>chip.mode) {
      case "all":
        this.workingVendors = this.vendors.getAllVendors();
        break;
      case "active":
        this.workingVendors = this.vendors.getByOperatingStatus(2);
        break;
      case "inactive":
        this.workingVendors = this.vendors.getByOperatingStatus(5);
        break;
      case "pending":
        this.workingVendors = this.vendors.getByOperatingStatus(1);
        break;
      case "requested":
        this.workingVendors = this.vendors.getPendingReviewed();
        break;
    }

    this.chips.forEach((c) => {
      if (c.mode === chip.mode) {
        c.outline = true;
      } else {
        c.outline = false;
      }
      
    })
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }
		
}

