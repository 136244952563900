import {Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { Model, QuerySubscription } from '../app-domain';




@Injectable({
    providedIn: "root"
})
export class SubscriptionManagerService{
	public subs: (Model | QuerySubscription)[] = [];

	constructor(){

	}

    public addSubcription(sub:  Model | QuerySubscription): void{
        this.subs.push(sub);
    }
	
    public destroyAllSubs(): void{
        if (this.subs.length > 0){
            this.subs.forEach(s => {
                s.unsubscribe();
            })
            this.subs = [];
        }
       
    }

}
