import { Component, OnInit, Input, ViewChild  } from '@angular/core';
import { AlertController, IonToggle, LoadingController, ModalController } from "@ionic/angular";


import { IMenuItem,MenuItemRepo, IMenuItemData } from "../../../../../app-domain/menu/index";
import { VendorService } from "../../../../../app-domain/vendor/index";
import { Persistable } from "../../../../../app-domain/index";
import { MenuService } from "../../../../../app-services/index";
/***Pages***/
import { VendorMenuItemBuilderPage } from "../../vendor-menu-pages/vendor-menu-item-builder/vendor-menu-item-builder.page";



@Component({
  selector: 'vendor-menu-item',
  templateUrl: 'vendor-menu-item.component.html',
   styleUrls: ['vendor-menu-item.component.scss']
})

export class VendorMenuItemComponent implements OnInit{
  @Input() menuItem: IMenuItem;
  @ViewChild(IonToggle) toggle: IonToggle;

	constructor(private modalCtrl: ModalController, private menuItemRepo: MenuItemRepo, 
			private vendorService: VendorService,private menuService: MenuService,
			private loadCtrl: LoadingController, private alertCtrl: AlertController){}

	ngOnInit(){}



	public async onUpdateMenuItem(): Promise<void>{
		const modal = await this.modalCtrl.create({
			component: VendorMenuItemBuilderPage,
			componentProps: {
				menuItem: this.menuItem
			}

		});
		await modal.present();
	}



	async onDeleteMenuItem(menuItem: Persistable<IMenuItemData>){
		const alert = await this.alertCtrl.create({
			header: "Delete Menu Item",
			message: "Are you sure you want to delete this menu item? This operation cannot be undone.",
			buttons: [
			{
				text: "Delete",
				role: 'confirm',
				handler: () => {
					this.deleteMenuItem(menuItem);
				}
			},{
				text: "Cancel",
				role: 'cancel'
			}	
			]
			
		})
		await alert.present();
	}

	public async deleteMenuItem(menuItem: Persistable<IMenuItemData>){
		const loader = await this.loadCtrl.create({
			message: "Deleting menu item"
		});
		await loader.present();
		try{
			await this.menuItemRepo.delete(menuItem, {vendorID: this.vendorService.getVendorFocus().getVendorID()});
			loader.dismiss();
		}catch(error){
			loader.dismiss();
			const alert = await this.alertCtrl.create({
				header: "Unable to delete menu item",
				message: "If this issue continues, please contact StreatSmart",
				buttons: ["Ok"]
			})
			await alert.present();
		}
		
	}

  public onToggleMenuItem(value?: boolean) {
    if (value != undefined) {
      if (value != this.menuItem.getActiveInd()) {
        this.toggle.checked = value;
        const persistable: Persistable<IMenuItemData> = this.menuItem as unknown as Persistable<IMenuItemData>;
        this.menuService.toggleMenuItem(persistable, { vendorID: this.vendorService.getVendorFocus().getVendorID() });

      }
    } else {
      const persistable: Persistable<IMenuItemData> = this.menuItem as unknown as Persistable<IMenuItemData>;
      this.menuService.toggleMenuItem(persistable, { vendorID: this.vendorService.getVendorFocus().getVendorID() });
    }
   	
	}


}

