import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';

import { IVendor, VendorService } from "../../../../../app-domain/vendor/index";
import {  IMenuItem, IMenuItemData } from "../../../../../app-domain/menu/index";
import { Persistable } from "../../../../../app-domain/index";
import { MenuService } from "../../../../../app-services/index";
import { VendorMenuItemComponent } from '../vendor-menu-item/vendor-menu-item.component';
import { AlertController, LoadingController } from '@ionic/angular';

@Component({
	selector: 'vendor-sub-menu',
	templateUrl: 'vendor-submenu.component.html',
	styleUrls: ['vendor-submenu.component.scss']
})

export class VendorSubMenuComponent implements OnInit{
	@Input() subMenuID: string;
	private vendor: IVendor;
	@ViewChildren("menuItem") vendorMenuItems!: QueryList<VendorMenuItemComponent>;
	constructor(private vendorService: VendorService, private menuService: MenuService,
		private alertCtrl: AlertController, private loadCtrl: LoadingController){}

	ngOnInit(){
		this.vendor = this.vendorService.getVendorFocus();
	}

	public reorder(event: CustomEvent): void{
    console.log(event);

		const subMenu: Persistable<{[key: string]: IMenuItemData}> = this.createPersistable();

		this.menuService.reorderSubMenu(subMenu,{value: {to: event.detail.to,from: event.detail.from}});
		event.detail.complete();
	}


  public onToggleSubMenu(event: boolean) {
		this.vendorMenuItems.forEach((menuItemComponent: VendorMenuItemComponent) => {
			menuItemComponent.onToggleMenuItem(event);
		})
		//const subMenu: Persistable<{[key: string]: IMenuItemData}> = this.createPersistable();
		//console.log(subMenu);
		//this.menuService.toggleSubMenu(subMenu,{value: event});
	}

	private createPersistable(): Persistable<{[key: string]: IMenuItemData}>{
		const subMenuData = <any>{
			id: this.vendor.getVendorID(),
			data: {}
		}

		//const subMenuData: Persistable<{[key: string]: IMenuItemData}> = <Persistable<{[key: string]: IMenuItemData}>>{};
		//subMenuData.id = this.vendor.getVendorID();
		//subMenuData.data = {};

		this.vendor.getMenu().getSubMenuByID(this.subMenuID).getAllMenuItems().forEach((menuItem: IMenuItem) => {
			subMenuData.data[menuItem.getItemID()] = menuItem.getMenuData();
		});
		subMenuData.getData = function(){
			return this.data;
		};

		subMenuData.getID = function(){
			return this.id;
		};
		return subMenuData;
	}

	public async onDeleteSubMenu(){
		const alert = await this.alertCtrl.create({
			header: "Delete Submenu",
			message: "Are you sure you want to delete this submenu? All menu items within this submenu will be deleted. This operation cannot be undone.",
			buttons: [
			{
				text: "Delete",
				role: 'confirm',
				handler: () => {
					this.deleteSubMenu();
				}
			},{
				text: "Cancel",
				role: 'cancel'
			}	
			]
			
		})
		await alert.present();
	}

	private async deleteSubMenu(){
		const loader = await this.loadCtrl.create({
			message: "Deleting Submenu"
		})
		await loader.present();
		try{
			const subMenu: Persistable<{[key: string]: IMenuItemData}> = this.createPersistable();
			console.log(subMenu);
			this.menuService.deleteSubMenu(subMenu);
			loader.dismiss();
		}catch(error){
			loader.dismiss();
			const alert = await this.alertCtrl.create({
				header: "Unable to delete submenu",
				message: "If this issue continues, please contact StreatSmart",
				buttons: ["Ok"]
			})
			await alert.present();
		}
	}
}

