import { Ionic2CalEvent, IEventAdapter, IEventRead } from "./index";


export class Ionic2CalAdapter implements IEventAdapter<Ionic2CalEvent>{

	public convert(events: IEventRead[]): Ionic2CalEvent[]{
		if (events){
			return events.map((event: IEventRead) => ({
					title: event.getTitle(),
					startTime: new Date(event.getStartTime()),
					endTime: new Date(event.getEndTime()),
					eventID: event.getEventID()
				}));
		}else{
			return [];
		}
	}
}
