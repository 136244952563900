import { Pipe, PipeTransform } from '@angular/core';


@Pipe({name: 'phoneFormat'})
export class PhoneFormatterPipe implements PipeTransform {
    transform(value, args?: string[]): string {
        if (value){
            if (value.length > 10){
                value = this.parseOutCountryCode(value);
            }

            const areaCodeStr = value.slice(0,3);
            const midSectionStr = value.slice(3,6);
            const lastSectionStr = value.slice(6);

            if (areaCodeStr.length && midSectionStr && lastSectionStr){
                 return `(${areaCodeStr}) ${midSectionStr} - ${lastSectionStr}`;
             }else if(areaCodeStr && midSectionStr){
                 return `(${areaCodeStr}) ${midSectionStr}`;
             }else if(areaCodeStr){
                 return `${areaCodeStr}`;
             }

        }

    }

    private parseOutCountryCode(number: any): string{
        console.log(number);
        return number.toString().slice(-10);
    }
}
