import { Component, OnInit } from '@angular/core';
import { NavParams,ModalController} from '@ionic/angular';
import { CuisineService } from '../../../../app-domain/admin';
import { Cuisine } from '../../../../app-domain/vendor';
/***Services***/

/***Models***/

import { AdminService } from '../../../../app-services';

@Component({
  selector: 'page-edit-cuisine',
  templateUrl: 'edit-cuisine.page.html',
})
export class EditCuisinePage implements OnInit {
  public cuisine: Cuisine;
	public mode: string;

	public cuisineFamily = ['Regional/Ethnic','Religious','Specialty','Style']

	constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private cuisineService: CuisineService
		) {}
	
	ngOnInit(){
		this.mode = this.navParams.get("mode");

		if (this.mode === "Edit"){
			this.cuisine = this.navParams.get("cuisine");
		}else{
      		this.cuisine = new Cuisine();
		}
	}	

  	public onDismiss(dataInd?: boolean) {
    	this.modalCtrl.dismiss(dataInd);
	}

  public onSubmitCuisine() {
    return this.cuisineService.updateCuisineOption(this.cuisine)
		.then(() => this.onDismiss(true));
	}
}
