import {Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

/***Interfaces */
import { Persistable } from "../../../app-domain/index";
import { IVendorData } from '../../../app-domain/vendor';
import { RemotePayload, RemoteResponse } from "../../../app-interfaces/index";
/***Services */
import { PermissionsService } from './permissions.service.abstract';



@Injectable()
export class FirebasePermissionsService extends PermissionsService{

	constructor(){
		super();
		/*if (window.location.host.includes('localhost')){
			firebase.functions().useFunctionsEmulator('http://localhost:5001');
		}*/
	}


  public grantPermissions(vendor: Persistable<IVendorData>, dataOptions?: { [key: string]: any }): Promise<RemoteResponse>{
    const payload: RemotePayload<IVendorData> = this.createPayload<IVendorData>(vendor, dataOptions);
		console.log(payload);
    const grantPermissions = firebase.functions().httpsCallable("service_permissions_grantPermissions");
    return grantPermissions(payload);
	}

  public removePermissions(vendor: Persistable<IVendorData>, dataOptions?: { [key: string]: any }): Promise<RemoteResponse>{
    const payload: RemotePayload<IVendorData> = this.createPayload<IVendorData>(vendor, dataOptions);
		console.log(payload);
    const remotePermissions = firebase.functions().httpsCallable("service_permissions_removePermissions");
    return remotePermissions(payload);
  }

  public getPermissions(vendor: Persistable<IVendorData>, dataOptions?: { [key: string]: any }): Promise<RemoteResponse> {
    const payload: RemotePayload<IVendorData> = this.createPayload<IVendorData>(vendor, dataOptions);
    console.log(payload);
    const grantPermissions = firebase.functions().httpsCallable("service_permissions_getPermissions");
    return grantPermissions(payload);
  }

  private createPayload<T>(persistable: Persistable<T>, dataOptions: { [key: string]: any }): RemotePayload<T> {
    const payload: RemotePayload<T> = <RemotePayload<T>>{};
    payload.id = persistable.getID();
    payload.data = persistable.getData();
    if (dataOptions && Object.keys(dataOptions).length > 0) Object.keys(dataOptions).forEach((option: string) => { payload[option] = dataOptions[option]; });
    return payload;
  }

}

