import {Injectable } from '@angular/core';
/***Firebase***/
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import { UserService } from './user.service';

export enum UserPrefs {
    GEOFENCE = "geofence",
    FCM = "fcm"
}

export interface UserPrefsFCM {
    notificationTokenUpdated: boolean
}


@Injectable()
export class UserPrefRepo{


	constructor(private userService: UserService){}

    public async getPrefByKey<T>(userPref: UserPrefs): Promise<T>{
        const snapshot = await firebase.database().ref("userPrefs/" + this.userService.getID() + "/" + userPref).once('value');
        return snapshot.val();
    }

	public updatePref<T>(userPref: UserPrefs, value: T){
        return firebase.database().ref("userPrefs/" + this.userService.getID() + "/" + userPref).set(value);
    }







}
