import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { Model, Image } from "../../app-domain/index";
import {Cuisine, IEasyEats, IICal, IGoogle, IFacebook} from "./index";
import { IReviewList } from "../review/index";
import { IParentCalendar } from "../scheduling/index";
import { IMenu } from "../menu/index";
import { Preference, PreferenceCollection } from '../preference';

export interface IVendor extends Model{
	getVendorID(): string;
	getDetail(detail: string): any;

	getAdmin(): string[];
	getAdminID(): string;

	getAffiliation(): string[];

	getAvatarImgUrl(): string;
	getAvatarImgSrc(): string;
	getBackgroundImgUrl(): string;
	getBackgroundImgSrc(): string;
	setAvatarImgUrl(url: string): void;
	setBackgroundImgUrl(url: string): void;
	setBackgroundImg(img: Image): void;
	setAvatarImg(img: Image): void;

	getCreatedDate(): number;

	getDescription(): string;
	setDescription(desc: string): void;

	hasEasyEats(): boolean;
	getEasyEatsID(): string;
	resetEasyEats(): void;

	hasEmail(): boolean;
	getEmail(): string;
	setEmail(email: string): void;

	hasFacebook(): boolean;
	getFacebookName(): string;
	getFacebookID(): string;
	resetFacebook(): void;

	getCuisine(): any[];
	setCuisine(cuisine: Cuisine[]): void;

	hasGoogleCalendar(): boolean;
	getGoogleCalName(): string;
	resetGoogle(): void;

	getICalURL(): string;
	hasICal(): boolean;
	setICal(url: string): void;

	hasInstagram(): boolean;
	getInstagramHandle(): string;
	setInstagramHandle(handle: string): void;

	getState(): string;

	getName(): string;

	hasPhone(): boolean;
	getPhoneNumber(): string;

	getRating(): number;

	isReviewed(): boolean;

	getStatusFlag(): number;
	setStatusFlag(status: number): void;
	getStatusMeaning(): string;

	getStreatCred(): number;
	getStreatCredPercentile(): string
	
	hasTwitter(): boolean;
	getTwitterHandle(): string;
	setTwitterHandle(handle: string): void;

	hasWebsite(): boolean;
	getWebsiteURL(): string;
	setWebsiteURL(url: string): void;

	getReviewList(): IReviewList;
	setReviewList(reviewList: IReviewList): void;
	getFavoriteCount(): number;
  	getReviewCount(): number;
  	getRatingCount(): number;

	setCalendar(calendar: IParentCalendar): void;
	getCalendar(): IParentCalendar;
	getMenu(): IMenu;
	setMenu(menu: IMenu): void;
	setPrefs(prefs: {[key: string]: any}): void;
	getPrefCollection(): PreferenceCollection;
	getAllPrefs(): Preference[];
	getPrefByKeys(key: string, subKey?: string): Preference
	setPref(value: any, key: string, subKey?: string): void
}
