import { UserRole } from "../user";

export enum FeatureRequestStatus{
    REQUESTED = 1,
    APPROVED = 2,
    COMPLETE = 3,
    DENIED = 4
}

export interface FeatureRequestData{
    requestorUserID: string,
    status: FeatureRequestStatus,
    userRole: UserRole,
    description: string,
    created: number,
    votes: {[userID: string]: boolean},
    adminResponse: string
}
