import {Injectable } from '@angular/core';
import { IVendor, VendorService } from '../app-domain/vendor';


export interface ProfileProgress {
  "activated": boolean,
  "completed": boolean,
  "bioStatus": ProgressStatus,
  "imgStatus": ProgressStatus,
  "menuStatus": ProgressStatus,
  "calendarStatus": ProgressStatus
}

export interface ProgressStatus {
  status: "success" | "partial" | undefined,
  missing?: string
}


@Injectable()
export class VendorProfileProgressService{
  
	constructor(private vendorService: VendorService){}

	evaluate(): ProfileProgress{
    const bioStatus = this.getProfileInfoStatus(this.vendorService.getVendorFocus());
    const imgStatus = this.getProfileImgStatus(this.vendorService.getVendorFocus());
    const menuStatus = this.getMenuStatus(this.vendorService.getVendorFocus());
    const calendarStatus = this.getCalendarStatus(this.vendorService.getVendorFocus());
    var activated: boolean;
    var completed: boolean;
    if (bioStatus?.status === "success" && imgStatus?.status === "success" && menuStatus?.status === "success") activated = true;
    if (activated && calendarStatus?.status === "success") completed = true;



    return {bioStatus, imgStatus, menuStatus, calendarStatus, completed, activated}
  }

  private getProfileInfoStatus(vendor: IVendor): ProgressStatus {
    const hasBio = vendor.getDescription() ? true : false;
    const hasCuisine = vendor.getCuisine() ? true : false;

    if (hasBio && hasCuisine) {
      return { status: "success" };
    } else if (hasBio) {
      return { status: "partial", missing: "cuisine" };
    } else if (hasCuisine) {
      return { status: "partial", missing: "bio" };
    } else {
      return { status: undefined };
    }
  }


  private getProfileImgStatus(vendor: IVendor): ProgressStatus {
    const hasAvatar = vendor.getAvatarImgSrc() != 'default/icon.png' ? true : false;
    const hasBackground = vendor.getBackgroundImgSrc() != 'default/icon.png' ? true : false;
    if (hasAvatar && hasBackground) {
      return { status: "success" };
    } else if (hasAvatar) {
      return { status: "partial", missing: "background" };
    } else if (hasBackground) {
      return { status: "partial", missing: 'avatar' };
    } else {
      return { status: undefined };
    }
  }

  private getMenuStatus(vendor: IVendor): ProgressStatus {
    const menu = vendor.getMenu();
    if (menu) return menu.getAllMenuItems().length > 0 ? { status: "success" } : { status: undefined };
  }

  private getCalendarStatus(vendor: IVendor): ProgressStatus {
    const calendar = vendor.getCalendar();
    if (calendar) return calendar.getAllEvents().length > 0 ? { status: "success" } : { status: undefined };

  }
	
}

