import { Component } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { Router } from "@angular/router";
import { UserService } from "../../../app-domain/user/index";
import { VendorService, IVendor } from "../../../app-domain/vendor/index";
import { IReviewList, IReview } from "../../../app-domain/review/index";
import { IToggleable } from "../../../app-domain/index";


@Component({
	selector: 'vendor-reviews',
	templateUrl: 'vendor-reviews.page.html',
	styleUrls: ['vendor-reviews.page.scss']
})
export class VendorReviewsPage implements IToggleable<number, void>{
	private vendor: IVendor;
	public reviewList: IReviewList;
	public userReview: IReview;
	public selectedRating: number = 0;

	constructor(private userService: UserService,
				private vendorService: VendorService,
				private router: Router,
				private modalCtrl: ModalController

	) {}

	//write guards for vendor vs user looking at vendorservice
	ngOnInit(){

		this.vendor = this.vendorService.getVendorFocus();
		this.reviewList = this.vendor.getReviewList();
		this.userReview = this.reviewList.getReviewByUserID(this.userService.getID());
	}

	public toggle(input: number): void{
		if (input && input >= 0 && input != this.selectedRating){
			this.selectedRating = input;
		}
	}

	public onDismiss(): void{
		this.modalCtrl.dismiss();
	}






}
