export interface CalendarPayload<T>{
    calendar: T,
    serverAuthCode?: string,
    accessToken?: string
    refreshToken?: string;
}

export enum CalendarType{
    GOOGLE = "GOOGLE",
    ICAL = "ICAL",
    FACEBOOK = "FACEBOOK"
}
