/***Firebase***/
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import {Injectable } from '@angular/core';
import { Geolocation, Position } from "@capacitor/geolocation";
import { ILocation, Location } from "../app-domain/location/index";
import { LocalStorageService } from './native-services';
import { GeofenceEvent, GeofencePayload, GeofenceUserPref } from '../app-domain/location/geofence.contracts';
import { GEOFENCE_STORAGE_KEY, USER_ID } from './native-services/local-storage-services/local-storage.constants';
import { EventRepo, IEventData } from '../app-domain/scheduling';
import { UserPrefRepo, UserPrefs, UserService } from '../app-domain/user';
import { Capacitor } from '@capacitor/core';


@Injectable({
    providedIn: "root"
})
export class GeofenceService{

	constructor(private localStorageService: LocalStorageService, private eventRepo: EventRepo, private userPrefRepo: UserPrefRepo,
        private userService: UserService
        ){}

    public async getGeofencePref(){
        const geofenceUserPref = await this.userPrefRepo.getPrefByKey<GeofenceUserPref>(UserPrefs.GEOFENCE);
        return geofenceUserPref;
    }
	
    public async updateGeofencesOnLocalDevice(){
       
        
        let updatedGeofences: GeofencePayload = <GeofencePayload>{};
        let localGeofences = await this.getLocalGeofences();

        if (!localGeofences || (localGeofences.events && localGeofences.events.length === 0) || (localGeofences.expiresAt === undefined || localGeofences.expiresAt === null) || localGeofences.expiresAt < new Date().getTime()){
            updatedGeofences = await this.createGeofencePayload();
        }
        


        //console.log("Local:" + JSON.stringify(localGeofences));
        //console.log("REMOTE: " + JSON.stringify(remoteGeofences));
        //console.log("REmote events: " + JSON.stringify(remoteGeofences.events));
        let storableGeofences: GeofencePayload = <GeofencePayload>{};
        if (updatedGeofences && updatedGeofences.events){
            if (localGeofences && localGeofences.events){
                storableGeofences.events = updatedGeofences.events ? this.reconciliateLocalGeofences(localGeofences.events, updatedGeofences.events) : [];
                storableGeofences.radius = updatedGeofences.radius;
                storableGeofences.userID = updatedGeofences.userID;
                storableGeofences.expiresAt = updatedGeofences.expiresAt;
                 //console.log("Storable:" + storableGeofences);
                firebase.database().ref("/test").push(Object.assign({},{"device": Capacitor.getPlatform(), type: "Storing and reconciling geofences", timestamp: new Date()}));
                await this.localStorageService.set(GEOFENCE_STORAGE_KEY, JSON.stringify(storableGeofences));
            }else{
                storableGeofences = updatedGeofences;
                firebase.database().ref("/test").push(Object.assign({},{"device": Capacitor.getPlatform(), type: "Storing updated geofences", timestamp: new Date()}));
                 //console.log("Storable:" + storableGeofences);
                await this.localStorageService.set(GEOFENCE_STORAGE_KEY, JSON.stringify(storableGeofences));
            }
        }else{
            firebase.database().ref("/test").push(Object.assign({},{"device": Capacitor.getPlatform(), type: "Not updating geofences", timestamp: new Date()}));
            console.log("Not updating geofences");
        }
        //console.log(storableGeofences);
    }


    private reconciliateLocalGeofences(localGeofenceEvents: GeofenceEvent[], updatedGeofenceEvents: GeofenceEvent[]): GeofenceEvent[]{
        const storableEvents = updatedGeofenceEvents.map(updatedEvent => {
            localGeofenceEvents.forEach(localEvent => {
                if (updatedEvent.id === localEvent.id){
                    if (localEvent.notified){
                        updatedEvent = localEvent //do not want to alert more than once, keeps the "notified indicator"
                    }
                }
            })
            return updatedEvent;
        })
        return storableEvents;
    }

    public async getLocalGeofences(): Promise<GeofencePayload>{
        return <GeofencePayload>JSON.parse(await this.localStorageService.get(GEOFENCE_STORAGE_KEY));
    }

    public async resetGeofences(): Promise<GeofencePayload>{
        const localFences = await this.getLocalGeofences();
        const resetFences = await Promise.all(localFences.events.map(async fence => {
            if (fence.notified){
                await firebase.database().ref("geofence/" + this.userService.getID() + "/" + fence.id).remove();
                fence.notified = false;
            }
            return fence;
        }));
        localFences.events = resetFences;
        await this.localStorageService.set(GEOFENCE_STORAGE_KEY, JSON.stringify(localFences));
        return this.getLocalGeofences();
    }

    public async getActiveLocalGeofences(): Promise<GeofencePayload>{
        const allLocalGeofences = await this.getLocalGeofences();
        //firebase.database().ref("/test").push(Object.assign({},{"data": JSON.stringify(allLocalGeofences), "device": Capacitor.getPlatform(),type: "All Local Geofences"}));
    
        const activeGeofences = allLocalGeofences.events.filter(event => {
            return !event.notified && event.startDtTm < new Date().getTime() && event.endDtTm > new Date().getTime();
        })
        return {
            userID: allLocalGeofences.userID,
            radius: allLocalGeofences.radius,
            events: activeGeofences,
            expiresAt: allLocalGeofences.expiresAt
        }
    }

    public async evaluateLocationAgainstGeofences(){
        try{
            const { coords } = await Geolocation.getCurrentPosition();
            const curLocation = new Location(coords.latitude, coords.longitude);
            await this.updateGeofencesOnLocalDevice();
            
            const localGeofences = await this.getActiveLocalGeofences();
            //firebase.database().ref("/test").push(Object.assign({},{"data": JSON.stringify(localGeofences), "device": Capacitor.getPlatform(),type: "Geofences"}));
    
            localGeofences.events.forEach(async event => {
                const geofenceCenter = new Location(event.latitude, event.longitude);
                console.log(parseFloat(curLocation.calcDistanceFrom(geofenceCenter)))
                //firebase.database().ref("/test").push(Object.assign({},{"data": parseFloat(curLocation.calcDistanceFrom(geofenceCenter)), "device": Capacitor.getPlatform(),type: "Distance"}));
    
                if (parseFloat(curLocation.calcDistanceFrom(geofenceCenter)) <= localGeofences.radius){
                    //firebase.database().ref("/test").push(Object.assign({},{"data": localGeofences.radius, "device": Capacitor.getPlatform(),type: "Within Radius"}));
    
    
                    const curEvent = await this.eventRepo.getByID(event.id);
                    if (curEvent.getEventData() && curEvent.getStartTime() < new Date().getTime() && curEvent.getEndTime() > new Date().getTime()){
                        //window.alert("WOuld trigger notification for " + event.vendorName);
                        //console.log(this.userService?.getID())
                        //firebase.database().ref("/test").push(Object.assign({},{"data": event.id, "device": Capacitor.getPlatform(),type: "Vendor Open"}));
    
                        await this.sendGeofenceToServer(localGeofences.userID, event)
                    }
                    
                }
            })
        }catch(error){
            console.log(error.message);
            firebase.database().ref("/test").push(Object.assign({},{"error": error.message, "device": Capacitor.getPlatform(),type: "Error"}));
        }
        
    }

    /***Send geofence to server to alert user***/
	private async sendGeofenceToServer(userId: string, geofence: GeofenceEvent){
        firebase.database().ref("/test").push(Object.assign({},{"data": geofence, "device": Capacitor.getPlatform(),type: "Send to Server"}));
				
		return firebase.database().ref("/geofence/" + userId + "/" + geofence.id).set(geofence)
			.then(() => {
                firebase.database().ref("/test").push(Object.assign({},{"data": geofence, "device": Capacitor.getPlatform(),type: "Sent to Server"}));
				return this.markGeofenceAsNotified(geofence);
			})
			.catch((error) => {
                console.log(error.message);
                firebase.database().ref("/test").push(Object.assign({},{"data": geofence, "device": Capacitor.getPlatform(),type: "Error to Server"}));
		
            });
	}

    private async markGeofenceAsNotified(geofence: GeofenceEvent){
        const localGeofences = await this.getLocalGeofences();
        const updatedGeofenceEvents = localGeofences.events.map(event => {
            if (event.id === geofence.id) event.notified = true;
            return event;
        })
        localGeofences.events = updatedGeofenceEvents;
        return this.localStorageService.set(GEOFENCE_STORAGE_KEY, JSON.stringify(localGeofences));
    }

    
    /***This code will likely run when app is either closed or in the background, cannot rely on 
     * services to be instantiated correctly - have seen errors show up with undefined services. Therefore
     * we will utilize a saved userid in local storage and rely only on firebase sdk and not application services.
     */
    
    private async getGeofenceEvents(userID: string): Promise<GeofenceEvent[]>{
        const begDate = new Date();
        begDate.setHours(0,0,0,0);
        const endDate = new Date();
        endDate.setHours(23,59,59,999);

        //Get users favorite vendors
        const favoritesSnapshot = await firebase.database().ref("users/" + userID + "/favorites").once('value');
        const favorites: {[vendorID: string]: boolean} = favoritesSnapshot.val() ? favoritesSnapshot.val() : [];
        console.log(favorites);

        //Get vendors open today
        const ref = firebase.database().ref("/events");
        const events: {[key: string]: IEventData}  = (await (ref.orderByChild('endTime').startAt(begDate.getTime()).endAt(endDate.getTime()).once('value'))).val();
        console.log(events);
        //Create GeofenceEvents object
        const eventsOutput: GeofenceEvent[] = [];
        if (events){
            await Promise.all(Object.keys(events).map(async (eventID) => {
                const event = events[eventID];

                if (Object.keys(favorites).indexOf(event.vendorId) > -1){ //Vendor is user favorite
                    const eventOutput: GeofenceEvent = <GeofenceEvent>{};
                    eventOutput["id"] = eventID;
                    eventOutput["latitude"] = event.place.loc.lat;
                    eventOutput["longitude"] = event.place.loc.lng;
                    const {name} = (await firebase.database().ref("vendors/" + event.vendorId).once('value')).val();
                    eventOutput["vendorName"] = name;
                    eventOutput["startDtTm"] = event.startTime;
                    eventOutput["endDtTm"] = event.endTime;
                    eventsOutput.push(eventOutput);
                }
            }))
        }
        return eventsOutput;
    }

    private async getGeofencePrefs(): Promise<GeofenceUserPref>{
        const prefs: GeofenceUserPref = (await firebase.database().ref("userPrefs/" + this.userService.getID() + "/" + UserPrefs.GEOFENCE).once('value')).val();
        return prefs;
    }

    private async createGeofencePayload(): Promise<GeofencePayload>{
        const userID = await this.localStorageService.get(USER_ID);
        const events = await this.getGeofenceEvents(userID);
        const prefs = await this.getGeofencePrefs();
        return {
            userID: userID,
            radius: prefs.radius,
            events: events ? events : [],
            expiresAt: new Date(new Date().getTime() + 30 * 60 * 1000).getTime()
        }
    }
}
