import { IEventData, IEventRead } from "./index";

export enum QueueEventStatus {
	QUEUED = 1,
	CONFIRMED,
	REJECTED
}

export enum QueueEventMethod {
	ADD = "Add",
	UPDATE = "Update",
	DELETE = "Delete"
}

export type QueuedEventData = {
  method: QueueEventMethod,
  data: IEventData,
	status: QueueEventStatus,
	updtId: string,
	eventId: string
}

export type QueuedEvent = {
	queueID: string,
	event: QueuedEventData
}
