/***Firebase***/
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import { Injectable } from '@angular/core';
import { Preference, PreferenceCollection } from "./index";


@Injectable()
export class VendorPreferenceRepo{

  constructor() {
   
  }

  async getByID(id: string, id2?: string): Promise<PreferenceCollection> {
    const ref: firebase.database.Reference = firebase.database().ref("/vendorPrefs/" + id);
    const snapshot: firebase.database.DataSnapshot = await ref.once('value');
    const prefData: {[key: string]: any} = snapshot.val();
    const preferenceCollection = new PreferenceCollection(id, prefData);
    return preferenceCollection;
  }

  async getByIDAndSubscribe(id: string, id2?: string): Promise<PreferenceCollection> {
    const ref: firebase.database.Reference = firebase.database().ref("/vendorPrefs/" + id);
    const snapshot: firebase.database.DataSnapshot = await ref.once('value');
    const prefData: {[key: string]: any} = snapshot.val();
    const preferenceCollection = new PreferenceCollection();
    preferenceCollection.subscribe(ref);
    return preferenceCollection;
  }

  async update(vendorID: string, preference: Preference): Promise<any> {
    await firebase.database().ref("vendorPrefs/" + vendorID + "/" + preference.key).set({[preference.subKey]: preference.value});
  }

  async delete(vendorID: string, preference: Preference): Promise<any> {
    await firebase.database().ref("vendorPrefs/" + vendorID + "/" + preference.key).remove();
  }

  add(data:any, miscData?: { [key: string]: any }): Promise<any> {
    throw new Error("Not implemented");
  }

}
