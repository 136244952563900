import {Injectable } from '@angular/core';
import { EmailComposerService } from './email-composer.service.abstract.';
import { EmailComposer } from "@awesome-cordova-plugins/email-composer";


@Injectable()
export class CordovaEmailComposerService extends EmailComposerService{

	constructor(
	
	){
		super();
	}

	public isAvailable(): Promise<boolean> {
		return EmailComposer.isAvailable();
	}

	public async open(to: string, body: string): Promise<void> {
		try{
			const isAvail = await this.isAvailable();
			console.log(isAvail);
			return EmailComposer.open({to: to, body: body});
		}catch(error){
			console.log(error.message);
		}
		
	}



   
}
 