import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { User, IUserData, UserRole } from "./index";


export class GuestUser extends User{

	constructor(userID: string, userData: IUserData, permissions?: Map<string,boolean>){
		super(userID, userData, permissions);
		this.role = UserRole.GUEST;
        this.userData = <IUserData>{};
        this.setLastName("Guest");
        this.setFirstName("Guest");
        this.setEmail("Guest");
	}

	public getRoot(): string{
		return "/user";
	}

	public loadDependencies(): void{
		console.log("No Dependencies");
	}

    public subscribe(db: firebase.database.Reference): void{
		console.log("Nothing");
	}

}
