import { Component, OnInit} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CuisineService } from '../../../app-domain/admin';
import { Cuisine, VendorCuisineOptions } from '../../../app-domain/vendor';

import { AdminService } from '../../../app-services';
/***Services***/

/***Pages***/
import { EditCuisinePage } from "./edit-cuisine/edit-cuisine.page";
/***Models***/

@Component({
  selector: 'page-cuisine',
  templateUrl: 'cuisine.page.html',
})
export class CuisinePage implements OnInit{
  public cuisine: VendorCuisineOptions;
  public dataLoaded: boolean = false;
  public cuisineFamily = ['Regional/Ethnic', 'Religious', 'Specialty', 'Style']
	constructor(
    private modalCtrl: ModalController,
    private adminService: AdminService,
    private cuisineService: CuisineService
	){}

	ngOnInit(){
		return this.getAllCuisineItems();
	}
	
  public async getAllCuisineItems() {
    this.cuisine = await this.cuisineService.getCuisineOptions(true);
	}

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  public onDeleteCuisineItem(event: Cuisine): Promise<any>{
    return this.cuisineService.deleteCuisineOption(event)
			.then(() => {return this.getAllCuisineItems();})
	}
	
	public async addCuisine(){
    let modal = await this.modalCtrl.create({
      component: EditCuisinePage
    })
		await modal.present();
		modal.onDidDismiss().then((data)=> {if (data) this.getAllCuisineItems();});
	}

  public async updateCuisine(event: Cuisine) {
    const modal = await this.modalCtrl.create({
      component: EditCuisinePage,
      componentProps: { mode: 'Edit', cuisine: event }
    })
		await modal.present();
		modal.onDidDismiss().then((data)=> {if (data) this.getAllCuisineItems();});
	}
	
}
