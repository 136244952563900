import {Injectable } from '@angular/core';

/***Domain***/
import { UserService } from "../../app-domain/user/index";

/***Environment Variables***/
import { environment } from "../../../environments/environment";
import { NotificationHandlerService } from './notification-handler.service';


@Injectable()
export abstract class NotificationService{

	constructor(
		protected userService: UserService, protected notificationHandler: NotificationHandlerService
	){

	}

	public abstract registerNotificationToken(token?: string): Promise<any>;

    public abstract subscribeToTopic(topic: string): Promise<any>;

	public abstract getAllSubscribedTopics(): Promise<any>;
	
	public abstract unsubscribeFromTopic(topic: string): Promise<any>;
}
 