import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NavParams, PopoverController } from "@ionic/angular";

export interface SortPopoverOption{
	display: string,
	active: boolean
}

@Component({
  	selector: 'sort-popover',
  	templateUrl: 'sort-popover.component.html',
  	styleUrls: ['sort-popover.component.scss']
})
export class SortPopoverComponent {
	private _sortOptions: SortPopoverOption[] = [];
  	@Input() set sortOptions(options: SortPopoverOption[]){
    	this._sortOptions = options;
  	}

  	get sortOptions(): SortPopoverOption[]{
  		return this._sortOptions;
  	}

  	@Output() selection = new EventEmitter<SortPopoverOption>();

  	constructor(private navParams: NavParams, private popoverCtrl: PopoverController){}

  	ngOnInit(){
  		if (this.navParams.get('sortOptions')){
  			this.sortOptions = this.navParams.get('sortOptions');

  		}
  	}

  	onSelection(option: SortPopoverOption){
  		this.selection.emit(option);
  		this.popoverCtrl.dismiss(option);
  	}


}
