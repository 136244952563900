import { Pipe, PipeTransform } from '@angular/core';


@Pipe({name: 'sortArrayObjects'})
export class SortArrayObjectsPipe implements PipeTransform {
    transform(value: any[], sortByKey: string, sortByNestedKey: string ): any[] {

        if (value.filter(entity => {return entity[sortByKey]}).length === 0) throw new Error("Sort by key not found");
        
        return value.sort((a,b) => {
            if (sortByNestedKey != undefined){
                if (a[sortByKey][sortByNestedKey] > b[sortByKey][sortByNestedKey]) return 1;
                if (a[sortByKey][sortByNestedKey] < b[sortByKey][sortByNestedKey]) return -1;
                return 0;
            }else{
                if (a[sortByKey] > b[sortByKey]) return 1;
                if (a[sortByKey] < b[sortByKey]) return -1;
                return 0;
            }
           
        })
    }
}
