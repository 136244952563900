import { Component } from '@angular/core';

import { IonModal, PopoverController } from "@ionic/angular";



@Component({
	selector: 'vendor-review-items-sort',
	templateUrl: 'vendor-review-items-sort.component.html',
	styleUrls: ['vendor-review-items-sort.component.scss'],
})
export class VendorReviewItemsSortComponent {

	constructor(){

	}

	ngOnInit(){

	}




}
