import {Injectable } from '@angular/core';
import { Capacitor } from "@capacitor/core";
import { RemoteConfigService } from './remote-configs.service';
import { App } from '@capacitor/app';
import { MarketService } from './native-services';
import { SubscriptionManagerService } from './subscription-manager.service';

//This is duplicated in view-manager.service, need to fix this
export enum Device{
	IOS = "ios",
	ANDROID = "android"
}

@Injectable()
export class AppManagerService{

	constructor(private marketService: MarketService, private remoteConfigService: RemoteConfigService, private subscriptionService: SubscriptionManagerService){
	}

    //this is duplicated in view-manager.service, need to remove from there
	public getDevice(): Device{
		switch(Capacitor.getPlatform()){
			case "ios": 
				return Device.IOS;
			case "android":
				return Device.ANDROID; 
		}
	}

	public isNative(): boolean{
		return Capacitor.isNativePlatform();
	}


    public async hasMinVersion(): Promise<void>{
        if (this.isNative()){
            console.log(Capacitor.getPlatform());
            const curAppVersion = await this.getAppVersion();
            const minAppVersion = this.remoteConfigService.getMinSupportedVersion(this.getDevice());
            console.log(curAppVersion);
            console.log(minAppVersion)
            if (parseFloat(curAppVersion.split('.').join('')) < parseFloat(minAppVersion.split('.').join(''))){
                this.marketService.openAppListing(this.getAppID());
            }
        }
       
        return;
    }

    //Duplicated in app-menu.component, need to remove from there
    public async getAppVersion(): Promise<string>{
        try {
          const appInfo = await App.getInfo();
          return appInfo.version;
        } catch (error) {
          console.warn("Capacitor App not available on web version");
        }
    }

    public getAppID(){
        switch(this.getDevice()){
            case Device.IOS: return "1464063868";
            case Device.ANDROID: return "app.streat.smart";
        }
    }

    public refreshApp(){
        this.subscriptionService.destroyAllSubs();
        location.reload();
    }



}
