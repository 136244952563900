import {Injectable } from '@angular/core';
import { AppLauncherService } from './app-launcher.service.abstract.';
import { AppLauncher } from "@capacitor/app-launcher"


@Injectable()
export class CapacitorAppLauncherService extends AppLauncherService{

	constructor(
	
	){
		super();
	}

	public async open(url: string): Promise<void>{
		const canOpen = await AppLauncher.openUrl({url: url});
	}

	public async canOpen(url: string): Promise<boolean>{
		const canOpen = await AppLauncher.canOpenUrl({url: url});
		return canOpen.value;
	}

	
   
}
 