import firebase from 'firebase/compat/app';
import 'firebase/compat/remote-config';
import {Injectable } from '@angular/core';

/***Domain***/
import { UserService, UserRole } from "../app-domain/user/index";


/***Environment Variables***/
import { environment } from "../../environments/environment";
import { Device } from './app-manager.service';


@Injectable()
export class RemoteConfigService{

	constructor(
		private userService: UserService
	){
		//firebase.remoteConfig().settings.minimumFetchIntervalMillis = 60;
	}


	public loadAndActivate(): Promise<boolean>{
		return firebase.remoteConfig().fetchAndActivate();
	}

	private getValue(val: string): any{
		return firebase.remoteConfig().getValue(val);
	}

	public getOperatingStatuses(all?: boolean): {[key: string]: any}[]{
		const allStatuses = JSON.parse(this.getValue("vendorOperatingStatus").asString());
		return allStatuses.filter((status) => this.userService.getRole() === UserRole.ADMIN || (all || status.selectable));
  }

	public getStatusDisplayByFlag(flag: 0 | 1 | 2 | 3 | 4 | 5): string {
		const allStatuses = JSON.parse(this.getValue("vendorOperatingStatus").asString());
		const [filterStatus] = allStatuses.filter((statusObj) => { return statusObj.value === flag; })
		return filterStatus.readable;
	}

	public getAffiliations(): {[key: string]: any}{
		const allAffiliations = JSON.parse(this.getValue('vendorAffiliations').asString());
		return allAffiliations;
	}

	public getAvailableStatesofService(): {[key: string]: any}[]{
		const states = JSON.parse(this.getValue('availableStatesOfService').asString());
		return states;
	}

	public getFacebookURL(): {ios: string, android: string, web:string}{
		const fbURL = JSON.parse(this.getValue('facebookURL').asString());
		return fbURL
	}

	public getInstagramURL(): {ios: string, android: string, web:string}{
		const igURL = JSON.parse(this.getValue('instagramURL').asString());
		return igURL
	}

	public getTwitterURL(): {ios: string, android: string, web:string}{
		const twitterURL = JSON.parse(this.getValue('twitterURL').asString());
		return twitterURL
	}

	public getMinSupportedVersion(device: Device): string {
		const version = JSON.parse(this.getValue("minSupportedVersion").asString());
		//console.log(version);
		//console.log(device);
		return version[device];
	}

	public getAndroidGeolocationFeatureFlag(): boolean{
		const flag: boolean = JSON.parse(this.getValue('androidGeolocationFeatureFlag').asString());
		return flag;
	}

}
