import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VendorCacheService } from 'src/app/app-services';
import { IVendor } from 'src/app/app-domain/vendor';
import { Router } from '@angular/router';
import { MultiNav } from 'src/app/app-interfaces/multi-route-nav.interface';




@Component({})
export abstract class VendorItemListComponent implements OnInit{
  private _vendors: IVendor[] = [];
  @Input() set vendors(vendors: IVendor[]){
    this._vendors = vendors;
  }

  get vendors(): IVendor[]{
    return this._vendors;
  }
  private _vendorIDs: string[] = [];
  @Input() set vendorIDs(vendorIDs: string[]){
    if (this._vendorIDs.length === 0){ //don't remove this, otherwise client will make repeated calls to load vendors
      this._vendorIDs = vendorIDs;
      this.loadVendors(vendorIDs);
    }
    
  }

  @Input() title: string = "Vendors";
  @Output() onClick = new EventEmitter<MultiNav>();
  constructor(protected vendorCacheService: VendorCacheService, protected router: Router
	){}

  async ngOnInit() {
    this.loadVendors(this.vendorIDs);
  }

  protected async loadVendors(vendorIDs: string[]){
    if (vendorIDs.length > 0) {
      this.vendors = await Promise.all(vendorIDs.map(vID => {
        return this.vendorCacheService.getByID(vID);
      }))
      this.vendors = this.vendors.filter(v => v != undefined);
      this.vendors.sort((a, b) => {if (a.getName().toLocaleLowerCase() > b.getName().toLocaleLowerCase()) return 1; if (a.getName().toLocaleLowerCase() < b.getName().toLocaleLowerCase()) return -1; return 0})
    }else{
      this.vendors = [];
    }
  }

  public abstract onVendorClick(vendor: IVendor);
}


