import { Pipe, PipeTransform } from '@angular/core';


@Pipe({name: 'arrayToReadable'})
export class ArrayToReadablePipe implements PipeTransform {
    transform(value: string[]): string {
        if (value.length > 2){
            return value.map((str, i, arr) => {
                return arr.length - 1 === i ? " and " + str : str;
            }).join(", ");
        }else if (value.length == 2){
            return value[0] + " and " + value[1];
        }else{
            return value[0]
        }
    }
}
