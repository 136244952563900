import { Component, OnInit, Input } from '@angular/core';
import { MenuTagsData } from '../../../module-vendor/vendor-pages/vendor-menu';


@Component({
	selector: 'menu-tag',
	templateUrl: './menu-tag.component.html',
	styleUrls: ['menu-tag.component.scss']
})
export class MenuTagComponent implements OnInit {
  @Input() tag: string;
  menuTags = {};
  
	constructor(){}
	
  ngOnInit() {
    MenuTagsData.forEach(tag => {
      this.menuTags[tag.abbrev] = tag;
    })
}

  


}
