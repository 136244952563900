import { User, IUserData, UserRole } from "./index";

export class AdminUser extends User {
	constructor(userID: string, userData: IUserData, permissions?: Map<string,boolean>){
		super(userID, userData, permissions);
		this.role = UserRole.ADMIN;
	}

	public getRoot(): string{
		return "/vendor";
	}

  public hasPermissionsForVendor(vendorID: string) {
    return true;
  }


}
