import {Injectable } from '@angular/core';
import { Router } from "@angular/router";

import { IVendor, IVendorData } from "../../app-domain/vendor/index";
import { Observer, Observable } from "../../app-interfaces/index";

export interface IVendorService extends Observable<IVendor>{
	setVendorList(vendors: IVendor[]): void;
	getVendorList(): IVendor[];
	isLoaded(): boolean;
	ready(): void;
	setVendorFocus(vendor: IVendor): void;
	getVendorFocus(): IVendor;
	deconstructor(): void;
}



@Injectable()
export class VendorService implements IVendorService{
	private vendorList: IVendor[] = [];
	private vendorFocus: IVendor;
	private loaded: boolean = false;
	private subscribers: Observer<IVendor>[] = [];
	constructor(){

	}


  public setVendorList(vendors: IVendor[]): void{
    if (this.getVendorList()){
    	this.destroyDependencies();
			this.loaded = false;
		}
		this.vendorList = vendors;
		if (this.vendorList.length > 0) {this.setVendorFocus(this.vendorList[0]);}
		this.ready();
	}


	public setVendorFocus(vendor: IVendor): void{
		this.vendorFocus = vendor;
		this.notifySubscribers();
	}

	public getVendorFocus(): IVendor{
		return this.vendorFocus;
	}

	public getVendorList(): IVendor[]{
		if (this.vendorList.length > 0){
			return this.vendorList;
		}
	}

	public toggleVendorFocus(vendorID: string): void{
		const vendor: IVendor[] = this.getVendorList().filter((_vendor: IVendor) => _vendor.getVendorID() === vendorID);
		if (vendor.length > 0) {this.setVendorFocus(vendor[0]);}
	}

	public ready(): void{
		this.loaded = true;
	}


	public isLoaded(): boolean{
		return this.loaded;
	}


	//Subscribe to Vendor Focus changes
	public subscribeToChanges(obs: Observer<IVendor>): void{
		this.subscribers.push(obs);
	}

	public removeSubscription(obs: Observer<IVendor>): void{
		this.subscribers.forEach((_obs: Observer<IVendor>, idx) => {
			if (obs.getID() === _obs.getID()) {this.subscribers.splice(idx,1);}
		});
	}

	public notifySubscribers(): void{
		this.subscribers.forEach((obs: Observer<IVendor>) => {
			obs.update(this.getVendorFocus());
		});
	}

	public deconstructor(){
		this.destroyDependencies();
		this.vendorList = [];
		this.vendorFocus = null;
		this.loaded = false;
		this.subscribers = [];
	}

	private destroyDependencies(){
		this.getVendorList().forEach((v) => {
			if (v.getCalendar() != undefined) v.getCalendar().destroy();
			if (v.getMenu() != undefined) v.getMenu().destroy();
		})
	}

}
