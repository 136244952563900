import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import {ElementRef, Injectable } from '@angular/core';

/***Plugins***/
import { Geolocation, Position } from "@capacitor/geolocation";

/***Models***/
import { ILocation, Location } from "../app-domain/location/index";
import { UserService } from '../app-domain/user';
import { VendorTabsComponent } from '../module-vendor/vendor-components/vendor-tabs/vendor-tabs.component';
import { AnimationBuilder, PopoverController } from '@ionic/angular';
import { FeaturePopoverComponent } from '../app-components';

@Injectable()
export class FeaturePopoverService{


	constructor(private userService: UserService, private popoverCtrl: PopoverController){}

	public async onboard(vendorTabs: VendorTabsComponent){
        const snapshot = await firebase.database().ref("featureTooltip/" + this.userService.getID() + "/onboarding").once('value');
		
        if (snapshot.val() && snapshot.val() === true) return; //user has already seen tooltip		
		await firebase.database().ref("featureTooltip/" + this.userService.getID() + "/onboarding").set(true);
		//Onboarding Tooltips/Popovers
		this.triggerPopover(vendorTabs.customTab.nativeElement, "Profile Preview","This is what your profile looks like to customers.",null, 
			() =>{
				this.triggerPopover(vendorTabs.schedulingTab.nativeElement, "Calendar","Manage your events to let customers know when and where you are open!",null, 
				()=>{
					this.triggerPopover(vendorTabs.menuTab.nativeElement, "Menu","Manage your menu to let customers know what you have to offer!",null, 
					()=>{
						this.triggerPopover(vendorTabs.statsTab.nativeElement, "Stats","View metrics such as profile page views and reviews.",null, 
						()=>{
							this.triggerPopover(vendorTabs.settingsTab.nativeElement, "Settings","Manage supplemental information to provide a detailed profile to your customer base!",null,null 
		)})})})});
    }



	private triggerPopover(el: Element, title: string, content: string, buttonText: string = "Got It", cb: any){
		const node = el;
        const trigger = new Event('popover-trigger');
        node.addEventListener('popover-trigger', (evt: Event) => this.openPopover(evt, title, content, buttonText, cb));
        node.dispatchEvent(trigger);
    }

	private async openPopover(event: Event,title: string, content: string, buttonText: string, cb: any){
		const popover = await this.popoverCtrl.create({
			component: FeaturePopoverComponent,
			componentProps: {title: title,content: content, buttonText: buttonText},
			event: event,
			cssClass: 'feature-popover',
			animated: true,
			mode: 'ios'
		})
		await popover.present();
		popover.onDidDismiss().then(() => {if (cb) {cb()}});
	}

}



