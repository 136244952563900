import { Component, OnInit, Input } from '@angular/core';

/***Models***/
import { IVendor } from "../../../../app-domain/vendor/index";


@Component({
	selector: 'vendor-card-simple',
	templateUrl: './vendor-card-simple.component.html',
	styleUrls: ['vendor-card-simple.component.scss']
})
export class VendorCardSimpleComponent implements OnInit {
	@Input() vendor: IVendor;

	constructor(){}
	
	ngOnInit() {}
}