import {Injectable } from '@angular/core';
import { Browser } from "@capacitor/browser";
import { MarketService } from './market.service.abstract.';
import { NativeMarket } from "@capacitor-community/native-market";


@Injectable()
export class CapacitorMarketService extends MarketService{

	constructor(
	
	){
		super();
	}

   public async openAppListing(appId: string): Promise<void> {
       NativeMarket.openStoreListing({appId: appId});
   }



   
}
 