import { Component, Input, Output, EventEmitter } from '@angular/core';
import type { OnInit } from '@angular/core';

export interface SearchListItem {
  getText(): string,
  getValue(): string,
  getCount(): number
}

@Component({
  selector: 'search-list',
  templateUrl: 'search-list-modal.component.html',
})
export class SearchlistModalComponent implements OnInit {
  @Input() items: SearchListItem[] = [];
  @Input() selectedItems: string[] = [];
  @Input() title = 'Select Items';
  @Input() anyValue: SearchListItem;
  @Output() selectionCancel = new EventEmitter<void>();
  @Output() selectionChange = new EventEmitter<SearchListItem[]>();
  filteredItems: SearchListItem[] = [];
  workingSelectedValues: SearchListItem[] = [];
 
  ngOnInit() {
    if (this.anyValue) this.items = [this.anyValue, ...this.items];
    this.filteredItems = [...this.items];
    this.reconcileSelection();
  }

  trackItems(index: number, item: SearchListItem) {
    return item.getValue();
  }

  cancelChanges() {
    this.selectionCancel.emit();
  }

  confirmChanges() {
    this.selectionChange.emit(this.workingSelectedValues);
  }

  searchbarInput(ev) {
    this.filterList(ev.target.value);
  }

  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  filterList(searchQuery: string | undefined) {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined) {
      this.filteredItems = [...this.items];
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase();
      this.filteredItems = this.items.filter((item) => {
        return item.getText().toLowerCase().includes(normalizedQuery);
      });
    }
  }

  isChecked(value: SearchListItem) {
    return this.workingSelectedValues.filter((item) => {
     return item.getValue() === value.getValue()
    }).length > 0;
  }

  checkboxChange(ev, item: SearchListItem) {
    const { checked } = ev.detail;

    if (checked) {
      if (item.getText() != "Any") {
        if (this.isChecked(this.anyValue)) {
          this.workingSelectedValues = [item];
        } else {
          this.workingSelectedValues = [...this.workingSelectedValues, item];
        }
      } else { //is Any
        this.workingSelectedValues = [this.anyValue];
      }
    } else {
      this.workingSelectedValues = this.workingSelectedValues.filter((i) => { return i.getValue() !== item.getValue() });
    }
  }

  reconcileSelection() {
    this.workingSelectedValues = [...
      this.selectedItems
        .map((value) => {
           return this.filteredItems.find((item) => item.getValue() === value);
        })
        .filter((value) => {
          return value != undefined;
        })
    ]
    console.log(this.workingSelectedValues)

  }



  removeAnySelection() {
    this.workingSelectedValues = this.workingSelectedValues.filter((item) => { return item.getText() != "Any" });
  }

  removeAllButAnySelection() {
    this.workingSelectedValues = this.workingSelectedValues.filter((item) => { return item.getText() === "Any" });
  }
}

