import {Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

/***Interfaces */
import { Persistable } from "../../../app-domain/index";
import { IVendorData } from '../../../app-domain/vendor';
import { RemotePayload, RemoteResponse } from "../../../app-interfaces/index";
/***Services */
import { LeaderboardService } from './leaderboard.service.abstract';



@Injectable()
export class FirebaseLeaderboardService extends LeaderboardService{

	constructor(){
		super();
	}

	public getUserEventLeaderboard(): Promise<RemoteResponse>{
		const getUserEventLeaderboard = firebase.functions().httpsCallable("service_leaderboard_getUserEventLeaderboard");
		return getUserEventLeaderboard();
	}


}

