import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { MenuController } from "@ionic/angular";
import { UserRole, UserService } from "../../app-domain/user/index";
import { IVendor, VendorService } from "../../app-domain/vendor/index";
import { AuthService } from "../../app-services/index";

import { App } from "@capacitor/app";
import { MultiNav } from 'src/app/app-interfaces/multi-route-nav.interface';

@Component({
	selector: 'app-menu',
	templateUrl: 'app-menu.component.html',
	styleUrls: ['app-menu.component.scss'],
})
export class AppMenuComponent {
  public appVersion: string;
	constructor(
		private router: Router,
		private menuCtrl: MenuController,
		private userService: UserService,
		private vendorService: VendorService,
		private authService: AuthService
		) {

	}

	async ngOnInit(){
    	await this.getAppVersion();

	}

	public onOpenMenu(): void{
		this.menuCtrl.enable(true,"main");
		this.menuCtrl.open("main");
	}


	public onNavTo(path: string, state?: any): void{
		this.router.navigateByUrl(path, state);
		this.closeMenu();
	}

	public async onMultiNavTo(multiNav: MultiNav){
		await this.router.navigate([multiNav.path1]);
		this.onNavTo(multiNav.path2);
	}


	private closeMenu(): void{
		this.menuCtrl.close();
	}

	public onUpdateVendorFocus(vendor: IVendor): void{
		this.onNavTo("/vendor/scheduling"); //need to change tab view to allow for ionViewDidEnter hook to trigger to reload vendor menu/calendar
		window.setTimeout(() => {
			this.onNavTo("/toggle-profile",{state: {vendorName: vendor.getName(), vendorImg: vendor.getBackgroundImgUrl()}});
		},0)
	
		window.setTimeout(() => {
			this.onNavTo('/vendor');
			this.vendorService.toggleVendorFocus(vendor.getVendorID());
		},3000)
		
		//this.vendorService.toggleVendorFocus(vendorID);
		//this.onNavTo("/vendor");
	}

	public onLogout(): void{
		this.closeMenu();
		this.authService.logout();
 	}

  public async getAppVersion() {
    try {
      const appInfo = await App.getInfo();
      this.appVersion = appInfo.version;
    } catch (error) {
      console.warn("Capacitor App not available on web version");
    }
  }

  public registerBusiness() {
    this.onNavTo("registration");
  }

  public guestSignIn(){
	if (this.userService.getRole() === UserRole.GUEST){
		firebase.auth().currentUser.delete()
		.then(() => {
			console.log("Guest Auth Account Deleted");
		})
	}
	this.authService.logout();
	this.onNavTo("");
  }


}
