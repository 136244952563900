import {Injectable } from '@angular/core';
/***Firebase***/
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import { ActivityType } from './activity.type';
import { Activity } from './activity.interface';



enum ActivitySearchEnum{
    M = 0,
    M3,
    M6 ,
    Y,
    A
}


@Injectable()
export class ActivityList{
    private activity: {[type: string]: Activity[]} = {}

	constructor(){}


    public loadActivity(activityType: ActivityType, data: Activity[]){
        if (!this.activity[activityType]) this.activity[activityType] = [];
        this.activity[activityType] = data;
    }

    public getActivityBy(type: ActivityType, search: ActivitySearchEnum): Activity[]{
        if (this.activity[type]) return this.activity[type].filter((a: Activity) => {
            return new Date(a.dateTime).getTime() > this.getSearchDateTimeBy(search);
        });
        return [];
    }

    public getActivityCountBy(type: ActivityType, search: ActivitySearchEnum): number{
        return this.getActivityBy(type, search).length;
    }


    private getSearchDateTimeBy(e: ActivitySearchEnum): number{
        let date = new Date();
        switch (e){
        case ActivitySearchEnum.M:
            return date.setMonth(date.getMonth() - 1);
        case ActivitySearchEnum.M3:
            return date.setMonth(date.getMonth() - 3);
        case ActivitySearchEnum.M6:
            return date.setMonth(date.getMonth() - 6);
        case ActivitySearchEnum.Y:
            return date.setFullYear(date.getFullYear() - 1);
        case ActivitySearchEnum.A:
            return date.setFullYear(date.getFullYear() - 100);
        }
    }
}


