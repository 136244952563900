import { Component, OnInit  } from '@angular/core';
import { Platform } from '@ionic/angular';


@Component({})
export class SwiperComponent implements OnInit {
		
  constructor(
    protected platform: Platform
	){}
	
	ngOnInit() {
		
	}
	
    public getSlidesPerView(): number{
      const screenWidth = this.platform.width();
      if (screenWidth < 350) {
       return 1;
      } else if (screenWidth < 1000) {
        return 1.25;
      } else if (screenWidth < 1400) {
       return 2.25;
      } else {
        return 2.25;
      }
  }


}
