import { Component, NgZone} from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { LoadingController, Platform } from '@ionic/angular';
/***Environment Variables***/
import { environment } from "../environments/environment";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
/***User Module***/
import {UserService,UserRepo, IUser, UserRole} from "./app-domain/user/index";
import { VendorRepo, VendorService } from "./app-domain/vendor/index";
//import { IUser } from "./module-user/interfaces/user.interface";
/***Services */
import { NotificationService, ViewManagerService, AppManagerService, RemoteConfigService, BackgroundLocationService, AnalyticsService, LandingNotificationService, LocalStorageService, Device } from './app-services';
/******Plugins */
/***Swiper */
import {register} from "swiper/element/bundle";
register();
//Splashscreen
import { SplashScreen } from "@capacitor/splash-screen";
import { DeeplinksService } from './app-services/deeplinks.service';
import { AppModule } from './app.module';
import { NotificationHandlerService } from './app-services/notification-services/notification-handler.service';
import {StatusBar} from "@capacitor/status-bar";
import { USER_ID } from './app-services/native-services/local-storage-services/local-storage.constants';
import { CapacitorNetworkService } from './app-services/native-services/network-services';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {
	private appManagerService: AppManagerService;
	constructor(
		private router: Router,
		private platform: Platform,
		private userService: UserService,
		private userRepo: UserRepo,
		private vendorRepo: VendorRepo,
		private vendorService: VendorService,
    	private notificationService: NotificationService,
		private viewManagerService: ViewManagerService,
    	private deeplinksService: DeeplinksService,
    	private ngZone: NgZone,
    	private loadCtrl: LoadingController,
		private backgroundLocationService: BackgroundLocationService,
		private notificationHandlerService: NotificationHandlerService,
		//private geofenceService: GeofenceService
		private analyticsService: AnalyticsService,
		private landingNotificationService: LandingNotificationService,
		private localStorage: LocalStorageService,
		private networkService: CapacitorNetworkService
		) {

		firebase.initializeApp(environment.fb);
				
		/**Set up our deeplinks listener */
		this.deeplinksService.setupDeeplinks();
		
		
		//Subscribe to route changes
		this.router.events.subscribe(val => {
			if (val instanceof NavigationEnd){
				const vendorID = this.vendorService && this.vendorService.isLoaded() && this.vendorService.getVendorFocus().getVendorID();
				this.analyticsService.logEvent("ss_page_view",{pagePath: val.url, vendorID: vendorID});
			}
		})

		firebase.auth().onAuthStateChanged(async (user) => {
			if (user){
				const loader = await this.loadCtrl.create({
					message: "Loading User Data"
				})
				loader.present();
				try{
					
					console.log("Logged In");
					const _user: IUser = await this.userRepo.getByIDAndSubscribe(user.uid);
					this.userService.setUser(_user);
					
					this.userService.ready();
					await this.loadDependencies();
					
	
					//Google Analytics
					this.analyticsService.setUserID(this.userService.getID());
	
					await this.router.navigate([this.userService.getRoot()]);
	
					/***Register Notification Token */
					if (this.viewManagerService.isNative()) await this.notificationService.registerNotificationToken();
	
					//Handle deferred notifications/deeplinks
					await this.notificationHandlerService.handleDeferredNavigation();
					await this.deeplinksService.handleDeferredNavigation();
	
					//Check Landing Notifications
					if (this.appManagerService && this.appManagerService.isNative()){
						window.setTimeout(async () => {
							await this.landingNotificationService.checkIfUserHasLandingNotifications();
						},0)
					}
	
					//Store user ID in local storage for background notifications
					this.localStorage.set(USER_ID,this.userService.getID());
	
					if (this.appManagerService.isNative()) this.backgroundLocationService.startOrStopBackgroundLocation();
					loader.dismiss();
				}catch(error){
					loader.dismiss();
				}
				
			}else{
				this.router.navigate([""]);
			}
			await SplashScreen.hide();
		});

		/*** Platform Ready! ***/
		platform.ready().then(async () => {
			console.log("Ready, yo!");
			
			window.setTimeout(async () => { //need to wait for userservice to be instantiated since we are injecting remote config service which depends on it
				
				/***Activate remote configs */
				await AppModule.injector.get(RemoteConfigService).loadAndActivate();
				/***Inject manager service to avoid cyclical deps */
				this.appManagerService = AppModule.injector.get(AppManagerService);
				/***Check app version */
				await this.appManagerService.hasMinVersion();
				/**Start background location listener */
				
				if (this.appManagerService.isNative()) StatusBar.setBackgroundColor({color:"#327CC5"});

				//geofence testing
				/*await this.geofenceService.updateGeofencesOnLocalDevice({
					radius: 100,
					events: [
						{id: '-NerTP_gpFt17wX098GF',latitude: 45.03698995863966, longitude: -93.50951138203125, vendorName: "StreatSmart4",startDtTm:1695286800000,endDtTm: 1695348000000}, //true
						{id: '126',latitude: 45.0725, longitude: -93.4558, vendorName: "StreatSmart2",startDtTm:1695027622000,endDtTm: 1695034822000}, //already ended (4-6)
						{id: '127',latitude: 45.0725, longitude: -93.4558, vendorName: "StreatSmart3",startDtTm:1695286800000,endDtTm: 1695348000000, notified: true}, //already alerted
						{id: '125',latitude: 45.0725, longitude: -92.4558, vendorName: "StreatSmart4",startDtTm:1695034822000,endDtTm: 1695043822000}, //true
					
					]
				})
				const test = await this.geofenceService.evaluateLocationAgainstGeofences();
				console.log(test)*/

			},100)
			/***Begin listening for Background notifications */
			
			/***Load Google Maps */
			
			this.loadScript("https://maps.googleapis.com/maps/api/js?libraries=places&key=" + environment.google.apiKey)
			//this.loadScript("https://maps.googleapis.com/maps/api/js?key=" + environment.google.apiKey)
				

			/***Firebase Plugins - Emulator for Local dev***/
			if (window.location.host.includes('localhost:8100')){
				firebase.functions().useEmulator("localhost",5001);
				firebase.remoteConfig().settings.minimumFetchIntervalMillis = 60;
				
			}
			
			// Add network listener
			this.networkService.addNetworkListener();	

			window.setTimeout(async () => {
				this.router.navigate([""]);
			},0)
    });

	platform.pause.subscribe(async () => {
		console.log("App now in background");
		this.userService.setlastActive(new Date());
	})

    platform.resume.subscribe(async () => {
		console.log("Resuming app")
		console.log("Checking min version")
		this.appManagerService.hasMinVersion();
		console.log("Got min version")
		if (this.appManagerService.getDevice() === Device.IOS && this.userService.isExpired()) {
			console.log("expired - refreshing app")
			this.appManagerService.refreshApp();
      	}
	  
    })

		
	}



	private async loadDependencies(): Promise<void>{
		if (this.userService.getRole() === UserRole.VENDOR){
			console.log("Vendor User");
			const _vendors = await this.vendorRepo.getByIDsAndSubscribe(this.userService.getPermissionList());
			firebase.analytics().logEvent
			this.vendorService.setVendorList(_vendors);
			this.analyticsService.setUserProperties("role", UserRole.VENDOR)
			return;
		}else if(this.userService.getRole() === UserRole.CUSTOMER){
			console.log("Customer User");
			this.analyticsService.setUserProperties("role", UserRole.CUSTOMER)
		} else if (this.userService.getRole() === UserRole.ADMIN) {
			console.log("Admin User")
			const _vendors = await this.vendorRepo.getAllVendors(true);
			this.vendorService.setVendorList(_vendors);
			this.analyticsService.setUserProperties("role", UserRole.ADMIN)
		}else{
			console.log(this.userService.getRole());
		}

		//const test = firebase.functions().httpsCallable("testPubSub");
		//test("test");
		
		return;

	}

	private loadScript(url: string){
		let script = document.createElement('script');
		script.src = url;
		document.getElementsByTagName('head')[0].appendChild(script);
		console.log('Script Loaded');		  
	}


}
