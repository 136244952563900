import firebase from 'firebase/compat/app';
import 'firebase/compat/database';


import { IReview, IReviewList, Review } from "./index";
import { Model, Persistable } from "../../app-domain/index";
import { AppModule } from 'src/app/app.module';
import { SubscriptionManagerService } from 'src/app/app-services/subscription-manager.service';



export class VendorReviewList implements Persistable<IReview[]>, IReviewList {
	private vendorID: string;
	private reviews: IReview[] = [];
	private reference: firebase.database.Reference;
	constructor(vendorID: string, reviews: IReview[]){
		this.reviews = reviews;
		this.vendorID = vendorID;

	}


	public getVendorID(): string{
		return this.vendorID;
	}

	// no longer going off of totalFavCnt field in Firebase - look to deprecating this cloud function
	public getFavoriteCount(): number{
		if (this.reviews.length > 0){
			return this.reviews.reduce((acc, curVal) => {
				if (curVal.isFavorite()) {return acc + 1;}
				return acc;
			}, 0);
		}else{
			return 0;
		}
	}

	public getFavoriteList(): IReview[]{
		return this.reviews.filter((review: IReview) => review.isFavorite());
	}

	public getReviewCount(): number{
		if (this.reviews.length > 0){
			return this.reviews.reduce((acc, curVal) => {
				if (curVal.hasComment()) {return acc + 1;}
				return acc;
			}, 0);
		}else{
			return 0;
		}
  }

  public getRatingCount(): number {
    if (this.reviews.length > 0) {
      return this.reviews.reduce((acc, curVal) => {
        if (curVal.hasRating()) { return acc + 1; }
        return acc;
      }, 0);
    } else {
      return 0;
    }
  }

  public getReviewList(): IReview[]{
    return this.reviews.filter((review: IReview) => review.hasComment()).sort((a, b) => {
			if (a.getCreatedTime() > b.getCreatedTime()) {return -1;}
			if (a.getCreatedTime() < b.getCreatedTime()) {return 1;}
			return 0;
		});
	}

	public getReviewsByRating(rating: number): IReview[]{
		if (this.reviews.length > 0){
			if (rating == 0){ //all reviews
				return this.getReviewList();
			}else if(rating < 6){
				return this.getReviewList().filter((review: IReview) => review.getRating() == rating).sort((a,b) => {
					if (a.getCreatedTime() > b.getCreatedTime()) {return -1;}
					if (a.getCreatedTime() < b.getCreatedTime()) {return 1;}
					return 0;
				});
			}
		}else{
			return [];
		}
  }


	public getReviewByUserID(userID: string): IReview{
		if (this.reviews.length > 0){
			const _review: IReview[] = this.reviews.filter((review: IReview) => review.getUserID() === userID && review.hasRating());

			if (_review.length > 0) {return _review[0];}
		}
	}


	public getAverageRating(): string{
		let totalCnt: number = 0;
		let totalRating: number = 0;

		if (this.reviews.length > 0){
			this.reviews.forEach((review: IReview) => {
				if (review.hasRating()){
					totalCnt++;
					totalRating += review.getRating();
				}
			});
			return totalRating ? (totalRating/totalCnt).toFixed(2) : "0";
		}else{
			return "0";
		}
	}

	public getID(): string{
		return this.vendorID;
	}

	public getData(): IReview[]{
		return this.reviews;
	}

	public subscribe(db: firebase.database.Reference): void{
		this.unsubscribe();
		AppModule.injector.get(SubscriptionManagerService).addSubcription(this);
		this.reference = db;
		db.on("value",(snapshot: firebase.database.DataSnapshot) => {
			if (snapshot.val()){
				//console.log(snapshot.val());
				this.reviews = Object.keys(snapshot.val()).map((userID: string) => new Review(db.key, userID, snapshot.val()[userID]));
			}
		});
	}

	public unsubscribe(): void {
		if (this.reference) this.reference.off();
	}

	/***Iterator***/
	public [Symbol.iterator]() {
		let cnt = 0;
		const that = this;
		return {
			next(){
				cnt++;
				return {
					done: cnt === that.reviews.length,
					value: that.reviews[cnt]
				};
			}
		};
	}



}
