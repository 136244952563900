import {Injectable } from '@angular/core';
import { UserPrefRepo } from 'src/app/app-domain/user';
import { AppManagerService } from '../../app-manager.service';
import { Observable } from 'rxjs';
import { ListenResponse } from './cap-background-location.service';

@Injectable()
export abstract class BackgroundLocationService{

	constructor(protected appManagerService: AppManagerService, protected userPrefRepo: UserPrefRepo,
	){

	}

	public abstract listen(): Promise<ListenResponse>;
	public abstract off(): Promise<void>
	public abstract startOrStopBackgroundLocation(): Promise<void>
}
 