import { Input, ViewChild, ElementRef } from '@angular/core';
import { IonButton } from "@ionic/angular";


export abstract class AbstractButtonComponent{
	private _color: string = 'primary';
	private _size: string = "16px";
	private _icon: string;
	private _visible: boolean = true;
	private _fill: string;
	private _data: any;
	@ViewChild(IonButton, {read: ElementRef}) nativeButton: ElementRef
	constructor(){}


	ngOnInit(){
		setTimeout(() => {
			if (this.nativeButton){
				this.nativeButton.nativeElement.style.fontSize = this.size;
			}
			this.visible();
		})
	}

	// [[Color]]
	@Input() set color(color: string){
		this._color = color;
	}

	get color(){
		return this._color;
	}

	// [[Size]]
	@Input() set size(size: string){
		this._size = size;
	}

	get size(){
		return this._size;
	}
	
	// [[Icon Name]]
	@Input() set icon(icon: string){
		this._icon = icon;
	}

	get icon(){
		return this._icon;
	}

	// [[Fill]]
	@Input() set fill(fill: string){
		this._fill = fill;
	}

	get fill(){
		return this._fill;
	}

	protected abstract visible(): void;

	public abstract onClick(data?: any);
}