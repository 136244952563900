import { Component, OnInit} from '@angular/core';
import { ModalController } from '@ionic/angular';
/***Services***/
import { AdminService } from '../../../app-services';
/***Pages***/
import { EditEZVendorPage } from "./edit-ez-vendor/edit-ez-vendor";
/***Models***/
import { IVendor } from '../../../app-domain/vendor';


@Component({
  selector: 'easyeats-page',
  templateUrl: 'easyeats-mappings.page.html',
})

export class EasyEatsMappingPage implements OnInit{
  public vendors: IVendor[];

	constructor(
    public modalCtrl: ModalController,
    private adminService: AdminService
	){}

	ngOnInit(){
		return this.getEZEatsVendors();
	}
	
	public async getEZEatsVendors(){
    this.vendors = await this.adminService.getEasyEatsVendors();
    this.vendors.sort(this.alphaSortVendors);
	}

	private alphaSortVendors(a,b){
		if (a.getName().toLowerCase() > b.getName().toLowerCase()) return 1; 
		if (a.getName().toLowerCase() < b.getName().toLowerCase()) return -1;
		return 0;
	}
	
	public async onDeleteEzEatsVendor(vendor: IVendor){
		return this.adminService.deleteVendorEasyEatsID(vendor.getVendorID(),vendor.getEasyEatsID())
			.then(() => {
				return this.getEZEatsVendors();
			})
			.catch(error => console.log(error.message));
	}
	
  public async onAddEasyEzVendor() {
    const modal = await this.modalCtrl.create({
      component: EditEZVendorPage
    });
		await modal.present();
    modal.onDidDismiss().then(async (data) => {
      if (data) {
        await this.getEZEatsVendors();
      }
    })
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

}
