import { Component, OnInit, Input } from '@angular/core';
import {  ModalController , AlertController } from '@ionic/angular';


/***Services***/
import { GeolocationService } from "../../../app-services/index";
/***Models***/
import { IVendor, VendorRepo, VendorList, VendorSortEnum } from "../../../app-domain/vendor/index";



@Component({
	selector: 'vendor-select',
	templateUrl: './vendor-select.component.html',
	styleUrls: ['vendor-select.component.scss']
})
export class VendorSelectComponent implements OnInit {
	public vendors: IVendor[] = [];
	public vendorList: VendorList;
	public vendorsLoaded: boolean = false;

	constructor(
		private modalCtrl: ModalController,
		private alertCtrl: AlertController,
		private vendorRepo: VendorRepo,
		private geoService: GeolocationService
	){}

	async ngOnInit() {
		const state = await this.geoService.getCurrentState();
		await this.searchVendorsByState(state);
	}

	private async searchVendorsByState(state: string){
		const vendors: IVendor[] = await this.vendorRepo.getVendorsByState(state);
		this.vendorList = new VendorList(vendors);
		this.vendorList.setSortStrategy(VendorSortEnum.NAME);
		this.vendors = this.vendorList.getByOperatingStatus(2);
		this.vendorsLoaded = true;
	}

	public onVendorSelect(vendor: IVendor){
		this.modalCtrl.dismiss({vendor});
	}

	public onDismiss() {
		this.modalCtrl.dismiss();
	}

	public onSearch(event: any){
		const input = event.srcElement.value;
		if (input.length > 2){
			this.vendors = this.vendorList.getVendorsByPartialMatchName(input);
		}else if(input === ""){
			this.onClear();
		}
	}

	public onClear(){
		this.vendors = this.vendorList.getAllVendors();
	}

	public async onInfoClick(){
		const alert = await this.alertCtrl.create({
			header: "Available Vendors",
			message: "These are vendors who have created StreatSmart accounts and have given venues permission to add scheduled events on their behalf. ",
			buttons: ["Ok"]
		});
		await alert.present();
	}

}
