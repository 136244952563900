/***Models***/
import { IUserData } from "src/app/app-domain/user";
/***Interfaces */
import { Persistable } from "../../../app-domain/index";


export abstract class RemoteUserService{
    constructor(){

    }

    public abstract updateUser(userData: Persistable<IUserData>, dataOptions?: {[key: string]: any}): Promise<any>
    public abstract addFavorite(userData: Persistable<IUserData>, dataOptions?: {[key: string]: any}): Promise<any>
	public abstract removeFavorite(userData: Persistable<IUserData>, dataOptions?: {[key: string]: any}): Promise<any>
}
