import { User, IUserData, UserRole  } from "./index";


export class VendorUser extends User {
	constructor(userID: string, userData: IUserData, permissions?: Map<string,boolean>){
		super(userID, userData, permissions);
		this.role = UserRole.VENDOR;
	}

	public getRoot(): string{
		return "/vendor";
	}



}
