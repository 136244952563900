import { Component, OnInit} from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
/***Services***/

/***Data***/
import usStates from "../../../../../assets/data/us-states";
import { IVendor, VendorRepo } from '../../../../app-domain/vendor';
import { AdminService } from '../../../../app-services';
import { VendorSearchOptions } from '../../../../module-customer/pages/trucks/utils';
/***Models***/


@Component({
  selector: 'page-edit-ez-vendor',
  templateUrl: 'edit-ez-vendor.html',
})

export class EditEZVendorPage implements OnInit{
  private searchOptions: VendorSearchOptions;
  public vendors: IVendor[] = [];
	public usStates = usStates;
	public stateSelection: string = '';
 
	constructor(
		public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    private vendorRepo: VendorRepo,
    private adminService: AdminService
	){}

	ngOnInit(){}

	private async getVendorByState(stateAbbrev: string){
    const searchOptions: VendorSearchOptions = new VendorSearchOptions(stateAbbrev, ["Any"], 0, [1, 2, 3]);
    this.vendors = await this.vendorRepo.getVendorsBySearch(searchOptions);
    this.vendors.sort(this.alphaSortVendors);
  }
		
	private alphaSortVendors(a,b){
		if (a.getName().toLowerCase() > b.getName().toLowerCase()) return 1; 
		if (a.getName().toLowerCase() < b.getName().toLowerCase()) return -1;
		return 0;
	}

  public onStateSelection(event: any) {
		return this.getVendorByState(event.detail.value);
	}

  public onCancel(data: boolean) {
    this.modalCtrl.dismiss(data);
	}

	public async onAddEZEatsId(vendor: IVendor){
		const alert = await this.alertCtrl.create({
			header: "Add the vendor's EasyEats Id",
			inputs: [{name: "id",type: 'text'}],
			buttons:[
				{
					text: "Submit",
					handler: (value) => {
						this.adminService.addEasyEatsVendor(vendor.getVendorID(),value.id)
							.then(() => {this.onCancel(true);})
					}
				},
				{
					text: 'Cancel',
					role: 'cancel'
				}
			]
		})
		alert.present();
	}
	
}
