import { Component} from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { NavParams, ModalController, AlertController, LoadingController } from "@ionic/angular";
import { IMenu, ISubMenu, MenuRepo, MenuTypeRepo, IMenuTypeData } from "../../../../../app-domain/menu/index";
import { VendorService, IVendor } from "../../../../../app-domain/vendor/index";
import { VendorSubMenuBuilderPage } from "../index";
import { Persistable } from "../../../../../app-domain/shared-interfaces/index";
import { MenuService } from "../../../../../app-services/index";
@Component({
	selector: 'vendor-sub-menu-config',
	templateUrl: 'vendor-sub-menu-config.page.html',
	styleUrls: ['vendor-sub-menu-config.page.scss']
})
export class VendorSubMenuConfigPage {
	public vendor: IVendor;
	private dataUpdated: boolean = false;

	constructor(private vendorService: VendorService, private menuService: MenuService, private navParams: NavParams,
			private modalCtrl: ModalController, private menuRepo: MenuRepo, private menuTypeRepo: MenuTypeRepo,
			private alertCtrl: AlertController, private loadCtrl: LoadingController
		) {
		this.vendor = this.vendorService.getVendorFocus();
		//this.menu = this.vendor.getMenu();
	}

	ngOnInit(){

	}


	public async reorder(event: CustomEvent){
		const menuType: unknown = this.vendorService.getVendorFocus().getMenu().getSubMenu(this.vendorService.getVendorFocus().getMenu().getSubMenus()[event.detail.from].getName());
		this.menuService.reorderMenuTypes(menuType as Persistable<IMenuTypeData>, {vendorID: this.vendor.getVendorID(), value: {to: event.detail.to,from: event.detail.from}});
		event.detail.complete();
	}


	public onDismiss(): void{
		this.modalCtrl.dismiss();
	}

	public async onAddSubMenu(){
		const modal = await this.modalCtrl.create({
			component: VendorSubMenuBuilderPage
		});
		await modal.present();
	}


	public async onUpdateSubMenu(subMenu: ISubMenu){
		const modal = await this.modalCtrl.create({
			component: VendorSubMenuBuilderPage,
			componentProps: {subMenu}
		});
		await modal.present();
		modal.onDidDismiss().then(() => {
			console.log(this.vendorService.getVendorFocus().getMenu());
		})
	}



	public async onDeleteSubMenu(subMenu: Persistable<IMenuTypeData>){
		const alert = await this.alertCtrl.create({
			header: "Are you sure you want to delete this sub menu option?",
			subHeader: "You will no longer be able to add menu items to this sub menu. Existing menu items within this sub menu will not be deleted",
			buttons: [{
				text: 'Yes, delete submenu',
				role: 'confirm',
				handler: async () => {
					const loader = await this.loadCtrl.create({
						message: "Deleting Sub Menu"
					});
					loader.present();
					try{
						await this.menuTypeRepo.delete(subMenu ,{vendorID: this.vendor.getVendorID()});
						loader.dismiss();
					}catch(error){
						loader.dismiss();
						const alert = await this.alertCtrl.create({
							header: "Unable to delete sub menu option",
							message: "If this issue continues, please contact StreatSmart",
							buttons: ["Ok"]
						})
						alert.present();
					}
					
				}
			},{
				text: 'Cancel',
				role: 'cancel'
			}]
		});
		await alert.present();

	}




	//Reload Vendor Menu on Menu Type Updates/Additions/Removals
	private async loadVendorMenu(): Promise<void>{
		/*this.dataUpdated = true;
		const menu: IMenu = await this.menuRepo.getByIDAndSubscribe(this.vendor.getVendorID());
		this.vendor.setMenu(menu);
		this.menu = this.vendor.getMenu();*/
	}








}
