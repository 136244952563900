import { NgModule } from '@angular/core';
import {ArrayToReadablePipe, HTMLFormatterPipe, PhoneFormatterPipe, SortArrayObjectsPipe, ToArrayPipe } from "./index";
import { DatePipe } from '@angular/common';
import { FeatureRequestStatusPipe } from './feature-request-status.pipe';

@NgModule({
  declarations: [HTMLFormatterPipe, PhoneFormatterPipe, ToArrayPipe, SortArrayObjectsPipe, FeatureRequestStatusPipe, ArrayToReadablePipe],
  exports: [HTMLFormatterPipe, PhoneFormatterPipe, ToArrayPipe, SortArrayObjectsPipe, FeatureRequestStatusPipe, ArrayToReadablePipe],
  providers:[DatePipe]
})
export class AppPipeModule {}

