import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

/***Models***/
import { IEventRead } from 'src/app/app-domain/scheduling';
import { IVendor } from 'src/app/app-domain/vendor';
import { VendorCacheService } from 'src/app/app-services';
import { EventDetailsComponent } from '../../event-details/event-details.component';


@Component({
	selector: 'venue-event-card',
	templateUrl: './venue-event-card.component.html',
	styleUrls: ['venue-event-card.component.scss']
})
export class VenueEventCardComponent implements OnInit {
	@Input() event: IEventRead;
  public vendor: IVendor;
  constructor(private vendorCacheService: VendorCacheService, private modalCtrl: ModalController) { }
	
	async ngOnInit() {
        this.vendor = await this.vendorCacheService.getByID(this.event.getVendorID())
    }


  public async onDetailsClick(event: IEventRead) {
    const modal = await this.modalCtrl.create({
      component: EventDetailsComponent,
      componentProps: { event: this.event },
      breakpoints: [0, .66, 1],
      initialBreakpoint: .66,
      backdropBreakpoint: .4,
      backdropDismiss: true
    });
    await modal.present();
    modal.onDidDismiss().then((redirecting: any) => { if (redirecting.data) { this.modalCtrl.dismiss() } });
  }

  onCardClick(){
    
  }
}
