import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { LoadingController,AlertController} from '@ionic/angular';
import {Injectable} from '@angular/core';

/***Services***/


import { AuthService } from './auth.service.abstract';
import { IUserData, UserRole, UserService } from 'src/app/app-domain/user';
import { VendorService } from 'src/app/app-domain/vendor';
import { RemoteResponse, RemoteResponseStatus } from 'src/app/app-interfaces';
import { Router } from '@angular/router';
import { MediaNavigatorService } from '../media-navigator.service';
import { ViewManagerService } from '../view-manager.service';
import { SubscriptionManagerService } from '../subscription-manager.service';
import { AnalyticsService } from '../analytics-services';
import { BackgroundLocationService } from '../native-services';



@Injectable()
export class FirebaseAuthService extends AuthService{
	private userId: string;
	constructor(
		private alertCtrl: AlertController,
		private userService: UserService,
		private loadingCtrl: LoadingController,
		private vendorService: VendorService,
		private router: Router,
		private mediaNav: MediaNavigatorService,
		private viewMgr: ViewManagerService,
		private subscriptionManager: SubscriptionManagerService,
		private analyticsService: AnalyticsService,
		private backgroundLocService: BackgroundLocationService
	){
		super();
	}

	public async signup(username: string, password: string, firstName: string, lastName: string){
		const loader = await this.loadingCtrl.create({message: 'Signing you up...'});
		await loader.present();
		try{
			const signUp = firebase.functions().httpsCallable("service_user_signUp");
			const response: RemoteResponse = await signUp({username: username, password: password, firstName: firstName, lastName: lastName});
			loader.dismiss();
			if (response.data.statusCode != RemoteResponseStatus.SUCCESS){
				const alert = await this.alertCtrl.create({
					header: "Unable to create StreatSmart account",
					message: response.data.msg,
					buttons: ["Ok"]
				})
				await alert.present()
			}else{
				this.signin(username, password);
				this.analyticsService.logEvent("ss_sign_up")
			}
			
		}catch(error){
			loader.dismiss();
			const alert = await this.alertCtrl.create({
				header: "Unable to create StreatSmart account",
				message: error.message,
				buttons: ["Ok"]
			})
			await alert.present()
		}

	}

	private createFireBaseAccount(email: string, password: string){
		/*if (firebase.auth().currentUser != null && firebase.auth().currentUser.isAnonymous)
			{return firebase.auth().currentUser.linkWithCredential(firebase.auth.EmailAuthProvider.credential(email,password))
				.then((user) => {
					console.log("Anonymous account successfully upgraded",user);
					return;
				});}
		else{
			return firebase.auth().createUserWithEmailAndPassword(email, password)
				.then(() => {
					console.log("New user successfully created");
					return;
				});
		}*/
	}

	public async signin(email: string, password: string): Promise<firebase.auth.UserCredential | void>{
		const signinLoader = await this.loadingCtrl.create({message: 'Signing you in...'});
		signinLoader.present();
		return firebase.auth().signInWithEmailAndPassword(email,password)
			.then((resp) => {
				signinLoader.dismiss();
				return resp;
			})
			.then(() => {
				if (this.viewMgr.isNative()) firebase.functions().httpsCallable("service_user_signin")().catch(() => {});
				this.analyticsService.logEvent("ss_login")
			})
			.catch(async (error) => {
				signinLoader.dismiss();
				const errorAlert = await this.alertCtrl.create({
					header: 'Signin failed!',
					subHeader: error.message,
					buttons:['Ok']
				});
				await errorAlert.present();
			});
	}

	public async logout(){
		const signoutLoader = await this.loadingCtrl.create({message: 'Signing you out...'});
		signoutLoader.present();
		try{
			this.router.navigate(['/'])
			if (this.viewMgr.isNative() && this.userService.getRole() !== UserRole.GUEST){
				firebase.functions().httpsCallable("service_user_signout")().catch(() => {});
				this.backgroundLocService.off();
			}
			
			this.subscriptionManager.destroyAllSubs();
			return firebase.auth().signOut()
				.then(() => {
					this.analyticsService.logEvent("ss_sign_out")
					this.userService.deconstructor();
					if (this.vendorService.getVendorFocus()) {this.vendorService.deconstructor();}
					signoutLoader.dismiss();
				})
		}catch(error){
			signoutLoader.dismiss();
			const errorAlert = await this.alertCtrl.create({
				header: 'Signout failed!',
				subHeader: error.message,
				buttons:['Ok']
			});
			await errorAlert.present();
		}
		
		
	}

	/*getCurrentUserId(): Promise<any>{
		/*if (this.userId){
			//console.log("User ID already stored: " + this.userId);
			return Promise.resolve(this.userId);
		}else{
			return Promise.resolve(firebase.auth().currentUser.uid)
				.then((userId) => {
					console.log("Setting UserId");
					this.setUserId(userId);
					return Promise.resolve(this.userId);
				})
				.catch((error) => console.log(error.message));
		}
	}*/



	/*public async resetPwEmail(email?: string){
		const tmpValue = email ? email : "";
		const alert = await this.alertCtrl.create({
			header: 'Please enter email address associated to account',
			//message: error.message,
			inputs: [
				{
					name: 'email',
					type: 'email',
					placeholder: "Email Address",
					value: tmpValue
				}
			],
			buttons:[{
				text: 'Send Password Email',
				handler: input => {
					firebase.auth().sendPasswordResetEmail(input.email)
						.then(async () => {
							console.log("Email Sent");
							const alert2 = await this.alertCtrl.create({
								header: "Email Sent",
								buttons:[{
									text: "Got It"
								}]
							});
							await alert2.present();
						})
						.catch(async (error )=> {
							const alert2 = await this.alertCtrl.create({
								header: error.message,
								buttons:[{
									text: "Got It"
								}]
							});
							await alert2.present();
						});
				}
			},
			{
				text: 'Cancel',
				role: 'cancel'
			}
			]
		});
		await alert.present();
	}*/


	async forgotPassword(email?: string){
		const alert = await this.alertCtrl.create({
			header: 'Please enter the email address associated to your account',
			message: "A password reset link will be sent to the entered email",
			inputs: [
				{
					name: 'email',
					type: 'email',
					placeholder: "Email Address"
				}
			],
			buttons:[{
				text: 'Send Password Link',
				handler: input => {
					firebase.auth().sendPasswordResetEmail(input.email)
						.then(async () => {
							console.log("Email Sent");
							const alert2 = await this.alertCtrl.create({
								header: "Email Sent",
								buttons:[{
									text: "Got It"
								}]
							})
							alert2.present();
						})
						.catch(async (error )=> {
							const alert2 = await this.alertCtrl.create({
								header: error.message,
								buttons:[{
									text: "Got It"
								}]
							})
							alert2.present();
						});
				}
			},
			{
				text: 'Cancel',
				role: 'cancel'
			}
			]
		});
		alert.present();
		
		
		
		/*const alert = await this.alertCtrl.create({
			header: "Reset Password",
			subHeader: 'A password reset link will be sent to the email associated with this account: ' + this.userService.getEmail(),	
			buttons:[{
				text: 'Send',
				handler: input => {
					firebase.auth().sendPasswordResetEmail(this.userService.getEmail())
						.then(async () => {
							const alert2 = await this.alertCtrl.create({
								subHeader: "Password reset link successfully sent",
								buttons:[{
									text: "OK"
								}]
							})
							await alert2.present();
						})
						.catch(async (error )=> {
							const alert2 = await this.alertCtrl.create({
								header: error.message,
								buttons:["Ok"]
							})
							await alert2.present();
						});
				}
			},
			{
				text: 'Cancel',
				role: 'cancel'
			}
			]
		});
		await alert.present();*/
	}
	

	async setPassword(username: string){
		const alert = await this.alertCtrl.create({
			header: "Change Password",
			subHeader: 'You will be redirected to another webpage to change your password for ' + username,
			buttons: [{
				text: "Okay",
				handler: async () => {
					try{
						const resetPassword = firebase.functions().httpsCallable("service_user_resetPassword");
						const response: RemoteResponse = await resetPassword();
						console.log(response);
						if (response.data.statusCode !== RemoteResponseStatus.SUCCESS){
							const alert = await this.alertCtrl.create({
								header: "Unable to generate password reset link",
								message: response.data.msg,
								buttons: ["Ok"]
							})
							await alert.present()
						}else{
							this.mediaNav.openWebURL(response.data.data);
						}
					}catch(error){
						const alert = await this.alertCtrl.create({
							header: "Unable to generate password reset link",
							message: error.message,
							buttons: ["Ok"]
						})
						await alert.present()
					}
					
				}
			},
			{
				text: "Cancel",
				role: 'cancel'
			}
		]
		})
		await alert.present();
	}


	

	async setUserName(username: string){
		const signInAlert = await this.alertCtrl.create({
			header: `Please sign in to change username.`,
			inputs:[{
					name: 'email',
					type: 'email',
					placeholder: "Username/Email Address",
				},
				{
					name: 'password',
					type: 'password',
					placeholder: 'Password'
				}
			],
			buttons: [{
				text: 'Change Username',
				handler: (data) => {
					return this.signin(data.email, data.password)
						.then(async (resp: any) => {
							if (resp){
								const loader = this.loadingCtrl.create({
									message: "Updating Username"
								});
								
								(await loader).present();
								
								try{
									const updateUserName = firebase.functions().httpsCallable("service_user_updateUserName");
									await updateUserName(username);
									(await loader).dismiss();
									const successAlert = await this.alertCtrl.create({header: "Successfully updated username", buttons: ["OK"]});
									await successAlert.present();
									
								}catch(error){
									(await loader).dismiss();
									const alert = await this.alertCtrl.create({
										header: "Unable to update username",
										message: error.message,
										buttons: ["Ok"]
									})
									await alert.present()
								}
								
							}
						},(resp: any) => {
							console.log(resp);
						})
						.catch(async (error) => {
							const errorAlert = await this.alertCtrl.create({
								header: error.message,
								buttons: ["OK"]
							})
							await errorAlert.present();
						})
	
				}
			},{
				text: 'Cancel',
				role: 'cancel'
			}]
		})				
		await signInAlert.present();	
	}

	public async deleteAccount(){
		const alert = await this.alertCtrl.create({
				header: 	`
					Are you sure you want to delete this account?
					All user & vendor data will be permanently deleted.
					Please sign in to delete account.
						`,
				inputs:[{
						name: 'email',
						type: 'email',
						placeholder: "Username/Email Address",
					},
					{
						name: 'password',
						type: 'password',
						placeholder: 'Password'
					}
				],
				buttons: [{
					text: 'Delete account',
					role: "destructive",
					handler: data => {
						let _data = data;
						return firebase.auth().signInWithEmailAndPassword(_data.email,_data.password)
							.then(async (data) => {
								if (data){
									console.log(data);
									const loader = await this.loadingCtrl.create({
										message: "Deleting User Account"
									});
									loader.present();
	
									try{
										const deleteUser = firebase.functions().httpsCallable("service_user_deleteUser");
										const response: RemoteResponse = await deleteUser(this.userService.getID());
										loader.dismiss();
										if (response.data.statusCode != RemoteResponseStatus.SUCCESS){
											const alert = await this.alertCtrl.create({
												header: "Unable to delete StreatSmart account",
												message: response.data.msg,
												buttons: ["Ok"]
											})
											await alert.present()
										}else{
											//await this.router.navigate(['']);
											await this.logout();
										}
									}catch(error){
										loader.dismiss();
										const alert = await this.alertCtrl.create({
											header: "Unable to delete StreatSmart account",
											message: error.message,
											buttons: ["Ok"]
										})
										await alert.present()
									}
								}								
							})
					}
				},{
					text: 'Cancel',
					role: 'cancel'
				}]
		})
		alert.present();
	}
}
