import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
/***Models***/
import { IPlace } from 'src/app/app-domain/place';
import { CallNumberService, MediaNavigatorService } from '../../../../app-services';

/***Components */
import { VenueCalendarModalComponent } from '../../modals';

/***Plugins */
import { LaunchNavigator, LaunchNavigatorOptions } from '@awesome-cordova-plugins/launch-navigator/ngx';


@Component({
	selector: 'venue-card',
	templateUrl: './venue-card.component.html',
	styleUrls: ['venue-card.component.scss']
})
export class VenueCardComponent implements OnInit {
	@Input() venue: IPlace;

  constructor(private modalCtrl: ModalController, private launchNavigator: LaunchNavigator,
    private mediaNavigator: MediaNavigatorService, private callNumberService: CallNumberService
  ) { }
	
	ngOnInit() {}

	public async onCalendar(venue: IPlace){
		const modal = await this.modalCtrl.create({
			component: VenueCalendarModalComponent,
			componentProps: {venue: venue},
			breakpoints: [0,.66,1],
			initialBreakpoint: 1,
			backdropBreakpoint: .4,
			backdropDismiss: true
		})
		await modal.present();
  }

  public onOpenWebURL() {
    this.mediaNavigator.openWebURL(this.venue.getWebsite());
  }

  public onNavigate() {
    this.launchNavigator.navigate([this.venue.getLat(), this.venue.getLng()])
      .then(() => {
        console.log("Navigation Launched")
      })
  }

  /*public onCall() {
    this.callNumberService.call(this.venue.getPhone());
  }*/



}
