import firebase from 'firebase/compat/app';
import 'firebase/compat/remote-config';
import 'firebase/compat/database';
import {Injectable } from '@angular/core';
import { UserService } from "../../app-domain/user/index";
import { environment } from "../../../environments/environment";
import { NotificationService } from './notification.service.abstract';
import { NotificationHandlerService } from './notification-handler.service';
import { PushNotifications, Token, PushNotificationSchema, ActionPerformed} from "@capacitor/push-notifications";
import { FirebaseMessaging, NotificationReceivedEvent } from "@capacitor-firebase/messaging";
import { GeofenceService } from '../geofence.service';
import { GeofencePayload } from 'src/app/app-domain/location/geofence.contracts';
import { AnalyticsService } from '../analytics-services';

@Injectable()
export class FCMNotificationService extends NotificationService{
    private isRegistered: boolean = false;
    private token: string;
	constructor(
		userService: UserService, notificationHandler: NotificationHandlerService, private geofenceService: GeofenceService,
        private analyticsService: AnalyticsService
	){
		super(userService, notificationHandler);
        console.log("Registering")
       // this.registerNotificationToken();
	}

//take a look here make sure were doing this right for firebase
	async registerNotificationToken(): Promise<any>{
        if (!this.isRegistered){
            this.isRegistered = true;
            const permissionStatus = await PushNotifications.requestPermissions();
            if (permissionStatus.receive === "granted"){
                const register = PushNotifications.register();
            }
    
            PushNotifications.addListener("registration", (token: Token) =>{
                console.log("Push registration success, token: " + token.value);
               // return firebase.messaging().getToken({"vapidKey": environment.vapid})
                //.then((curToken) => {
                this.token = token.value;
                    return firebase.database().ref("_users/" + this.userService.getID() + "/notificationToken/").set(token.value)
                        .then(() => console.log("Notification Token Set"))
                        .catch(error => console.log(error.message));
                //})
            });
    
              // Some issue with our setup and push will not work
            PushNotifications.addListener('registrationError', (error: any) => {
                //alert('Error on registration: ' + JSON.stringify(error));
            });
    
             // Show us the notification payload if the app is open on our device
            PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
                //alert('Push received: ' + JSON.stringify(notification));
            });
    
            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
                //alert('Push action performed: ' + JSON.stringify(notification));
                if (notification.actionId === "tap"){console.log("BELOW");
                    console.log(notification.notification.data._topic);
                    this.analyticsService.logEvent("ss_notification_open",{notificationTopic: <string>notification.notification.data._topic})
                    console.log("Notification Tapped");
                    this.notificationHandler.handleNotification(notification.notification);
                }
            }) 
            //Using different plugin for silent notifications
            FirebaseMessaging.addListener('notificationReceived', async (event: NotificationReceivedEvent) => {
                console.log("Silent notification received");
                console.log("Payload: " + JSON.stringify(event.notification.data));
                //firebase.database().ref("/test").push(Object.assign({},{"data": JSON.stringify(event), type: "Firebase Messaging"}));
    
                const notification: any = event.notification.data;
                const geofencePayload: string = notification.geofencePayload;
                //await this.geofenceService.updateGeofencesOnLocalDevice(<GeofencePayload>JSON.parse(geofencePayload));
            })
        }else{
            return firebase.database().ref("_users/" + this.userService.getID() + "/notificationToken/").set(this.token)
            .then(() => console.log("Notification Token Set"))
            .catch(error => console.log(error.message));
        }
       
    }

    async subscribeToTopic(topic: string): Promise<firebase.functions.HttpsCallableResult>{
        const subscribeToTopic = firebase.functions().httpsCallable("service_notifications_subscribeToNotificationTopic");
        const remoteResponse: firebase.functions.HttpsCallableResult = await subscribeToTopic(topic);
        return remoteResponse.data;
    }
    

    async getAllSubscribedTopics(): Promise<string[]>{
        const getAllTopics = firebase.functions().httpsCallable("service_notifications_getAllSubscribedTopics");
        const topics: firebase.functions.HttpsCallableResult = await getAllTopics("null");
        return topics.data;
    }

    public async unsubscribeFromTopic(topic: string): Promise<any> {
        const unsubscribeFromTopic = firebase.functions().httpsCallable("service_notifications_unsubscribeFromNotificationTopic");
        const remoteResponse: firebase.functions.HttpsCallableResult = await unsubscribeFromTopic(topic);
        return remoteResponse.data;
    }
	
}
 