import { ViewChild, ElementRef } from "@angular/core";
import {IonButton} from "@ionic/angular";
import { AbstractButtonComponent } from "./abstract-button";

/***Services */
import { ViewManagerService, App } from "src/app/app-services/index";



export abstract class NativeFeatureButton extends AbstractButtonComponent{
	@ViewChild(IonButton, {read: ElementRef}) protected ionButton: ElementRef;
	constructor(private viewManager: ViewManagerService){
		super()
	}

    visible(){
        if (this.viewManager.getPlatform() === App.WEB){
            this.ionButton.nativeElement.style.visibility = "hidden";
        };
    }

    
}
