import { Component } from '@angular/core';
import { Router } from "@angular/router";


import {UserService} from "../../app-domain/user/index";



@Component({
  selector: 'app-tabs',
  templateUrl: 'app-tabs.component.html',
  styleUrls: ['app-tabs.component.scss']
})
export class TabsComponent {

	constructor(
		private userService: UserService,
		private router: Router
	) {

	}


	ionViewDidEnter() {

	   // document.querySelector('#tab-button-tab3').shadowRoot.querySelector('.button-native').setAttribute('style', 'margin-top: -2px');
	}

	ngOnInit(){
		//this.layout = this.userService.getLayout();
		console.log(this.userService);
	}




}
