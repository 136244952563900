import {Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
/***Models***/
import {IMenuItemData, IMenuTypeData } from "../../../app-domain/menu/index";
/***Interfaces */
import { Persistable } from "../../../app-domain/index";
import { RemotePayload } from "../../../app-interfaces/index";
/***Services */
import { MenuService } from "./menu.service.abstract"



@Injectable()
export class FirebaseMenuService extends MenuService{

	constructor(){
		super();
		/*if (window.location.host.includes('localhost')){
			firebase.functions().useFunctionsEmulator('http://localhost:5001');
		}*/
	}


	public addMenuItem(menuItem: Persistable<IMenuItemData>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<IMenuItemData> = this.createPayload<IMenuItemData>(menuItem, dataOptions);
		console.log(payload);
		const addMenuItem = firebase.functions().httpsCallable("service_menu_addMenuItem");
		return addMenuItem(payload);
	}

	public updateMenuItem(menuItem: Persistable<IMenuItemData>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<IMenuItemData> = this.createPayload<IMenuItemData>(menuItem, dataOptions);
		console.log(payload);
		const updateMenuItem = firebase.functions().httpsCallable("service_menu_updateMenuItem");
		return updateMenuItem(payload);
	}

	public deleteMenuItem(menuItem: Persistable<IMenuItemData>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<IMenuItemData> = this.createPayload<IMenuItemData>(menuItem, dataOptions);
		const deleteMenuItem = firebase.functions().httpsCallable("service_menu_deleteMenuItem");
		return deleteMenuItem(payload);
	}


	public toggleMenuItem(menuItem: Persistable<IMenuItemData>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<IMenuItemData> = this.createPayload<IMenuItemData>(menuItem, dataOptions);
		const toggleMenuItem = firebase.functions().httpsCallable("service_menu_toggleMenuItem");
		return toggleMenuItem(payload);
	}

	public toggleSubMenu(menu: Persistable<{[key: string]: IMenuItemData}>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<{[key: string]: IMenuItemData}> = this.createPayload<{[key: string]: IMenuItemData}>(menu, dataOptions);
		const toggleSubMenu = firebase.functions().httpsCallable("service_menu_toggleSubMenu");
		return toggleSubMenu(payload);
	}

	public reorderSubMenu(menu: Persistable<{[key: string]: IMenuItemData}>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<{[key: string]: IMenuItemData}> = this.createPayload<{[key: string]: IMenuItemData}>(menu, dataOptions);
		const reorderSubMenu = firebase.functions().httpsCallable("service_menu_reorderSubMenu");
		return reorderSubMenu(payload);
	}

	public deleteSubMenu(menu: Persistable<{[key: string]: IMenuItemData}>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<{[key: string]: IMenuItemData}> = this.createPayload<{[key: string]: IMenuItemData}>(menu, dataOptions);
		const deleteSubMenu = firebase.functions().httpsCallable("service_menu_deleteSubMenu");
		return deleteSubMenu(payload);
	}


	public deleteMenuType(menuType: Persistable<IMenuTypeData>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<IMenuTypeData> = this.createPayload<IMenuTypeData>(menuType, dataOptions);
		const deleteMenuType = firebase.functions().httpsCallable("service_menu_deleteMenuType");
		return deleteMenuType(payload);
	}

	public updateMenuType(menuType: Persistable<IMenuTypeData>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<IMenuTypeData> = this.createPayload<IMenuTypeData>(menuType, dataOptions);
		const updateMenuType = firebase.functions().httpsCallable("service_menu_updateMenuType");
		return updateMenuType(payload);
	}


	public addMenuType(menuType: Persistable<IMenuTypeData>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<IMenuTypeData> = this.createPayload<IMenuTypeData>(menuType, dataOptions);
		const addMenuType = firebase.functions().httpsCallable("service_menu_addMenuType");
		return addMenuType(payload);
	}

	public reorderMenuTypes(menuType: Persistable<IMenuTypeData>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<IMenuTypeData> = this.createPayload<IMenuTypeData>(menuType, dataOptions);
		const reorderMenuTypes = firebase.functions().httpsCallable("service_menu_reorderMenuTypes");
		return reorderMenuTypes(payload);
	}


	/*private createPayload(persistable: Persistable<{[key: string]: IMenuItemData}>, dataOptions: {[key: string]: any}): RemotePayload<{[key: string]: IMenuItemData}>;
	private createPayload(persistable: Persistable<IMenuItemData>, dataOptions: {[key: string]: any}): RemotePayload<IMenuItemData>;
	private createPayload(persistable: Persistable<IMenuItemData>, dataOptions: {[key: string]: any}): RemotePayload<IMenuTypeData>;
	private createPayload(persistable: Persistable<IMenuTypeData> | Persistable<{[key: string]: IMenuItemData}> | Persistable<IMenuItemData>, dataOptions: {[key: string]: any}): RemotePayload<IMenuItemData> | RemotePayload<{[key: string]: IMenuItemData}> | RemotePayload<IMenuTypeData> {
		console.log(dataOptions)
		if (dataOptions.activeInd === undefined){
			const payload: RemotePayload<{[key: string]: IMenuItemData}> = <RemotePayload<{[key: string]: IMenuItemData}>>{};
			payload.id = persistable.getID();
			payload.data = persistable.getData() as {[key: string]: IMenuItemData};
			Object.keys(dataOptions).forEach((option: string) => {payload[option] = dataOptions[option];})
			return payload;
		}else{
			const payload: RemotePayload<IMenuItemData> = <RemotePayload<IMenuItemData>>{};
			payload.id = persistable.getID();
			payload.data = persistable.getData() as IMenuItemData;
			Object.keys(dataOptions).forEach((option: string) => {payload[option] = dataOptions[option];})
			return payload;
		}

		const payload: RemotePayload<{[key: string]: IMenuItemData}> = <RemotePayload<{[key: string]: IMenuItemData}>>{};
		payload.id = persistable.getID();
		payload.data = persistable.getData() as {[key: string]: IMenuItemData};
		Object.keys(dataOptions).forEach((option: string) => {payload[option] = dataOptions[option];})
		return payload;

	}*/

	private createPayload<T>(persistable: Persistable<T>, dataOptions: {[key: string]: any}): RemotePayload<T>{
		const payload: RemotePayload<T> = <RemotePayload<T>>{};
		payload.id = persistable.getID();
		payload.data = persistable.getData();
		if (dataOptions) Object.keys(dataOptions).forEach((option: string) => {payload[option] = dataOptions[option];});
		return payload;
	}

}

