/***Models***/
import { IVendorData } from "../../../app-domain/vendor";
/***Interfaces */
import { Persistable } from "../../../app-domain/index";
import { RemoteResponse } from "../../../app-interfaces";



export abstract class PermissionsService{
    constructor(){

    }

  public abstract grantPermissions(vendor: Persistable<IVendorData>, dataOptions?: { [key: string]: any }): Promise<RemoteResponse>
  public abstract removePermissions(vendor: Persistable<IVendorData>, dataOptions?: { [key: string]: any }): Promise<RemoteResponse>
  public abstract getPermissions(vendor: Persistable<IVendorData>, dataOptions?: { [key: string]: any }): Promise<RemoteResponse>
}
