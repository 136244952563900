import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from "@ionic/angular";
import { VendorService } from "../../../../../app-domain/vendor/index";
import { Preference, VendorPreferenceRepo } from 'src/app/app-domain/preference';

interface CalOption {
  display: string,
  value: string
}

type SyncValue = "Apple" | "Google" | "Facebook" | "None" | "Unavailable"

@Component({
	templateUrl: 'calendar-sync.page.html',
	styleUrls: ['calendar-sync.page.scss']
})
export class CalendarSyncPage {
	public calOptions: CalOption[] = [];
	public syncValue: SyncValue;
	
	constructor(private vendorService: VendorService, private modalCtrl: ModalController,
	private router: Router, private vendorPrefRepo: VendorPreferenceRepo
	) {
		
	}

	async ionViewDidEnter() {
		const { subKey } = this.vendorService.getVendorFocus().getPrefByKeys("eventSync") || {};
		this.syncValue = subKey ? <SyncValue>subKey : "None";
		if (this.vendorService.getVendorFocus().hasGoogleCalendar()) this.calOptions.push({ display: "Google Calendar", value: 'Google' });
		if (this.vendorService.getVendorFocus().hasICal()) this.calOptions.push({ display: "Apple Calendar", value: 'Apple' });
		if (this.calOptions.length > 0) {
			this.calOptions.push({ display: "No Calendar Selected", value: "None" })
		} else {
			this.calOptions.push({ display: "No Available Calendars", value: "Unavailable" });
			this.syncValue = "Unavailable";
		}

		console.log(this.calOptions);
		console.log(this.syncValue);

	}


	navToVendorSettings() {
		this.dismiss();
		this.router.navigate(["vendor/settings"]);
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}

	async updateEventSync(){
		if (this.syncValue === "Apple" || this.syncValue === "Google"){
			await this.vendorPrefRepo.update(this.vendorService.getVendorFocus().getVendorID(), new Preference(true, 'eventSync', this.syncValue))
		}else{
			await this.vendorPrefRepo.delete(this.vendorService.getVendorFocus().getVendorID(), new Preference(null, "eventSync"));
		}
	}
}
