import { Component, Input, ViewChild, ElementRef  } from '@angular/core';
import { Platform} from '@ionic/angular';
import { SwiperComponent } from '../swiper.base.component';
import { IVendor } from 'src/app/app-domain/vendor';

@Component({
	selector: 'vendor-swiper',
	templateUrl: './vendor-swiper.component.html',
	styleUrls: ['vendor-swiper.component.scss']
})
export class VendorSwiperComponent extends SwiperComponent {
	@ViewChild("swiper") private swiperRef: ElementRef;
	@Input() vendors: IVendor[];

  	constructor(platform: Platform) {
    	super(platform)
	}
	
	public getSlidesPerView(){
		const screenWidth = this.platform.width();
		if (screenWidth < 350) {
			return 1;
		} else if (screenWidth < 1000) {
			return 1.5;
		} else if (screenWidth < 1400) {
			return 2.5;
		} else {
			return 2.5;
		}
	}

}
