import { AnalyticsService } from "../analytics-services";
import { GeofenceService } from "../geofence.service";
import { NotificationHandlerService } from "./notification-handler.service";
import { NotificationService } from "./notification.service.abstract";
import { NotificationServiceFactory } from "./notification.service.factory";
import { UserService } from "src/app/app-domain/user/index";

export let NotificationServiceProvider = {
    provide: NotificationService,
    useFactory: NotificationServiceFactory,
    deps: [UserService, NotificationHandlerService, GeofenceService, AnalyticsService]
}