import {Injectable, NgZone } from '@angular/core';
import { UserService } from "../../app-domain/user/index";
import { Router } from '@angular/router';
import { Notification, NotificationTopic } from './notification.contracts';
import { ModalController } from '@ionic/angular';
import { VerifiedEventPage } from 'src/app/module-vendor/vendor-pages/vendor-scheduling';
import { LocalStorageService } from '../native-services';
import { NAVIGATION_TOPIC } from '../native-services/local-storage-services/local-storage.constants';
import { LaunchNavigator, LaunchNavigatorOptions } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { VendorReviewsPage } from 'src/app/module-vendor/vendor-pages/vendor-reviews/vendor-reviews.page';



@Injectable({providedIn: "root"})
export class NotificationHandlerService{

	constructor(
		private userService: UserService, private router: Router, private modalCtrl: ModalController, private ngZone: NgZone,
        private localStorageService: LocalStorageService, private launchNavigator: LaunchNavigator
	){

	}

    public handleNotification(payload: Notification){
        this.ngZone.run(async () => {
            console.log(JSON.stringify(payload));
           
            if (this.userService.isExpired() && !(await this.deferredNotificationExists())){
                console.log("App session expired- storing notification")
                //if app is expired, it will be refreshed on resume when notification is tapped, need to store notification topic and re-handle notification after refresh 
                await this.storeDeferredNotification(payload);
            }else{
                console.log("Routing Topic ")
                console.log(payload.data._topic);
                switch(payload.data._topic){
                    case NotificationTopic.NEW_VENDOR:  //need to test
                        await this.router.navigate(["/user/trucks/"]); //this will provide a back button
                        await this.router.navigate(["/user/trucks/vendor/" + payload.data.vendorId]); 
                        break;
                    case NotificationTopic.DAILY_EVENTS: //tested successfully
                        await this.router.navigate(["/user/map/"]); 
                        break;
                    case NotificationTopic.ADDED_EVENT: //tested successfully
                        await this.router.navigate(["/user/trucks/"]); //this will provide a back button
                        await this.router.navigate(["/user/trucks/vendor/" + payload.data.vendorId]); 
                        break;
                    case NotificationTopic.OPEN_TODAY:  //tested successfully
                        await this.router.navigate(["/user/trucks/"]); //this will provide a back button
                        await this.router.navigate(["/user/trucks/vendor/" + payload.data.vendorId]); 
                        break;
                    case NotificationTopic.HOTSPOT:  //tested successfully
                        await this.router.navigate(['/vendor/scheduling']);
                        const modal = await this.modalCtrl.create({
                        component: VerifiedEventPage
                        })
                        await modal.present();
                        break;
                    case NotificationTopic.REMINDER:  //tested successfully
                        await this.router.navigate(['/vendor/scheduling']); 
                        break;
                    case NotificationTopic.PROXIMITY: 
                        this.launchNavigator.navigate([parseFloat(payload.data.lat),parseFloat(payload.data.lng)]);
                        break;
                    case NotificationTopic.NEW_CUSTOMER_REVIEW:
                        await this.router.navigate(['/vendor/stats']);
                        const modal2 = await this.modalCtrl.create({
                            component: VendorReviewsPage
                        });
                        await modal2.present();
                        break;
                }
           }
            
        })
    }

    public async handleDeferredNavigation(){
        const deferredNotification = <Notification>JSON.parse(await this.localStorageService.get(NAVIGATION_TOPIC));
        console.log("Deferred");
        console.log(deferredNotification);
        if (deferredNotification){
            this.handleNotification(deferredNotification);
            this.localStorageService.remove(NAVIGATION_TOPIC);
            return;
        }
        return;
    }

    private async storeDeferredNotification(notification: Notification): Promise<void>{
        return this.localStorageService.set(NAVIGATION_TOPIC,JSON.stringify(notification)); 
    }

    private async deferredNotificationExists(): Promise<boolean>{
        const notification = await this.localStorageService.get(NAVIGATION_TOPIC);
        return notification ? true : false;
    }

	
    //This will not be used yet as fcm/notifications on capacitor aren't supported at the moment, will need to leverage service worker???
    //Using firebase messaging capacitor plugin, fcm-notification.service handles all silent notifications delegating work to geofence.service
    public handleSilent(topic: string){

    }

}
 