/***Interfaces */
import { Persistable } from "src/app/app-domain";
import { RemoteResponse } from "../../../app-interfaces";
import { FeatureRequestData } from "src/app/app-domain/feature-request/feature-request-data.interface";



export abstract class FeatureRequestService{
  constructor(){}

  public abstract addFeatureRequest(data: Persistable<FeatureRequestData>): Promise<RemoteResponse>

  public abstract updateFeatureRequest(data: Persistable<FeatureRequestData>): Promise<RemoteResponse>

  public abstract deleteFeatureRequest(data: Persistable<FeatureRequestData>): Promise<RemoteResponse>

  public abstract completeFeatureRequest(data: Persistable<FeatureRequestData>): Promise<RemoteResponse>

  public abstract approveFeatureRequest(data: Persistable<FeatureRequestData>): Promise<RemoteResponse>

  public abstract rejectFeatureRequest(data: Persistable<FeatureRequestData>): Promise<RemoteResponse>

  public abstract supportFeatureRequest(data: Persistable<FeatureRequestData>): Promise<RemoteResponse>

  public abstract unsupportFeatureRequest(data: Persistable<FeatureRequestData>): Promise<RemoteResponse>

}
