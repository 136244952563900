import {Injectable } from '@angular/core';

/***Models***/
import {IVendor, IVendorData, VendorRepo } from "../app-domain/vendor/index";
import { IRepoRead } from "../app-domain/shared-interfaces/index";




@Injectable()
export class VendorCacheService implements IRepoRead<IVendor, IVendorData>{
	private vendors: IVendor[] = [];

	constructor(
		private vendorRepo: VendorRepo
	){}


	public async getByID(id: string, id2?: string): Promise<IVendor>{
		const cachedVendor: IVendor = this.findVendorInCache(id);
		if (cachedVendor){
			return Promise.resolve(cachedVendor);
		}else{
			const vendor: IVendor = await this.vendorRepo.getByID(id);
			this.addVendorToCache(vendor);
			return vendor;
		}
	}

	public async getByIDAndSubscribe(id: string, id2?: string): Promise<IVendor>{
		const cachedVendor: IVendor = this.findVendorInCache(id);
		if (cachedVendor){
			return Promise.resolve(cachedVendor);
		}else{
			const vendor = await this.vendorRepo.getByIDAndSubscribe(id);
			this.addVendorToCache(vendor);
			return vendor;
		}
	}


	private findVendorInCache(vendorID: string){
		const vendor = this.vendors.filter((vendor: IVendor) => vendor.getVendorID() === vendorID);

		if (vendor.length > 0){
			return vendor[0];
		}

		return;
	}

	private addVendorToCache(vendor: IVendor){
		if (vendor != undefined) this.vendors.push(vendor);
	}
}

