import { TopicGroupOptions, TopicGroup } from "./index";

export interface TopicProps{
    key: string,
    value?: boolean,
    prefix?: string,
    display: string,
    description: string,
    category: TopicGroupOptions
}


export class Topic {
	private key: string;
	private value: boolean;
    private prefix: string;
    private hasPrefix: boolean;
	private display: string;
    private description: string;
    private category: string;
    private group: TopicGroup;

    constructor(properties: TopicProps){
        Object.assign(this, properties)
        if (this.prefix){this.hasPrefix = true;}
        if (this.value === undefined) this.value = false;
	}

    public isActive(): boolean{
        return this.value;
    }

    public setValue(val: boolean){
        this.value = val;
        if (val){
            this.group.addUserTopic(this.getTopic());
        }else{
            this.group.removeUserTopic(this.getTopic());
        }
        console.log(this.group)
    }

    public reconcileValue(val: boolean){
        this.value = val;
    }

    public produceTopicName(): string{
        return this.isActive() ? this.prefix + "_" + this.key : "";
    }

    public setPrefix(prefix: string){
        this.prefix = prefix;
    }

    public getCategory(): string{
        return this.category
    }

    public getTopic(): string{
        if (this.hasPrefix){
            return this.prefix + "_" + this.key;
        }else{
            return this.key;
        }
    }

    public getPrefix(){
        return this.prefix;
    }

    public getKey(){
        return this.key;
    }

    public setTopicGroup(group: TopicGroup){
        this.group = group;
    }

    public getDescription(): string{
        return this.description
    }

 
}
