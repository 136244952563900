import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './app-guards/admin.guard';
import { AdminPage } from './module-admin';
import { VendorGuard } from './app-guards/vendor.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./app-pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'app',
    loadChildren: () => import('./app-components/app-tabs/app-tabs.module').then(m => m.TabsPageModule)
  },
    {
    path: 'user',
    loadChildren: () => import('./module-customer/customer.module').then(m => m.CustomerModule)
  },
    {
    path: 'vendor',
    loadChildren: () => import('./module-vendor/vendor.module').then(m => m.VendorModule),
    canActivate: [VendorGuard]
  },
  {
    path: 'preferences/notifications',
    loadChildren: () => import('./app-pages/notifications/notifications.module').then(m => m.NotificationsPageModule)
  },
  {
    path: 'user/settings',
    loadChildren: () => import('./module-customer/pages/user-settings/user-settings.module').then(m => m.UserSettingsPageModule)
  },
  {
    path: "registration",
    loadChildren: () => import("./app-pages/registration/registration.module").then(m => m.RegistrationPageModule)
  },
  {
    path: "toggle-profile",
    loadChildren: () => import("./module-vendor/vendor-pages/toggle-profile/toggle-profile.module").then(m => m.ToggleProfilePageModule)
  },
  {
    path: 'admin',
    component: AdminPage,
    canActivate: [AdminGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
