import {Injectable} from '@angular/core';

/***Models***/
import { IVendor, VendorRepo } from "src/app/app-domain/vendor/index";
import { UserService, IUser } from "src/app/app-domain/user/index";

/***Interfaces */
import { Observer } from "src/app/app-interfaces/index";


@Injectable()
export class FavoritesService implements Observer<IUser>{
	private favorites: IVendor[] = [];
    private userID: string;

	constructor(
		private vendorRepo: VendorRepo,
        private userService: UserService
	){
        window.setTimeout(() => {
            this.userService.subscribeToChanges(this);
            this.init();
        },500);
    }

    async init(){
        this.userID = this.userService.getID();
        const favoriteIDs = this.userService.getFavorites();
        await this.reconstituteFavorites(favoriteIDs);
        console.log(this.favorites)
    }

    public async getFavorites(): Promise<IVendor[]>{
        if (this.userID != this.userService.getID()){
            console.log("Awaiting initilaization")
            await this.init();
        } 
        return this.favorites;
    }

    public update(user: IUser): void{
		this.reconstituteFavorites(user.getFavorites());
	}

	public getID(): string{
		return "service.favorites";
	}

    private async reconstituteFavorites(vendorIDs: {[key: string]: boolean}){
        if (vendorIDs && Object.keys(vendorIDs).length > 0){
            this.favorites = await this.vendorRepo.getByIDsAndSubscribe(Object.keys(vendorIDs));
            this.removeDeletedVendorsFromFavorites();
        }else{
            this.favorites = [];
        }
    }

    private removeDeletedVendorsFromFavorites(){
        this.favorites.filter((vendor: IVendor) => {
            return vendor?.getName();
        })
    }

}
