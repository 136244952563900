import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import {Injectable} from '@angular/core';

import { AnalyticsService } from './analytics.service.abstract';

import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { environment } from 'src/environments/environment';
import { ViewManagerService } from '../view-manager.service';

@Injectable()
export class GoogleAnalytics4Service extends AnalyticsService{

	constructor(viewManagerService: ViewManagerService
	){
		super();
		if (!viewManagerService.isNative()){
			FirebaseAnalytics.initializeFirebase(environment.fb);
		}
	}

	public logEvent(eventName: string, eventParams?: {[key: string]: any}) {
		FirebaseAnalytics.logEvent({"name": eventName, params: eventParams});
		//firebase.analytics().logEvent(eventName, eventParams)
	}

	public setUserID(id: string) {
		//firebase.analytics().setUserId(id);
		FirebaseAnalytics.setUserId({userId: id});
	}

	public setUserProperties(property: string, value: string) {
		//firebase.analytics().setUserProperties({[property]: value})
		FirebaseAnalytics.setUserProperty({name: property, value: value});
	}

}
