import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ModalController, AlertController, LoadingController } from "@ionic/angular";
import { IEventRead, EventRepo, IEventData } from "../../../../../app-domain/scheduling/index";
import { VendorEventBuilderPage } from "../../index";
import { Persistable } from "../../../../../app-domain/shared-interfaces/index";
import { RemoteResponse, RemoteResponseStatus } from 'src/app/app-interfaces';

@Component({
  selector: 'vendor-scheduling-event-card',
  templateUrl: 'vendor-scheduling-event-card.component.html',
  styleUrls: ['vendor-scheduling-event-card.component.scss']
})
export class VendorSchedulingEventCardComponent {
	@Input() event: IEventRead;
	@Output() stateChange = new EventEmitter<void>();
	constructor(private modalCtrl: ModalController, private alertCtrl: AlertController, private eventRepo: EventRepo,
				private loadingCtrl: LoadingController) {

	}

	ngOnInit(){}



	async onModifyEvent(event: IEventRead){
		const modal = await this.modalCtrl.create({
			component: VendorEventBuilderPage,
			componentProps: {event, mode: "Update"}
		});
		modal.onDidDismiss().then((stateChange) => {
			if (stateChange.data) {this.emitStateChange();}
		});
		await modal.present();
	}

	async onCopyEvent(event: IEventRead){
		const modal = await this.modalCtrl.create({
			component: VendorEventBuilderPage,
			componentProps: {event}
		});

		modal.onDidDismiss().then((stateChange) => {
			if (stateChange.data) {this.emitStateChange();}
		});
		await modal.present();
	}


	async onDeleteEvent(event: IEventRead){
		const alert = await this.alertCtrl.create({
			header: "Delete Event",
			subHeader: "This action can not be undone",
			buttons:[{
				text: 'Cancel',
				role: 'cancel'
			},{
				text: 'Delete',
				role: 'confirm',
				handler: async () => {
					const loader = await this.loadingCtrl.create({
						message: "Deleting Event",
						spinner: "circles"
					});
					await loader.present();
					try{
						const remoteResponse: RemoteResponse = await this.eventRepo.delete(this.event as unknown as Persistable<IEventData>);
						loader.dismiss();
						
						const alert = await this.alertCtrl.create({
							subHeader: remoteResponse.data.msg,
							buttons: ["Ok"]
						})
						alert.present();
						
					}catch(error){
						loader.dismiss();
						const alert = await this.alertCtrl.create({
							header: "Unable to delete event",
							message: error.message,
							buttons: ["Ok"]
						})
						alert.present();
					}
					this.emitStateChange();
					await loader.dismiss();

				}
			}]
		});
		await alert.present();
	}

	private emitStateChange(): void{
		this.stateChange.emit();
	}

	public async onSelectEntryTypeIcon(entryTypeFlag: number){
		let message: string;
		switch (entryTypeFlag){
			case 2:
				message = "This event was automatically imported from Google calendar";
				break;
			case 3:
				message = "This event was automatically imported from Facebook";
				break;
			case 4:
				message = "This event was automatically imported from iOS calendar";
				break;
			case 5:
				message = "This event was automatically imported from EasyEats";
				break;
		}
		
		const alert = await this.alertCtrl.create({
			header: "Imported Event",
			message: message,
			buttons: ["OK"]
		});
		alert.present();
	}

}
