import {Injectable } from '@angular/core';

/***Services */
import { IonicNativeStorageService } from './local-storage-services';


@Injectable()
export class PreferenceService{

	constructor(private storage: IonicNativeStorageService){ }

    /*** Structured like this (on local device):
	{
		prefs: {
			device: {
				fcmTopics: {
					user: {
						MN: {
							new_vendor_added: <boolean>
							daily_events: <boolean>
						},
						WI: {
							new_vendor_added: <boolean>
							daily_events: <boolean>
						}
						.
						.
						.				
					},
					vendor: {
						weekly_calendar_reminder : <boolean>,
						verified_event_creation : <boolean>
					},
					favorites: {
						[vendorId]: {
							open_today: <boolean>,
							added_event: <boolean>
						}
					}
				},
				location: string,
				vendorFavId: "",
				geofences: {
					[eventId] : {
						lat: number,
						lng: number,
						id: string,
						vendorName: string
					}
				}
			}
		}
	}
	***/
		
	//JSON object stored on local device with user prefs
	private prefsData: any = <any> {};
	
	public init(){
		return this.getPrefs(); //retrieve data for singleton instance
	}

	
	/***Public Methods***/		
	public getDevicePrefs(){
		return this.prefsData.prefs.device;
	}
	
	public setDevicePrefs(data: any){
		this.prefsData.prefs.device = data;
	}
	
	//Clears all data on device
	public clearLocalStorage(){
		//Create Empty StreatSmart key for device
		return this.storage.set(null)
			.then((data)=>{
				//console.log(data);
				console.log("Local Storage Emptied");
				return this.getPrefs();
			})
			.catch(error => console.log(error.message));
	};

	//Commit Data to Native Storage/Phone
	public commit(){
		console.log("Commiting to Local Storage");
		return this.storage.set(this.prefsData)
			.then(()=> {
				//reset global json object
				return this.getPrefs();
			})
			.catch(error => console.log(error.message));
	};	


	//Retrieve StreatSmart Native Prefs from Phone
	private async getPrefs(){
		this.prefsData = await this.storage.get()
        if (this.prefsData === null) this.prefsData = this.initPrefsData();    
		return this.prefsData;    
	}
	
	//Create Local Storage Object
	private initPrefsData(){
		return {
			prefs: {
				device: {
					fcmTopics: {
						user: {
							
						},
						vendor: {
							
						},
						favorites: {
							
						},
					},
				location: "",
				vendorFavId: ""
				}
			}
		}
	}
   
	
}

