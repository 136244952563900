import { IVendorSearchOptions } from "../../../../app-domain/vendor/index";

export class VendorSearchOptions{
	private _state: string;
	private _cuisine: string[];
	private _rating: number;
	private _status: number[];

	constructor(state: string, cuisine?: string[], rating?: number, status?: number[]){
		this._state = state;
		this._cuisine = cuisine ? cuisine: ['Any'];
		this._rating = rating ? rating : 0;
		this._status = status ? status : [2];
	}


	public set state(state: string){
		this._state = state;
	}

	public get state(): string{
		return this._state;
	}

	public set cuisine(cuisine: string[]){
		this._cuisine = cuisine;
	}

	public get cuisine(): string[]{
		return this._cuisine;
	}

	public set rating(rating: number){
		this._rating = rating;
	}

	public get rating(): number{
		return this._rating;
	}

	public set status(status: number[]){
		this._status = status;
	}

	public get status(): number[]{
		return this._status;
	}

	public getSearchOptions(): IVendorSearchOptions{
		return {
			state: this.state,
			cuisine: this.cuisine,
			rating: this.rating,
			status: this.status
		};
	}
}
