import {Injectable} from '@angular/core';

/***Models***/
import { UserService } from "src/app/app-domain/user/index";
/***Services */
import { LogService } from '../remote-services/logs';
import { Activity, ActivityRepo, ActivityType } from 'src/app/app-domain/activity';



interface ActivityCache {
    [userID: string]: {
        [vendorID: string]: {
            [activity: string]: {
                [date: string]: boolean
            }
        }
        
       
    } 
}


@Injectable()
export class UserActivityService{
	private activityCache: ActivityCache;

	constructor(
        private userService: UserService,
        private logService: LogService,
        private activityRepo: ActivityRepo
	){
        this.activityCache = {};
    }

    
    public logVendorProfileVisit(vendorID: string){
        const userID = this.userService.getID();
        const payload: Activity = {
            activity: "profile-visit",
            vendorID: vendorID,
            date: this.createLogDate(new Date()),
            userID: userID,
            dateTime: new Date().getTime()
        }
        //console.log(this.activityCache);
        if (this.existsInCache(payload)) return;
        this.addToCache(payload);
        return this.logService.logUserActivity(payload);
    }
    
    public async getUserActivityByVendor(activity: ActivityType, vendorID: string): Promise<Activity[]>{
        return this.activityRepo.getActivityByVendor(activity, vendorID);
    }

    private existsInCache(payload: Activity): boolean{
        const vendorID = payload.vendorID, userID = payload.userID, date = payload.date, activity = payload.activity;
        
        if (this.activityCache[userID] && this.activityCache[userID][vendorID] && this.activityCache[userID][vendorID][activity] && this.activityCache[userID][vendorID][activity][date]){
            return true;
        }else{
            return false;
        }
    }

    private createLogDate(date: Date): string{
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return "" + month + day + year;
    }

    private addToCache(payload: Activity): void{
        if (!this.activityCache[payload.userID]) this.activityCache[payload.userID] = {};
        if (!this.activityCache[payload.userID][payload.vendorID]) this.activityCache[payload.userID][payload.vendorID] = {};
        if (!this.activityCache[payload.userID][payload.vendorID][payload.activity]) this.activityCache[payload.userID][payload.vendorID][payload.activity] = {};
        this.activityCache[payload.userID][payload.vendorID][payload.activity][payload.date] = true;
    }

}
