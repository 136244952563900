import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { PhoneFormatterPipe } from "../../../../app-pipes/index";

@Component({
  selector: 'phone-form-item',
  templateUrl: 'phone-form-item.component.html',
  styleUrls: ['phone-form-item.component.scss'],
  providers: [PhoneFormatterPipe, {
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => PhoneFormItemComponent),
  },
  { provide: NG_VALIDATORS, useExisting: forwardRef(() => PhoneFormItemComponent), multi: true }]
})

export class PhoneFormItemComponent implements ControlValueAccessor, Validator{
  public phone: string = "";
  @Input() title: string;
  onTouched = () => { };
  touched = false;
  onChange = (value) => { };


  disabled = false;
  constructor(private phoneFormatPipe: PhoneFormatterPipe) { 

  }

  ngOnInit(){
    this.phone = "";
  }

  public onInput(event: CustomEvent, input: number): void {
    this.markAsTouched();
    //console.log(event);
    if (event.detail.data && !isNaN(event.detail.data) && input.toString().length <= 16) {
      this.phone = this.removeFormatting(input.toString());
    } else if (event.detail.inputType === "deleteContentBackward") {
      this.phone = this.removeFormatting(input.toString());
    }
    this.onChange(this.phone);
  }

  public clearedInput(event: CustomEvent, input: number): void {
    if (input === undefined || input.toString() === '') {
      this.phone = undefined;
    }else if(this.removeFormatting(this.phone) != this.removeFormatting(event.detail.value)){
      this.phone = this.removeFormatting(event.detail.value);
    }
    this.onChange(input);
  }

  private removeFormatting(formattedPhone: string): string {
    if (formattedPhone) {
      formattedPhone = formattedPhone.replace("(", "").replace(") ", "").replace(" - ", "").replace(")", "");
      return formattedPhone;
    }
  }

  get formattedPhone(): string {
    return this.phoneFormatPipe.transform(this.phone);
  }

  writeValue(value: string) {
    this.phone = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  validate(control: AbstractControl<any, any>): ValidationErrors {
    return control.value != null && control.value != undefined && control.value != "" && this.removeFormatting(this.phone).length === 10 ? null : {invalid: true};
  }


}
