/***Interfaces */
import { Activity } from "src/app/app-domain/activity";


export abstract class LogService{
    constructor(){

    }

	public abstract logUserActivity(payload: Activity): Promise<any>
   
}
