import { Component} from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from "@angular/forms";
import { NavParams, ModalController, AlertController, LoadingController } from "@ionic/angular";
import { ISubMenu, SubMenu, IMenu, IMenuTypeData, MenuTypeRepo} from "../../../../../../app-domain/menu/index";
import { VendorService, IVendor } from "../../../../../../app-domain/vendor/index";
import { Persistable } from "../../../../../../app-domain/shared-interfaces/index";

@Component({
	selector: 'vendor-sub-menu-builder',
	templateUrl: 'vendor-sub-menu-builder.page.html',
	styleUrls: ['vendor-sub-menu-builder.page.scss']
})
export class VendorSubMenuBuilderPage {
	public subMenu: ISubMenu;
	private vendor: IVendor;
	public mode: "Add" | "Update" = "Add";
	public subMenuForm: FormGroup;
	public formInitialized: boolean = false;
	constructor(private vendorService: VendorService, private navParams: NavParams, private modalCtrl: ModalController,
				private menuTypeRepo: MenuTypeRepo, private alertCtrl: AlertController, private loadCtrl: LoadingController) {
		this.vendor = this.vendorService.getVendorFocus();
		if (this.navParams.get('subMenu')){
			this.subMenu = this.navParams.get('subMenu');
			
			this.mode = "Update";
		}else{
			this.subMenu = new SubMenu();
		}

	}

	ngOnInit(){
		this.initializeForm();
	}

	private initializeForm() {
		let description = null;
		let name = null;
		let order = null;


		if (this.mode === "Update") {
			description = this.subMenu.getDescription();
			name = this.subMenu.getName();
			order = this.subMenu.getOrder();
		}

		this.subMenuForm = new FormGroup({
		  name: new FormControl(name, Validators.required),
		  description: new FormControl(description),
		});
		this.formInitialized = true;

	}



	onDismiss(data?: boolean): void{
		this.modalCtrl.dismiss(data);
	}

	public async onSubmit(form: FormGroup): Promise<void>{
		console.log(this.subMenu);
		if (this.subMenu.getMenuTypeData() === undefined || this.subMenu.getOrder() === undefined){
			const menu: IMenu = this.vendor.getMenu();
			const order: number = menu.getSubMenus().length;
			form.value.order = order;
		}

		if (this.mode === "Update") {var oldName = this.subMenu.getName();}
		const menuItemDataClone = Object.assign(this.subMenu.getMenuTypeData() ? {...this.subMenu.getMenuTypeData()} : {},form.value)
		const menuItem: Persistable<IMenuTypeData> = new SubMenu(this.subMenu.getMenuTypeID(),menuItemDataClone);

		const loader = await this.loadCtrl.create({
			message: (this.mode === "Update" ? "Updating " : "Adding ") + "Sub Menu"
		});
		loader.present();
		
		try{
			if (this.mode === "Update"){
				const response = await this.menuTypeRepo.update(menuItem, {vendorID: this.vendor.getVendorID(), value: oldName});
			}else{
				const response = await this.menuTypeRepo.add(menuItem, {vendorID: this.vendor.getVendorID()});
			}
			loader.dismiss();
		}catch(error){
			loader.dismiss();
			const alert = await this.alertCtrl.create({
				header: "Unable to " + this.mode === "Update" ? "update " : "add "  + "sub menu option",
				message: "If this issue continues, please contact StreatSmart",
				buttons: ["Ok"]
			})
			alert.present();
		}

		this.onDismiss(true);
	}


	public async onDeleteSubMenu(subMenu: Persistable<IMenuTypeData>){
		const alert = await this.alertCtrl.create({
			header: "Are you sure you want to delete this sub menu?",
			subHeader: "You will no longer be able to add menu items to this sub menu",
			buttons: [{
				text: 'Yes, delete submenu',
				role: 'confirm',
				handler: async () => {
					await this.menuTypeRepo.delete(subMenu ,{vendorID: this.vendor.getVendorID()});
					this.onDismiss(true);
				}
			},{
				text: 'Cancel',
				role: 'cancel'
			}]
		});
		await alert.present();

	}



}
