import { Component, OnInit} from '@angular/core';
import { ModalController } from '@ionic/angular';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';


@Component({
  templateUrl: 'backend-jobs.page.html',
})
export class BackendJobsPage implements OnInit{
 
	constructor(
    	private modalCtrl: ModalController
	){}

	ngOnInit(){
		
	}
	
 
	onDismiss() {
		this.modalCtrl.dismiss();
	}

	runVenueUpdate(){
		const venueUpdate = firebase.functions().httpsCallable("debug_venueUpdate");
		return venueUpdate();
	}

	runStreatCredCalc(){
		const streatCredCalc = firebase.functions().httpsCallable("debug_streatCredCalculator");
		return streatCredCalc();
	}

	runLeaderboardAggr(){
		const leaderboardAggr = firebase.functions().httpsCallable("debug_eventLeaderboardCollation");
		return leaderboardAggr();
	}
	
}
