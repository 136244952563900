import { Persistable } from "../shared-interfaces";
import { UserRole } from "../user";
import { FeatureRequestData, FeatureRequestStatus } from "./feature-request-data.interface";

export class FeatureRequest implements Persistable<FeatureRequestData>{
    private featureRequestData: FeatureRequestData;
    private featureRequestID: string;

    constructor(data: FeatureRequestData, id?: string){
        this.featureRequestData = data ? data : <FeatureRequestData>{};
        this.featureRequestID = id;
    }

    public getID(): string {
        return this.featureRequestID
    }

    public getData(): FeatureRequestData {
        console.log(this)
        return this.featureRequestData;
    }

    public getRequestorID(): string{
        return this.featureRequestData.requestorUserID;
    }

    public getStatus(): FeatureRequestStatus{
        return this.featureRequestData.status;
    }

    public getUserRole(): UserRole{
        return this.featureRequestData.userRole
    }

    public setUserRole(role: UserRole){
        this.featureRequestData.userRole = role;
    }

    public getDescription(): string{
        return this.featureRequestData.description;
    }

    public setDescription(desc: string){
        this.featureRequestData.description = desc;
    }

    public getVotes(): {[userID: string]: boolean}{
        return this.featureRequestData.votes;
    }

    public getNumberOfVotes(): number{
        if (this.getVotes() != undefined){
            return Object.keys(this.getVotes()).length;
        }else{
            return 0;
        }
    }

    public hasUserVoted(userID: string): boolean{
        if (this.getRequestorID() != userID && this.getVotes() != undefined){
            const votes = this.getVotes();
            if (votes[userID]){
                return true
            }
        }
        return false;
    }

    public getCreatedDate(): Date{
        return new Date(this.featureRequestData.created)
    }

    public getAdminResponse(): string{
        return this.featureRequestData.adminResponse;
    }


}