import {Injectable } from '@angular/core';
import {IFactory} from "../index";
import { IVendor, IVendorData, Vendor } from "./index";
import { ReviewListRepo, IReviewList } from "../review/index";

@Injectable()
export class VendorFactory implements IFactory<IVendor, IVendorData>{
	constructor(private reviewListRepo: ReviewListRepo){}

	public async build(id: string, data: IVendorData): Promise<IVendor>{
		const _vendor: IVendor = new Vendor(id, data);
		const _reviewList: IReviewList = await this.reviewListRepo.getByIDAndSubscribe(id);
		_vendor.setReviewList(_reviewList);
		return _vendor;
	}

}


