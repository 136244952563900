import {Injectable } from '@angular/core';



@Injectable()
export abstract class EmailComposerService{

	constructor(
	){

	}

	public abstract isAvailable(): Promise<boolean>;
	public abstract open(to: string, body: string): Promise<void>
}
 