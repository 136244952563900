import {Injectable } from '@angular/core';
import {IUserData, IUser, UserRepo,  AdminUser, VendorUser, CustomerUser, GuestUser} from "./index";
import { environment } from "../../../environments/environment";

@Injectable()
export class UserFactory {
	constructor(){}

	public build(userID: string, userData: IUserData, customClaims?: {[key: string]: any}): IUser{
		if (userID === environment.admin){
			return new AdminUser(userID,userData);
		}else if(userData === null){
			return new GuestUser(userID,null);
		}else if(customClaims === undefined || customClaims.permissions === undefined || (customClaims.permissions && Object.keys(customClaims.permissions).length === 0)){
			return new CustomerUser(userID,userData);
		//}else if (permissions && permissions['venue']){
			//_user = new VenueUser(userID,userData['firstName'],userData['lastName'],userData['email']);
		}else if (customClaims && customClaims.permissions && Object.keys(customClaims.permissions).length > 0){
			return new VendorUser(userID,userData);
			//await _user.initPermissions(fbClaimTokens);
		}
	}

}



	//console.log(environment);
	//const tokenResult: firebase.auth.IdTokenResult = await firebase.auth().currentUser.getIdTokenResult(true);
	//console.log(tokenResult);



