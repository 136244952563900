import { Component, Renderer2, ViewChild } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { VendorService, IVendor } from "../../../app-domain/vendor/index";
import { EventRepo, ICalendar, IEventRead, IParentCalendar } from "../../../app-domain/scheduling/index";
import { CalendarSyncPage, VendorEventBuilderPage, VerifiedEventPage, VerifiedEventUpdate } from "./index";
import { CalendarComponent } from "../../../app-components/index";
import { VendorPreferenceRepo } from '../../../app-domain/preference';

@Component({
  selector: 'vendor-scheduling',
  templateUrl: 'vendor-scheduling.page.html',
  styleUrls: ['vendor-scheduling.page.scss']
})
export class VendorSchedulingPage{
	private vendor: IVendor;
	public selectedEvents: IEventRead[] = [];
	public selectedDate: Date;
	
	@ViewChild(CalendarComponent) appCal: CalendarComponent;
	constructor(private vendorService: VendorService, private eventRepo: EventRepo, private modalCtrl: ModalController,
		private vendorPrefRepo: VendorPreferenceRepo
	) {

		this.vendor = this.vendorService.getVendorFocus();
	}
		

	async ionViewDidEnter() {
		if (this.vendorService.getVendorFocus().getPrefCollection() === undefined) {
			const preferences = await this.vendorPrefRepo.getByIDAndSubscribe(this.vendorService.getVendorFocus().getVendorID());
			this.vendorService.getVendorFocus().setPrefs(preferences);
		}
	}

	public updateEventCard(events: IEventRead[]): void{
		this.selectedEvents = events;
	}

	public async onAddEvent(){
		const modal = await this.modalCtrl.create({
			component: VendorEventBuilderPage,
			componentProps: {selectedDate: this.selectedDate}
		});
		modal.onDidDismiss().then((stateChange) => {
			if (stateChange.data) {this.onStateChange();}
		});
		await modal.present();
	}

	public setSelectedDate(event: Date): void{
		this.selectedDate = event;
	}

	public onStateChange(): void{
		this.appCal.reloadEvents();
	}

	public async onHotSpot() {
		const modal = await this.modalCtrl.create({
		component: VerifiedEventPage
		})
		await modal.present();
		modal.onDidDismiss().then((data) => {
			if (data.data){
				const _data: VerifiedEventUpdate = data.data;
				const calendar = this.vendorService.getVendorFocus().getCalendar();
				if (_data.status === "add"){
					calendar.addEventToGrouping(_data.event, "HotSpots")
				}else{
					calendar.removeEventFromGrouping(_data.event, "HotSpots")
				}
			}
		})
	}

	async onSync() {
		const modal = await this.modalCtrl.create({
			component: CalendarSyncPage,
			componentProps: { vendor: this.vendor },
			initialBreakpoint: .4,
			breakpoints: [0, .4, 1],
			backdropBreakpoint: .3,
			backdropDismiss: true
		})
		await modal.present();
	}


	public onRefresh(event): void{
        setTimeout(async () => {
            await this._refresh();
            event.target.complete();
        },0)
    };

	async _refresh(): Promise<void> {
		const calendar: IParentCalendar = await this.eventRepo.getAllEventsByVendor(this.vendorService.getVendorFocus());
		this.vendorService.getVendorFocus().setCalendar(calendar);
		return;
	}

}
