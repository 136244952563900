/// <reference types="@types/google.maps"/>
import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter, Input} from '@angular/core';


/***Domain***/
import { ILocation, Location } from "../../../app-domain/location/index";
import { IPlace } from "../../../app-domain/place/index";

/***Services***/
import { GeolocationService, GooglePlaceService } from "../../../app-services/index";

/***Environment***/
import { environment } from "../../../../environments/environment";

/***Interfaces***/
import { Mappable } from "../../../app-interfaces/index";

declare let google;
const mapStyles = [
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [
            {
                lightness: 100
            },
            {
                visibility: "simplified"
            }
        ]
    },
    {
        featureType: "water",
        elementType: "geometry",
        stylers: [
            {
                visibility: "on"
            },
            {
                color: "#C6E2FF"
            }
        ]
    },
    {
        featureType: "poi",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#C5E3BF"
            }
        ]
    },
    {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#D1D1B8"
            }
        ]
    }
];

interface MapClickCallbackData{
	mapId: string;
	latitude: number;
	longitude: number;
}



@Component({
	selector: 'app-map',
	templateUrl: 'map.component.html',
	styleUrls: ['map.component.scss']
})
export class MapComponent implements OnInit{
	@Input() marker: Mappable;
	@Output() newPlace = new EventEmitter<IPlace>();

	//Allow flexibility with zoom, center, icon size and other map options
	@Input() zoom: number;
	@Input() center: Mappable
	@Input() iconWidth: number = 25;
	@Input() iconHeight: number = 25;
	@Input() options: google.maps.MapOptions = {
		zoomControl: false,
		disableDoubleClickZoom: true,
		maxZoom: 15,
		minZoom: 8,
		disableDefaultUI: true,
		keyboardShortcuts: false,
	}


	constructor(
		private geolocation: GeolocationService,
		private placeService: GooglePlaceService
	){

	}

	async ngOnInit(){
    	let location: Mappable;
   		console.log(this.marker);

		if (this.marker === undefined || !this.marker.getLat()){
			this.marker = await this.geolocation.getCurrentLocation() as unknown as Mappable;
		}

		if (!this.center){
			this.center = this.marker;
		}
		console.log(this.center)
		console.log(this.marker);
  	}

      public async onMapClick(data: google.maps.MapMouseEvent){
		const loc = new Location(data.latLng.lat(),data.latLng.lng());
		const place: IPlace = await this.placeService.getPlaceFromGoogleMap(loc, new google.maps.Geocoder());
		this.newPlace.emit(place);
	}


	
}
