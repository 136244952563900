import {Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
/***Models***/
import {IReviewData } from "../../../app-domain/review/index";
/***Interfaaces */
import { Persistable } from "../../../app-domain/index";
import { RemotePayload } from "../../../app-interfaces/index";
/***Services */
import { ReviewService } from "./review.service.abstract"


@Injectable()
export class FirebaseReviewService extends ReviewService{

	constructor(){
		super();
		/*if (window.location.host.includes('localhost')){
			firebase.functions().useFunctionsEmulator('http://localhost:5001');
		}*/
	}


	public addReview(review: Persistable<IReviewData>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<IReviewData> = this.createPayload<IReviewData>(review, dataOptions);
		console.log(payload);
		const addReview = firebase.functions().httpsCallable("service_review_addReview");
		return addReview(payload);
	}

	public updateReview(review: Persistable<IReviewData>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<IReviewData> = this.createPayload<IReviewData>(review, dataOptions);
		console.log(payload);
		const updateReview = firebase.functions().httpsCallable("service_review_updateReview");
		return updateReview(payload);
	}

	public deleteReview(review: Persistable<IReviewData>, dataOptions?: {[key: string]: any}): Promise<firebase.functions.HttpsCallableResult>{
		const payload: RemotePayload<IReviewData> = this.createPayload<IReviewData>(review, dataOptions);
		console.log(payload);
		const deleteReview = firebase.functions().httpsCallable("service_review_deleteReview");
		return deleteReview(payload);
  }

  public censorReview(review: Persistable<IReviewData>, dataOptions?: { [key: string]: any }): Promise<firebase.functions.HttpsCallableResult> {
    const payload: RemotePayload<IReviewData> = this.createPayload<IReviewData>(review, dataOptions);
    console.log(payload);
    const censorReview = firebase.functions().httpsCallable("service_review_censorReview");
    return censorReview(payload);
  }

  public flagReview(review: Persistable<IReviewData>, dataOptions?: { [key: string]: any }): Promise<firebase.functions.HttpsCallableResult> {
    const payload: RemotePayload<IReviewData> = this.createPayload<IReviewData>(review, dataOptions);
    console.log(payload);
    const flagReview = firebase.functions().httpsCallable("service_review_flagReview");
    return flagReview(payload);
  }

  public respondToReview(review: Persistable<IReviewData>, dataOptions?: { [key: string]: any }): Promise<firebase.functions.HttpsCallableResult> {
    const payload: RemotePayload<IReviewData> = this.createPayload<IReviewData>(review, dataOptions);
    console.log(payload);
    const respondToReview = firebase.functions().httpsCallable("service_review_respondToReview");
    return respondToReview(payload);
  }

	private createPayload<T>(persistable: Persistable<T>, dataOptions: {[key: string]: any}): RemotePayload<T>{
		const payload: RemotePayload<T> = <RemotePayload<T>>{};
		payload.id = persistable.getID();
		payload.data = persistable.getData();
		Object.keys(dataOptions).forEach((option: string) => {payload[option] = dataOptions[option];});
		return payload;
	}

}

