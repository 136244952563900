import { Pipe, PipeTransform } from '@angular/core';


@Pipe({name: 'toArray'})
export class ToArrayPipe implements PipeTransform {
    transform(value: object, args: string[]): any[] {
        if (typeof value !== "object") throw new Error("ToArrayPipe unable to handle non-object types")
        return Object.keys(value).map(id => {
            return value[id]
        })
       /* console.log(value);
        const array = [];
        array.push(value);
        console.log(array);
        return array;*/
    }
}
