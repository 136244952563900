/***Models***/
import { IEventData } from "../../../app-domain/scheduling/index";
/***Interfaces */
import { Persistable } from "../../../app-domain/index";
import { CalendarPayload } from "./scheduling.contracts";
import { HotspotEventData } from "../../../app-domain/scheduling/hotspot-event-data.interface";


export abstract class SchedulingService{
    constructor(){

    }

    public abstract addEvent(event: Persistable<IEventData>, dataOptions?: {[key: string]: any}): Promise<any>
	  public abstract updateEvent(event: Persistable<IEventData>, dataOptions?: {[key: string]: any}): Promise<any>
	  public abstract deleteEvent(event: Persistable<IEventData>, dataOptions?: {[key: string]: any}): Promise<any>
    public abstract linkExternalCalendar<T>(payload: CalendarPayload<T>, dataOptions?: {[key: string]: any}): Promise<any>
    public abstract unlinkExternalCalendar(dataOptions?: { [key: string]: any }): Promise<any>
    public abstract addVendorToHotSpot(eventID: string, data: HotspotEventData, vendorID: string): Promise<any>
    public abstract removeVendorFromHotSpot(eventID: string, data: HotspotEventData, vendorID: string): Promise<any>
}
