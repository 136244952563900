import { ViewManagerService } from "../view-manager.service";
import { AnalyticsService } from "./analytics.service.abstract";
import { AnalyticsServiceFactory } from "./analytics.service.factory";




export let AnalyticsServiceProvider = {
    provide: AnalyticsService,
    useFactory: AnalyticsServiceFactory,
    deps: [ViewManagerService]
}