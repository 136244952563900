import { Component, OnInit} from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
/***Services***/
import { AdminService, ReviewService } from '../../../app-services';

/***Models***/
import { IReview, IReviewData } from '../../../app-domain/review';
import { Persistable } from '../../../app-domain';


@Component({
  templateUrl: 'flagged-reviews.page.html',
})
export class FlaggedReviewsPage implements OnInit{
  public reviews: IReview[] = [];
	
	constructor(
    private alertCtrl: AlertController,
    private adminService: AdminService,
    private reviewService: ReviewService,
    private modalCtrl: ModalController
	){}

	ngOnInit(){
		return this.getFlaggedReviews();
	}
	
	private async getFlaggedReviews(){
    this.reviews = await this.adminService.getFlaggedReviews();
    if (this.reviews) {
      this.reviews.sort((a, b) => {
        if (a.getCreatedTime() > b.getCreatedTime()) return 1;
        if (a.getCreatedTime() < b.getCreatedTime()) return -1;
        return 0;
      })	
    }	
	}
	
	public async onHandleReview(review: IReview){
		const alert = await this.alertCtrl.create({
			header: "How should this review be handled?",
			buttons: [
				{
					text: "Ignore Request",
					role: 'destructive',
					handler: () => {
						this.discardFlaggedReview(review);
					}
				},
				{
					text: "Censor Review",
					handler: () => {
						this.censorFlaggedReview(review);
					}
				},
				{
					text: 'Cancel',
					role: 'cancel'
				}
			]
		})
		await alert.present();
	}

  onDismiss() {
    this.modalCtrl.dismiss();
  }

	private discardFlaggedReview(review: IReview){
		return this.adminService.discardFlaggedReview(review)
			.then(() => {return this.getFlaggedReviews();});
	}
	
  private censorFlaggedReview(review: IReview) {
    return this.reviewService.censorReview(review as unknown as Persistable<IReviewData>, { vendorID: review.getVendorID() })
			.then(() => {return this.getFlaggedReviews();});
	}
}
