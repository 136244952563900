import {Injectable } from '@angular/core';

import { CameraService } from './camera.service.abstract';

import { Camera, CameraResultType, CameraSource, PermissionStatus, Photo, GalleryPhotos, CameraPluginPermissions } from "@capacitor/camera";
import { DataURL } from 'src/app/app-interfaces';


@Injectable()
export class CapCameraService extends CameraService{

	constructor(
	
	){
		super();
	}


    async takeNewPhoto(): Promise<DataURL>{
        const image = await Camera.getPhoto({
            quality: 50,
            allowEditing: true,
            resultType: CameraResultType.DataUrl,
            correctOrientation: true,
            source: CameraSource.Camera
        });
        console.log(image)
        return this.createDataURLObj(image.dataUrl);
    }

	async selectPhotoFromGallery(): Promise<DataURL>{
        const image = await Camera.pickImages({
            quality: 50,
            correctOrientation: true,
            limit: 1
        })

        if (image.photos.length > 0){
            const blob = await fetch(image.photos[0].webPath).then(r => r.blob());
            const dataURL: string = await this.blobToBase64(blob) as string;
            return this.createDataURLObj(dataURL)
        }
    }

	requestPermissions(): Promise<any>{
        return Promise.resolve();
    }

	checkPermissions(): Promise<any>{
        return Promise.resolve();
    }

    private blobToBase64(blob: Blob){
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
    }

    private createDataURLObj(url: string): DataURL{
        if (!url.startsWith("data:")) throw new Error("Inappropriate Data URL format");
        
        const contentIdx: number = url.indexOf(',');
        const contentString: string = url.substring(contentIdx + 1)
        url = url.substring(0,contentIdx);
        
        const contentEncodeIdx: number = url.indexOf(";");
        const contentEncodeString: string = url.substring(contentEncodeIdx + 1);
        url = url.substring(0,contentEncodeIdx);
        
        const contentTypeIdx: number = url.indexOf(":");
        const contentTypeString: string = url.substring(contentTypeIdx + 1);
      
        const dataURL: DataURL = {
            contentEncode: contentEncodeString,
            contentString: contentString,
            contentType: contentTypeString
        }
        return dataURL

    }
}
 