import { IonicModule } from '@ionic/angular';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



/*** Modules ***/
import { SharedComponentsModule } from "../app-components/shared-components/shared-components.module";
import { EventQueuePage } from './pages/event-queue/event-queue.page';
import { AdminPage } from './admin.page';
import { EasyEatsMappingPage } from './pages/easyeats/easyeats-mappings.page';
import { EditEZVendorPage } from './pages/easyeats/edit-ez-vendor/edit-ez-vendor';
import { CuisinePage } from './pages/cuisine/cuisine.page';
import { EditCuisinePage } from './pages/cuisine/edit-cuisine/edit-cuisine.page';
import { HotSpotPage } from './pages/hotspot/hotspot.page';
import { HotSpotBuilderPage } from './pages/hotspot/update-hotspot/hotspot-builder.page';
import { VendorMgmtCardComponent } from './pages/vendor-management';
import { VendorManagementPage } from './pages/vendor-management/vendor-management.page';
import { FlaggedReviewsPage } from './pages/flagged-reviews/flagged-reviews.page';
import { GeofenceTestingPage } from './pages/geofence-testing/geofence-testing';
import { SendNotificationsPage } from './pages/send-notifications/send-notifications.page';
import { IntegrationTestingPage } from './pages/integration-testing/integration-testing';
import { BackendJobsPage } from './pages/backend-jobs/backend-jobs.page';


/***Services */

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    SharedComponentsModule,
    ReactiveFormsModule
  ],
  exports: [AdminPage, EventQueuePage, EasyEatsMappingPage, EditEZVendorPage, CuisinePage, EditCuisinePage, HotSpotPage, HotSpotBuilderPage, VendorMgmtCardComponent, VendorManagementPage
            ,FlaggedReviewsPage, GeofenceTestingPage, SendNotificationsPage,IntegrationTestingPage, BackendJobsPage
  ],
  declarations: [AdminPage, EventQueuePage, EasyEatsMappingPage, EditEZVendorPage, CuisinePage, EditCuisinePage, HotSpotPage, HotSpotBuilderPage, VendorMgmtCardComponent, VendorManagementPage
                ,FlaggedReviewsPage,GeofenceTestingPage,SendNotificationsPage, IntegrationTestingPage, BackendJobsPage
  ],
  providers:[],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AdminModule {}
