import { Component, Input, ViewChild, ElementRef  } from '@angular/core';



@Component({
	selector: 'card-navigation-arrows',
	templateUrl: 'card-navigation-arrows.component.html',
	styleUrls: ['card-navigation-arrows.component.scss'],
})
export class CardNavigationArrowsComponent {
	@ViewChild("right") public iconRight: ElementRef;
	@ViewChild("left") public iconLeft: ElementRef;
	public isScrollingCallback: any;
	private _contentLength: number;
	private bubbleArray = [];
	@Input() set contentLength(value: number){
		this._contentLength = value;
		this.bubbleArray = [...Array(value)];
		/*if (this.contentLength && this.contentLength < 2){
			window.setTimeout(() => this.iconRight.nativeElement.color = "light",0);
		}else if(this.iconRight && this.contentLength){
			this.iconRight.nativeElement.color = "medium";
		}*/
	}

	get contentLength(): number{
		return this._contentLength;
	}


	@Input() id: string;

	private _color: string = "medium";
	@Input() set arrowColor(color: string){
		this._color = color;
	}

	get arrowColor(){
		return this._color;
	}

	constructor(){

	}

	ngOnInit(){

	}

	public onScroll(){
		window.clearTimeout(this.isScrollingCallback); //clear any existing callbacks
		this.isScrollingCallback = window.setTimeout(() => {
			this.onScrollEnd();
		},100); //create new callback to end scroll
	}

	private onScrollEnd(){
		console.log("TR");
		this.updateArrows();
	}

	private calculateWidthInView(el){
		if (el.length > 0){
			const rect = el[0].getBoundingClientRect();
			const screenWidth = window.innerWidth || document.documentElement.clientWidth;
			const widthInView = rect.right < screenWidth ? rect.right : screenWidth - rect.left;
			return widthInView;
		}

	}

	private updateArrows(){
		const widthsInViewArray = Array(this.contentLength).fill(1).map((el,i) => this.calculateWidthInView(document.getElementsByClassName("scroll-item scroll-idx-" + this.id + "-" + i)));
		widthsInViewArray.forEach((el,i) => {
			if (i === widthsInViewArray.indexOf(Math.max(...widthsInViewArray))){
				document.getElementById("bubble-" + this.id + "-" + i).classList.add("active");
				if (i+1 === this.contentLength){
					this.iconRight.nativeElement.style.visibility = "hidden";
					this.iconLeft.nativeElement.style.visibility = "visible";
					//this.iconRight.nativeElement.color = "light";
					//this.iconLeft.nativeElement.color = "medium";
				}else if(i === 0){
					//this.iconLeft.nativeElement.color = "light";
					//this.iconRight.nativeElement.color = "medium";
					this.iconRight.nativeElement.style.visibility = "visible";
					this.iconLeft.nativeElement.style.visibility = "hidden";
				}else{
					this.iconRight.nativeElement.style.visibility = "visible";
					this.iconLeft.nativeElement.style.visibility = "visible";
					//this.iconRight.nativeElement.color = "medium";
					//this.iconLeft.nativeElement.color = "medium";
				}
				//document.getElementById("bubble-" + i).classList.add("active");
			}else{
				document.getElementById("bubble-" + this.id + "-" + i).classList.remove("active");
			}
		});
	}






}
