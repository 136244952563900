/***Models***/
import {IReviewData } from "../../../app-domain/review/index";
/***Interfaces */
import { Persistable } from "../../../app-domain/index";


export abstract class ReviewService{
    constructor(){

    }

    public abstract addReview(review: Persistable<IReviewData>, dataOptions?: {[key: string]: any}): Promise<any>;
    public abstract updateReview(review: Persistable<IReviewData>, dataOptions?: {[key: string]: any}): Promise<any>;
    public abstract deleteReview(review: Persistable<IReviewData>, dataOptions?: {[key: string]: any}): Promise<any>;
    public abstract censorReview(review: Persistable<IReviewData>, dataOptions?: { [key: string]: any }): Promise<any>;
    public abstract flagReview(review: Persistable<IReviewData>, dataOptions?: { [key: string]: any }): Promise<any>;
    public abstract respondToReview(review: Persistable<IReviewData>, dataOptions?: { [key: string]: any }): Promise<any>;
}
