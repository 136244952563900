import {Injectable} from '@angular/core';
import { IUserData } from 'src/app/app-domain/user';


@Injectable()
export abstract class AuthService {
	
	constructor(
		
	){}
	
	public abstract signup(username: string, password: string, firstName: string, lastName: string): Promise<any>;
	
    public abstract signin(username: string, password: string): Promise<any>;
	
    public abstract logout(): Promise<any>;
	
    public abstract setPassword(email?: string): Promise<any>;

    public abstract forgotPassword(email?: string): Promise<any>;
	
    public abstract setUserName(username: string): Promise<any>;
	
    public abstract deleteAccount(): Promise<any>;
	
}
