import { Component } from '@angular/core';
import { PopoverController } from "@ionic/angular";

@Component({
  	selector: 'cuisine-popover',
  	templateUrl: 'cuisine-popover.component.html',
})
export class CuisinePopoverComponent {
	
  	constructor(private popoverCtrl: PopoverController){}

  	ngOnInit(){
		
		
  	}

  	dismiss(){
  		this.popoverCtrl.dismiss();
  	}

}
