import { IPlace, IPlaceData } from "./index";
import { ILocation, Location } from "../location/index";
import { Persistable } from "../../app-domain/index";
import { Mappable } from "../../app-interfaces/index";

export class Place implements Persistable<IPlaceData>, IPlace, Mappable{
	private placeID: string;
	private placeData: IPlaceData = <IPlaceData>{};
	private location: ILocation;
	constructor(placeID?: string, placeData?: IPlaceData){
		this.placeID = placeID;
		this.placeData = placeData;
		if (this.placeData) {this.location = new Location(this.placeData.loc.lat, this.placeData.loc.lng);}
	}

	public getID(): string{
		return this.placeID;
	}

	public getData(): IPlaceData{
		return this.placeData;
	}

	public getPlaceID(): string{
		return this.placeID;
	}

	public setPlaceID(placeID: string): IPlace{
		this.placeID = placeID;
		return this;
	}

	public getPlaceData(): IPlaceData{
		return this.placeData;
	}

	public setPlaceData(placeData?: IPlaceData): void{
		if (!placeData){
			this.placeData = <IPlaceData>{};
		}else{
			this.placeData = placeData;
		}
	}

	public getAddress(): string{
		return this.placeData.address;
	}

	public getPhone(): string{
		return this.placeData.phone;
	}

	public getName(): string{
		return this.placeData.name;
	}

	public getTypes(): string[]{
		return this.placeData.types;
	}

	public getWebsite(): string{
		return this.placeData.website;
	}

	public getLocation(): ILocation{
		return this.location;
	}

	public getRating(): number{
		return this.placeData.rating;
	}

	public setLocation(location: ILocation): void{
		//console.log(location)
		this.placeData.loc = location;
		//console.log(this.placeData)
	}

	public setState(state: string): void{
		this.placeData.state = state;
	}

	public getState(): string{
		return this.placeData.state;
	}

	public setAddress(address: string): void{
		this.placeData.address = address;
	}

	public setPhone(phone: string): void{
		this.placeData.phone = phone;
	}

	public setName(name: string): void{
		this.placeData.name = name;
	}

	public setTypes(types: string[]): void{
		this.placeData.types = types;
	}

	public setWebsite(website: string): void{
		this.placeData.website = website;
	}

	public setRating(rating: number): void{
		this.placeData.rating = rating;
	}

	public setHours(hours: string[]): void{
		this.placeData.hoursOperating = hours;
	}

	public setHourByDay(dayIdx: number, hourStr: string): void{
		if (this.getHours() && dayIdx >= 0 && dayIdx <= 6){
			this.placeData.hoursOperating[dayIdx] = hourStr;
		}
	}

	public getHoursByDay(dayIdx: number, includeDay?: boolean): string{
		if (this.getHours() && dayIdx >= 0 && dayIdx <= 6){
			const _dayIdx: number = dayIdx > 0 ? dayIdx - 1 : 6;
			if (!includeDay){
				return this.placeData.hoursOperating[_dayIdx].replace(/Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday/gi,"");
			}else{
				return this.placeData.hoursOperating[_dayIdx];
			}
		}
	}


	public getHours(): string[]{
		return this.placeData.hoursOperating;
	}

	public setPhotos(photos: any): void{
		this.placeData.photos = photos;
	}

	public getLat(): number{
		return this.location.getLat();
	}

	public getLng(): number{
		return this.location.getLng();
	}

	public getIconURL(): string{
		return "";
	}

	public getPhotos(){
		return this.placeData.photos;
	}
}
