import {Injectable } from '@angular/core';
import { DataURL } from 'src/app/app-interfaces';

@Injectable()
export abstract class CameraService{

	constructor(
	){

	}

	public abstract takeNewPhoto(): Promise<DataURL>

	public abstract selectPhotoFromGallery(): Promise<DataURL>

	public abstract requestPermissions(): Promise<any>

	public abstract checkPermissions(): Promise<any>
}
 