import firebase from 'firebase/compat/app';
import { LoadingController,AlertController/*, Events */} from '@ionic/angular';
import {Injectable} from '@angular/core';

/***Services***/
import { UserService } from "../app-domain/user/index";
import { VendorService } from "../app-domain/vendor/index";

/***Models***/
//import { _User } from "../models/_User";


export interface IAuthService {
	signup(firstName: string, lastName: string, email: string, password: string);
	signin(email: string, password: string);
	logout();
	resetPwEmail(email?: string);
	updateAuthUsername(newEmail: string);
	deleteAuthAccount();
}



@Injectable()
export class AuthService2 implements IAuthService{
	private userId: string;
	constructor(
		private alertCtrl: AlertController,
		private userService: UserService,
		private loadingCtrl: LoadingController,
		private vendorService: VendorService
	){}

	public async signup(firstName: string, lastName: string, email: string, password: string){
		const loader = await this.loadingCtrl.create({message: 'Signing you up...'});
		await loader.present();
		return this.createFireBaseAccount(email, password)
			.then(() => firebase.auth().currentUser.uid)
			.then((userID: string) => {
				const newUserID: string = userID;
				//this.userService.addUser(firstName, lastName, email, newUserID);
			})
			.then(() => {
				console.log("New User Committed");
				loader.dismiss();
			})
			.catch(async (error) => {
				loader.dismiss();
				const alert = await this.alertCtrl.create({
					header: 'Signup failed!',
					subHeader: error.message,
					buttons:['Ok']
				});
				await alert.present();
			});




	}

	private createFireBaseAccount(email: string, password: string){
		if (firebase.auth().currentUser != null && firebase.auth().currentUser.isAnonymous)
			{return firebase.auth().currentUser.linkWithCredential(firebase.auth.EmailAuthProvider.credential(email,password))
				.then((user) => {
					console.log("Anonymous account successfully upgraded",user);
					return;
				});}
		else{
			return firebase.auth().createUserWithEmailAndPassword(email, password)
				.then(() => {
					console.log("New user successfully created");
					return;
				});
		}
	}

	public async signin(email: string, password: string){
		const loader = await this.loadingCtrl.create({message: 'Signing you in...'});
		loader.present();
		return firebase.auth().signInWithEmailAndPassword(email,password)
			.then(() => {
				loader.dismiss();
				return true;
			})
			.catch(async (error) => {
				loader.dismiss();
				const alert = await this.alertCtrl.create({
					header: 'Signin failed!',
					subHeader: error.message,
					buttons:['Ok']
				});
				await alert.present();
			});
	}

	public logout(){
		this.userService.deconstructor();
		if (this.vendorService.getVendorFocus()) {this.vendorService.deconstructor();}
		firebase.auth().signOut();
	}

	getCurrentUserId(): Promise<any>{
		if (this.userId){
			//console.log("User ID already stored: " + this.userId);
			return Promise.resolve(this.userId);
		}else{
			return Promise.resolve(firebase.auth().currentUser.uid)
				.then((userId) => {
					console.log("Setting UserId");
					this.setUserId(userId);
					return Promise.resolve(this.userId);
				})
				.catch((error) => console.log(error.message));
		}
	}

	setUserId(userId: string){
		this.userId = userId;
	}




	public async resetPwEmail(email?: string){
		const tmpValue = email ? email : "";
		const alert = await this.alertCtrl.create({
			header: 'Please enter email address associated to account',
			//message: error.message,
			inputs: [
				{
					name: 'email',
					type: 'email',
					placeholder: "Email Address",
					value: tmpValue
				}
			],
			buttons:[{
				text: 'Send Password Email',
				handler: input => {
					firebase.auth().sendPasswordResetEmail(input.email)
						.then(async () => {
							console.log("Email Sent");
							const alert2 = await this.alertCtrl.create({
								header: "Email Sent",
								buttons:[{
									text: "Got It"
								}]
							});
							await alert2.present();
						})
						.catch(async (error )=> {
							const alert2 = await this.alertCtrl.create({
								header: error.message,
								buttons:[{
									text: "Got It"
								}]
							});
							await alert2.present();
						});
				}
			},
			{
				text: 'Cancel',
				role: 'cancel'
			}
			]
		});
		await alert.present();
	}

	updateAuthUsername(newEmail: string){
		return firebase.auth().currentUser.updateEmail(newEmail)
			.then(() => {
				console.log("Firebase Username/Email Updated");
			});
	}


	deleteAuthAccount(){
		return firebase.auth().currentUser.delete()
			.then(() => {
				console.log("Firebase Auth Account Deleted");
			});
	}


	/*deleteUser(){
		const alert = this.alertCtrl.create({
				title: 	`
					Are you sure you want to delete this account?
					All user & vendor data will be permanently deleted.
					Please sign in to delete account.
						`,
				inputs:[{
						name: 'email',
						type: 'email',
						placeholder: "Username/Email Address",
					},
					{
						name: 'password',
						type: 'password',
						placeholder: 'Password'
					}
				],
				buttons: [{
					text: 'Delete account',
					handler: data => {
						let _data = data;
						this.signin(_data.email,_data.password)
							.then(() => {
								return this.generalService.deleteUser(firebase.auth().currentUser.uid)
							})
							.then(() => {
								console.log("Firebase User Deleted");
								return firebase.auth().currentUser.delete()
							})
							.catch((error) => {
								const alert2 = this.alertCtrl.create({
									title: error.message,
									buttons: [{text:"OK"}]
								})
								alert2.present()
							});
					}
				},{
					text: 'Cancel',
					role: 'cancel'
				}]
		})
		alert.present();
	}*/

	isVendor(): Promise<boolean>{
		return firebase.auth().currentUser.getIdTokenResult()
			.then((token: any) => Object.keys(token.claims.permissions).length > 0 ? true : false);
	}
}
