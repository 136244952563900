import {Cuisine} from "../vendor/index";


export class VendorCuisineOptions{
	private cuisineFamilies: string[] = []; //unique cuisine families used for iterating through
	private cuisineOptions: Cuisine[] = [];

	constructor(cuisineOptions: Cuisine[]){
		this.consume(cuisineOptions);
	}


	public addCuisine(cuisine: Cuisine): void{
		this.cuisineOptions.push(cuisine);
		this.addFamily(cuisine.getFamily());
	}

	private addFamily(family: string): void{
		if (this.cuisineFamilies.indexOf(family) < 0){ //consider ignoring case here in future
			this.cuisineFamilies.push(family);
		}
	}


	private consume(cuisineOptions: Cuisine[]): void{
		cuisineOptions.forEach((cuisine: Cuisine) => {
			this.addCuisine(cuisine);
		});
	}

	public getFamilies(): string[]{
		return this.cuisineFamilies;
	}

	public getAllCuisine(): Cuisine[]{
		return this.cuisineOptions;
	}

	public getCuisineByFamily(family: string): Cuisine[]{
		return this.cuisineOptions.filter((cuisine: Cuisine) => cuisine.getFamily() === family);
	}

	public getCuisine(cuisineName: string): Cuisine{
		const cuisineOptions = this.cuisineOptions.filter((option: Cuisine) => option.getName() === cuisineName);
		if (cuisineOptions.length > 0) {return cuisineOptions[0];}
	}

	public filterCuisinesByFamily(family: string, cuisine: string[]): string[]{
		if (cuisine){
			return cuisine.filter((c) => {
				return family === this.getFamilyByCuisine(c);
			})
		}
	}

	public getFamilyByCuisine(cuisineName: string): string{
		const cuisine = this.cuisineOptions.filter((o: Cuisine) => {
			return o.getName() === cuisineName
		});
		if (!(cuisine.length > 0)) return;
		return cuisine[0].getFamily();
	}


}
