import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GeofencePayload } from 'src/app/app-domain/location/geofence.contracts';
import { GeofenceService } from 'src/app/app-services';


@Component({
  templateUrl: 'geofence-testing.html',
})
export class GeofenceTestingPage implements OnInit{
	public geofences: GeofencePayload;
	
	constructor(private geofenceService: GeofenceService, private modalCtrl: ModalController
		
	){}
	
	async ngOnInit() {
		this.geofences = await this.geofenceService.getLocalGeofences();
	}

	onDismiss() {
		this.modalCtrl.dismiss();
	}

	async resetGeofences(){
		this.geofences = await this.geofenceService.resetGeofences();
	}
}

