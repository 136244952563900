import firebase from 'firebase/compat/app';
import 'firebase/compat/database';


import { Model, Persistable } from "../../app-domain/index";
import { IEventData, IEventRead, IEventWrite } from "./index";
import { IPlace, Place, IPlaceData } from "../place/index";
import { IHotSpotData, IHotSpot, HotSpot } from "./index";
import { ILocation } from "../location/index";
import { IUser, UserRole} from "../user/index";
import { Mappable } from "../../app-interfaces/index";
import { AppModule } from 'src/app/app.module';
import { SubscriptionManagerService } from 'src/app/app-services/subscription-manager.service';
import { Unsubscribable } from 'rxjs';


export class Event implements Persistable<IEventData>, IEventRead, IEventWrite, Mappable{
	private eventID: string;
	private eventData: IEventData;
	private hotSpot: IHotSpot;
	private place: IPlace;
	private reference: firebase.database.Reference;
	constructor(eventID?: string, eventData?: IEventData){
		this.eventID = eventID;
		this.eventData = eventData;
		if (this.eventData) {this.place = new Place(this.getPlaceID(), this.eventData.place);}
	}

	static createTemplatedEvent(user: IUser): IEventRead{
		const event = new Event();
		event.setStartTime(new Date().getTime());
		event.setEndTime(new Date().getTime());

		if (user.getRole() != UserRole.VENUE && user.getRole() != UserRole.VENDOR) {event.setNotes("Customer entered event");}
		return event;
	}


	public getID(): string{
		return this.eventID;
	}

	public getData(): IEventData{
		return this.eventData;
	}


	public getEventID(): string{
		return this.eventID;
	}

	public getEventData(): IEventData{
		return this.eventData;
	}

	public getStartTime(): number{
		return this.eventData.startTime;
	}

	public getEndTime(): number{
		return this.eventData.endTime;
	}

	public getPlaceID(): string{
		return this.eventData.placeId;
	}

	public getPlace(): IPlace{
		return this.place;
	}

	/*private setPlace(place: IPlaceData): void{
		let _place:IPlace = new Place();
		_place.setPlaceID(this.getPlaceID()).setPlaceData(place);
		this.place = _place;
	}*/

	public getVendorID(): string{
		return this.eventData.vendorId;
	}

	public getEntryTypeFlag(): number{
		return this.eventData.entryTypeFlag;
	}

	public getCreatedByID(): string{
		return this.eventData.createdById;
	}

	public getIconURL(): string{
		return this.eventData.iconUrl;
	}

	public setIconURL(url: string): void{
		this.eventData.iconUrl = url;
	}

	public isHotSpot(): boolean{
		return this.eventData.hotSpotInd;
	}

	public getTitle(): string{
		return this.eventData.title;
	}

	public getNotes(): string{
		return this.eventData.notes;
	}

	public isOpen(): boolean{
		return this.getStartTime() < new Date().getTime() && new Date().getTime() < this.getEndTime();
	}

	public isClosed(): boolean{
		return this.getEndTime() < new Date().getTime();
	}

	public getPreOrderLink(): string{
		return this.eventData.preOrderLink;
	}

	public setHotSpotInd(ind: boolean): void{
		this.eventData.hotSpotInd = ind;
	}

	public setTitle(title: string): void{
		this.eventData.title = title;
	}

	public setNotes(notes: string): void{
		this.eventData.notes = notes;
	}

	public setVendorID(vendorID: string): void{
		this.eventData.vendorId = vendorID;
	}


	public setStartTime(startTime: number): void{
		this.eventData.startTime = startTime;
	}

	public setEndTime(endTime: number): void{
		this.eventData.endTime = endTime;
	}

	public getCreatedTime(): number{
		return this.eventData.createdTime;
	}

	//Place

	public getLat(): number{
		if (this.place)	return this.place.getLat();
	}

	public getLng(): number{
		if (this.place) return this.place.getLng();
	}

	public getPlaceState(): string{
		return this.place.getState();
	}

	public getEventPlaceData(): IPlaceData{
		return this.place.getPlaceData();
	}

	public getEventPlaceLoc(): ILocation{
		return this.place.getLocation();
	}


	public getEventPlaceName(): string{
		return this.place.getName();
	}

	public getEventPlaceAddr(): string{
		return this.place.getAddress();
	}

	public getEventPlaceWebsite(): string{
		return this.place.getWebsite();
	}
	public getEventWebsite(): string{
		if (this.hotSpot && this.hotSpot.getWebsite()){
			return this.hotSpot.getWebsite();
		}else{
			return this.getEventPlaceWebsite();
		}
	}


	//HotSpot
	public setHotSpotData(hotSpot: IHotSpotData): void{
		const _hotSpot = new HotSpot(this.getEventID(), hotSpot);
		this.hotSpot = _hotSpot;
	}

  public setHotSpot(hotSpot: IHotSpot): void {
    this.hotSpot = hotSpot;
  }

	public getHotSpotName(): string{
		return this.hotSpot.getName();
	}

	public getHotSpot(): IHotSpot{
		return this.hotSpot;
	}

	public isVendorParticipating(vendorID: string): boolean{
		if (this.hotSpot){
			return this.hotSpot.isVendorParticipating(vendorID);
		}else{
			return false;
		}
	}

	public getParticipatingVendors(): string[]{
		return this.hotSpot.getParticipants();
	}

	public getParticipantsDesc(vendorID: string): string{
		return this.hotSpot.getParticipantsDesc(vendorID);
	}

	public getHotSpotData(): IHotSpotData{
		return this.hotSpot.getHotSpotData();
	}

	public getHotSpotEndTime(): number{
		return this.hotSpot.getEndTime();
	}

	public setParticipatingVendorData(vendors: any): void{
		this.hotSpot.setVendorData(vendors);
	}

  public getParticipatingVendorData(): any{
    if (this.hotSpot) {
      return this.hotSpot.getVendorData();
    }
	}

	public getHotSpotWebsite(): string{
		return this.hotSpot.getWebsite();
	}

	public getEventPlaceHoursByDay(dayIdx: number, includeDay?: boolean): string {
		return this.place.getHoursByDay(dayIdx, includeDay);
  }

  public setHotSpotName(name: string) {
    this.hotSpot.setName(name);
  }

  public setHotSpotURL(url: string) {
    this.hotSpot.setURL(url);
  }

	/***New Data***/
	public setNewPlace(place: IPlace): void{
		if (this.eventData === undefined) {this.eventData = <IEventData>{};}
		this.eventData.place = place.getPlaceData();
		this.setPlaceID(place.getPlaceID());
		this.place = place;
		console.log(this);
	}

	private setPlaceID(placeID: string): void{
		this.eventData.placeId = placeID;
	}

	/*public setNewEventData(eventData: IEventFormOptions): void{
		this.eventData = eventData;
	}

	public setNewHotSpotData(hotSpotData: IHotSpotFormOptions): void{
		let _hotSpot = new _HotSpot();
		_hotSpot.setHotSpotData(hotSpotData);
		_hotSpot.setCreatedTime();
		this.hotSpot = _hotSpot;
	}*/


	public subscribe(db: firebase.database.Reference): void{
		this.unsubscribe();
		AppModule.injector.get(SubscriptionManagerService).addSubcription(this);
		this.reference = db;
		db.on("value",(snapshot: firebase.database.DataSnapshot) => {
			this.eventData = snapshot.val();
		});
	}

	public destroy(){
		this.unsubscribe();
	}

	public unsubscribe(): void {
		if (this.reference) this.reference.off();
	}

}
