import { IPlace, Place, IPlaceData } from "./index";
import { ILocation } from "../location/index";


export interface IPlaceBuilder {
	setAddress(address: string): void;
	setPhone(phone: string): void;
	setName(name: string): void;
	setTypes(types: string[]): void;
	setWebsite(website: string): void;
	setRating(rating: number): void;
	setState(state: string): void;
	setHours(hours: string[]): void;
	setPhotos(photos: any): void;
	setLocation(location: ILocation );
	getPlace(): IPlace;
}


export class PlaceBuilder {
	private place: IPlace;

	constructor(){
		this.reset();
	}

	private reset(){
		this.place = new Place();
		this.place.setPlaceData();
	}
	public setAddress(address: string): void{
		this.place.setAddress(address);
	}

	public setPhone(phone: string): void {
		this.place.setPhone(phone);
	}

	public setName(name: string): void{
		this.place.setName(name);
	}

	public setTypes(types: string[]): void{
		this.place.setTypes(types);
	}

	public setWebsite(website: string): void{
		this.place.setWebsite(website);
	}

	public setRating(rating: number): void{
		this.place.setRating(rating);
	}

	public setState(state: string): void{
		this.place.setState(state);
	}

	public setHours(hours: string[]): void{
		this.place.setHours(hours);
	}

	public setPhotos(photos: any): void{
		this.place.setPhotos(photos);
	}

	public setLocation(location: ILocation): void{
		this.place.setLocation(location);
	}

	public getPlace(): IPlace{
		return this.place;
	}
}


