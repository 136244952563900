import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { IonicModule, IonicRouteStrategy, isPlatform } from '@ionic/angular';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { RemoteService } from "./app-services/index";


/***Directives Module***/
import {AppDirectiveModule} from "./app-directives/app-directive.module";
/***Pipes Module***/
import {AppPipeModule} from "./app-pipes/app-pipe.module";
/***Shared Components Module***/
import {SharedComponentsModule} from "./app-components/shared-components/shared-components.module";

/*** Components ***/
import { AppMenuComponent} from "./app-components/index";


/*** Services ***/
import { GeolocationService, RemoteConfigService, GooglePlaceService, ViewManagerService ,AuthService2, VendorCacheService, RemoteService,
  PreferenceService, IonicNativeStorageService, FavoritesService, AppLauncherServiceProvider, MediaNavigatorService, UserActivityService,
  VendorProfileProgressService, AdminService, FeaturePopoverService, AppManagerService, PhotoViewerServiceProvider, LandingNotificationService
} from "./app-services/index";
//import { AuthService } from "./services/authentication/auth";
import { UserService, UserRepo, UserFactory, UserPrefRepo } from "./app-domain/user/index";
import { VendorRepo, VendorService, VendorFactory } from "./app-domain/vendor/index";
import { ReviewListRepo, ReviewRepo } from "./app-domain/review/index";
import { CuisineService } from "./app-domain/admin/cuisine.service";
import { EventRepo } from "./app-domain/scheduling/index";
import { MenuRepo, MenuTypeRepo, MenuItemRepo } from "./app-domain/menu/index";
import { PlaceRepo } from './app-domain/place';
import { ActivityRepo } from './app-domain/activity';
import { VendorPreferenceRepo } from './app-domain/preference';
import { FeatureRequestRepo } from './app-domain/feature-request/feature-request-repo';
/***Service Providers */
import { ReviewServiceProvider, SchedulingServiceProvider, MenuServiceProvider, NotificationServiceProvider, CameraServiceProvider, 
  RemoteStorageServiceProvider, AuthServiceProvider, VendorServiceProvider, InAppBrowserServiceProvider, EmailComposerServiceProvider,
  LogServiceProvider, UserServiceProvider, CallNumberServiceProvider, MarketServiceProvider, BackgroundLocationServiceProvider, LocalStorageServiceProvider,
  AnalyticsServiceProvider,FeatureRequestServiceProvider
} from './app-services/index';

/***Third Party Modules****/
import { IonicRatingComponentModule} from 'ionic-rating-component';
import { IonicStorageModule } from '@ionic/storage-angular'

/***Capacitor/Cordova Plugins */
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
//import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';

/***Internal Modules*/
import { AdminModule } from './module-admin';
import { CapacitorNetworkService } from './app-services/native-services/network-services';


@NgModule({
	declarations: [
		AppComponent, AppMenuComponent

	],
	imports: [BrowserModule, IonicModule.forRoot({
			"mode" : isPlatform("android") ? 'md' : 'ios'
		}), 
		AppRoutingModule, AppDirectiveModule, AppPipeModule, SharedComponentsModule,
		IonicRatingComponentModule,IonicStorageModule.forRoot(), 
		ServiceWorkerModule.register('combined-sw.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		}),
		HttpClientModule,
		HttpClientJsonpModule,
		AdminModule,
		BrowserAnimationsModule

			/*AgmCoreModule.forRoot({
				apiKey: environment.google.apiKey,//'AIzaSyAIEuCPd4H21qqnT3nJdhgu5aoIDCXvuck',
				libraries: ["places"]
			})*/


	],
	providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },RemoteConfigService, RemoteService,
		GeolocationService,GooglePlaceService, UserService, UserRepo, UserFactory, VendorRepo, VendorService,AuthService2,
		ViewManagerService, VendorFactory, ReviewListRepo, CuisineService, EventRepo, MenuRepo, MenuTypeRepo, MenuItemRepo, 
		ReviewRepo,ReviewServiceProvider,VendorCacheService,LaunchNavigator,SchedulingServiceProvider,MenuServiceProvider,PreferenceService
		,IonicNativeStorageService,NotificationServiceProvider,FavoritesService,PlaceRepo,CameraServiceProvider,RemoteStorageServiceProvider
		, AuthServiceProvider,VendorServiceProvider,InAppBrowserServiceProvider, AppLauncherServiceProvider,AppLauncherServiceProvider,MediaNavigatorService,
    EmailComposerServiceProvider, LogServiceProvider, UserActivityService, ActivityRepo, VendorProfileProgressService, UserServiceProvider, CallNumberServiceProvider,
    AdminService, VendorPreferenceRepo, FeaturePopoverService,MarketServiceProvider,AppManagerService,BackgroundLocationServiceProvider,LocalStorageServiceProvider,
	PhotoViewerServiceProvider, UserPrefRepo, AnalyticsServiceProvider, LandingNotificationService, FeatureRequestServiceProvider, FeatureRequestRepo,
	CapacitorNetworkService
	
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {
  static injector: Injector;
  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
