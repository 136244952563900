import { SearchListItem } from "../../app-components";



export class Cuisine implements SearchListItem{
	private family: "Regional/Ethnic" | "Specialty" | "Religious" | "Style";
	private name: string;
	private color: string;
	private info: string;
  private count: number;
	constructor(family?: "Regional/Ethnic" | "Specialty" | "Religious" | "Style", name?: string, info?: string){
		this.family = family;
		this.name = name;
		this.color = this.determineColor(family);
    this.info = info;
	}

	private determineColor(family: string): string{
		switch (family){
			case "Regional/Ethnic": return "regional"; break;
			case "Specialty": return "specialty"; break;
			case "Religious": return "religious"; break;
			case "Style": return "style"; break;
		}
	}

	public getFamily(): string{
		return this.family;
	}

	public setFamily(family: "Regional/Ethnic" | "Specialty" | "Religious" | "Style"): void{
		this.family = family;
	}

	public getName(): string{
		return this.name;
	}

	public setName(name: string): void{
		this.name = name;
	}

	public getColor(): string{
		return this.color;
	}

	public setColor(color: string): void{
		this.color = color;
	}

	public getInfo(): string{
		return this.info;
	}

	public setInfo(info: string): void{
		this.info = info;
  }

  public getText(): string {
    return this.getName();
  }

  public getValue(): string {
    return this.getName();
  }

  public getCount(): number {
    return this.count;
  }

  public setCount(cnt: number) {
    this.count = cnt;
  }
}
