import { IPlace } from "./index";


export enum PlaceSortEnum {
  NAME = 1,
  DISTANCE,
  EVENTS
}

export class VenueList {
	private places: IPlace[] = [];
  private sortStrategy: PlaceSort = new NameSort();

	constructor(vendors: IPlace[]){
		this.places = vendors;
	}

	public getVenuesByIDs(ids: string[]): IPlace[]{
		return this.places.filter((place: IPlace) => ids.includes(place.getPlaceID()));
	}

	public getVenuesByPartialMatchName(searchName: string): IPlace[]{
		return this.places.filter((place: IPlace) => place.getName().toLowerCase().indexOf(searchName) >= 0);
	}

	public getAllVenues(): IPlace[]{
		return this.places;
	}


	/***Iterator***/
	public [Symbol.iterator]() {
		let cnt = 0;
		const that = this;
		return {
			next(){
				cnt++;
				return {
					done: cnt-1 === that.places.length,
					value: that.places[cnt-1]
				};
			}
		};
  }


  public setSortStrategy(sortStrategy: PlaceSortEnum) {
    switch (sortStrategy) {
      case PlaceSortEnum.NAME: this.sortStrategy = new NameSort(); break;
    }
  }

  public sort(sortStrategy: PlaceSortEnum) {
    this.setSortStrategy(sortStrategy);
    this.places.sort(this.sortStrategy.sort);
  }



}




abstract class PlaceSort {
  constructor() { }
  public abstract sort(a: IPlace, b: IPlace): number;
}


class NameSort extends PlaceSort {
  constructor() {
    super();
  }

  public sort(a: IPlace, b: IPlace): number {
    if (a.getName().toLowerCase() > b.getName().toLowerCase()) { return 1; }
    if (a.getName().toLowerCase() < b.getName().toLowerCase()) { return -1; }
    return 0;
  }
}
