import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { IVendor, VendorService } from '../../../../app-domain/vendor';


@Component({
	selector: 'vendor-menu-accordion',
	templateUrl: './vendor-menu-accordion.component.html'
})
export class VendorMenuAccordionComponent {
  public states: string[] = [];
  constructor(private vendorService: VendorService, private router: Router, private menuCtrl: MenuController) { }

  ngOnInit() {
    this.states = this.determineStates();

    this.vendorService.getVendorList().sort((a, b) => {
      if (a.getName() > b.getName()) return 1;
      if (a.getName() < b.getName()) return -1;
      return 0;
    })
  }

  private determineStates(): string[] {
    const states: Set<string> = new Set();
    this.vendorService.getVendorList().forEach((v) => { states.add(v.getState()); })
    return Array.from(states);

  }


  public onUpdateVendorFocus(vendor: IVendor): void {
  
    this.onNavTo("/vendor/scheduling"); //need to change tab view to allow for ionViewDidEnter hook to trigger to reload vendor menu/calendar
    window.setTimeout(() => {
      this.onNavTo("/toggle-profile",{state: {vendorName: vendor.getName(), vendorImg: vendor.getBackgroundImgUrl()}});
    },0)
   
   window.setTimeout(() => {
      this.onNavTo('/vendor');
      this.vendorService.toggleVendorFocus(vendor.getVendorID());
    },3000)//this timer is coupled with toggle-profile.page.ts - I'm lazy and need to get v2 out!!!!
   
  }

  public onNavTo(path: string, state?: any): void {
    this.router.navigateByUrl(path, state);
    this.menuCtrl.close();
  }
}
