import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

import { IUserData, IUser, UserRole } from "./index";
import { Image, Model, Persistable } from "../../app-domain/index";
import { Observable, Observer } from "../../app-interfaces/index";
import { SubscriptionManagerService } from 'src/app/app-services/subscription-manager.service';
import { AppModule } from 'src/app/app.module';

export abstract class User implements Persistable<IUserData>, IUser, Observable<IUser>{
	private userID: string;
	protected userData: IUserData;
	protected role: UserRole;
	private observers: Observer<IUser>[] = [];
	private reference: firebase.database.Reference;


	constructor(userID: string, userData: IUserData, permissions?: Map<string,boolean>){
		this.userID = userID;
		this.userData = userData;
	}


	public update(): void{

	}

	public add(): void{

	}

	public delete(): void{

	}

	public getID(): string{
		return this.userID;
	}

	public getData(): IUserData{
		return this.userData;
	}


	public getFirstName(): string{
		return this.userData.firstName;
	}

	public setFirstName(firstName: string): void{
		this.userData.firstName = firstName;
	}

	public getLastName(): string{
		return this.userData.lastName;
	}

	public setLastName(lastName: string): void{
		this.userData.lastName = lastName;
	}

	public getEmail(): string{
		return this.userData.email;
	}

	public setEmail(email: string): void{
		this.userData.email = email;
	}

	public getFullName(): string{
		return this.getFirstName() + " " + this.getLastName();
	}

	public getFavorites(): {[key: string]: boolean}{
		return this.userData.favorites;
  }

  public getFavoriteList(): string[] {
    if (this.userData.favorites) {
      if (Object.keys(this.userData.favorites).length > 0) {
        return Object.keys(this.userData.favorites).filter((key) => this.userData.favorites[key]);
      }
    } else {
      return [];
    }
  }

	public getCreatedTime(): number{
		return this.userData.createdTime;
	}

	public setFavorites(favorites: {[key: string]: boolean}): void{
		this.userData.favorites = favorites;
	}

	public getPermissions():  Map<string,boolean>{
		return this.userData.permissions;
	}

  public getPermissionList(): string[]{
    if (this.userData.permissions) {
      if (Object.keys(this.userData.permissions).length > 0) {
        return Object.keys(this.userData.permissions).filter((key) => this.userData.permissions[key]);
      }
    } else {
      return [];
    }
	}

	public setPermissions(permissions:  Map<string,boolean>): void{
		this.userData.permissions = permissions;
	}

	public isVendorFavorited(vendorID: string): boolean{
		if (this.getFavorites()){
			return Object.keys(this.getFavorites()).filter((vID: string) => vendorID === vID).length > 0;
		}else{
			return false;
		}
	}

	public hasPermissionsForVendor(vendorID: string): boolean{
		const permissionList: string[] = this.getPermissionList();

		if (permissionList.length > 0){
			const hasPermission: string[] = permissionList.filter((vID: string) => vID === vendorID);

			if (hasPermission.length > 0) {return true;}
			return false;
		}

		return false;
	}

	public getRoot(): string{
		return "/user";
	}

	public subscribe(db: firebase.database.Reference): void{
		this.unsubscribe();
		AppModule.injector.get(SubscriptionManagerService).addSubcription(this);
		this.reference = db;
		db.on("value",(snapshot: firebase.database.DataSnapshot) => {
			this.userData = snapshot.val();
			this.notifySubscribers();
		});
	}

	public unsubscribe(): void {
		if (this.reference) this.reference.off();
	}

	public getRole(): UserRole{
		return this.role;
	}

	public getStreatCred(): number {
		if (this.userData.streatCred){
			return this.userData.streatCred;
		}else{
			return 0;
		}
		
	}
	
	/*public setProfileImage(image: Image): void {
		this.userData.profileImage = image;
	}*/

	public getProfileURL(): string {
		if (!this.userData.profileImage){
			return "../../assets/icon/streatsmart.png"
		}else{
			return this.userData.profileImage.imageUrl;
		}
	}

	public getProfileImage(): Image {
		return this.userData.profileImage;
	}

	//Observable methods
	subscribeToChanges(obs: Observer<IUser>): void{
		this.observers.push(obs);
	}

	removeSubscription(obs: Observer<IUser>): void{
		this.observers.forEach((_obs: Observer<IUser>, idx) => {
			if (obs.getID() === _obs.getID()) {this.observers.splice(idx,1);}
		});
	}

	notifySubscribers(): void{
		this.observers.forEach((obs: Observer<IUser>) => {
			obs.update(this);
		});
	}


}
