import {Injectable, NgZone } from '@angular/core';
import { Router } from "@angular/router";
import { App, URLOpenListenerEvent } from "@capacitor/app"
import { UserService } from '../app-domain/user';
import { LocalStorageService } from './native-services';
import { NAVIGATION_URL } from './native-services/local-storage-services/local-storage.constants';


@Injectable({
    providedIn: "root"
})
export class DeeplinksService{
    public VENDOR_URL = "https://streatsmart.co/user/trucks/vendor/";
    protected DELETE_USER_URL = "https://streatsmart.co/delete-user";

	constructor(private router: Router, private zone: NgZone, private userService: UserService, 
        private localStorageService: LocalStorageService
        ){

	}

    public setupDeeplinks(){
       // window.setTimeout(() => {
        console.log("setting up listener")
            App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
                console.log("triggered below")
                console.log(event.url);
                this.zone.run(async () => {
                    console.log("Setting up deeplinks");
                    console.log(this.validateDeepLink(event.url))
                    if (this.validateDeepLink(event.url)){
                         if (this.userService.isExpired()){
                            console.log("App session expired- storing deeplink")
                            await this.storeDeferredNavigation(event.url);
                         }else{
                            this.handleNavigation(event.url);
                         }
                    } 
                })
            })
       // },2000)
        
    }

    private validateDeepLink(url: string){
        if (url.startsWith(this.VENDOR_URL)){
            return true;
        }else if(url.startsWith(this.DELETE_USER_URL)){
            return true;
        }
        return false;
    }

    private async handleNavigation(url: string){
        if (url === this.DELETE_USER_URL){
            await this.router.navigateByUrl("user/delete-user");
        }else{
            const slug = url.split('/').pop();
            console.log(this.userService.isLoaded());

            const that = this;
            //On Android startups are slower and we need to poll the user service isloaded method every .5 s to see if it is ready
            let interval = window.setInterval(async () => {
                console.log("Interval")
                if (that.userService.isLoaded()){
                    console.log("Loaded")
                    await that.router.navigateByUrl("user/trucks");
                    await that.router.navigateByUrl("user/trucks/vendor/" + slug);
                    window.clearInterval(interval);
                    interval = null;
                }
            },500)
            //Safety net
            window.setTimeout(async () => {
                console.log("Interval over")
                if (interval){
                    console.log("IN TIMEOUT")
                    window.clearInterval(interval); interval = null;
                    await that.router.navigateByUrl("");
                }
            },10000)
        }
    }


    public async handleDeferredNavigation(){
        const deferredNavigation = <string>JSON.parse(await this.localStorageService.get(NAVIGATION_URL));
        console.log("Deferred");
        console.log(deferredNavigation);
        if (deferredNavigation){
            this.handleNavigation(deferredNavigation);
            this.localStorageService.remove(NAVIGATION_URL);
            return;
        }
        return;
    }

    private async storeDeferredNavigation(url: string): Promise<void>{
        return this.localStorageService.set(NAVIGATION_URL,JSON.stringify(url)); 
    }

    private async deferredNotificationExists(): Promise<boolean>{
        const notification = await this.localStorageService.get(NAVIGATION_URL);
        return notification ? true : false;
    }
	

}
