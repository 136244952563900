import {Injectable } from '@angular/core';



@Injectable()
export abstract class CallNumberService{

	constructor(
	){

	}

	public abstract call(number: string): Promise<any>;
	
}
