import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, ToastController } from '@ionic/angular';
import { DatePipe } from '@angular/common';
import { IEventRead } from "../../../app-domain/scheduling/index";
//import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Clipboard } from "@capacitor/clipboard"
import { VendorCacheService } from 'src/app/app-services';
import { IVendor, VendorService } from 'src/app/app-domain/vendor';
import { Router } from '@angular/router';
import { UserService } from 'src/app/app-domain/user';
import { IMenu, IMenuItem, MenuRepo } from '../../../app-domain/menu';
import { IPlace } from 'src/app/app-domain/place';
import { VenueCalendarModalComponent } from '../modals';

@Component({
	selector: 'event-details',
	templateUrl: './event-details.component.html',
	styleUrls: ['event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {
	public event: IEventRead;
	public startTime: string;
	public endTime: string;
	public vendor: IVendor;
	public view: "menu" | "event" = "event";
	public menuType: string = "Main Menu";
	private menuLoaded: boolean = false;
	public participants: IVendor[] = [];

	constructor(
		private navParams: NavParams,
		private datePipe: DatePipe,
		private modalCtrl: ModalController,
		private toastCtrl: ToastController,
		private vendorCache: VendorCacheService,
		private router: Router,
		private vendorService: VendorService,
		private userService: UserService,
		private menuRepo: MenuRepo
		//private iab: InAppBrowser,
	){}

	async ngOnInit(){
		this.event = this.navParams.get('event');
		this.startTime = this.datePipe.transform(this.event.getStartTime(), 'MM/dd/yyyy hh:mm a');
		this.endTime = this.datePipe.transform(this.event.getEndTime(),"MM/dd/yyyy hh:mm a");
    	this.vendor = await this.vendorCache.getByID(this.event.getVendorID());
    	await this.loadMenu();
		if (this.event.isHotSpot() && this.event.getParticipatingVendors().length > 0) await this.loadParticipants();
	}
	
	onDismissView(data?: boolean){
		this.modalCtrl.dismiss(data);
	}

	onPreOrderClick(url: string){
		//return this.iab.create(url,'_self');
	}

	public onEventAdded(eventAdded: boolean){
		if (eventAdded) this.onDismissView(true);
	}
	
	public async onCopyAddress(address: string){
		await Clipboard.write({string: address})
		this.presentToast("Address Copied!", 'bottom');
	}

	async presentToast(msg: string, position: 'top' | 'middle' | 'bottom'){
		const toast = await this.toastCtrl.create({
			message: msg,
			duration: 1000,
			position: position,
		});

		await toast.present();
	}

	public navigateToProfile(vendorID: string){
		this.onDismissView(true);
		this.router.navigate([this.router.url + '/vendor/' + vendorID]);
  }

	public canDisplayProfile() {
		return !this.router.url.includes('/vendor/')
	}

	private async loadMenu(): Promise<void> {
		if (!this.event.isHotSpot() && this.vendor.getMenu() === undefined){
			const menu: IMenu = await this.menuRepo.getByIDAndSubscribe(this.vendor.getVendorID());
			this.vendor.setMenu(menu);
		}
	}



	public onSlideChanged(menuItem: IMenuItem) {
		console.log(menuItem);

		this.menuType = menuItem.getMenuType();
	}

	public async loadParticipants(){
		this.participants = await Promise.all(this.event.getParticipatingVendors().map(vID => {
			return this.vendorCache.getByIDAndSubscribe(vID);
		}))
	}

	public async onCalendar(venue: IPlace){
		const modal = await this.modalCtrl.create({
			component: VenueCalendarModalComponent,
			componentProps: {venue: venue},
			breakpoints: [0,.66,1],
			initialBreakpoint: 1,
			backdropBreakpoint: .4,
			backdropDismiss: true
		})
		await modal.present();
	}

}


