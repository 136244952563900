import { Topic, TopicFilterBy, TopicGroupOptions, TopicsConfig, TopicProps } from "./index";



export class TopicGroup {
	private topics: Topic[] = []; //Available topics for selection
	private group: TopicGroupOptions;
	private filterBy: TopicFilterBy[];
	private prefix: string;
	private userTopics: string[] = []; //user subscribed topics in the form of string (prefix + key)

	constructor(group: TopicGroupOptions, userTopics: string[], filterBy?: TopicFilterBy[], prefix?: string){
		this.group = group;
		this.userTopics = userTopics;
		this.userTopics = userTopics;
		this.filterBy = filterBy;

		if (prefix){
			this.setPrefix(prefix);
		}
		this.createAvailTopics();
	}

	private createAvailTopics(){
		console.log(this.filterBy)
		const t = TopicsConfig.filter((config: TopicProps) => {return config.category === this.group})
		
		t.forEach((config: TopicProps) => {
			if (this.filterBy){
				this.filterBy.forEach((filter) => {
					config.prefix = filter.id;
					const top = new Topic(config);
					top.setTopicGroup(this);
					this.reconcileTopic(top);
					this.topics.push(top);
				})
			}else{
				const top = new Topic(config);
				top.setTopicGroup(this);
				this.reconcileTopic(top);
				this.topics.push(top);
			}
			
		})
		console.log(this);
	}

	public getGroup(): TopicGroupOptions{
		return this.group;
	}
	public getAllTopics(){
		return this.topics;
	}

	public getTopicsByFilter(filter: string){
		return this.topics.filter((topic: Topic) => {
			return filter === topic.getPrefix();
		})
	}

	public getPrefix(): string{
		return this.prefix;
	}

	private setPrefix(prefix: string){
		this.prefix = prefix;
	}

	/*public reconcileTopics(curPrefix: string){
		this.setTopicsPrefix(curPrefix);
		this.resetTopics()
		this.topics.forEach((topic: Topic) => {
			this.reconcileTopic(topic);
		})
	}*/


	private reconcileTopic(topic: Topic){
		if (this.userTopics.includes(topic.getTopic())){
			topic.reconcileValue(true);
		}
	}

	/*private resetTopics(){
		this.topics.forEach((topic: Topic) => {
			topic.reconcileValue(false);
		})
	}*/

	public addUserTopic(topic: string){
		this.userTopics.push(topic);
	}

	public removeUserTopic(topicStr: string){
		this.userTopics = this.userTopics.filter((topic: string) => {return topicStr != topic})		
		console.log(topicStr);
		console.log(this.userTopics);
	}

	public getUserTopics(): string[]{
		return this.userTopics;
	}

	/*public setTopicVal(topic: Topic, value: boolean){
		this.getAllTopics().forEach((_topic: Topic) => {
			if (_topic.getTopic() === topic.getTopic()){
				_topic.setValue(value);
			}
		})
	}*/
   
}
