import { FCMNotificationService } from "./fcm-notification.service";
import { UserService } from "src/app/app-domain/user"; 
import { NotificationHandlerService } from "./notification-handler.service";
import { GeofenceService } from "../geofence.service";
import { AnalyticsService } from "../analytics-services";


export let NotificationServiceFactory = function(
    userService: UserService, notificationHandlerService: NotificationHandlerService, geofenceService: GeofenceService,
    analyticsService: AnalyticsService
){
    return new FCMNotificationService(userService, notificationHandlerService, geofenceService,analyticsService);
}