import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';



@Component({
  templateUrl: 'integration-testing.html',
})
export class IntegrationTestingPage implements OnInit{
	
	constructor( private modalCtrl: ModalController
		
	){}
	
	async ngOnInit() {
		
	}

	onDismiss() {
		this.modalCtrl.dismiss();
	}

	onSubmit(form){
		const initIntegrations = firebase.functions().httpsCallable("debug_initIntegrations");
		return initIntegrations({provider: form.value.provider,lookback: form.value.lookahead}); //backend naming convention is lookback because I'm an idiot
	}
}

