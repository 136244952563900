import { Component} from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NavParams, ModalController, ActionSheetController, LoadingController, AlertController } from "@ionic/angular";
import { IMenuItem, IMenuItemData, IMenu, MenuItemRepo, MenuItem, ISubMenu} from "../../../../../app-domain/menu/index";
import { VendorService, IVendor } from "../../../../../app-domain/vendor/index";
import { MenuAllergensData, MenuTagsData } from "../../index";
import { Image, NewImage, Persistable } from "../../../../../app-domain/shared-interfaces/index";
//import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { DataURL, RemoteResponse, RemoteResponseStatus } from 'src/app/app-interfaces';
import { CameraService, RemoteStorageService, RemoteStorageTaskState } from 'src/app/app-services';

@Component({
	selector: 'vendor-menu-item-builder',
	templateUrl: 'vendor-menu-item-builder.page.html',
	styleUrls: ['vendor-menu-item-builder.page.scss']
})
export class VendorMenuItemBuilderPage {
	public menuItem: IMenuItem;
	public vendor: IVendor;
	public menuItemForm: FormGroup;
	public mode: "Add" | "Update" = "Add";
	public menuTags = MenuTagsData;
	public menuAllergens = MenuAllergensData;
	public formInitialized: boolean = false;
	public menu: IMenu;
	public newImage: NewImage;

	constructor(private vendorService: VendorService, private navParams: NavParams,private modalCtrl: ModalController, 
				private menuItemRepo: MenuItemRepo,  private actionSheet: ActionSheetController
				,private loadCtrl: LoadingController, private cameraService: CameraService, private remoteStorageService: RemoteStorageService,
				private alertCtrl: AlertController) {
		this.vendor = this.vendorService.getVendorFocus();
		this.menu = this.vendor.getMenu();
		if (this.navParams && this.navParams.get('menuItem')){
			this.menuItem = this.navParams.get('menuItem');
			this.mode = "Update";
		}else{
			this.menuItem = new MenuItem();
		}
		console.log(this.menuItem);
	}

	ngOnInit(){
		this.initializeForm();
	}

	private initializeForm() {
		let title = null;
		let description = null;
		let price = null;
		let menuTags = [];
		let menuType = "Main Menu";
		let allergens = [];

		if (this.mode === "Update") {
			title = this.menuItem.getTitle();
			description = this.menuItem.getDescription();
			price = this.menuItem.getPrice();
			menuTags = this.menuItem.getMenuTags();
			menuType = this.menuItem.getMenuType();
			allergens = this.menuItem.getAllergens();
		}
		
		this.menuItemForm = new FormGroup({
		  title: new FormControl(title, Validators.required),
		  description: new FormControl(description),
		  menuType : new FormControl(menuType,Validators.required),
		  price: new FormControl(price),
		  menuTags: new FormControl(menuTags),
		  allergens: new FormControl(allergens)
		});

		this.formInitialized = true;

	}

	onDismiss(): void{
		this.modalCtrl.dismiss();
	}

	public async onSubmit(form: FormGroup){

		if (this.menuItem.getMenuData() === undefined || this.menuItem.getOrder() === undefined){
			const subMenu: ISubMenu = this.menu.getSubMenu(form.value.menuType);
			const order: number = subMenu.getAllMenuItems().length + 1;
			form.value.order = order;
		}

		const menuItem = new MenuItem(Object.assign(this.menuItem.getMenuData() ? this.menuItem.getMenuData() : {},form.value), this.menuItem.getItemID());

		if (this.newImage){
			const image = await this.uploadNewImage();
			if (image) menuItem.setImage(image)
			await this.commitMenuItem(menuItem);
		}else{
			await this.commitMenuItem(menuItem);
		}
		
		this.onDismiss();
	}
	
	//public displayImage(url: string){
		//this.photoViewer.show(url);
	//}

	async onImageOptions(){
        const actionSheet = await this.actionSheet.create({
            header: "Image Options",
            subHeader: "Select an option to perform",
            buttons:[
                {
                    text: 'Take New Photo',
                    handler: async () => {
                        const imagePath: string = this.createNewMenuItemImageSrc();
                        const dataUrl: DataURL = await this.cameraService.takeNewPhoto();
						this.newImage = new NewImage(imagePath, dataUrl);
					}
                },
                {
                    text: 'Upload Photo',
                    handler: async () => {
						const imagePath: string = this.createNewMenuItemImageSrc();
                        const dataUrl: DataURL = await this.cameraService.selectPhotoFromGallery();
                        this.newImage = new NewImage(imagePath, dataUrl);
					}
                },
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ]
        })
        await actionSheet.present();
    }

	private createNewMenuItemImageSrc(): string{
		return `menu/${this.vendor.getVendorID()}/${Math.random().toString(13).replace('0.', '')}`
	}

	private async uploadNewImage(): Promise<Image>{
		const loader = await this.loadCtrl.create({
			message: "Uploading new image"
		});
		await loader.present();
		
		try{
			const uploadResponse = await this.remoteStorageService.uploadAndReplaceImage(this.newImage.imageSrc,this.newImage.dataUrl,this.menuItem.getImage());
			loader.dismiss();

			if (uploadResponse.state === RemoteStorageTaskState.FAILURE){
				const alert = await this.alertCtrl.create({
					message: uploadResponse.message,
					buttons: ["Ok"]
				})
				await alert.present();
			}
			return {
				imageUrl: uploadResponse.url,
				imageSrc: this.newImage.imageSrc
			};
			

		}catch(error){
			loader.dismiss();
			const alert = await this.alertCtrl.create({
				message: error.message,
				buttons: ["Ok"]
			})
			await alert.present();
		}
	}


	private async commitMenuItem(menuItem: Persistable<IMenuItemData>){
		const loader = await this.loadCtrl.create({
			message: this.mode === "Add" ? "Adding menu item" : "Updating menu item"
		})
		await loader.present();
		try{
			let response: RemoteResponse;
			if (this.mode === "Update"){
				response = await this.menuItemRepo.update(menuItem, {vendorID: this.vendor.getVendorID()});
			}else{
				response = await this.menuItemRepo.add(menuItem, {vendorID: this.vendor.getVendorID()});
			}
			loader.dismiss();
			console.log(response);
			if (response.data.statusCode === RemoteResponseStatus.FAILURE){
				const alert = await this.alertCtrl.create({
					header: "Unable to " + (this.mode === "Add" ? "add" : "update") + " menu item",
					message: response.data.msg,
					buttons: ["OK"]
				})
				alert.present()
			}

		}catch(error){
			loader.dismiss();
			const alert = await this.alertCtrl.create({
				header: "Unable to " + (this.mode === "Add" ? 'add' : 'update') + " menu item",
				message: "If this issue continues, please contact StreatSmart. Error msg: " + error.message
			})
			await alert.present();
		}
		
	}

	async onDeleteImage(){
		const alert = await this.alertCtrl.create({
			header: "Delete Image?",
			message: "This action cannot be undone.",
			buttons: [{
				text: "Delete",
				role: 'confirm',
				handler: async () => {
					this.deleteMenuItemImage();
				}
			},
			{
				text: "Cancel",
				role: 'cancel'
			}
		]
		})
		await alert.present();
	}


	async deleteMenuItemImage(){
		const loader = await this.loadCtrl.create({
			message: "Removing menu item image"
		})
		loader.present();
		try{
			const storageResp = await this.remoteStorageService.deleteImage(this.menuItem.getImage());
			if (storageResp.state === RemoteStorageTaskState.FAILURE){
				const alert = await this.alertCtrl.create({
					header: "Unable to delete menu item",
					message: "If this issue persists, please contact StreatSmart",
					buttons: ["OK"]
				})
				alert.present()
			}else{
				this.menuItem.setImage(null);
				await this.commitMenuItem(this.menuItem as unknown as Persistable<IMenuItemData>);
			}
			
			loader.dismiss();
		}catch(error){
			loader.dismiss();
			const alert = await this.alertCtrl.create({
				header: "Unable to remove image",
				message: "If this issue continues, please contact StreatSmart",
				buttons: ["Ok"]
			})
			await alert.present();
		}
		this.onDismiss();
	}


	async onDeleteMenuItem(){
		const alert = await this.alertCtrl.create({
			header: "Delete Menu Item",
			message: "Are you sure you want to delete this menu item? This operation cannot be undone.",
			buttons: [
			{
				text: "Delete",
				role: 'confirm',
				handler: () => {
					this.deleteMenuItem();
				}
			},{
				text: "Cancel",
				role: 'cancel'
			}	
			]
			
		})
		await alert.present();
	}

	private async deleteMenuItem(){
		const loader = await this.loadCtrl.create({
			message: "Deleting Menu Item"
		})
		await loader.present();
		try{
			await this.menuItemRepo.delete(this.menuItem as unknown as Persistable<IMenuItemData>,{vendorID: this.vendor.getVendorID()})
			loader.dismiss();
		}catch(error){
			loader.dismiss();
			const alert = await this.alertCtrl.create({
				header: "Unable to delete menu item",
				message: "If this issue continues, please contact StreatSmart",
				buttons: ["Ok"]
			})
			await alert.present();
		}
		this.onDismiss();
		
	}
}
