import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

import { IMenuItem, IMenuItemData } from "./index";
import { Image, Model } from "../index";
import { Persistable } from "../../app-domain/index";
import { AppModule } from 'src/app/app.module';
import { SubscriptionManagerService } from 'src/app/app-services/subscription-manager.service';

export class MenuItem implements IMenuItem, Persistable<IMenuItemData>{
	private itemID: string;
	private itemData: IMenuItemData = <IMenuItemData>{};
	private reference: firebase.database.Reference;
	constructor(itemData?: IMenuItemData, itemID?: string){
		this.itemID = itemID;
		this.itemData = itemData;
	}


	public getID(): string{
		return this.itemID;
	}

	public getData(): IMenuItemData{
		return this.itemData;
	}

	public getItemID(): string{
		return this.itemID;
	}

	public getMenuData(): IMenuItemData{
		return this.itemData;
	}

	public setMenuItemData(data: IMenuItemData): void{
		this.itemData = data;
	}

	public getTitle(): string{
		return this.itemData.title;
	}

	public getDescription(): string{
		return this.itemData.description;
	}

	public getMenuType(): string{
		return this.itemData.menuType;
	}

	public getPrice(): string{
		return this.itemData.price;
	}

  public getMenuTags(): string[]{
    if (this.itemData.menuTags) {
      return this.itemData.menuTags;
    } else {
      return []
    }
		//return this.itemData.menuTags;
	}

	public getImageURL(): string{
		if (this.itemData.image && this.itemData.image.imageUrl){
			return this.itemData.image.imageUrl;
		}else{
			return "../../../assets/icon/menu-item.png"
		}
	}

	public hasImage(): boolean{
		return this.itemData && this.itemData.image ? true:false;
	}
	public isActive(): boolean{
		return this.itemData.activeInd;
	}

	public setActive(value: boolean){
		this.itemData.activeInd = value;
	}

	public toggle(): void{
		this.itemData.activeInd = !this.itemData.activeInd;
	}
	public getURL(): string{
		return this.itemData.url;
	}

	public getImageSource(): string{
		return this.itemData.image.imageSrc;
	}

	public getImage(): Image{
		if (this.itemData && this.itemData.image){
			return this.itemData.image;
		}
	}

	public setImage(image: Image){
		this.itemData.image = image;
	}

	public getCreatedTime(): number{
		return this.itemData.createdTime;
	}

	public getActiveInd(): boolean{
		return this.itemData.activeInd;
	}

	public subscribe(db: firebase.database.Reference): void{
		this.unsubscribe();
		AppModule.injector.get(SubscriptionManagerService).addSubcription(this);
		this.reference = db;
		db.on("value",(snapshot: firebase.database.DataSnapshot) => {
			//console.log("Subscribe Menu Item")
			//console.log(snapshot.val());
			//if (snapshot.val() != undefined) {this.setMenuItemData(snapshot.val());}
		});
	}

	public setOrder(order: number): void{
		this.itemData.order = order;
	}

	public getOrder(): number{
		return this.itemData.order;
	}

	public getAllergens(): string[]{
		return this.itemData.allergens;
	}

	public destroy(){
		this.unsubscribe();
	}

	public unsubscribe(): void {
		if (this.reference) this.reference.off();
	}
}
