import {Injectable } from '@angular/core';
/***Firebase***/
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';


import { IRepository, Persistable } from "../../app-domain/index";
import { IReviewData, IReview, Review } from "./index";
import { ReviewService } from "../../app-services/index";


@Injectable()
export class ReviewRepo implements IRepository<IReview, IReviewData>{

	constructor(private reviewService: ReviewService){}

	//Get all reviews for a single vendor
	public async getByID(id: string, id2: string): Promise<IReview>{
		const ref: firebase.database.Reference = firebase.database().ref("/reviews/" + id + "/" + id2);
		const snapshot: firebase.database.DataSnapshot = await ref.once('value');
		const _reviewData: {[key: string]: IReviewData} = snapshot.val();
		return new Review(id, id2, _reviewData);
	}

	//Get all reviews for a single vendor and subscribe to changes
	public async getByIDAndSubscribe(id: string, id2: string): Promise<IReview>{
		const ref: firebase.database.Reference = firebase.database().ref("/reviews/" + id + "/" + id2);
		const snapshot: firebase.database.DataSnapshot = await ref.once('value');
		const _reviewData: {[key: string]: IReviewData} = snapshot.val();
		const review: IReview =  new Review(id, id2, _reviewData);
		review.subscribe(ref);
		return review;
	}

	public getByIDs(ids: string[]): Promise<IReview[]>{
		throw new Error("Not implemented");
	}


	public getByIDsAndSubscribe(ids: string[]): Promise<IReview[]>{
		throw new Error("Not implemented");
	}

	public update(data: Persistable<IReviewData>,miscData: {[key: string]: any}): Promise<any>{
		if (!miscData.vendorID) {throw new Error("No VendorID Provided");}
		return this.reviewService.updateReview(data, miscData);
	}

	public delete(data: Persistable<IReviewData>,miscData: {[key: string]: any}): Promise<any>{
		if (!miscData.vendorID) {throw new Error("No VendorID Provided");}
		return this.reviewService.deleteReview(data, miscData);
	}

	public add(data: Persistable<IReviewData>,miscData: {[key: string]: any}): Promise<any>{
		if (!miscData.vendorID) {throw new Error("No VendorID Provided");}
		return this.reviewService.addReview(data, miscData);
	}








}
