export abstract class Accordion<T> {
	protected label: string;
	protected drawer: AccordionDrawerItem[];
	protected _isOpen: boolean = false;

	constructor(label: string, drawerData: T){
		this.label = this.createLabel(label);
		this.drawer = this.createDrawer(drawerData);
	}

	public display(): string{
		return this.label;
	}

	public getDrawer(): AccordionDrawerItem[]{
		if (this._isOpen) {return this.drawer;}
	}

	public onOpen(): void{
		this._isOpen = true;
	}

	public onClose(): void{
		this._isOpen = false;
	}

	public isOpen(): boolean{
		return this._isOpen;
	}

	public getLabel(): string{
		return this.label;
	}

	protected abstract createLabel(label: string): string;

	protected abstract createDrawer(drawerData: T): AccordionDrawerItem[];


}



export class AccordionDrawerItem  {
	protected pane: string;
	protected informative: string;

	constructor(pane: string, informative: string){
		this.pane = pane;
		this.informative = informative;

	}

	public display(): string{
		return this.pane;
	}

	public alert(): string{
		return this.informative;
	}

	public getPane(): string{
		return this.pane;
	}

	public getInformative(): string{
		return this.informative;
	}
}
