import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter  } from '@angular/core';
import { Platform} from '@ionic/angular';

/***Domain***/
import { Image } from 'src/app/app-domain';
/***Plugins***/
//import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { IMenu, IMenuItem } from 'src/app/app-domain/menu';
import { SwiperComponent } from '../swiper.base.component';

@Component({
	selector: 'menu-swiper',
	templateUrl: './menu-swiper.component.html',
	styleUrls: ['menu-swiper.component.scss']
})
export class MenuSwiperComponent extends SwiperComponent {
  @ViewChild("swiper") private swiperRef: ElementRef;
  @Output() onSlideChanged =new EventEmitter<IMenuItem>();
	private _menu: IMenu;
	@Input() set menu(menu:IMenu){
		 this._menu = menu;
      this.onSlideChanged.emit(this.menu.getAvailableMenuItems()[0])
		 console.log(this._menu);
	}

	get menu(): IMenu{
		return this._menu;
	}

	public slidesPerView: number;

  constructor(platform: Platform) {
    super(platform)
	}
	
	ngOnInit(): void {
    
	}

  public getSlidesPerView(){
		return super.getSlidesPerView();
  }

  onSlideChange(event) {
    this.onSlideChanged.emit(this.menu.getAvailableMenuItems()[this.swiperRef.nativeElement.swiper.activeIndex]);

  }


}
