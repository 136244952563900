import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'star-rating',
	templateUrl: './star-rating.component.html',
	styleUrls: ['star-rating.component.scss'],
})
export class StarRatingComponent implements OnInit {
	private _rating: string;
	private starWidthArray: string[];
	private noReviewFlag: boolean = false;
	@Input() set rating(value: string){
		this._rating = value;
	}

	get rating(){
		return this._rating;
	}

	@Input() sz: number;

	ngOnInit() {
		
		if (!isNaN(parseFloat(this.rating))){
			/*if (parseFloat(this.rating) != 0){
				this.createStarWidthArray(parseFloat(this.rating));
			}else{
				this.noReviewFlag = true;
			}*/
			this.createStarWidthArray(parseFloat(this.rating));
		}else{
			this.noReviewFlag = true;
		}

	}

	private createStarWidthArray(rating: number){
		this.starWidthArray = [];
		let breakInd = 0;
		for (let i = 0;i<5;i++){
			if (rating - (i+1) > 0){
				this.starWidthArray.push("100");
			}else if(!breakInd){
				this.starWidthArray.push("" + this.mapStarWidths((rating-((i+1)-1))*100));
				breakInd = 1;
			}else{
				this.starWidthArray.push("0");
			}
		}
	}

	mapStarWidths(rating: number){
		//LaGrange Transformation
		return (-(rating**10)/6048000000000000) +
			   ((rating**9)/6720000000000) -
			   ((193*(rating**8))/4032000000000) +
			   ((17*(rating**7))/2100000000) -
			   ((23863*(rating**6))/28800000000) +
			   ((1043*(rating**5))/19200000) -
				((2782861*(rating**4))/1209600000) +
				((204823*(rating**3))/3360000) -
				((524497*(rating**2))/560000) +
				((66383*(rating))/8400);

	}


}
