import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { ICalendar, EventGroup, IEventRead, IParentCalendar, IChildCalendar, IEventAdapter} from "./index";


export class Calendar implements IParentCalendar{
	private events: IChildCalendar[] = [];

	constructor(events?: EventGroup[]){
		this.events = events ? events : [];
	}

	public getAllEvents(): IEventRead[]{
		return this.events.map((eventGroup: EventGroup) => eventGroup.getAllEvents()).flat();
	}

	public getAllOpenEvents(): IEventRead[]{
		return this.events.map((eventGroup: EventGroup) => eventGroup.getAllOpenEvents()).flat();
	}

	public getAllNonClosedEvents(): IEventRead[]{
		return this.events.map((eventGroup: EventGroup) => eventGroup.getAllNonClosedEvents()).flat();
	}

	public getAllCnvtEvents<t>(eventAdapter: IEventAdapter<t>): t[]{
		return eventAdapter.convert(this.getAllEvents());
	}

	public addEventToGrouping(event: IEventRead, group: string): void{
		const _group: EventGroup = this.getEventGroup(group);
		_group.addEvent(event);
	}

	public addEventsToGrouping(events: IEventRead[], group: string): void{
		const _group: EventGroup = this.getEventGroup(group);
		_group.addEvents(events);
	}

	public removeEventFromGrouping(event: IEventRead, group: string): void {
		console.log(event)
		const _group: EventGroup = this.getEventGroup(group);
		_group.removeEventById(event.getEventID());
	}

	private getEventGroup(group: string): EventGroup{
		let _group: EventGroup;
		this.events.forEach((eventGrp: EventGroup) => {
			if (eventGrp.getGroupName() === group) {_group = eventGrp;}
		});
		return _group;
	}

	public getEventsByGrouping(group: string): IEventRead[]{
		const _group: IChildCalendar = this.getEventGroup(group);
		if (_group) {return _group.getAllNonClosedEvents();}
		return [];
	}

	public getOpenEventsByGrouping(group: string): IEventRead[]{
		const _group: IChildCalendar = this.getEventGroup(group);
		if (_group) {return _group.getAllOpenEvents();}
		return [];
	}

	public addEventGroup(eventGroup: IChildCalendar): void{
		this.events.push(eventGroup);
	}

	public findEventsByID(eventIDs: string[]): IEventRead[]{
		return this.getAllEvents().filter((event: IEventRead) => eventIDs.indexOf(event.getEventID()) > -1);
	}

	public numberOfEventsInMonth(date?: Date): number{
		if (date === undefined) {date = new Date();}
		return this.getAllEvents().filter((event: IEventRead) => new Date(event.getStartTime()).getMonth() === date.getMonth() && new Date(event.getStartTime()).getFullYear() === date.getFullYear()).length;
	}

	public refresh(){
		this.events.forEach(event => {
			event.refresh();
		})
	}

	public destroy(){
		this.events.forEach(event => event.destroy());
		this.events = [];
	}

	/*public setEvents(events: IEvent[]): void{
		this.events = events;
	}


	public addEvents(events: IEvent[]): void{
		this.events = this.events.concat(events);
	}

	public getEvents(): IEvent[]{
		return this.events;
	}

	public getEventByID(eventID: string): IEvent{
		let _event = this.getEvents().filter((event: IEvent) => {
			return event.getEventID() === eventID;
		});
		if (_event.length > 0) return _event[0];
		return;
	}

	public getEventsByID(eventIDs: string[]): IEvent[]{
		return eventIDs.map((eventID: string) => {
			return this.getEventByID(eventID);
		})
	}

	public getEventsByPlaceIDs(placeIDs: {[key: string]: any}): IEvent[]{
		return this.getEvents().filter((event: IEvent) => {
			return placeIDs.hasOwnProperty(event.getPlaceID());
		});
	}

	public getOpenNowEvents(): IEvent[]{
		return this.getEvents().filter((event: IEvent) => {
			return event.isOpen();
		});
	}*/

	public subscribeToQuery(query: firebase.database.Query): void{


	}

	public unsubscribe(): void {
	
	}

}
