import {Injectable } from '@angular/core';
import { RemoteStorageService, RemoteStorageTaskResponse, RemoteStorageTaskState } from '../index';
import { ImageType } from 'src/app/app-domain';
import { DataURL } from 'src/app/app-interfaces';



@Injectable()
export abstract class ImageOrchestratorService{

	constructor(
	){
		
	}

    abstract uploadImage(path: string, image: DataURL, imageType: ImageType)
   
}
 