import {Injectable } from '@angular/core';
import { AlertController, LoadingController } from "@ionic/angular";
import { Persistable } from "../../app-domain/index";
import { RemoteResponse, RemoteResponseStatus } from "../../app-interfaces/index";

@Injectable()
export class RemoteService{
	constructor(protected alertCtrl: AlertController, protected loadCtrl: LoadingController){

	}

	public async displayRemoteResponse(response: RemoteResponse){
		const alert = await this.alertCtrl.create({
			header: response.data.msg,
			buttons: [{
				"text": "OK",
				handler: () => {
					
				}
			}]
		})
		await alert.present();
	}

	public async displayRemoteError(response: RemoteResponse){
		if (response.data.statusCode === RemoteResponseStatus.FAILURE){
			this.displayRemoteResponse(response);
		}
	}

	public async displayLoader(message: string): Promise<HTMLIonLoadingElement>{
		console.log(this.loadCtrl)
		const loader = await this.loadCtrl.create({
			message: message
		})
		await loader.present();
		return loader;
	}


}