import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IEventRead } from "../../../../app-domain/scheduling/index";
import { EventDetailsComponent } from '../../event-details/event-details.component';

@Component({
	selector: 'event-card',
	templateUrl: 'event-card.component.html',
	styleUrls: ['event-card.component.scss'],
})
export class EventCardComponent{
	@Input() public event: IEventRead;

  constructor(private modalCtrl: ModalController) {

	}

	ngOnInit(){

	}

  public async onDetailsClick(event: IEventRead) {
    const modal = await this.modalCtrl.create({
      component: EventDetailsComponent,
      componentProps: { event: this.event },
      breakpoints: [0, .66, 1],
      initialBreakpoint: .66,
      backdropBreakpoint: .4,
      backdropDismiss: true
    });
    await modal.present();
  }

}
