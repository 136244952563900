import { Component, OnInit, Input, ViewChild, ElementRef  } from '@angular/core';
import { Platform} from '@ionic/angular';

/***Domain***/
import { Image } from 'src/app/app-domain';
/***Plugins***/
//import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';

interface IVenueCardSlides{
	photos: Image,
	slidesPerView: number,
	getScreenWidth(): number,
	onViewImage(image:any): void,
	slideChanged(): void,
}

@Component({
	selector: 'image-slides',
	templateUrl: './image-slides.component.html',
	styleUrls: ['image-slides.component.scss']
})
export class ImageSlidesComponent implements OnInit {
 	@ViewChild("swiper") private swiperRef: ElementRef;
 	@Input() photos: Image;

	public slidesPerView: number;
	//public slideEnd: boolean = false;
	//public slideBegin: boolean = true;
		
	constructor(
		private platform: Platform
		//private photoViewer: PhotoViewer
	){}
	
	ngOnInit() {
		this.calculateSlidesPerScreen();
	}
	
	
	/*onError(){
		console.log('test');
	}*/

	private calculateSlidesPerScreen(){
		const screenWidth = this.platform.width();
		if (screenWidth < 350){
			this.slidesPerView = 1;
		}else if(screenWidth < 1000){
			this.slidesPerView = 2;
		}else if(screenWidth < 1400){
			this.slidesPerView = 3;
		}else{
			this.slidesPerView = 2;
		}
	}

	public async slideChanged(){
		//this.slideEnd = await this.swiperRef?.nativeElement.swiper.isEnd;
		//this.slideBegin = await this.swiperRef?.nativeElement.swiper.isBeginning;
	}

	//public onViewImage(image: any): void {
		//this.photoViewer.show(image);
	//}
}