import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VendorCacheService } from 'src/app/app-services';
import { IVendor } from 'src/app/app-domain/vendor';
import { Router } from '@angular/router';
import { MultiNav } from 'src/app/app-interfaces/multi-route-nav.interface';
import { VendorItemListComponent } from '../vendor-item-list.abstract.component';
import { Observer } from 'src/app/app-interfaces';
import { IUser, UserService } from 'src/app/app-domain/user';



@Component({
	selector: 'favorites-item-list',
  templateUrl: 'favorites-item-list.component.html',
  styleUrls: ['favorites-item-list.component.scss']
})
export class FavoritesItemListComponent extends VendorItemListComponent implements OnInit, Observer<IUser> {
  constructor(vendorCacheService: VendorCacheService, router: Router, private userService: UserService
	){
    super(vendorCacheService, router);
    this.userService.subscribeToChanges(this);
  }

  async ngOnInit() { }

  public async onVendorClick(vendor: IVendor) {
    this.onClick.emit({path1: 'user/trucks/', path2:'user/trucks/vendor/' + vendor.getVendorID()});
    //await this.router.navigate(['user/trucks/']); //need to go to trucks page before to allow ion-back-button
    //this.router.navigate(['user/trucks/vendor/' + vendor.getVendorID()]);
  }

  public getID(): string {
      return "component.favorites-item-list";
  }

  public update(payload: IUser): void {
      this.loadVendors(payload.getFavoriteList());
  }

}


