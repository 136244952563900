import { Component, Input } from '@angular/core';



@Component({
	selector: 'ngx-chart',
	templateUrl: 'ngx-chart.component.html',
	styleUrls: ['ngx-chart.component.scss']
})
export class NGXChartComponent {
	@Input() showXAxis: boolean = true;
	@Input() showYAxis: boolean = true;
	@Input() gradient: boolean = false;
	@Input() showLegend: boolean = true;
	@Input() showXAxisLabel: boolean = true;
	@Input() showYAxisLabel: boolean = true;
	@Input() xAxisLabel: string = "";
	@Input() yAxisLabel: string = "";
	@Input() animations: boolean = true;
	@Input() legendPosition: string = "right";
	
	private _data: any[] = [];
	@Input() set data(data: any[]){
		this._data = data;
		//this.calcYTicks();
	}

	public get data(): any[]{
		return this._data;
	}

	@Input() view: any[];
	
	public yAxisTicks: number[] = [];

	colorScheme = {
		domain: ['#5AA454', '#C7B42C', '#AAAAAA']
		//domain: ['#c56832', '#327CC5', '#AAAAAA']
	};

	constructor() {
		//Object.assign(this, { this.data });
	}

	ngOnInit(){	}

	onSelect(event) {
		console.log(event);
		console.log(this.data)
		console.log(this.yAxisTicks)
	}

	/*calcYTicks(){
		console.log(this.data)
		let maxVal: number = 0;
		this.data.forEach(v => {
			let totalVal: number = 0;
			v.series.forEach(s => {
				totalVal += s.value;
			});
			maxVal = Math.max(maxVal,totalVal);
		})
		console.log(maxVal);
		if (maxVal > 25){
			this.yAxisTicks = [0,10,20,30];
		}else if(maxVal > 10){
			this.yAxisTicks = [0,5,10,15,20,25,30];
		}else if(maxVal > 6){
			this.yAxisTicks = [0,2,4,6,8,10];
		}else{
			this.yAxisTicks = [0,1,2,3,4,5];
		}
	}*/

}
