import {Injectable } from '@angular/core';
/***Firebase***/
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import { IPlace, IPlaceData, Place, IVenueSearch } from "./index";
import { ILocation, Location } from "../location/index";

import { IRepoRead, Persistable } from "../../app-domain/index";

export interface IPlaceRepo extends IRepoRead<IPlace, IPlaceData>, IVenueSearch {}


@Injectable()
export class PlaceRepo implements IPlaceRepo{
	
	constructor(){
		
	}

    public async getByID(id: string): Promise<IPlace>{
		const ref: firebase.database.Reference = firebase.database().ref("/places/" + id);
		const snapshot: firebase.database.DataSnapshot = await ref.once("value");
		const _placeData: IPlaceData = snapshot.val();
		return new Place(id, _placeData);
	}


	public async getByIDAndSubscribe(id: string): Promise<IPlace>{
		throw new Error("Not implemented");
	}

    public async searchVenuesWithEvents(state: string): Promise<[IPlace, number][]> {
        const ref: firebase.database.Reference = firebase.database().ref('/venueEventAgg/' + state);
        const snapshot: firebase.database.DataSnapshot = await ref.once("value");
        const placeIDs: {[placeID: string]: number} = snapshot.val();
    
        if (placeIDs && Object.keys(placeIDs).length > 0){
            const orderedPlaceIDs = Object.keys(placeIDs).sort((a,b) => {
                if (placeIDs[a] > placeIDs[b]) return -1;
                if (placeIDs[a] < placeIDs[b]) return 1;
                return 0;
            })
            return Promise.all(orderedPlaceIDs.map(async (placeID: string) => {
                const place = await this.getByID(placeID);
                return [place, placeIDs[placeID]];
            }))
        }else{
            return [];
        }
       
    }


	
	
}
