export interface RemoteStorageTaskResponse{
    state: RemoteStorageTaskState,
    message: string,
    url: string
}


export enum RemoteStorageTaskState{
    SUCCESS = 1,
    FAILURE = 2
}


