import { Component, Input } from '@angular/core';
import { Router } from "@angular/router";
import { IVendor } from "../../../../app-domain/vendor/index";
import { IUserData, UserService } from "../../../../app-domain/user/index";
import { RemoteUserService } from 'src/app/app-services';
import { Persistable } from 'src/app/app-domain';
import { IonIcon } from '@ionic/angular';

@Component({
	selector: 'vendor-card',
	templateUrl: 'vendor-card.component.html',
	styleUrls: ['vendor-card.component.scss'],
})
export class VendorCardComponent{
	@Input() public vendor: IVendor;

	public favoritesIcon = {
		true: {
			icon: 'heart',
			color: 'primary'
		},
		false: {
			icon: 'heart-outline',
			color: 'primary'
		}
	};

	constructor(private userService: UserService, private router: Router, private remoteUserService: RemoteUserService){

	}

	ngOnInit(){

	}

  public onCardClick() {
		this.router.navigate([this.router.url + '/vendor/' + this.vendor.getVendorID()]);
	}


	onFavoritesClick(icon: IonIcon){
		if (icon.name === this.favoritesIcon.true.icon){
			icon.name = this.favoritesIcon.false.icon;
			const persistable = this.userService as unknown as Persistable<IUserData>;
			this.remoteUserService.removeFavorite(persistable, {vendorID: this.vendor.getVendorID()})
		}else{
			icon.name = this.favoritesIcon.true.icon;
			const persistable = this.userService as unknown as Persistable<IUserData>;
			this.remoteUserService.addFavorite(persistable, {vendorID: this.vendor.getVendorID()})
		}
		
	}

}
