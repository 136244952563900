import { Component, OnInit} from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
/***Services***/

/***Models***/
import { EventRepo, IChildCalendar, IHotSpot, IEvent } from '../../../app-domain/scheduling';
/***Pages***/
import { HotSpotBuilderPage } from './update-hotspot/hotspot-builder.page';
/***Data***/
import usStates from "../../../../assets/data/us-states";
/***Services */
import { AdminService } from '../../../app-services';



@Component({
  selector: 'page-hotspot',
  templateUrl: 'hotspot.page.html',
})
export class HotSpotPage implements OnInit{
  public state: string;
  public hotSpots: IChildCalendar;
  public usStates = usStates;
  public dataLoaded: boolean = false;
  constructor(
    private modalCtrl: ModalController,
    private eventRepo: EventRepo,
    private adminService: AdminService,
    private loadCtrl: LoadingController,
    private alertctrl: AlertController
	){}

	ngOnInit(){	
		this.state = "MN";
	}
	
  async ionViewDidEnter() {
    await this.searchHotSpots(this.state);
    this.dataLoaded = true;
	}
	
	public onChange(value: string){
		return this.searchHotSpots(value);
	}
	
	private async searchHotSpots(state: string){
		this.hotSpots = await this.eventRepo.getAllHotSpotsByState(state)
    console.log(this.hotSpots);
	}

  public onDismiss() {
    this.modalCtrl.dismiss();
  }

  public async updateHotspot(mode: string, hotSpot: IHotSpot) {
    var hotspotCompProps: any;
    if (mode === "New") {
      hotspotCompProps = { mode: mode };
    } else {
      hotspotCompProps = { mode: mode, event: hotSpot };
    }

    const modal = await this.modalCtrl.create({
      component: HotSpotBuilderPage,
      componentProps: hotspotCompProps
    })
    await modal.present();
    modal.onDidDismiss().then(() => {
      this.searchHotSpots(this.state);
    })
	}

  public async onDeleteHotSpot(event: IEvent) {
    const loader = await this.loadCtrl.create({
      message: "Deleting Hotspot Event"
    })
    await loader.present();
    try {
      this.adminService.deleteHotSpot(event.getEventID(), {
        event: event.getEventData(),
        hotspot: event.getHotSpot().getHotSpotData()
      })
        .then(() => { return this.searchHotSpots(this.state); })
      loader.dismiss();
    } catch (error: any) {
      loader.dismiss();
      const alert = await this.alertctrl.create({
        header: "Unable to delete hotspot event",
        message: error.message,
        buttons: ["Ok"]
      })
      await alert.present();
    }
  }
}
