import {Injectable } from '@angular/core';
import { CallNumberService } from './call-number.service.abstract.';
import { CallNumber } from "@awesome-cordova-plugins/call-number";

@Injectable()
export class CordovaCallNumberService extends CallNumberService{

	constructor(
	
	){
		super();
	}

	
		
  call(number: string) {
    return CallNumber.callNumber(number,true)
  }



   
}
