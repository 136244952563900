import {Injectable } from '@angular/core';
import { InAppBrowserService } from './in-app-browser.service.abstract.';
import { Browser } from "@capacitor/browser";


@Injectable()
export class CapacitorIABService extends InAppBrowserService{

	constructor(
	
	){
		super();
	}

   public async open(url: string, target?: string): Promise<void> {
       await Browser.open({url: url});
   }



   
}
 