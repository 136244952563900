import { Component, OnInit, Input, EventEmitter, Output} from '@angular/core';

/***Models***/
import { Cuisine } from "../../../../app-domain/vendor/index";

/***Components***/
//import { CuisineAccordion } from "./index";


@Component({
	selector: 'cuisine-accordion',
	templateUrl: './cuisine-accordion.component.html'
})
export class CuisineAccordionComponent implements OnInit {
	private _accordionList;

	/*@Input() set accordionList(value: Cuisine[]){
		this._accordionList = value.map((v) => {
			return new CuisineAccordion({[v]: value[v]})
		});
	}

	get accordionList(){
		return this._accordionList;
	}

	@Output() public selectedCuisineItem = new EventEmitter<Cuisine>();
	@Output() public deletedCuisineItem = new EventEmitter<Cuisine>();
	constructor(){}
*/
	ngOnInit(){
		console.log(this._accordionList);
	}
	/*
	public onSelectCuisineItem(item: any,subItem: any): void{
		const cuisine: Cuisine = new Cuisine(item.getLabel(), subItem.getPane(), subItem.getInformative());
		this.selectedCuisineItem.emit(cuisine)
	}

	public onDeleteCuisineItem(item: any, subItem: any): void{
		const cuisine: Cuisine = new Cuisine(item.getLabel(), subItem.getPane(), subItem.getInformative());
		this.deletedCuisineItem.emit(cuisine)
	}
*/

}
