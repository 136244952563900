import {Injectable } from '@angular/core';

/***Plugins */
import { Storage } from '@ionic/storage-angular';


@Injectable()
export class IonicNativeStorageService{
    private _storage: Storage | null = null;
    private nameSpace: string = "StreatSmart";

	constructor(private storage: Storage){
        this.init()
	}

    private async init(){
        this._storage = await this.storage.create();
    }


    public async get(): Promise<any>{
        if (this._storage === null){
            await this.init()
            return this._storage.get(this.nameSpace);
        }else{
            return this._storage.get(this.nameSpace);
        }
       
    }

    public set(value: any): Promise<void>{
        return this._storage.set(this.nameSpace, value);
    }
	
}

