import { Directive, ElementRef, Input, OnInit} from '@angular/core';
import { ViewManagerService } from "../app-services/index";

@Directive({
	selector: '[nativeAccess]',
})
export class NativeAccessDirective implements OnInit{
	constructor(private viewMgr: ViewManagerService, private el: ElementRef) {

	}


	ngOnInit(){
		if (!this.viewMgr.isNative()){
			this.el.nativeElement.style.display = "none";
		}
	}

}
