import { Component, Input } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { ICalendar, IEventRead } from "../../../../app-domain/scheduling/index";

import { IPlace } from 'src/app/app-domain/place';


@Component({
	selector: 'venue-calendar',
	templateUrl: 'venue-calendar.component.html',
	styleUrls: ['venue-calendar.component.scss'],
})
export class VenueCalendarComponent {
	@Input() public calendar: ICalendar;
	@Input() public venue: IPlace;
	public dataLoaded: boolean = false;
	public selectedEvents: IEventRead[] = [];
	public selectedDate: Date;
	constructor(private modalCtrl: ModalController){

	}

	ngOnInit(){
		if (this.calendar) {this.dataLoaded = true;}
	}


	public setSelectedDate(event: Date): void{
		this.selectedDate = event;
	}

	public updateEventCard(events: IEventRead[]): void{
		this.selectedEvents = events;
	}

}
