import { Injectable } from '@angular/core';
import { PhotoViewerService } from './photo-viewer.service.abstract';
import { PhotoViewer } from '@capacitor-community/photoviewer';
import { AppManagerService } from '../../app-manager.service';

@Injectable()
export class CapacitorPhotoViewerService extends PhotoViewerService{
	private photoViewerEl: HTMLElement;
	constructor(private appManagerService: AppManagerService){
		super();
		if (!this.appManagerService.isNative()){
			this.photoViewerEl = document.getElementById("photoviewer-container")
			this.photoViewerEl.addEventListener("jeepPhotoButtonsClose",() => {
				this.photoViewerEl.style.display = "none";
			})  
		}
		
	}

	public async show(url: string, title?: string): Promise<void> {
		if (url.startsWith("https:")){
			if (title){
				title = title.replace('<a href="',"").replace('">'," by ").replace("</a>","");
			}else{
				
			}
			if (!this.appManagerService.isNative()) this.photoViewerEl.style.display = "block";
			await PhotoViewer.show({images: [{url: url, title: title}], mode: 'one'});
		}
		
   	}

   	public exit(){
		console.log("Not implemented in Capacitor photo viewer, using event listeners")
   	}


   
}
 