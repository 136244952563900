import {Injectable } from '@angular/core';
import { BackgroundLocationService } from './background-location.service.abstract';

import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

import BackgroundGeolocation, {
	ProviderChangeEvent,
	Subscription
  } from "@transistorsoft/capacitor-background-geolocation";
import { GeofenceService } from '../../geofence.service';
import { Location } from 'src/app/app-domain/location';
import { AppModule } from 'src/app/app.module';
import { AppManagerService, Device } from '../../app-manager.service';
import { UserPrefRepo, UserPrefs } from 'src/app/app-domain/user';
import { GeofenceUserPref } from 'src/app/app-domain/location/geofence.contracts';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic';
import { Capacitor } from '@capacitor/core';



/***Interfaces***/
interface GeofencePayload {
	radius: number,
	events: EventPayload[],
	notified?: boolean
}


interface EventPayload{
	id: string,
	latitude: number,
	longitude: number,
	vendorName: string,
	startDtTm: number,
	endDtTm: number
}

export interface ListenResponse{
	status: boolean,
	message: string
}


@Injectable()
export class CapacitorBackgroundLocationService extends BackgroundLocationService{
	private subscriptions: Subscription[] = [];
	private geofences: GeofencePayload[] = []; 
	private geofenceService: GeofenceService = AppModule.injector.get(GeofenceService);
	private enabled: boolean = false;
	private ready: boolean = false;
	constructor(appManagerService: AppManagerService, userPrefRepo: UserPrefRepo
	){
		super(appManagerService, userPrefRepo);
		if (this.appManagerService.isNative()) this.init();
	}


	private init(){
		// 1.  Listen to events.
		BackgroundGeolocation.onLocation(location => {
			//this.log(Object.assign({},{"data": location, "device": Capacitor.getPlatform(),type: "On Location Callback"}));
			this.geofenceService.evaluateLocationAgainstGeofences();
		});


		BackgroundGeolocation.onProviderChange(async (event) => {
			this.onBackgroundPermissionChange(event);
		})

		// 2.  Configure the plugin with #ready
		console.log("Readying");
		BackgroundGeolocation.ready({
			reset: true,
			debug: false, //turn off in prod
			logLevel: BackgroundGeolocation.LOG_LEVEL_OFF,
			desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
			distanceFilter: 50,//150, //meters - 164 feet
			// url: 'http://my.server.com/locations',
			//autoSync: true,
			stationaryRadius: 200,
			locationAuthorizationRequest: 'Always',
			stopOnTerminate: false,
			startOnBoot: true,
			showsBackgroundLocationIndicator: false,
			backgroundPermissionRationale: {
				title: "Allow StreatSmart to access this device's location even when closed or not in use?",
				message: "In order for proximity notifications to work, StreatSmart needs access to background location. Please enable {backgroundPermissionOptionLabel} location permission.",
				positiveAction: "Change to {backgroundPermissionOptionLabel}",
				negativeAction: "Cancel"
			}
		}).then((state) => {
			console.log("Background Location Services Ready");
			this.ready = true;
			this.enabled = state.enabled;
		})

		

	}

	public async startOrStopBackgroundLocation(): Promise<void>{
		if (this.ready){
			if (this.appManagerService.isNative()){
				//console.log("Native")
				if ((await this.hasGeofencePref())){
					//console.log("Has GEo")
					if (await this.checkLocationAuthAlways()) {
						//console.log("Listening");
						this.listen();
					}else{
						this.off();
					}
				}	
			}
		}else{
			console.log("Unable to start background location services since it is not yet enabled");
		}
	}



	public async listen(): Promise<ListenResponse> {
		const status = await BackgroundGeolocation.requestPermission();
		if (status){
			//console.log(status);
			if (await this.checkLocationAuthAlways()){
				//console.log("true");
				const state = await BackgroundGeolocation.getState();
				//console.log(state.enabled);
				if (!state.enabled){
					// 3.  Start tracking.
					BackgroundGeolocation.start().then((state) => this.enabled = state.enabled);
					return {status: true, message: "Background location successfully started"};
				}else{
					return {status: true, message: "Background location is already enabled"};
				}
			}else{
				return {status: false, message: "Location permissions are not set to 'Always'"};
			}
		}else{
			return {status: false, message: "Location permissions are not set to 'Always'"};
		}
   }


   public async off(): Promise<any> {
		//console.log("Listener Off");
		await this.turnOffProximityNotifications();
		return BackgroundGeolocation.stop();
   }

   private addGeofences(geofences: GeofencePayload[]){

   } 

	private log(msg: string | Object){
		firebase.database().ref("/test").push(msg);
	}

	private async hasGeofencePref(){
        const geofenceUserPref = await this.getGeofencePref();
        if (geofenceUserPref.active) return true;
        return false;
    }

	private getGeofencePref(): Promise<GeofenceUserPref>{
		return this.userPrefRepo.getPrefByKey<GeofenceUserPref>(UserPrefs.GEOFENCE)
	}


	private async checkLocationAuthAlways(){
        const status = await Diagnostic.getLocationAuthorizationStatus();
        if (status === Diagnostic.permissionStatus.GRANTED){
            return true;
        }else{
			return false;
		}
        
    }

	private turnOffProximityNotifications(){
		return this.userPrefRepo.updatePref<GeofenceUserPref>(UserPrefs.GEOFENCE, <GeofenceUserPref>{active: false, radius:10})
	}

	private async onBackgroundPermissionChange(event: ProviderChangeEvent){
		if (event.status !== BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS){
			
			if (await this.hasGeofencePref()){
				//console.log("Turning Geofence Off");
				await this.off();
			}
	
		}
		
		
	}
   
}