import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

import { IHotSpot, IHotSpotData } from "./index";
import { AppModule } from 'src/app/app.module';
import { SubscriptionManagerService } from 'src/app/app-services/subscription-manager.service';
import { Unsubscribable } from 'rxjs';
import { Model } from '../shared-interfaces';

export class HotSpot implements IHotSpot{
	private hotSpotID: string;
	private hotSpotData: IHotSpotData;
	private reference: firebase.database.Reference;
	constructor(hotSpotID: string, hotSpotData: IHotSpotData){
		this.hotSpotID =  hotSpotID;
		this.hotSpotData = hotSpotData;
	}

	public setHotSpotID(hotSpotID: string): IHotSpot{
		this.hotSpotID = hotSpotID;
		return this;
	}

	public getHotSpotID(): string{
		return this.hotSpotID;
	}

	public setHotSpotData(hotSpotData: IHotSpotData): void{
		if (hotSpotData === null){
			this.hotSpotData = <IHotSpotData>{};
		}else{
			this.hotSpotData = hotSpotData;
		}

	}

	public getHotSpotData(): IHotSpotData{
		return this.hotSpotData;
	}

	public getEndTime(): number{
		return this.hotSpotData.endTime;
	}

	public isUpcoming(): boolean{
		return new Date().getTime() < new Date(this.getEndTime()).setHours(23,59,59,999);
	}

	public getName(): string{
		return this.hotSpotData.name;
	}

	public setCreatedTime(time?: number): void{
		if (time){
			this.hotSpotData.createdTime = time;
		}else{
			this.hotSpotData.createdTime = new Date().getTime();
		}
	}

	public getCreatedTime(): number{
		return this.hotSpotData.createdTime;
	}

	public isVendorParticipating(vendorID: string): boolean{
		if (this.hotSpotData.vendors){
			if (this.hotSpotData.vendors[vendorID] && this.hotSpotData.vendors[vendorID].activeInd) {return true;}
			return false;
		}else{
			return false;
		}
	}

	public getParticipants(): string[]{
		if (this.hotSpotData.vendors){
			const vendorIDs: string[] = [];
			Object.keys(this.hotSpotData.vendors).forEach((vID: string) => {
				if (this.hotSpotData.vendors[vID].activeInd) {vendorIDs.push(vID);}
			});
			return vendorIDs;
		}
		return [];
	}

	public getVendorData(): any{
		return this.hotSpotData.vendors;
	}

	public getParticipantsDesc(vendorID: string): string{
		if (this.isVendorParticipating(vendorID) && this.hotSpotData.vendors[vendorID].desc) {return this.hotSpotData.vendors[vendorID].desc.data;}
	}

	public setVendorData(vendors: any): void{
		this.hotSpotData.vendors = vendors;
	}

	public getWebsite(): string{
		return this.hotSpotData.website;
  }

  public setName(name: string) {
    this.hotSpotData.name = name;
  }

  public setURL(url: string) {
    this.hotSpotData.website;
  }

  public subscribe(db: firebase.database.Reference): void {
    this.unsubscribe();
	AppModule.injector.get(SubscriptionManagerService).addSubcription(this);
	db.on("value", (snapshot: firebase.database.DataSnapshot) => {
      if (snapshot.val() != undefined) { this.setHotSpotData(snapshot.val()); }
    });
  }

  public unsubscribe(): void {
	  if (this.reference) this.reference.off();
  }

}
