import { Component, Input } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { SettingDetail, IClickable } from "../../../app-domain/index";
import { VSNameComponent } from "../../../module-vendor/vendor-pages/vendor-settings/index";


@Component({
	selector: 'setting-list-item-button',
	templateUrl: 'setting-list-item-button.component.html',
	styleUrls: ['setting-list-item-button.component.scss']
})
export class SettingListItemButton implements IClickable{
	@Input() public setting: SettingDetail;

	constructor(private modalCtrl: ModalController) {}

	ngOnInit(){}

	public async click(): Promise<void>{

		const modal = await this.modalCtrl.create({
			component: this.setting.data.component,
			componentProps: {
				model: this.setting.data.model,
				label: this.setting.button.label
			}
		});
		return await modal.present();

	}

}
