import {Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

/***Interfaces */
import { RemotePayload, RemoteResponse } from "../../../app-interfaces/index";
/***Services */
import { FeatureRequestService } from './feature-requests.service.abstract';
import { Persistable } from 'src/app/app-domain';
import { FeatureRequestData } from 'src/app/app-domain/feature-request/feature-request-data.interface';



@Injectable()
export class FirebaseFeatureRequestService extends FeatureRequestService{

	constructor(){
		super();
	}

	public addFeatureRequest(data: Persistable<FeatureRequestData>): Promise<RemoteResponse> {
		const payload: RemotePayload<FeatureRequestData> = this.createPayload<FeatureRequestData>(data);
		const addFeatureRequest = firebase.functions().httpsCallable("service_community_addFeatureRequest");
		return addFeatureRequest(payload);
	}

	public updateFeatureRequest(data: Persistable<FeatureRequestData>): Promise<RemoteResponse> {
		const payload: RemotePayload<FeatureRequestData> = this.createPayload<FeatureRequestData>(data);
		const updateFeatureRequest = firebase.functions().httpsCallable("service_community_updateFeatureRequest");
		return updateFeatureRequest(payload);
	}

	public deleteFeatureRequest(data: Persistable<FeatureRequestData>): Promise<RemoteResponse> {
		const payload: RemotePayload<FeatureRequestData> = this.createPayload<FeatureRequestData>(data);
		const deleteFeatureRequest = firebase.functions().httpsCallable("service_community_deleteFeatureRequest");
		return deleteFeatureRequest(payload);
	}

	public rejectFeatureRequest(data: Persistable<FeatureRequestData>): Promise<RemoteResponse> {
		const payload: RemotePayload<FeatureRequestData> = this.createPayload<FeatureRequestData>(data);
		const rejectFeatureRequest = firebase.functions().httpsCallable("service_community_rejectFeatureRequest");
		return rejectFeatureRequest(payload);
	}

	public approveFeatureRequest(data: Persistable<FeatureRequestData>): Promise<RemoteResponse> {
		const payload: RemotePayload<FeatureRequestData> = this.createPayload<FeatureRequestData>(data);
		const approveFeatureRequest = firebase.functions().httpsCallable("service_community_approveFeatureRequest");
		return approveFeatureRequest(payload);
	}

	public completeFeatureRequest(data: Persistable<FeatureRequestData>): Promise<RemoteResponse> {
		const payload: RemotePayload<FeatureRequestData> = this.createPayload<FeatureRequestData>(data);
		const completeFeatureRequest = firebase.functions().httpsCallable("service_community_completeFeatureRequest");
		return completeFeatureRequest(payload);
	}

	public supportFeatureRequest(data: Persistable<FeatureRequestData>): Promise<RemoteResponse> {
		const payload: RemotePayload<FeatureRequestData> = this.createPayload<FeatureRequestData>(data);
		const supportFeatureRequest = firebase.functions().httpsCallable("service_community_supportFeatureRequest");
		return supportFeatureRequest(payload);
	}

	public unsupportFeatureRequest(data: Persistable<FeatureRequestData>): Promise<RemoteResponse> {
		const payload: RemotePayload<FeatureRequestData> = this.createPayload<FeatureRequestData>(data);
		const unsupportFeatureRequest = firebase.functions().httpsCallable("service_community_unsupportFeatureRequest");
		return unsupportFeatureRequest(payload);
	}
	
	private createPayload<T>(persistable: Persistable<T>): RemotePayload<T>{
		const payload: RemotePayload<T> = <RemotePayload<T>>{};
		payload.id = persistable.getID();
		payload.data = persistable.getData();
		return payload;
	}

}

