import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavParams, ModalController, IonTextarea, LoadingController, AlertController } from '@ionic/angular';
import { Persistable } from '../../../../app-domain';

/***Services***/
//import { ReviewService} from "../../../../services/Review.service";

import { IReview, IReviewData } from "../../../../app-domain/review/index";
import { ReviewService } from '../../../../app-services';

@Component({
  selector: 'vendor-review-response',
  templateUrl: 'vendor-review-response.component.html',
  styleUrls: ['vendor-review-response.component.scss']
})
export class VendorReviewResponseComponent implements OnInit{
	public review: IReview;
	public responseType: "Update" | "View" = "View";
	public readOnly: boolean = true;
	@ViewChild('response') response: ElementRef;

	constructor(
		private navParams: NavParams,
    private modalController: ModalController,
    private reviewService: ReviewService,
    private loadCtrl: LoadingController,
    private alertCtrl: AlertController
	){}

	ngOnInit(){
		this.review = this.navParams.get("review");
		this.responseType = this.navParams.get('responseType');
		this.readOnly = this.responseType === "Update" ? false : true;
	}

	ionViewDidEnter(){}

	public onExit(): void{
		this.modalController.dismiss();
	}

  public async onSubmitResponse(text: string) {
    const loader = await this.loadCtrl.create({
      message: "Updating review response"
    })
    await loader.present();
    try {
      return this.reviewService.respondToReview(this.review as unknown as Persistable<IReviewData>, { value: text, vendorID: this.review.getVendorID() })
        .then(() => {
          loader.dismiss();
          this.modalController.dismiss();
        })
    } catch {
      loader.dismiss();
      const alert = await this.alertCtrl.create({
        header: "Unable to update review response",
        message: "If this issue continues, please contact StreatSmart"
      })
      await alert.present();
    }
	}

}

