import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { IEventRead, ICalendar, IChildCalendar, Event,  IEventAdapter, Ionic2CalAdapter, Ionic2CalEvent, EventRepo} from "./index";
import { Observer } from "../../app-interfaces/index";
import { AppModule } from '../../app.module';
import { SubscriptionManagerService } from 'src/app/app-services/subscription-manager.service';
import { Unsubscribable } from 'rxjs';
import { QuerySubscription } from '../shared-interfaces';


export class EventGroup implements IChildCalendar{
	private events: IEventRead[] = [];
	private name: string;
	private subscribers: Observer<IEventRead[]>[] = [];
	private reference: firebase.database.Query;
	private id: number;
	constructor(name: string, events?: IEventRead[]){
		this.name = name;
		this.events = events ? events : [];
		this.id = new Date().getTime();
	}

	public getAllEvents(): IEventRead[]{
		return this.events;
	}

	public getAllOpenEvents(): IEventRead[]{
		return this.events.filter((event: IEventRead) => event.isOpen());
	}

	public getAllNonClosedEvents(): IEventRead[]{
		return this.events.filter((event: IEventRead) => !event.isClosed());
	}

	public getAllCnvtEvents<t>(eventAdapter: IEventAdapter<t>): t[]{
		return eventAdapter.convert(this.getAllEvents());
	}

	public addEvent(event: IEventRead): void{
		this.events.push(event);
	}

	public addEvents(events: IEventRead[]): void{
		events.forEach((event: IEventRead) => {
			this.events.push(event);
		});
	}

	public getGroupName(): string{
		return this.name;
	}

	public removeEvent(index: number): void{
		this.events.splice(index,1);
	}

	public removeEventById(id: string): void {
		this.events = this.events.filter(e => e.getEventID() != id);
		console.log(this.events);
	}

	private reset(): void{
		this.events = [];
	}

	public findEventsByID(eventIDs: string[]): IEventRead[]{
		return this.getAllEvents().filter((event: IEventRead) => eventIDs.indexOf(event.getEventID()) > 0);
	}

	public numberOfEventsInMonth(date: Date): number{
		return this.getAllEvents().filter((event: IEventRead) => new Date(event.getStartTime()).getMonth() === date.getMonth() && new Date(event.getStartTime()).getFullYear() === date.getFullYear()).length;
	}


	/*public setEvents(events: IEvent[]): void{
		this.events = events;
	}


	public addEvents(events: IEvent[]): void{
		this.events = this.events.concat(events);
	}

	public getEvents(): IEvent[]{
		return this.events;
	}

	public getEventByID(eventID: string): IEvent{
		let _event = this.getEvents().filter((event: IEvent) => {
			return event.getEventID() === eventID;
		});
		if (_event.length > 0) return _event[0];
		return;
	}

	public getEventsByID(eventIDs: string[]): IEvent[]{
		return eventIDs.map((eventID: string) => {
			return this.getEventByID(eventID);
		})
	}

	public getEventsByPlaceIDs(placeIDs: {[key: string]: any}): IEvent[]{
		return this.getEvents().filter((event: IEvent) => {
			return placeIDs.hasOwnProperty(event.getPlaceID());
		});
	}

	public getOpenNowEvents(): IEvent[]{
		return this.getEvents().filter((event: IEvent) => {
			return event.isOpen();
		});
	}*/

	public subscribeToQuery(query: firebase.database.Query): void{
		this.unsubscribe();
		AppModule.injector.get(SubscriptionManagerService).addSubcription(this);
		//console.log(AppModule.injector.get(SubscriptionManagerService))
		this.reference = query;
		console.log("Subscribing to event group");
    	query.on('value', async (snapshot) => {			
			this.reset();

			if (!snapshot.val() || Object.keys(snapshot.val()).length === 0) {return;}

			Object.keys(snapshot.val()).forEach((eventID: string) => {
				this.addEvent(new Event(eventID,snapshot.val()[eventID]));
			});
			//console.log("Checking hotspot");
			//console.log(this);
			await Promise.all(this.getAllEvents()
				.filter((event) => {return event.isHotSpot();})
				.map(async (event) => {
					const hotspot = await AppModule.injector.get(EventRepo).getHotSpotByEvent(event);
					event.setHotSpot(hotspot);
					return;
				})
			)
			//console.log("finished");
			//console.log(this);

			this.notifySubscribers();
		}, (cancel) => {
			console.log("canceled");
			console.log(cancel);
		});
		
	}

	async refresh(){
		const test = await this.reference.get();
	}

	//Subscribe to Event Changes
	public subscribeToChanges(obs: Observer<IEventRead[]>): void{
		this.subscribers.push(obs);
	}

	public removeSubscription(obs: Observer<IEventRead[]>): void{
		this.subscribers.forEach((_obs: Observer<IEventRead[]>, idx) => {
			if (obs.getID() === _obs.getID()) {this.subscribers.splice(idx,1);}
		});
	}

	public notifySubscribers(): void{
		this.subscribers.forEach((obs: Observer<IEventRead[]>) => {
			obs.update(this.getAllEvents());
		});
	}

	public destroy(): void {
		this.unsubscribe();
		this.events.forEach(e => {e.destroy()});
		this.events = [];
	}

	public unsubscribe(): void{
		if (this.reference) this.reference.off();
	}
}
