import {Injectable } from '@angular/core';
/***Firebase***/
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
/***Models***/
import { IEventRead, IEventData, Event, IHotSpot, HotSpot, IEventSearch, ICalendar, IParentCalendar, Calendar, IChildCalendar, EventGroup } from "./index";
import { IRepository, Persistable } from "../../app-domain/index";
import { IVendor } from "../vendor/index";
import { IPlace } from "../place/index";
import { SchedulingService } from "../../app-services/index";

export interface IEventRepo extends IRepository<IEventRead, IEventData>, IEventSearch {}

@Injectable()
export class EventRepo implements IEventRepo{

	constructor(private schedulingService: SchedulingService){}


	public async getByID(id: string): Promise<IEventRead>{
		const ref: firebase.database.Reference = firebase.database().ref("/events/" + id);
		const snapshot: firebase.database.DataSnapshot = await ref.once("value");
		const _eventData: IEventData = snapshot.val();
		return new Event(id, _eventData);
	}


	public async getByIDAndSubscribe(id: string): Promise<IEventRead>{
		const ref: firebase.database.Reference = firebase.database().ref("/events/" + id);
		const snapshot: firebase.database.DataSnapshot = await ref.once('value');
		const eventData: IEventData = snapshot.val();
		const event = new Event(id, eventData);
		event.subscribe(ref);
		return event;
	}


	public update(data: Persistable<IEventData>): Promise<any>{
		return this.schedulingService.updateEvent(data);
	}

	public delete(data: Persistable<IEventData>): Promise<any>{
		return this.schedulingService.deleteEvent(data);
	}

	public add(data: Persistable<IEventData>): Promise<any>{
		return this.schedulingService.addEvent(data);
	}


	public async getAllEventsByVendor(vendor: IVendor, eventLimit?: number): Promise<IParentCalendar>{
		const _eventLimit = !eventLimit ? 1000 : eventLimit;
		const calendar: IParentCalendar = new Calendar();

		const eventGroup: IChildCalendar = await this.getOnlyEventsByVendor(vendor.getVendorID(), _eventLimit);
		calendar.addEventGroup(eventGroup);

		const hotSpotGroup: IChildCalendar = await this.getUpcomingHotSpotsByVendor(vendor.getState(),vendor.getVendorID());
		calendar.addEventGroup(hotSpotGroup);

		//console.log(hotspots)
		//merge hotspot events with regular events and resort order
		//events = events.concat(hotspots).sort((a,b) => {return new Date(a.getStartTime()).getTime() - new Date(b.getStartTime()).getTime();});
		return calendar;
	}


	/*public getAllEventsByPlace(place: IPlace, eventLimit: number): Promise<IEventRead[]>{
		return this.restAPIHandler.get("/events", '&orderBy="placeId"&equalTo="' + placeID + '"')
			.then((events: {[key: string]: IEventData}) => {
				return Object.entries(events)
					.filter(([evId,event]: [string,IEventData]) => {return new Date().getTime() < event.endTime && !event.hotSpotInd && event.endTime < new Date().setDate(new Date().getDate() + 30)})
					.map(([evId,event]: [string,IEventData]) => { return this.deserialize(evId,event);})
			})
	}*/

  public async getAllHotSpotsByState(state: string): Promise<IChildCalendar>{
		const ref: firebase.database.Reference = firebase.database().ref("/hotspots/" + state);
		const snapshot: firebase.database.DataSnapshot = await ref.once('value');

    let events: IEventRead[] = [];

		if (snapshot.val()){
			events = await this.getHotSpotEvents(Object.keys(snapshot.val()).map((h) => {const hotSpot: IHotSpot = new HotSpot(h, snapshot.val()[h]);return hotSpot;}));
		}

		const eventGroup: IChildCalendar = new EventGroup("HotSpots", events);
		return eventGroup;
	}

	public async getUpcomingHotSpotsByState(state: string): Promise<IChildCalendar>{
		const ref: firebase.database.Reference = firebase.database().ref("/hotspots/" + state);
		const snapshot: firebase.database.DataSnapshot = await ref.once('value');

		let events: IEventRead[] = [];

		if (snapshot.val()){
			events = await this.getHotSpotEvents(Object.keys(snapshot.val()).map((h) => {const hotSpot: IHotSpot = new HotSpot(h, snapshot.val()[h]);return hotSpot;}));
		}

		events = events.filter((h) => h.getHotSpot().isUpcoming());

		const eventGroup: IChildCalendar = new EventGroup("HotSpots", events);
		return eventGroup;

	}


	public async getUpcomingHotSpotsByVendor(state: string, vendorID: string): Promise<IChildCalendar>{
		const ref: firebase.database.Reference = firebase.database().ref("/hotspots/" + state);
		const snapshot: firebase.database.DataSnapshot = await ref.once('value');

		let events: IEventRead[] = [];

		if (snapshot.val()){
			events = await this.getHotSpotEvents(Object.keys(snapshot.val()).map((h) => {const hotSpot: IHotSpot = new HotSpot(h, snapshot.val()[h]);return hotSpot;}));
		}

		events = events.filter((h) => h.getHotSpot().isUpcoming()).filter((h) => h.isVendorParticipating(vendorID));

		const eventGroup: IChildCalendar = new EventGroup("HotSpots", events);
		return eventGroup;





		/*return this.getUpcomingHotSpotsByState(state)
			.then((_hotSpots: IEventRead[]) => {
				//console.log(_hotSpots)
				return _hotSpots.filter(h => {
					//console.log(h.isVendorParticipating(vendorID))
					return h.isVendorParticipating(vendorID);
				});
			})*/
	}

  public async searchEventsByDay(year: number, monthIdx: number, day: number): Promise<IChildCalendar>{
		const begDate = new Date(year,monthIdx,day);
		const endDate = new Date(year,monthIdx,day);
		const today = new Date();
		let todayInd: boolean = false;

		if (begDate.getDate() === today.getDate() && begDate.getMonth() === today.getMonth() && begDate.getFullYear() === today.getFullYear()){
			begDate.setHours(today.getHours(), today.getMinutes(), today.getSeconds(), today.getMilliseconds());
			todayInd = true;
		}

		endDate.setHours(23,59,59,999);

		const events: IEventRead[] = [];
		const eventGroup: IChildCalendar = new EventGroup(todayInd ? "today" : begDate.getFullYear() + '-' + begDate.getMonth() + '-' + begDate.getDate());

		const ref: firebase.database.Reference = await firebase.database().ref("/events/");
    const query: firebase.database.Query = ref.orderByChild('endTime').startAt(begDate.getTime()).endAt(endDate.getTime());
   
		eventGroup.subscribeToQuery(query);

		return eventGroup;
	}

	public async getAllEventsByPlace(place: IPlace, eventLimit?: number): Promise<IParentCalendar>{
		const _eventLimit = !eventLimit ? 1000 : eventLimit;
		const calendar: IParentCalendar = new Calendar();

		const eventGroup: IChildCalendar = await this.getOnlyEventsByVenue(place.getPlaceID(), _eventLimit);
		calendar.addEventGroup(eventGroup);

		//console.log(hotspots)
		//merge hotspot events with regular events and resort order
		//events = events.concat(hotspots).sort((a,b) => {return new Date(a.getStartTime()).getTime() - new Date(b.getStartTime()).getTime();});
		return calendar;
	}

	private async getOnlyEventsByVendor(vendorID: string, eventLimit: number): Promise<IChildCalendar>{
		const events: IEventRead[] = [];
	
		const eventGroup: IChildCalendar = new EventGroup("Events");
		const ref: firebase.database.Reference = firebase.database().ref("/events/");
		const query: firebase.database.Query = ref.orderByChild('vendorId').equalTo(vendorID);

		/*query.once('value', (snapshot) => {
			console.log(snapshot.val());
			if (Object.keys(snapshot.val()).length === 0) return;
			Object.keys(snapshot.val()).forEach((eventID: string) => {
				console.log(snapshot.val()[eventID]);
				eventGroup.addEvent(new Event(eventID,snapshot.val()[eventID]));
			})
		});*/
		eventGroup.subscribeToQuery(query);
		
		return eventGroup;
	}

	private async getOnlyEventsByVenue(venueID: string, eventLimit: number): Promise<IChildCalendar>{
		const events: IEventRead[] = [];
		const eventGroup: IChildCalendar = new EventGroup("Events");
		const ref: firebase.database.Reference = await firebase.database().ref("/events/");
		const query: firebase.database.Query = ref.orderByChild('placeId').equalTo(venueID).limitToFirst(eventLimit);
		eventGroup.subscribeToQuery(query);
		return eventGroup;
	}

	private getHotSpotEvents(hotSpotArray: IHotSpot[]): Promise<IEventRead[]>{
		return Promise.all(hotSpotArray.map((hotSpot: IHotSpot) => this.getHotSpotEvent(hotSpot)));
	}

	private async getHotSpotEvent(hotSpot: IHotSpot): Promise<IEventRead>{
		const event: IEventRead = await this.getByIDAndSubscribe(hotSpot.getHotSpotID());
    	event.setHotSpot(hotSpot);
    	this.subscribeHotSpot(event);
		return event;
  }

	public async getHotSpotByEvent(event: IEventRead): Promise<HotSpot> {
		const ref: firebase.database.Reference = firebase.database().ref("/hotspots/" + event.getPlaceState() + "/" + event.getEventID());
		const snapshot: firebase.database.DataSnapshot = await ref.once('value');
		const hotspot = new HotSpot(event.getEventID(), snapshot.val());
		hotspot.subscribe(ref);
		return hotspot;
	}

	private subscribeHotSpot(hotspot: IEventRead): void {
		const ref: firebase.database.Reference = firebase.database().ref("/hotspots/" + hotspot.getPlaceState() + "/" + hotspot.getEventID());
		hotspot.getHotSpot().subscribe(ref);
	}

	public async getHistoricalEventsByVendor(vendorID: string): Promise<{[eventID: string]: IEventData}>{
		const snapshot = await firebase.database().ref("eventArchive").orderByChild('vendorId').equalTo(vendorID).once('value');
		return snapshot.val();
	}


}
