/***Firebase***/
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { Injectable } from '@angular/core';
import { FeatureRequest } from './feature-request.model';
import { FeatureRequestStatus } from './feature-request-data.interface';
import { FeatureRequestService } from 'src/app/app-services/remote-services/feature-requests/feature-requests.service.abstract';


@Injectable()
export class FeatureRequestRepo{

	constructor(private featureRequestService: FeatureRequestService ) {

	}

    addFeatureRequest(featureRequest: FeatureRequest){
        console.log(featureRequest)
        return this.featureRequestService.addFeatureRequest(featureRequest)
    }

    updateFeatureRequest(featureRequest: FeatureRequest){
        return this.featureRequestService.updateFeatureRequest(featureRequest)
    }

    deleteFeatureRequest(featureRequest: FeatureRequest){
        return this.featureRequestService.deleteFeatureRequest(featureRequest)
    }

    rejectFeatureRequest(featureRequest: FeatureRequest){
        return this.featureRequestService.rejectFeatureRequest(featureRequest)
    }

    approveFeatureRequest(featureRequest: FeatureRequest){
        return this.featureRequestService.approveFeatureRequest(featureRequest)
    }

    completeFeatureRequest(featureRequest: FeatureRequest){
        return this.featureRequestService.completeFeatureRequest(featureRequest)
    }

    supportFeatureRequest(featureRequest: FeatureRequest){
        return this.featureRequestService.supportFeatureRequest(featureRequest);
    }

    unsupportFeatureRequest(featureRequest: FeatureRequest){
        return this.featureRequestService.unsupportFeatureRequest(featureRequest);
    }

	async getAllFeatureRequests(): Promise<FeatureRequest[]>{
        const snapshot = await firebase.database().ref("/featureRequest").once('value');
        if (snapshot.val()){
            return Object.keys(snapshot.val()).map(id => {
                return new FeatureRequest(snapshot.val()[id],id);
            })
        }else{
            return [];
        }
    }

    async getFeatureRequestByStatus(status: FeatureRequestStatus): Promise<FeatureRequest[]>{
        const output: FeatureRequest[] = [];
        const requests = await this.getAllFeatureRequests();
        Object.keys(requests).forEach(requestID => {
            if (requests[requestID].getStatus() === status) output.push(requests[requestID]);
        })
        return output;
    }
 

}
