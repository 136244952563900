import {Injectable } from '@angular/core';
/***Firebase***/
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
/***Models***/
import { IRepository, Persistable } from "../../app-domain/index";
import { IUserData, IUser, UserFactory } from "./index";



@Injectable()
export class UserRepo implements IRepository<IUser, IUserData>{


	constructor(private userFactory: UserFactory){}


	public async getByID(id: string): Promise<IUser>{
		const ref: firebase.database.Reference = firebase.database().ref("/users/" + id);
		const snapshot: firebase.database.DataSnapshot = await ref.once('value');
		const _user: IUserData = snapshot.val();
		const _claims: {[key: string]: any} = await this.getCustomClaims(id);
		return this.userFactory.build(id, _user, _claims);

	}

	public async getByIDAndSubscribe(id: string): Promise<IUser>{
		const ref: firebase.database.Reference = firebase.database().ref("/users/" + id);
		const snapshot: firebase.database.DataSnapshot = await ref.once('value');
		const _user: IUserData = snapshot.val();
		const _claims: {[key: string]: any} = await this.getCustomClaims(id);
		const user: IUser = this.userFactory.build(id, _user, _claims);
		user.subscribe(ref);
		console.log("Subscribing User");
		return user;
	}

	//public getByIDs<IUser>(id: string[]): Promise<IUser[]>{

	//}

	public update(data: Persistable<IUserData>): Promise<any>{
		const p = new Promise((resolve,reject) => {resolve(1);});
		return p.then(() => 1);
	}

	public delete(data: Persistable<IUserData>): Promise<any>{
		const p = new Promise((resolve,reject) => {resolve(1);});
		return p.then(() => 1);
	}

	public add(data: Persistable<IUserData>): Promise<any>{
		const p = new Promise((resolve,reject) => {resolve(1);});
		return p.then(() => 1);
	}


	//private deserialize(id: string, modelData: IUserData, id2?: string): IUser{

	//}

	private getCustomClaims(userID: string): {[key: string]: any}{
		return firebase.auth().currentUser.getIdTokenResult()
			.then((result) => result.claims);
	}






}
