export class Preference {
	private _key: string;
	private _subKey: string;
	private _value: any;

	constructor(value: any, key: string, subKey?: string){
		this._value = value;
		this._key = key;
		this._subKey = subKey
	}

	public serialize() {
		if (this.subKey){
			return {[this.key]: {[this.subKey] : this.value}};
		}else{
			return {[this.key] : this.value};
		}
	}

	public get key(){
		return this._key;
	}

	public get subKey(){
		return this._subKey;
	}

	public get value(){
		return this._value;
	}

	public set value(value: any){
		this._value = value;
	}

 
}

