import { User, IUserData, UserRole } from "./index";


export class CustomerUser extends User{

	constructor(userID: string, userData: IUserData, permissions?: Map<string,boolean>){
		super(userID, userData, permissions);
		this.role = UserRole.CUSTOMER;
	}

	public getRoot(): string{
		return "/user";
	}

	public loadDependencies(): void{
		console.log("No Dependencies");
	}



}
