import {Injectable } from '@angular/core';
/***Firebase***/
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
/***Models***/
import { ISubMenu, SubMenu, IMenuTypeData} from "./index";
import { IRepository, Persistable } from "../../app-domain/index";
import { MenuService } from "../../app-services/index";


const defaultMenuTypes = [
  { name: "Main Menu", order: 1 },
  { name: "Breakfast", order: 2 },
  { name: "Lunch", order: 3 },
  { name: "Dinner", order: 4 },
  { name: "Appetizers", order: 5 },
  { name: "Dessert", order: 6 },
  { name: "Beverages", order: 7 }
]

@Injectable()
export class MenuTypeRepo implements IRepository<ISubMenu[], IMenuTypeData>{

	constructor(private menuService: MenuService){}


	public async getByID(id: string): Promise<ISubMenu[]>{
		const ref: firebase.database.Reference = firebase.database().ref("/menuTypes/" + id);
    	const snapshot: firebase.database.DataSnapshot = await ref.once("value");
    	const menuTypeData: { [key: string]: IMenuTypeData } = snapshot.val() ? snapshot.val() : await this.addDefaultMenuTypes(id);
		return Object.keys(menuTypeData).map((menuTypeID: string) => new SubMenu(menuTypeID,menuTypeData[menuTypeID])).sort((a,b) => {
			if (a.getOrder() > b.getOrder()) {return 1;}
			if (a.getOrder() < b.getOrder()) {return -1;}
			return 0;
		});
	}


	public async getByIDAndSubscribe(id: string): Promise<ISubMenu[]>{
		const ref: firebase.database.Reference = firebase.database().ref("/menuTypes/" + id);
		const snapshot: firebase.database.DataSnapshot = await ref.once('value');
    	const menuTypeData: { [key: string]: IMenuTypeData } = snapshot.val() ? snapshot.val() : await this.addDefaultMenuTypes(id);
		
		const menuTypes: ISubMenu[] = Object.keys(menuTypeData).map((menuTypeID: string) => {
			const ref2: firebase.database.Reference = firebase.database().ref("/menuTypes/" + id +'/' + menuTypeID);
			const menuType: ISubMenu = new SubMenu(menuTypeID,menuTypeData[menuTypeID]);
			menuType.subscribe(ref2);
			const query = firebase.database().ref("menus/" + id).orderByChild('menuType').equalTo(menuType.getName());
			menuType.subscribeToQuery(query);
			return menuType;
		});
		return menuTypes;
	}


	public update(data: Persistable<IMenuTypeData>, miscData: {[key: string]: any}): Promise<any>{
		if (!miscData.vendorID) {throw new Error("No VendorID Provided");}
		return this.menuService.updateMenuType(data, miscData);
	}

	public delete(data: Persistable<IMenuTypeData>, miscData: {[key: string]: any}): Promise<any>{
		if (!miscData.vendorID) {throw new Error("No VendorID Provided");}
		return this.menuService.deleteMenuType(data, miscData);
	}

	public add(data: Persistable<IMenuTypeData>, miscData: {[key: string]: any}): Promise<any>{
		if (!miscData.vendorID) {throw new Error("No VendorID Provided");}
		return this.menuService.addMenuType(data, miscData);
	}


  private async addDefaultMenuTypes(vendorID: string) {
    const _defaultMenuTypes = {};
    await Promise.all(defaultMenuTypes.map(async (type) => {
      const result = await firebase.database().ref("menuTypes/" + vendorID).push(type);
      _defaultMenuTypes[result.key] = type;
    }))
    return _defaultMenuTypes;
    
  }

}
