import { Component } from '@angular/core';
import { NavParams, PopoverController } from "@ionic/angular";

@Component({
  	selector: 'feature-popover',
  	templateUrl: 'feature-popover.component.html',
  	styleUrls: ['feature-popover.component.scss']
})
export class FeaturePopoverComponent {
	public title: string;
	public content: string;
	public buttonText: string;

  	constructor(private navParams: NavParams, private popoverCtrl: PopoverController){}

  	ngOnInit(){
		this.title = this.navParams.get("title");
		this.content = this.navParams.get("content");
		if (this.navParams.get("buttonText") != null){
			this.buttonText = this.navParams.get("buttonText")
		}else{
			this.buttonText = "Got it"
		}
		
  	}

  	dismiss(){
  		this.popoverCtrl.dismiss();
  	}

}
