export const environment = {
  fb:{
		apiKey: "AIzaSyBe8g_ODItRY0hQoNpONx__lUfoB6Vq1zA",
		databaseURL: "https://streatsmart-f4ca8.firebaseio.com/",
		storageBucket: "gs://streatsmart-f4ca8.appspot.com/",
		projectId: "streatsmart-f4ca8",
		appId: "1:772059676310:ios:52660261983acada204cb7",
		measurementId: "G-Y45DKG0941",
		messagingSenderId: "772059676310",
		authDomain: "streatsmart-f4ca8.firebaseapp.com"
	},
	production: true,
	admin: 'mrRTZGlaqKXuE6jvxK56BMZNFVA2',
	google:{
		webClientId: '772059676310-tgpoot1i3sc9dd3q4nlsunl96qumegj5.apps.googleusercontent.com',
		apiKey: 'AIzaSyBe8g_ODItRY0hQoNpONx__lUfoB6Vq1zA'
	},
	isApp: ((!document.URL.startsWith("https://streatsmart-f4ca8.web.app") && !document.URL.startsWith("https://streatsmart-f4ca8.firebaseapp.com") && !document.URL.startsWith("https://www.streatsmart.co") && !document.URL.startsWith("http://localhost:5000"))  || document.URL.startsWith("http://localhost:8100"))
};
