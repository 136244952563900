import { Component, Input } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { IClickable } from "../../../app-domain/index";

@Component({
	selector: 'app-list-item-button',
	templateUrl: 'app-list-item-button.component.html',
	styleUrls: ['app-list-item-button.component.scss']
})
export class AppListItemButton implements IClickable{
	@Input() public label: string;
	@Input() public icon: string;
	@Input() public iconColor: string;
	@Input() private navigateTo: any;

	constructor(private modalCtrl: ModalController) {}

	ngOnInit(){}


	public async click(): Promise<void>{
		if (this.navigateTo){
			const modal = await this.modalCtrl.create({
				component: this.navigateTo
			});
			return await modal.present();
		}

	}


}
