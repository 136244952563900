import { Component, Input } from '@angular/core';
import { IMenuItem } from "../../../../app-domain/menu/index";
import { MenuTagsData } from '../../../../module-vendor/vendor-pages/vendor-menu';
import { trigger, state, style, animate, transition, group } from '@angular/animations';
import { PhotoViewer, Image, capShowResult } from '@capacitor-community/photoviewer'; //Unable to get this to work using photoviewer directive
import { PhotoViewerService } from 'src/app/app-services';


@Component({
	selector: 'menu-card',
	templateUrl: 'menu-card.component.html',
  	styleUrls: ['menu-card.component.scss'],
  /*animations: [       // metadata array
    trigger('myInsertRemoveTrigger', [     // trigger block
      state('close', style({ width: 'auto', visibility: 'visible', opacity: 1 })),
      state('open', style({ width: 0, visibility: 'hidden', opacity: 0, padding: '0px', margin: '0px' })),
      transition('* => open', [
        style({ opacity: 1 }),
        group([
          animate('2s 1s ease', style({ opacity: 0 })),
          animate('1s 2s', style({ width: 0, padding: '0px', margin: '0px' }))
        ])
      ])
    ]),
    trigger('myInsertAddTrigger', [
      state('open', style({ width: 'auto', visibility: 'visible', opacity: 1 })),
      state('close', style({ width: 0, visibility: 'hidden', opacity:0, margin: '0px', padding: '0px'})),
      transition('* => open', [
        style({ opacity: 0, width: 0 }),
        group([
          animate('100ms 2s ease', style({ width: 'auto' })),
          animate('2s 3s ease', style({ opacity: 1}))
        ])
      ])
    ])
  ]  */      // end of trigger block
})


export class MenuCardComponent{
	@Input() public menuItem: IMenuItem;
	public menuTags = {};
	@Input() desc: boolean = true;

	constructor(private photoViewerService: PhotoViewerService){

  }

	ionViewDidEnter() {
	}


	ngOnInit() {
		MenuTagsData.forEach(tag => {
			this.menuTags[tag.abbrev] = tag;
		})
		console.log(this.menuTags);
	}

	async showImage(src: string){
		this.photoViewerService.show(src);	
	}


}
