import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AlertController, IonSearchbar, LoadingController } from "@ionic/angular";
import { NavParams, ModalController } from "@ionic/angular";
import { Event, IEventWrite, IEventData} from "../../../../app-domain/scheduling/index";
import { VendorService, IVendor } from "../../../../app-domain/vendor/index";
import { IPlace } from "../../../../app-domain/place/index";
import { MapComponent } from "../../../../app-components/index";
import * as moment from 'moment';

/***Services***/
import { AdminService, GeolocationService, GooglePlaceService } from "../../../../app-services/index";

/* Interfaces */
import { Mappable} from "../../../../app-interfaces/index";


@Component({
	templateUrl: 'hotspot-builder.page.html',
	styleUrls: ['hotspot-builder.page.scss']
})
export class HotSpotBuilderPage {
	public event: IEventWrite;
	public vendor: IVendor[];
	public eventForm: FormGroup;
	public mode: "Add" | "Update" = "Add";
	public formInitialized: boolean = false;
	@ViewChild(IonSearchbar ) searchElementRef: IonSearchbar;
	@ViewChild(MapComponent) map: MapComponent;
	public waitForMapRendering: boolean = false;
	private selectedDate: Date = new Date();
	//public minDate = moment(new Date().toISOString()).format("YYYY-MM-DD");


  constructor(private vendorService: VendorService, private navParams: NavParams, private modalCtrl: ModalController,
    private adminService: AdminService, private googlePlaceService: GooglePlaceService, private geolocation: GeolocationService,
    private loadCtrl: LoadingController, private alertCtrl: AlertController
				) {
		if (this.navParams){
			if (this.navParams.get('mode')) {this.mode = this.navParams.get('mode');}

			if (this.navParams.get('event')){
				this.event = this.navParams.get('event');
			}else{
				this.event = new Event();
			}
		}
  }


	ngOnInit(){
		window.setTimeout(() => this.initAutoComplete(),1000);
		this.initializeForm();
	}

	private initializeForm() {
		let placeName = null; let address = null; let name = null; let url = null;
		let start = moment(new Date(this.selectedDate).toISOString()).format();
		let end = moment(new Date(this.selectedDate).toISOString()).format();

    console.log(this.event);
    if (this.navParams.get('event')) {
      console.log("Updating");
			placeName = this.event.getEventPlaceName();
      address = this.event.getEventPlaceAddr();
      name = this.event.getHotSpot().getName();
      url = this.event.getHotSpot().getWebsite();
			start =  moment(new Date(this.event.getStartTime()).toISOString()).format();
      end = moment(new Date(this.event.getEndTime()).toISOString()).format();
      console.log(name);

		}

		this.eventForm = new FormGroup({
		  placeName: new FormControl(placeName, Validators.required),
		  address: new FormControl(address, Validators.required),
      name: new FormControl(name, Validators.required),
      url: new FormControl(url),
		  start: new FormControl(start, Validators.required),
		  end: new FormControl(end, Validators.required)
		});
		this.formInitialized = true;

	}

	onDismiss(stateChange?: boolean): void{
		//this.map.destroyMap();
		this.modalCtrl.dismiss(stateChange);
	}

  public async onSubmit(form: FormGroup) {
    console.log(this.event);

		this.updateEventDetails(form);
		let remoteResponse;

    const loader = await this.loadCtrl.create({
      message: this.mode === "Update" ? "Updating hotspot event" : "Adding hotspot event"
    })
    await loader.present();
    try {
      if (this.mode === "Update") {
        remoteResponse = await this.adminService.updateHotSpot(this.event.getEventID(), {
          event: this.event.getEventData(),
          hotspot: this.event.getHotSpot().getHotSpotData()
        })
      } else {
        remoteResponse = await this.adminService.addHotSpot({
          event: this.event.getEventData(),
          hotspot: this.event.getHotSpot().getHotSpotData()
        })
      }
      loader.dismiss();
      console.log(remoteResponse);
      this.onDismiss(true);

    } catch (error) {
      loader.dismiss();
      const alert = await this.alertCtrl.create({
        header: "Unable to add/update event",
        message: error.message,
        buttons: ["Ok"]
      })
      await alert.present();
    }
	}

	public onNewPlace(place: IPlace){
		this.updateEventsPlace(place);
	}

	private async initAutoComplete(){
		//Allow autocomplete search functionality to search bar
		const inputElement = await this.searchElementRef.getInputElement();

    this.googlePlaceService.createGoogleAutocompleteListener(inputElement, async (place: IPlace) => {
	    this.updateEventsPlace(place);
		//this.map.updateMarker(place as unknown as Mappable);
	});
		this.waitForMapRendering = true;
	}

  private async updateEventsPlace(place: IPlace){
   		const state = await this.geolocation.getStateFromLoc(place.getLocation());
    	place.setState(state);
		this.event.setNewPlace(place);
		this.map.marker = this.event;
		this.map.center = this.event;
		this.updatePlaceDetails();
	}


	private updatePlaceDetails(){
		this.eventForm.patchValue({
			placeName: this.event.getEventPlaceName(),
			address: this.event.getEventPlaceAddr(),
		});
	}

	public onStartChange(value: CustomEvent): void{
		this.eventForm.patchValue({
			end: value.detail.value
		});
	}

  private updateEventDetails(form: FormGroup) {
    console.log(form);
    this.event.setHotSpotData({
      name: form.value.name,
      endTime: form.value.end,
      website: form.value.url,
      vendors: this.event.getParticipatingVendorData()
    }) 
    this.event.setStartTime(new Date(form.value.start).getTime());
    this.event.setEndTime(new Date(form.value.end).getTime());
	}
}
