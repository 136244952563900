import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { IVendor } from '../../../../app-domain/vendor';


@Component({
	selector: 'vendor-accordion',
	templateUrl: './vendor-accordion.component.html'
})
export class VendorAccordionComponent {
  @Input() detail: "status" | "rating";
  private _vendors: IVendor[] = [];
  @Input() set vendors(vendors: IVendor[]) {
    this._vendors = vendors;
    this.init();
  }

  get vendors(): IVendor[] {
    return this._vendors;
  }

  public states: string[] = [];
  @Input() component: any;
  @Output() vendorUpdated = new EventEmitter<boolean>();
  constructor(private router: Router, private modalCtrl: ModalController) { }

  public init() {
    this.states = this.determineStates();
    this.vendors.sort((a, b) => {
      if (a.getName() > b.getName()) return 1;
      if (a.getName() < b.getName()) return -1;
      return 0;
    })
  }

  private determineStates(): string[] {
    const states: Set<string> = new Set();
    this.vendors.forEach((v) => {if (v.getState()) states.add(v.getState()); })
    return Array.from(states).sort();
  }

  public async onVendorClick(vendor: IVendor) {
    const modal = await this.modalCtrl.create({
      component: this.component,
      componentProps: { vendor: vendor }
    })
    await modal.present();
    modal.onDidDismiss().then((data: any) => {
      if (data.data) this.vendorUpdated.emit(true);
    })
  }

}
