/***Interfaces */
import { RemoteResponse } from "../../../app-interfaces";



export abstract class LeaderboardService{
    constructor(){

    }

  public abstract getUserEventLeaderboard(): Promise<RemoteResponse>
}
