import { IVendor } from "./index";

export enum VendorSortEnum{
	NAME = 1,
	LOC,
	STREATCRED
}

export class VendorList {
	private vendors: IVendor[] = [];
	private sortStrategy: VendorSort = new StreatCredSort();

	constructor(vendors: IVendor[]){
		this.vendors = vendors;
	}

	public getVendorsByIDs(ids: string[]): IVendor[]{
		return this.vendors.filter((vendor: IVendor) => ids.includes(vendor.getVendorID())).sort(this.sortStrategy.sort);
	}

	public getVendorsByPartialMatchName(searchName: string): IVendor[]{
		return this.vendors.filter((vendor: IVendor) => vendor.getName().toLowerCase().indexOf(searchName.toLowerCase()) >= 0).sort(this.sortStrategy.sort);
	}

	public getAllVendors(): IVendor[]{
		return this.vendors.sort(this.sortStrategy.sort);
  }

  public getPendingReviewed(): IVendor[]{
    return this.vendors.filter((v) => {
      return v.getVendorID() === null;
    }).sort(this.sortStrategy.sort)
  }

  public getByOperatingStatus(status: 0 | 1 | 2 | 3 | 4 | 5): IVendor[] {
    return this.vendors.filter((v) => {
      return v.getStatusFlag() === status;
    }).sort(this.sortStrategy.sort)
  }

  public add(vendors: IVendor[]) {
    this.vendors.push(...vendors);
  }
	
	/***Iterator***/
	public [Symbol.iterator]() {
		let cnt = 0;
		const that = this;
		return {
			next(){
				cnt++;
				return {
					done: cnt-1 === that.vendors.length,
					value: that.vendors[cnt-1]
				};
			}
		};
	}

	public setSortStrategy(sortStrategy: VendorSortEnum){
		switch (sortStrategy){
			case VendorSortEnum.LOC: this.sortStrategy = new LocationSort(); break;
			case VendorSortEnum.STREATCRED: this.sortStrategy = new StreatCredSort(); break;
			case VendorSortEnum.NAME: this.sortStrategy = new NameSort(); break;
		}
	}

	public sort(sortStrategy: VendorSortEnum){
		this.setSortStrategy(sortStrategy);
		this.vendors.sort(this.sortStrategy.sort);
	}




}

abstract class VendorSort{
	constructor(){}
	public abstract sort(a: IVendor, b: IVendor): number;
}


class LocationSort extends VendorSort{
	constructor(){
		super();
	}

	public sort(a: IVendor, b: IVendor): number{
		if (a.getState().toLowerCase() < b.getState().toLowerCase()) {return 1;}
		if (a.getState().toLowerCase() > b.getState().toLowerCase()) {return -1;}
		return 0;
	}
}

class StreatCredSort extends VendorSort{
	constructor(){
		super();
	}

	public sort(a: IVendor, b: IVendor): number{
		const streatCredA = a.getStreatCred() ? a.getStreatCred() : 0;
		const streatCredB = b.getStreatCred() ? b.getStreatCred() : 0;
		if (streatCredA < streatCredB){
			return 1;
		}
		if (streatCredA > streatCredB){
			return -1;
		}
		return 0;
	}
}

class NameSort extends VendorSort{
	constructor(){
		super();
	}

	public sort(a: IVendor, b: IVendor): number{
		if (a.getName().toLowerCase() > b.getName().toLowerCase()) {return 1;}
		if (a.getName().toLowerCase() < b.getName().toLowerCase()) {return -1;}
		return 0;
	}
}
