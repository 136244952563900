import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import {Injectable } from '@angular/core';

import { IUser, IUserData  } from "./index";
import { Image, Model, Persistable } from "../../app-domain/index";
import { Observable, Observer } from "../../app-interfaces/index";
import { AppModule } from 'src/app/app.module';
import { SubscriptionManagerService } from 'src/app/app-services/subscription-manager.service';

export enum UserRole {
	CUSTOMER = "customer",
	VENDOR = "vendor",
	VENUE = "venue",
	ADMIN = "admin",
	GUEST = "guest",
	PLANNER = "event planner",
	PARTNER = "partner"
}


export interface IUserService extends Persistable<IUserData>, IUser, Observable<IUser>{
	deconstructor(): void;
	isLoaded(): boolean;
  	ready(): void;
  	isExpired(): boolean;
}



//Proxy for User Model. Had a fucking issue with using factory to instantiate a concrete service class after Firebase auth state triggered.
@Injectable()
export class UserService implements IUserService{
	private user: IUser;
	private loaded: boolean = false;
	private observers: Observer<IUser>[] = [];
	private reference: firebase.database.Reference;
  	private lastActive: Date = new Date();

	constructor(){

	}

	public isLoaded(): boolean{
		return this.loaded;
	}

  	public ready(): void{
    	this.setlastActive(new Date());
		this.loaded = true;
	}

	public setUser(user: IUser){
		this.user = user;
	}

	public addUser(firstName: string, lastName: string, email: string, userID: string): void{
		const payload = Object.assign({}, {firstName, lastName, email, createdTime: new Date().getTime()});
		//return this.restAPIHandler.put('/users/' + userID,payload);
	}

	public getID(): string{
		if (this.user){
			return this.user.getID();
		}else{
			return firebase.auth().currentUser.uid;
		}
		
	}

	public getData(): IUserData{
		return this.user.getData();
	}

	public getFirstName(): string{
		return this.user.getFirstName();
	}

	public setFirstName(firstName: string): void{
		this.user.setFirstName(firstName);
	}

	public getLastName(): string{
		return this.user.getLastName();
	}

	public getCreatedTime(): number{
		return this.user.getCreatedTime();
	}

	public setLastName(lastName: string): void{
		this.user.setLastName(lastName);
	}

	public getEmail(): string{
		return this.user.getEmail();
	}

	public setEmail(email: string): void{
		this.user.setEmail(email);
	}

	public getFullName(): string{
		return this.user.getFullName();
	}

	public getFavorites(): {[key: string]: boolean}{
		return this.user.getFavorites();
  }

  public getFavoriteList(): string[] {
    return this.user.getFavoriteList();
  }

	public setFavorites(favorites: {[key: string]: boolean}): void{
		this.user.setFavorites(favorites);
	}

	public getPermissions():  Map<string,boolean>{
		return this.user.getPermissions();
	}

	public getPermissionList(): string[]{
		return this.user.getPermissionList();
	}

	public hasPermissionsForVendor(vendorID: string): boolean{
		return this.user.hasPermissionsForVendor(vendorID);
	}

	public setPermissions(permissions:  Map<string,boolean>): void{
		this.user.setPermissions(permissions);
	}

	public isVendorFavorited(vendorID: string): boolean{
		return this.user.isVendorFavorited(vendorID);
  }

  public getLastActive(): Date {
    return this.lastActive
  }

  public setlastActive(date: Date) {
    this.lastActive = date;
  }

  public isExpired(): boolean {
	//After 5 minutes of inactivity we will want to refresh app to replace stale data (app killing memory in background)
    if ((((new Date().getTime() - this.getLastActive().getTime()) / 1000) / 60) > 5) { 
      return true;
    } else {
      return false;
    }
  }

	public getRoot(): string{
		if (!this.isLoaded()){
			return "/user";
		}else{
			return this.user.getRoot();
		}
	}

	public subscribe(db: firebase.database.Reference): void{
		this.unsubscribe();
		AppModule.injector.get(SubscriptionManagerService).addSubcription(this);
		this.reference = db;
		db.on("value",(snapshot: firebase.database.DataSnapshot) => {
			this.setUser(snapshot.val());
		});
	}

	public unsubscribe(): void {
		if (this.reference) this.reference.off();
	}

	public getRole(): UserRole{
		return this.user?.getRole();
	}

	public getStreatCred(): number {
		return this.user.getStreatCred();
	}

	/*public setProfileImage(image: Image): void {
		this.user.setProfileImage(image);
	}*/

	public getProfileImage(): Image {
		return this.user.getProfileImage();
	}

	public getProfileURL(): string {
		return this.user.getProfileURL();
	}



	public deconstructor(): void{ //utilize this in logout auth service function
		this.user = null;

		this.loaded = false;
	}


	public load(): void{

	}

	subscribeToChanges(obs: Observer<IUser>): void{
		const user = this.user as unknown as Observable<IUser>;
		user.subscribeToChanges(obs);
	}

	removeSubscription(obs: Observer<IUser>): void{
		const user = this.user as unknown as Observable<IUser>;
		user.removeSubscription(obs);
	}

	notifySubscribers(): void{
		const user = this.user as unknown as Observable<IUser>;
		user.notifySubscribers();
	}



}
