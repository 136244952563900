import { Component } from '@angular/core';

@Component({
	selector: 'vendor-card-skeleton',
	templateUrl: 'vendor-card-skeleton.component.html',
	styleUrls: ['../vendor-card/vendor-card.component.scss'],
})
export class VendorCardSkeletonComponent{
	

	constructor(){

	}

	

}
