import {EventEmitter } from '@angular/core';
import { IEventAdapter, Ionic2CalEvent, IEventRead, IParentCalendar} from "../../../../app-domain/scheduling/index";

export interface CalendarConfig{
	onViewTitleChange(event: string);
	onDaySelection(events: Ionic2CalEvent[]);
	mode: CalendarDisplayMode;
	eventAdapter: IEventAdapter<Ionic2CalEvent>;
	title: string;
	//events: Ionic2CalEvent[],
	template: any; //template that will render event details,
	currentDate: Date;
	selectedEvent: EventEmitter<IEventRead[]>;
	selectedMonth: Date;
	calendar: IParentCalendar;
}

export enum CalendarDisplayMode {
	MONTH = "month",
	WEEK = "week"
}
