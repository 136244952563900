import {Injectable } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { AlertController, ToastController } from '@ionic/angular';


@Injectable()
export class CapacitorNetworkService{
	private networkAlert: HTMLIonAlertElement;
	constructor(
		private toastCtrl: ToastController, private alertCtrl: AlertController
	){

	}

   	public async addNetworkListener(): Promise<void> {
       	Network.addListener("networkStatusChange", (status: ConnectionStatus) => {
			if (status.connectionType === 'none'){
				this.displayNoNetwork();
			}else{
				this.networkAlert.dismiss();
				this.displayNetworkToast();
			}
	   	})
   	}

   	private async displayNoNetwork(){
		this.networkAlert = await this.alertCtrl.create({
			header: "No internet available",
			message: "Please connect to the internet or try again later when service is available",
			backdropDismiss: false
		})
		this.networkAlert.present();
   	}

	private async displayNetworkToast(){
		const toast = await this.toastCtrl.create({
			message: "Network restored",
			duration: 2000
		})
		toast.present();
	}

	public mockNoNetwork(){
		this.displayNoNetwork();
	}

	public mockNetworkRestore(){
		this.networkAlert.dismiss();
		this.displayNetworkToast();
	}


   
}
 