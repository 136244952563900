import { DataURL } from "src/app/app-interfaces";

export interface Image {
	imageSrc: string;
	imageUrl?: string;
}

export class NewImage implements Image{
	public imageSrc: string;
	public imageUrl: string;
	public dataUrl: DataURL;
	constructor(imageSrc: string, dataUrl: DataURL){
		this.imageSrc = imageSrc;
		this.dataUrl = dataUrl;
		this.imageUrl = this.createImageURL(dataUrl);
	}

	private createImageURL(dataUrl: DataURL){
		return "data:" + dataUrl.contentType + ";" + dataUrl.contentEncode + ',' + dataUrl.contentString;
	}

}


export enum ImageType {
	PROFILE = 1,
	BACKGROUND = 2,
	CONTEXTUAL = 3
}
