import { Component, OnInit} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';


@Component({
  templateUrl: 'send-notifications.page.html',
})
export class SendNotificationsPage implements OnInit{
 
	constructor(
    	private modalCtrl: ModalController
	){}

	ngOnInit(){
		
	}
	
 

	onDismiss() {
		this.modalCtrl.dismiss();
	}

	onSubmit(form: NgForm){
		const sendTestNotification = firebase.functions().httpsCallable("debug_sendTestNotification");
		return sendTestNotification(form.value);
	}
	
}
