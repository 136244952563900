import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StarRatingComponent, AppListItemButton, SettingListItemButton, CuisineAccordionComponent, CalendarComponent,CardNavigationArrowsComponent,
  EventCardComponent, MapComponent, VendorReviewItemComponent,MenuCardComponent, VendorReviewItemsSortComponent, CalendarSearchComponent, SortPopoverComponent
  , VendorCardComponent, EventBuilderComponent, VendorSelectComponent, VendorCardSimpleComponent, EventDetailsComponent,CustomerEventButton, NavigationButton
  , VenueCardComponent, ImageSlidesComponent, VenueCalendarComponent, VenueEventCardComponent, VenueCalendarModalComponent, 
  VendorHotspotCardComponent, VendorMenuAccordionComponent, SearchlistModalComponent, PhoneFormItemComponent, 
  VendorAccordionComponent, VendorCard2Component, VendorReviewResponseComponent, SwiperComponent, MenuSwiperComponent, 
  MenuTagComponent, VendorSwiperComponent, NGXChartComponent, FavoritesItemListComponent, FeaturePopoverComponent, 
  CuisinePopoverComponent,VendorCardSkeletonComponent

} from "./index";
import { AppDirectiveModule } from '../../app-directives/app-directive.module';
/***Third Party Modules****/
import { NgCalendarModule } from 'ionic2-calendar';
import { IonicRatingComponentModule} from 'ionic-rating-component';
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { GoogleMapsModule } from '@angular/google-maps';
import { AppPipeModule } from 'src/app/app-pipes/app-pipe.module';




@NgModule({
  imports: [IonicModule, CommonModule, NgCalendarModule, IonicRatingComponentModule, FormsModule, ReactiveFormsModule, CommonModule, AppDirectiveModule, NgxChartsModule,GoogleMapsModule, AppPipeModule],
  declarations: [StarRatingComponent, AppListItemButton, SettingListItemButton, CuisineAccordionComponent,CalendarComponent, CardNavigationArrowsComponent, EventCardComponent
  ,MapComponent, VendorReviewItemComponent, MenuCardComponent, VendorReviewItemsSortComponent,CalendarSearchComponent,SortPopoverComponent ,VendorCardComponent,EventBuilderComponent,
  VendorSelectComponent, VendorCardSimpleComponent, EventDetailsComponent,CustomerEventButton,NavigationButton, VenueCardComponent,ImageSlidesComponent,VenueCalendarModalComponent,
    VenueCalendarComponent, VenueEventCardComponent, VendorHotspotCardComponent, VendorMenuAccordionComponent, SearchlistModalComponent, PhoneFormItemComponent, VendorAccordionComponent,
    VendorCard2Component, VendorReviewResponseComponent, SwiperComponent, MenuSwiperComponent, MenuTagComponent, VendorSwiperComponent, NGXChartComponent, FavoritesItemListComponent,
    FeaturePopoverComponent, CuisinePopoverComponent,VendorCardSkeletonComponent
  ],
  exports: [StarRatingComponent, AppListItemButton, SettingListItemButton, CuisineAccordionComponent, CalendarComponent, CardNavigationArrowsComponent, EventCardComponent
    ,MapComponent, VendorReviewItemComponent, MenuCardComponent, VendorReviewItemsSortComponent, CalendarSearchComponent,SortPopoverComponent, VendorCardComponent, EventBuilderComponent,
    VendorSelectComponent, VendorCardSimpleComponent, EventDetailsComponent, CustomerEventButton, NavigationButton, VenueCardComponent, ImageSlidesComponent, VenueCalendarModalComponent,
    VenueCalendarComponent, VenueEventCardComponent, VendorHotspotCardComponent, VendorMenuAccordionComponent, SearchlistModalComponent, PhoneFormItemComponent, VendorAccordionComponent,
    VendorCard2Component, VendorReviewResponseComponent, SwiperComponent, MenuSwiperComponent, MenuTagComponent, VendorSwiperComponent, NGXChartComponent, FavoritesItemListComponent,
    FeaturePopoverComponent, CuisinePopoverComponent, VendorCardSkeletonComponent

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedComponentsModule {}
