import {Injectable} from '@angular/core';
import { UserService, IUser, UserRole } from "src/app/app-domain/user/index";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { AlertController } from '@ionic/angular';

interface LandingNotification {
	header: string,
	subHeader: string,
	message: string
}

@Injectable()
export class LandingNotificationService {
    private _messages: LandingNotification[] = [];

	constructor(
        private userService: UserService, private alertCtrl: AlertController
	){

    }

    async checkIfUserHasLandingNotifications(){
		this._messages = [];
		//await firebase.database().ref("notifications/vendor/landingHook/message").set("This is a test");
		const userMessageSnapshot = await firebase.database().ref("/notifications/landing/user/notification").once('value');
		if (userMessageSnapshot.val()){
			const userWasAlertedSnapshot = await firebase.database().ref("notifications/landing/user/recipient/" + this.userService.getID()).once('value');
			if (!userWasAlertedSnapshot.val()){
				this._messages.push(userMessageSnapshot.val());
				this.updateUsersNotificationStatus(UserRole.CUSTOMER, this.userService.getID());
			}
		}

		if (this.userService.getRole() === UserRole.VENDOR || this.userService.getRole() === UserRole.ADMIN){
			const userMessageSnapshot = await firebase.database().ref("/notifications/landing/vendor/notification").once('value');
			if (userMessageSnapshot.val()){
				const userWasAlertedSnapshot = await firebase.database().ref("notifications/landing/vendor/recipient/" + this.userService.getID()).once('value');
				if (!userWasAlertedSnapshot.val()){
					this._messages.push(userMessageSnapshot.val());
					this.updateUsersNotificationStatus(UserRole.VENDOR, this.userService.getID());
				}
			}
		}
		console.log(this._messages);
		this.alertUserWithLandingNotifications()
    }

	private alertUserWithLandingNotifications(){
		this._messages.forEach(async({header,subHeader,message}) => {
			const alert = await this.alertCtrl.create({
				header: header,
				subHeader: subHeader,
				message: message,
				buttons: [{
					text: "OK",
					handler: () => {

					}
				}],
				
			})
			alert.present();
		})

	}

	private updateUsersNotificationStatus(role: UserRole, userID: string){
		firebase.database().ref("notifications/landing/" + (role === UserRole.VENDOR ? "vendor" : "user") + "/recipient/" + userID).set(true);
	}

  
}
