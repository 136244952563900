import { IPlace, IPlaceData, IGoogleMapPlace, IPlaceBuilder } from "./index";
import { Location } from "../location/index";



export interface IPlaceDirector {
	createGoogleResultPlace(placeOptions: google.maps.places.PlaceResult): IPlace;
	createGoogleMapPlace(placeOptions: IGoogleMapPlace): IPlace;
	setBuilder(builder: IPlaceBuilder): void;
}

export class PlaceDirector {
	private builder: IPlaceBuilder;
	constructor(){}

	public setBuilder(builder: IPlaceBuilder){
		this.builder = builder;
	}

	/**
	 * Creates StreatSmart compatible location based on Google Place Results
	 */
	public createGoogleResultPlace(placeOptions: google.maps.places.PlaceResult): IPlace{
		this.builder.setAddress(placeOptions.formatted_address);
		this.builder.setPhone(placeOptions.formatted_phone_number);
		this.builder.setName(placeOptions.name);
		this.builder.setTypes(placeOptions.types);
		this.builder.setWebsite(placeOptions.website);
		this.builder.setLocation(new Location(placeOptions.geometry.location.lat(),placeOptions.geometry.location.lng()));
		this.builder.setRating(placeOptions.rating ? parseFloat(placeOptions.rating.toFixed(2)) : null);
		this.builder.setHours(placeOptions.opening_hours && placeOptions.opening_hours.weekday_text ? placeOptions.opening_hours.weekday_text: null);
		this.builder.setPhotos(placeOptions.photos ? placeOptions.photos : null);
		return this.builder.getPlace();
	}

	/**
	 * Creates StreatSmart compatible location based on Google Map click
	 */
	public createGoogleMapPlace(placeOptions: IGoogleMapPlace): IPlace{
		this.builder.setLocation(placeOptions.loc);
		this.builder.setState(placeOptions.state);
		this.builder.setName(placeOptions.name);
		this.builder.setTypes(placeOptions.types);
		this.builder.setAddress(placeOptions.address);
		return this.builder.getPlace();
	}

}


