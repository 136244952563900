import Geohash from 'latlon-geohash';
import {ILocation} from "./location.interface";
import { Mappable } from "../../app-interfaces/index";

export class Location implements ILocation, Mappable{
	constructor(public lat: number, public lng: number){}

	public getLat(): number{
		return this.lat;
	}

	public getLng(): number{
		return this.lng;
	}

	public getGeoHash(): string{
		return Geohash.encode(this.getLat(), this.getLng(), 4);
	}

	public getProximityGeoHashes(geoHashCenter: string, neighborsNeighborsInd: boolean): string[]{
		const neighborsList: string[] = this.getGeoHashNeighbors(geoHashCenter);
		let neighborsNeighborsList: string[][] = [];
		if (neighborsNeighborsInd){
			 neighborsNeighborsList = neighborsList.map((geoHash: string) => this.getGeoHashNeighbors(geoHash));
		}
		return Array.from(new Set(neighborsList.concat(neighborsNeighborsList.flat(1))));
	}

	private getGeoHashNeighbors(geoHashCenter): string[]{
		return Object.keys(Geohash.neighbours(geoHashCenter)).map((dir: string) => Geohash.neighbours(geoHashCenter)[dir]);
	}

	public getIconURL(): string{
		return "";
	}

	public calcDistanceFrom(loc: ILocation): string{
		const R = 6371; // Radius of the earth in km
		const dLat = this.deg2rad(loc.getLat()-this.getLat());  // deg2rad below
		const dLon = this.deg2rad(loc.getLng()-this.getLng());
		const a =
			Math.sin(dLat/2) * Math.sin(dLat/2) +
			Math.cos(this.deg2rad(this.getLat())) * Math.cos(this.deg2rad(loc.getLat())) *
			Math.sin(dLon/2) * Math.sin(dLon/2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
		const d = 0.621371*(R * c); // Distance in miles
		return d.toFixed(2);
	}

	private deg2rad(deg: number) {
		return deg * (Math.PI/180);
    }

}
