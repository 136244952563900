export interface RemoteResponse{
	data: RemoteResponseData
}

export interface RemoteResponseData{
	msg?: string;
  	statusCode?: RemoteResponseStatus,
  	data?: any
}


export enum RemoteResponseStatus{
	PENDING = 0,
	SUCCESS = 1,
	FAILURE = 2
}
