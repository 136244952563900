import {Injectable } from '@angular/core';
/***Firebase***/
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
/***Models***/
import { IMenu, Menu, IMenuItemData, MenuTypeRepo, ISubMenu, IMenuItem, MenuItem, SubMenu } from "./index";
import { IRepository, Persistable } from "../../app-domain/index";


@Injectable()
export class MenuRepo implements IRepository<IMenu, IMenuItemData>{

	constructor(private menuTypeRepo: MenuTypeRepo){}


	public async getByID(id: string): Promise<IMenu>{
		const ref: firebase.database.Reference = firebase.database().ref("/menus/" + id);
		const snapshot: firebase.database.DataSnapshot = await ref.once("value");
		const menuData: {[key: string]: IMenuItemData} = snapshot.val();

		const menu: IMenu = new Menu(id);

		const subMenus: ISubMenu[] = await this.getSubMenus(id);
		menu.addSubMenus(subMenus);
		if (menuData) {
			const menuItems: IMenuItem[] = Object.keys(menuData).map((menuItemID: string) => new MenuItem(menuData[menuItemID], menuItemID));
			menu.addMenuItems(menuItems);
		}
		return menu;
	}


	public async getByIDAndSubscribe(id: string): Promise<IMenu>{
		const ref: firebase.database.Reference = firebase.database().ref("/menus/" + id);
		const snapshot: firebase.database.DataSnapshot = await ref.once('value');
		const menuData: {[key: string]: IMenuItemData} = snapshot.val();

		const menu: IMenu = new Menu(id);		
		menu.subscribe(firebase.database().ref("menuTypes/" + id));
		
		const dummySubMenu = new SubMenu("-1",{name: "Missing Sub Menu",order: 99, description: "Menu items with no specified sub menu"});
		menu.addSubMenus([dummySubMenu]);
		dummySubMenu.subscribeToQuery(firebase.database().ref("menus/" + id).orderByChild('menuType').equalTo("Missing Sub Menu"));
		
		
		if (menuData) {
			const menuItems: IMenuItem[] = Object.keys(menuData).map((menuItemID: string) => {
				const ref: firebase.database.Reference = firebase.database().ref("/menus/" + id + '/' + menuItemID);
				const menuItem: IMenuItem = new MenuItem(menuData[menuItemID], menuItemID);
				menuItem.subscribe(ref);
				return menuItem;
			});
			//console.log("ADDING")
			menu.addMenuItems(menuItems);
		}
		//menu.reconciliateMenu();
		//console.log(menu);
		return menu;
	}


	public update(data: Persistable<IMenuItemData>): Promise<any>{
		throw new Error("Not implemented");
	}

	public delete(data: Persistable<IMenuItemData>): Promise<any>{
		throw new Error("Not implemented");
	}

	public add(data: Persistable<IMenuItemData>): Promise<any>{
		throw new Error("Not implemented");
	}


	private getSubMenus(vendorID: string): Promise<ISubMenu[]>{
		return this.menuTypeRepo.getByIDAndSubscribe(vendorID);

	}



}
