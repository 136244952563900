import { Directive, ElementRef, HostListener } from '@angular/core';
//import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { ViewManagerService } from '../app-services';
import { PhotoViewerService } from '../app-services';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
	selector: '[photoViewer]',
})
export class PhotoViewerDirective{
  @HostListener('click', ['$event']) onClick($event) {
    //if (this.viewMgr.isNative()){
      //this.photoViewer.show(this.el.nativeElement.src);
      this.photoViewer.show(this.el.nativeElement.src, this.el.nativeElement.dataset.photoviewertitle);
    //}
  }

  constructor(private photoViewer: PhotoViewerService, private el: ElementRef, private viewMgr: ViewManagerService, private domSanitizer: DomSanitizer) {}
}
