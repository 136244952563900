import {Injectable } from '@angular/core';
/***Firebase***/
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';


import { IRepository, Persistable } from "../../app-domain/index";
import { IReviewData, IReview, Review, IReviewList, VendorReviewList } from "./index";



@Injectable()
export class ReviewListRepo implements IRepository<IReviewList, IReview[]>{

	constructor(){}

	//Get all reviews for a single vendor
	public async getByID(id: string): Promise<IReviewList>{
		const ref: firebase.database.Reference = firebase.database().ref("/reviews/" + id);
		const snapshot: firebase.database.DataSnapshot = await ref.once('value');
		const _reviewData: {[key: string]: IReviewData} = snapshot.val();

		let reviewList: IReview[] = [];

		if (_reviewData){
			reviewList = Object.keys(_reviewData).map((userID: string) => new Review(id,userID, _reviewData[userID]));
		}

		return new VendorReviewList(id, reviewList);

	}

	//Get all reviews for a single vendor and subscribe to changes
	public async getByIDAndSubscribe(id: string): Promise<IReviewList>{
		const ref: firebase.database.Reference = firebase.database().ref("/reviews/" + id);
		const snapshot: firebase.database.DataSnapshot = await ref.once('value');
		const _reviewData: {[key: string]: IReviewData} = snapshot.val();


		let reviewList: IReview[] = [];

		if (_reviewData){
			reviewList = Object.keys(_reviewData).map((userID: string) => new Review(id,userID, _reviewData[userID]));
		}


		const vendorReviewList: IReviewList = new VendorReviewList(id, reviewList);
		vendorReviewList.subscribe(ref);
		return vendorReviewList;
	}

	public getByIDs(ids: string[]): Promise<IReviewList[]>{
		if (ids.length > 0){
			return Promise.all(ids.map(async (id: string) => {
				const reviewList = await this.getByID(id);
				return reviewList;
			}));
		}
	}


	public getByIDsAndSubscribe(ids: string[]): Promise<IReviewList[]>{
		if (ids.length > 0){
			return Promise.all(ids.map(async (id: string) => {
				const reviewList = await this.getByIDAndSubscribe(id);
				return reviewList;
			}));
		}
	}

	public update(data: Persistable<IReview[]>): Promise<any>{
		throw new Error("Not implemented");
	}

	public delete(data: Persistable<IReview[]>): Promise<any>{
		throw new Error("Not implemented");
	}

	public add(data: Persistable<IReview[]>): Promise<any>{
		throw new Error("Not implemented");
	}








}
