/// <reference types="google.maps"/>
import {Injectable, NgZone } from '@angular/core';

/***Models***/
import { ILocation, Location } from "../app-domain/location/index";
import { IGoogleMapPlace, IPlace, Place, IPlaceData } from "../app-domain/place/index";
import { Image } from '../app-domain';


@Injectable()
export class GooglePlaceService{



	constructor(private ngZone: NgZone){}




	public getPlaceFromGoogleMap(location: ILocation, geocoder: google.maps.Geocoder): Promise<IPlace>{
		return this.geocodeLocation(location,geocoder);
	}



	private async geocodeLocation(location: ILocation, geocoder: google.maps.Geocoder): Promise<IPlace>{
		const geocodeResults = await geocoder.geocode({location: {lat: location.getLat(), lng: location.getLng()}});
		if (geocodeResults && geocodeResults.results.length > 0){
			const results = geocodeResults.results;
			let place: IPlace;
			let placeData: IGoogleMapPlace;
			//console.log(results)
			for (const i in results){
				for (const j in results[i].types){
					if (results[i].types[j] === "locality" || results[i].types[j] === 'postal_code'){
						placeData = {address: results[i].formatted_address,name: results[i].formatted_address,types: results[i].types,loc: location};
						console.log(placeData);
						place = new Place(results[i].place_id,<IPlaceData>placeData);
					}
				}
			}
			//console.log(place);
			return(place);
		}else{
			console.log('Geocoder failed');
		}
	}


	public extractGooglePlaceResult(result: google.maps.places.Autocomplete){
		//if (!result.place_id) throw new Error("Invalid Google Place ID");

	}


	public createGoogleAutocompleteListener(inputElement: HTMLInputElement, callback: any){
		const autocomplete = new google.maps.places.Autocomplete(inputElement, {componentRestrictions: { country: 'US' }});
		autocomplete.addListener("place_changed", () => {
			this.ngZone.run(() => {
				const place: google.maps.places.PlaceResult = autocomplete.getPlace();
				//verify result
				console.log(place);
				
				if (place.geometry === undefined || place.geometry === null) {return;}

				callback(this.convertGooglePlaceToStreatSmartPlace(place));
			});
		});
	}


	private convertGooglePlaceToStreatSmartPlace(place: google.maps.places.PlaceResult): IPlace{
		const placeData: IPlaceData = {
			address: place.formatted_address,
			phone: place.formatted_phone_number,
			name: place.name,
			types: place.types,
			website: place.website,
			loc: {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()},
			rating: place.rating ? parseFloat(place.rating.toFixed(2)) : null,
			hoursOperating: place.opening_hours && place.opening_hours.weekday_text ? place.opening_hours.weekday_text : null,
			photos: place.photos ? this.retrievePhotoURLs(place.photos) : null
		};
		console.log(placeData)
		return new Place(place.place_id,placeData);
	}

	private retrievePhotoURLs(photosObj: google.maps.places.PlacePhoto[]): Image[]{
		return photosObj.map((obj: google.maps.places.PlacePhoto) => {
			let img: Image = <Image>{};
			img.imageUrl = obj.getUrl();
			return img;
		})
	}


}
