import { TopicProps } from "./index";
import { TopicGroupOptions } from "./topic-group-options.interface";

export const TopicsConfig: TopicProps[] = [
    {
        "key": "new_vendor_added",
        "prefix": "state",
        "description": "Receive a push notification when a new vendor is created in the selected state",
        "display": "New Vendor Created",
        "category": TopicGroupOptions.LOCATION
    },
    {
        "key": "daily_events",
        "prefix": "state",
        "description": "Receive a push notification displaying the amount of vendors open today in the selected state",
        "display": "Daily Events",
        "category": TopicGroupOptions.LOCATION
    },
    {
        "key": "open_today",
        "display": "Open Today",
        "description": "Receive a push notification on days the selected vendor is open",
        "category": TopicGroupOptions.FAVORITES,
        "prefix": "vendorID"
    },
    {
        "key": "added_event",
        "display": "New Event",
        "description": "Receive a push notification when the selected vendor adds an event to StreatSmart",
        "category": TopicGroupOptions.FAVORITES,
        "prefix": "vendorID"
    },
    {
        "key": "new_menu_item",
        "display": "New Menu Item",
        "description": "Receive a push notification when the selected vendor adds a new menu item to StreatSmart",
        "category": TopicGroupOptions.FAVORITES,
        "prefix": "vendorID"
    },
    {
        "key": "verified_event_creation",
        "display": "Verified Event Creation",
        "description": "Receive a push notification every time StreatSmart adds a verified event to the platform",
        "category": TopicGroupOptions.VENDOR
    },
    {
        "key":  "weekly_calendar_reminder",
        "display": "Weekly Calendar Reminder",
        "description": "Receive a push notification every Monday reminding you to update your StreatSmart calendar",
        "category": TopicGroupOptions.VENDOR
    },
    {
        "key":  "new_customer_review",
        "display": "New Customer Review",
        "description": "Receive a push notification when a customer adds a new review",
        "category": TopicGroupOptions.VENDOR
    },
    {
        "key": "admin_event_queue", //this is server side, updates here must go to Message.ts
        "display": "Event Queue",
        "description": "Receive a push notification when a user adds/updates/deletes an event",
        "category": TopicGroupOptions.ADMIN
    },
    {
        "key": "admin_vendor_request", //this is server side, updates here must go to Message.ts
        "display": "Vendor Requests",
        "description": "Receive a push notification for new vendor requests",
        "category": TopicGroupOptions.ADMIN
    },

]
