import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { IonButton } from '@ionic/angular';

/***Services */
import { ViewManagerService, App } from "src/app/app-services/index";

/***Components***/
import { NativeFeatureButton } from "../native-feature-button.component"; 

/***Plugins */
import { LaunchNavigator, LaunchNavigatorOptions } from '@awesome-cordova-plugins/launch-navigator/ngx';

/***Models */
import { ILocation } from "src/app/app-domain/location/index";
import { IEventRead } from "src/app/app-domain/scheduling/index";
@Component({
	selector: 'navigation-button',
	templateUrl: './navigation-button.component.html',
	styleUrls: ["navigation-button.component.scss"]
})
export class NavigationButton extends NativeFeatureButton {
	private _event: IEventRead;
	@ViewChild(IonButton, {read: ElementRef}) protected ionButton: ElementRef;
	@Input() set event(event: IEventRead){
		this._event = event;
	}

	get event(): IEventRead{
		return this._event;
	}
	constructor(viewManager: ViewManagerService, private launchNavigator: LaunchNavigator
	){
		super(viewManager);
	}
 

	onClick(){
		this.launchNavigator.navigate([this.event.getLat(),this.event.getLng()])
			.then(() => {
				console.log("Navigation Launched")
			})
	}
	
	
	
}
