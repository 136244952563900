import { AlertController, LoadingController } from "@ionic/angular";
import { FirebaseAuthService } from "./firebase-auth.service";
import { UserService } from "src/app/app-domain/user";
import { VendorService } from "src/app/app-domain/vendor";
import { Router } from "@angular/router";
import { MediaNavigatorService } from "../media-navigator.service";
import { ViewManagerService } from "../view-manager.service";
import { SubscriptionManagerService } from "../subscription-manager.service";
import { AnalyticsService } from "../analytics-services";
import { BackgroundLocationService } from "../native-services";


export let AuthServiceFactory = function(
    alertController: AlertController,
    userService: UserService,
    loadingController: LoadingController,
    vendorService: VendorService,
    router: Router,
    mediaNav: MediaNavigatorService,
    viewMgr: ViewManagerService,
    subscriptionManager: SubscriptionManagerService,
    analyticsService: AnalyticsService,
    backgroundLocService: BackgroundLocationService
){
    return new FirebaseAuthService(alertController, userService, loadingController, vendorService, router ,mediaNav, viewMgr, subscriptionManager, analyticsService,backgroundLocService);
}
