import {Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

/***Services */
import { LogService} from "./log.service.abstract"
import { Activity } from 'src/app/app-domain/activity';



@Injectable()
export class FirebaseLogService extends LogService{

	constructor(){
		super();
	}

	public logUserActivity(payload: Activity): Promise<any> {
		const logUserActivity = firebase.functions().httpsCallable("service_activity_logUserActivity");
		return logUserActivity(payload);
	}
	

}

