import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[coreSuppressScrollEvent]'
})
export class SuppressScrollEventDirective implements OnInit {
  public constructor(private el: ElementRef) {}

  public ngOnInit(): void {
    (this.el.nativeElement as HTMLElement).ontouchmove = function(e) {
      e.stopPropagation();
    };
  }
}
