/***Models***/
import { IVendorData } from "src/app/app-domain/vendor";
/***Interfaces */
import { Persistable } from "../../../app-domain/index";
import { RemoteResponse } from "src/app/app-interfaces";




export abstract class RemoteVendorService{
    constructor(){
    }

    public abstract updateVendor(data: Persistable<IVendorData>, dataOptions?: {[key: string]: any}): Promise<RemoteResponse>
    public abstract addVendor(data: Persistable<IVendorData>, dataOptions?: {[key: string]: any}): Promise<RemoteResponse>
    public abstract deleteVendor(data: Persistable<IVendorData>, dataOptions?: { [key: string]: any }): Promise<RemoteResponse>
    public abstract activateVendor(data: Persistable<IVendorData>, dataOptions?: { [key: string]: any }): Promise<RemoteResponse>
    public abstract inactivateVendor(data: Persistable<IVendorData>, dataOptions?: { [key: string]: any }): Promise<RemoteResponse>
}
