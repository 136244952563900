import { AlertController, LoadingController } from "@ionic/angular";
import { AuthService } from "./auth.service.abstract";
import { AuthServiceFactory } from "./auth.service.factory";
import { UserService } from "src/app/app-domain/user";
import { VendorService } from "src/app/app-domain/vendor";
import { Router } from "@angular/router";
import { MediaNavigatorService } from "../media-navigator.service";
import { ViewManagerService } from "../view-manager.service";
import { SubscriptionManagerService } from "../subscription-manager.service";
import { AnalyticsService } from "../analytics-services";
import { BackgroundLocationService } from "../native-services";



export let AuthServiceProvider = {
    provide: AuthService,
    useFactory: AuthServiceFactory,
    deps: [AlertController, UserService, LoadingController, VendorService, Router, MediaNavigatorService, ViewManagerService,SubscriptionManagerService, AnalyticsService, BackgroundLocationService]
}