import { Pipe, PipeTransform } from '@angular/core';
import { FeatureRequestStatus } from '../app-domain/feature-request/feature-request-data.interface';


@Pipe({name: 'featureRequestStatus'})
export class FeatureRequestStatusPipe implements PipeTransform {
    transform(value, args: string[]): string {
        return FeatureRequestStatus[value];
    }
}
