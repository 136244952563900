import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Authentication, GoogleAuth, User } from '@codetrix-studio/capacitor-google-auth';
import { ViewManagerService } from "../view-manager.service";
import { AlertController, AlertInput, LoadingController } from "@ionic/angular";
import { GoogleCalData, GoogleCalList, GoogleCalOption } from "./google-calendar.contracts";
import { CalendarPayload, CalendarType, RemoteService, SchedulingService } from "../remote-services";
import { VendorService } from "src/app/app-domain/vendor";
import { RemoteResponse, RemoteResponseStatus } from "src/app/app-interfaces";
import { environment } from "src/environments/environment";

@Injectable()
export class GoogleCalendarService extends RemoteService{
    private payload: CalendarPayload<GoogleCalData> = <CalendarPayload<GoogleCalData>>{};
    
    constructor(private httpClient: HttpClient, private viewManager: ViewManagerService, alertCtrl: AlertController,
                private schedulingService: SchedulingService, private vendorService: VendorService,
                loadCtrl: LoadingController
        ){
            super(alertCtrl, loadCtrl);
            this.init();
    }

    public async connect(): Promise<User | Authentication>{
        try{
            const user =  await GoogleAuth.signIn();
           // console.log(user);
            return user;
        }catch(error){
            if (error.message.includes("-8")){
                const auth = await GoogleAuth.refresh();
                //console.log(auth);
                return auth
            }
            //console.log("Error" + error);
            //console.log("Message" + error.message);
        }
       

    }


    private init(){
        if (!this.viewManager.isNative()){
            GoogleAuth.initialize({
                clientId: environment.google.webClientId,//"901410696126-6je9bfovbulqvfsf14dtthoi1b4b7s93.apps.googleusercontent.com",
                scopes: [
                    "profile",
                    "email",
                    "https://www.googleapis.com/auth/calendar.readonly"
                  ],
                  grantOfflineAccess: true,
            });
        }
    }

    private async getCalendarOptions(accessToken: string): Promise<GoogleCalOption[]>{
		return this.httpClient.get('https://www.googleapis.com/calendar/v3/users/me/calendarList',{"headers": {"Authorization": "Bearer " + accessToken}}).toPromise()
			.then((list: GoogleCalList) => {
                let calendarList = list.items.filter((c) =>{
                    /*if (c.accessRole === "owner")*/ return {id: c.id,summary: c.summary};
                })
                return calendarList;
			})
	}

    public async linkGoogleCalendar(){
        this.payload = <CalendarPayload<GoogleCalData>>{};
        const user: User | Authentication = await this.connect();
        if ("serverAuthCode" in user){
            this.payload.serverAuthCode = user.serverAuthCode;
            this.payload.accessToken = user.authentication.accessToken;
        }else if ("refreshToken" in user){
            this.payload.accessToken = user.accessToken;
            this.payload.refreshToken = user.refreshToken;
        }
        const calOptions = await this.getCalendarOptions(this.payload.accessToken);
        this.selectGoogleCalendar(calOptions)
    }


    private async selectGoogleCalendar(calOptions: GoogleCalOption[]){
        const alertInputs: AlertInput[] = calOptions.map((option, i) => {
            return {
                name: option.summary,
                type: 'radio', 
                label: option.summary, 
                value: {id: option.id, name: option.summary}, 
                checked: i === 0 ? true : false
            }
        })


        const alert = await this.alertCtrl.create({
            header: 'Please select the calendar you would like to sync with StreatSmart',
            inputs: alertInputs,
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel'
                }, {
                    text: 'Sync',
                    handler: async (val) => {
                        this.payload.calendar = <GoogleCalData>{};
                        this.payload.calendar.id = val.id; 
                        this.payload.calendar.name = val.name;
                        await this.linkExternalCalendar();
                    }
                }
            ]
        })
        await alert.present();
    }


    private async linkExternalCalendar(): Promise<any>{
        const loader = await this.loadCtrl.create({
            message: "Connecting Google Calendar"
        });
        await loader.present();
        try{
            const response: RemoteResponse = await this.schedulingService.linkExternalCalendar<GoogleCalData>(this.payload,{vendorID: this.vendorService.getVendorFocus().getVendorID(),value:CalendarType.GOOGLE});
            this.displayRemoteError(response);
            loader.dismiss();
        }catch(error){
            loader.dismiss();
        }
	}
	

    public async unlinkGoogleCalendar(): Promise<any>{
        const loader = await this.loadCtrl.create({
            message: "Removing Google Calendar Connection"
        });
        await loader.present();
        try{
            const response: RemoteResponse = await this.schedulingService.unlinkExternalCalendar({vendorID: this.vendorService.getVendorFocus().getVendorID(),value:CalendarType.GOOGLE});
            this.displayRemoteError(response);
            loader.dismiss();
        }catch(error){
            loader.dismiss();
        }
        
        
    }
    



    

}