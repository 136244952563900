import { Component } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavParams } from '@ionic/angular';


@Component({})
export abstract class ServiceBaseComponent {
  private loader: HTMLIonLoadingElement;
  constructor(
    protected modalCtrl: ModalController, protected loadCtrl: LoadingController, protected alertCtrl: AlertController
  ) { }

  public dismissModal(data: any = undefined) {
    this.modalCtrl.dismiss(data);
  }

 
  public async startLoader(message: string) {
    this.loader = await this.loadCtrl.create({
      message: message
    });
    await this.loader.present();
  }

  public stopLoader() {
    this.loader.dismiss();
  }

  public async createAlert(header: string, message: string, buttonText?: string, subHeader?: string) {
    const alert = await this.alertCtrl.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: [buttonText ? buttonText : "Ok"]
    })
    await alert.present();
  }

}
