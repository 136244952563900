import {Injectable } from '@angular/core';

@Injectable()
export abstract class AppLauncherService{

	constructor(
	){

	}

	public abstract open(url: string): Promise<void>

	public abstract canOpen(url: string): Promise<boolean>

}
 