import { Component } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "../app-domain/user/index";
import { VendorService, IVendor } from "../app-domain/vendor/index";
import {  VendorCacheService } from "../app-services/index";
import { EventQueuePage } from './pages/event-queue/event-queue.page';
import { EasyEatsMappingPage } from './pages/easyeats/easyeats-mappings.page';
import { CuisinePage } from './pages/cuisine/cuisine.page';
import { HotSpotPage } from './pages/hotspot/hotspot.page';
import { VendorManagementPage } from './pages/vendor-management/vendor-management.page';
import { FlaggedReviewsPage } from './pages/flagged-reviews/flagged-reviews.page';
import { GeofenceTestingPage } from './pages/geofence-testing/geofence-testing';
import { SendNotificationsPage } from './pages/send-notifications/send-notifications.page';
import { IntegrationTestingPage } from './pages/integration-testing/integration-testing';
import { BackendJobsPage } from './pages/backend-jobs/backend-jobs.page';


@Component({
	selector: 'admin',
	templateUrl: 'admin.page.html',
	styleUrls: ['admin.page.scss']
})
export class AdminPage{
  public eventQueuePage = EventQueuePage;
  public easyEatsMappingPage = EasyEatsMappingPage;
  public cuisinePage = CuisinePage;
  public hotspotPage = HotSpotPage;
  public vendorMgmtPage = VendorManagementPage;
  public flaggedReviewsPage = FlaggedReviewsPage;
  public geofenceTestingPage = GeofenceTestingPage;
  public sendNotificationsPage = SendNotificationsPage;
  public integrationTestPage = IntegrationTestingPage
  public backendJobsPage = BackendJobsPage;

	constructor(private userService: UserService, private vendorService: VendorService, private router: Router, private route: ActivatedRoute
		, private vendorCacheService: VendorCacheService,private modalController: ModalController
	) {
		
	}

	async ngOnInit(){}

  public async openModal(comp: any, props: any) {
    const modal = await this.modalController.create({
      component: comp,
      componentProps: props
    })
    await modal.present();
  }
}
