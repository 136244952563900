import {Injectable } from '@angular/core';

import { RemoteStorageTaskResponse } from './remote-storage.service.contracts';
import { Image } from 'src/app/app-domain';
import { DataURL } from 'src/app/app-interfaces';

@Injectable()
export abstract class RemoteStorageService{

	constructor(
	){

	}

	public abstract uploadImage(path: string, image: DataURL): Promise<RemoteStorageTaskResponse>

	public abstract deleteImage(image: Image): Promise<RemoteStorageTaskResponse>

	public abstract uploadAndReplaceImage(newImagePath: string, newImage: DataURL, oldImage?: Image): Promise<RemoteStorageTaskResponse>
	
}
 