import {Injectable } from '@angular/core';
/***Firebase***/
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import { ActivityType, Activity } from './index';






@Injectable()
export class ActivityRepo{

	constructor(){}


    public async getActivityByVendor(activity: ActivityType, vendorID: string): Promise<Activity[]>{
        const ref: firebase.database.Reference = firebase.database().ref("/activity/" + activity);
        const snapshot = await ref.orderByChild('vendorID').equalTo(vendorID).once('value');
        if (snapshot.val()){
            if (Object.keys(snapshot.val()).length > 0){
                return Object.keys(snapshot.val()).map((key: string) => {
                    return snapshot.val()[key];
                })
            }
        }
    }
   


}


