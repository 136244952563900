import { Component, Input } from '@angular/core';
import { Router } from "@angular/router";
import { IVendor } from "../../../../app-domain/vendor/index";
import { IUserData } from "../../../../app-domain/user/index";
import { Persistable } from 'src/app/app-domain';
import { IonIcon } from '@ionic/angular';

@Component({
	selector: 'vendor-hotspot-card',
	templateUrl: 'vendor-hotspot-card.component.html',
	styleUrls: ['vendor-hotspot-card.component.scss'],
})
export class VendorHotspotCardComponent{
	@Input() public vendor: IVendor;


	constructor(private router: Router){

	}

	ngOnInit(){

	}

	public onCardClick(){
		this.router.navigate([this.router.url + '/vendor/' + this.vendor.getVendorID()]);
	}

}
