import {Injectable } from '@angular/core';
/***Firebase***/
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';


import { IRepository, Persistable } from "../../app-domain/index";
import { IVendorData, IVendor, VendorFactory, IVendorSearch, IVendorSearchOptions } from "./index";
import { RemoteFieldPayload, RemoteResponse } from 'src/app/app-interfaces';
import { RemoteVendorService } from 'src/app/app-services/remote-services/index';


export interface IVendorRepo extends IRepository<IVendor, IVendorData>, IVendorSearch {
  activateVendor(data: Persistable<IVendorData>): Promise<RemoteResponse>;
  inactivateVendor(data: Persistable<IVendorData>): Promise<RemoteResponse>;
}


@Injectable()
export class VendorRepo implements IVendorRepo{

	constructor(private vendorFactory: VendorFactory,private remoteVendorService: RemoteVendorService){}


	public async getByID(id: string): Promise<IVendor>{
		const ref: firebase.database.Reference = firebase.database().ref("/vendors/" + id);
		const snapshot: firebase.database.DataSnapshot = await ref.once('value');
		const _vendorData: IVendorData = snapshot.val();

    	if (snapshot.val()) {
			const vendor: IVendor = await this.vendorFactory.build(id, _vendorData);
			return vendor;
		}	

		return;
	}

	public async getByIDAndSubscribe(id: string): Promise<IVendor>{
		const ref: firebase.database.Reference = firebase.database().ref("/vendors/" + id);
		const snapshot: firebase.database.DataSnapshot = await ref.once('value');
		const _vendorData: IVendorData = snapshot.val();
		if (snapshot.val()){
			const _vendor: IVendor = await this.vendorFactory.build(id, _vendorData);
			_vendor.subscribe(ref);
			return _vendor;
		}
		return;
	}

	public getByIDs(ids: string[]): Promise<IVendor[]>{
		if (ids.length > 0){
			return Promise.all(ids.map(async (id: string) => {
				const _vendor = await this.getByID(id);
				return _vendor;
			}));
		}
	}

	public getByIDsAndSubscribe(ids: string[]): Promise<IVendor[]>{
		if (ids.length > 0){
			return Promise.all(ids.map(async (id: string) => {
				const _vendor = await this.getByIDAndSubscribe(id);
				return _vendor;
			})).then((vendors: IVendor[]) => {
				return vendors.filter((vendor: IVendor) => {
					return vendor != undefined
				})
			});
		}
	}

	public async update(data: Persistable<IVendorData>, misc: RemoteFieldPayload<any>): Promise<RemoteResponse>{
		return this.remoteVendorService.updateVendor(data,misc);
	}

  public delete(data: Persistable<IVendorData>): Promise<RemoteResponse>{
    return this.remoteVendorService.deleteVendor(data)
	}

  public add(data: Persistable<IVendorData>): Promise<RemoteResponse>{
    return this.remoteVendorService.addVendor(data)
  }

  public activateVendor(data: Persistable<IVendorData>): Promise<RemoteResponse> {
    return this.remoteVendorService.activateVendor(data)
  }

  public inactivateVendor(data: Persistable<IVendorData>): Promise<RemoteResponse> {
    return this.remoteVendorService.inactivateVendor(data)
  }

	public async getVendorsByState(state: string): Promise<IVendor[]>{
		const ref: firebase.database.Reference = await firebase.database().ref("/vendors/");
		const snapshot = await ref.orderByChild('loc').equalTo(state).once('value');
		const vendorData: {[key: string]: IVendorData} = snapshot.val();
		let vendors: IVendor[] = await Promise.all(Object.keys(vendorData).map((vID: string) => this.vendorFactory.build(vID, vendorData[vID])));
		vendors = this.filterReviewedVendors(vendors);
		return vendors;
	}

	public async getVendorsBySearch(searchOptions: IVendorSearchOptions): Promise<IVendor[]>{
		const ref: firebase.database.Reference = await firebase.database().ref("/vendors/");
		const snapshot = await ref.orderByChild('loc').equalTo(searchOptions.state).once('value');
    const vendorData: { [key: string]: IVendorData } = snapshot.val();
    if (vendorData) {
      const vendors: IVendor[] = await Promise.all(Object.keys(vendorData).map((vID: string) => this.vendorFactory.build(vID, vendorData[vID])));
      return this.filterVendors(vendors, searchOptions);
    }
    return [];

		/*return vendors
			.filter((vendor: IVendor) =>  //Status Filter
				 searchOptions.status.includes(vendor.getStatusFlag())
			)
			.filter((vendor: IVendor) =>  //Cuisine Filter
				 (searchOptions.cuisine[0] === "Any" ||
					(vendor.getCuisine() && vendor.getCuisine().reduce((accumulator,currentValue) => accumulator || searchOptions.cuisine.includes(currentValue),0))
				)
			)
			.filter((vendor: IVendor) =>  //Rating Filter
				 searchOptions.rating === 0 || vendor.getRating() > searchOptions.rating
			);*/
	}


	private filterReviewedVendors(vendors: IVendor[]){
		return vendors.filter((vendor: IVendor) => {
			return vendor.isReviewed() && vendor.getStatusFlag() !== 5 && vendor.getStatusFlag() !== 1; //reviewed and no inactivated
		})
	}

  public async getAllVendors(subscribe?: boolean) {
    const ref: firebase.database.Reference = firebase.database().ref("/vendors/");
    const snapshot: firebase.database.DataSnapshot = await ref.once('value');
    const _vendorData: { [key: string]: IVendorData } = snapshot.val();
    if (_vendorData) {
      return Promise.all(Object.keys(_vendorData).map(async (id: string) => {
        const _vendor: IVendor = await this.vendorFactory.build(id, _vendorData[id]);
        if (subscribe) _vendor.subscribe(firebase.database().ref("/vendors/" + id));
        return _vendor;
      })).then((vendors: IVendor[]) => {
        return vendors.filter((vendor: IVendor) => {
          return vendor.getVendorID() != undefined && vendor.getVendorID() != "undefined";
        })
      })
    }
  }

  public filterVendors(vendors: IVendor[], searchOptions: IVendorSearchOptions, noCuisine?: boolean): Promise<IVendor[]> {
    return Promise.resolve(vendors
      .filter((vendor: IVendor) =>  //Status Filter
        searchOptions.status.includes(vendor.getStatusFlag())
      )
      .filter((vendor: IVendor) =>  //Cuisine Filter
      (searchOptions.cuisine[0] === "Any" || noCuisine != undefined ||
        (vendor.getCuisine() && vendor.getCuisine().reduce((accumulator, currentValue) => accumulator || searchOptions.cuisine.includes(currentValue), 0))
      )
      )
      .filter((vendor: IVendor) =>  //Rating Filter
        searchOptions.rating === 0 || vendor.getRating() >= searchOptions.rating
      )
      .filter((vendor: IVendor) => { //Optional State if not called from vendorSearch method above
        return searchOptions.state === vendor.getState()
      }));
  }

  public async getRequestedVendors(): Promise<IVendor[]> {
    const snapshot = await firebase.database().ref("/vendorRequest/").once('value')
    if (!snapshot.val()) return;
    const vendors: IVendor[] = await Promise.all(Object.keys(snapshot.val()).map((vID: string) => this.vendorFactory.build(null, snapshot.val()[vID])));
    return vendors;
  }
}


