import {Injectable } from '@angular/core';
/***Firebase***/
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/functions';
import { QueuedEvent, QueueEventStatus } from '../app-domain/scheduling';
import { HotspotEventData } from '../app-domain/scheduling/hotspot-event-data.interface';
import { IVendor } from '../app-domain/vendor';
import { VendorCacheService } from './vendor-cache.service';
import { RemotePayload } from '../app-interfaces';
import { IReview, ReviewRepo } from '../app-domain/review';

@Injectable()
export class AdminService{

  constructor(private vendorCacheService: VendorCacheService, private reviewRepo: ReviewRepo) { }

  public async getEventsOnQueue(): Promise<QueuedEvent[]>{
    const snapshot = await firebase.database().ref("_eventQueue").once('value');
    if (!snapshot.val()) return [];
    return Object.keys(snapshot.val()).map((id: string) => {
      return { queueID: id, event: snapshot.val()[id] };
    })
  }

  public confirmQueuedEvent(queueID: string) {
    return firebase.database().ref("/_eventQueue/" + queueID + "/status").set(QueueEventStatus.CONFIRMED).then(() => { return; });
  }

  public rejectQueuedEvent(queueID: string) {
    return firebase.database().ref("/_eventQueue/" + queueID + "/status").set(QueueEventStatus.REJECTED).then(() => { return; });
  }

  public async getEasyEatsVendors(): Promise<IVendor[]> {
    const snapshot = await firebase.database().ref("vendors/").orderByChild("easyEats").startAt("1").once('value');
    if (!snapshot.val()) return;
    return Promise.all(Object.keys(snapshot.val()).map(async (vID: string) => {
      const vendor = await this.vendorCacheService.getByID(vID);
      return vendor;
    }));
  }

  public async deleteVendorEasyEatsID(vendorID: string, easyEatsID: string): Promise<any> {
    await firebase.database().ref("/vendors/" + vendorID + "/easyEats").remove();
    await this.deleteVendorFromEZMap(easyEatsID);
  }

  public async addEasyEatsVendor(vendorID: string, easyEatsID: string): Promise<any> {
    await firebase.database().ref("/vendors/" + vendorID + "/easyEats").set({ 'id': easyEatsID });
    await this.addVendorToEZMap(vendorID, easyEatsID)
  }

  public deleteVendorFromEZMap(easyEatsID: string): Promise<any> {
    return firebase.database().ref("/_ezMap/" + easyEatsID).remove();
  }

  public addVendorToEZMap(vendorID: string, easyEatsID: string): Promise<any> {
    return firebase.database().ref("/_ezMap/").update(Object.assign({}, { [easyEatsID]: vendorID }))
  }

  public updateHotSpot(id: string, data: HotspotEventData) {
    const payload: RemotePayload<HotspotEventData> = <RemotePayload<HotspotEventData>>{};
    payload.data = data;
    payload.id = id;
    const updateHotSpot = firebase.functions().httpsCallable("service_scheduling_updateHotSpot");
    return updateHotSpot(payload);
  }

  public addHotSpot(data: HotspotEventData) {
    const payload: RemotePayload<HotspotEventData> = <RemotePayload<HotspotEventData>>{};
    payload.data = data;
    const addHotSpot = firebase.functions().httpsCallable("service_scheduling_addHotSpot");
    return addHotSpot(payload);
  }

  public deleteHotSpot(id: string, data: HotspotEventData) {
    const payload: RemotePayload<HotspotEventData> = <RemotePayload<HotspotEventData>>{};
    payload.data = data;
    payload.id = id;
    const deleteHotSpot = firebase.functions().httpsCallable("service_scheduling_deleteHotSpot");
    return deleteHotSpot(payload);
  }

  public async getFlaggedReviews(): Promise<IReview[]> {
    let reviewData = await firebase.database().ref("_flagReviewRequest/").once('value');
    console.log(reviewData);

    if (reviewData.val()) {
      return Promise.all(Object.keys(reviewData.val()).map((requestID: string) => {
        return this.reviewRepo.getByID(reviewData.val()[requestID].vendorID, reviewData.val()[requestID].userID);
      }));
    }
  }

  public discardFlaggedReview(review: IReview): Promise<any> {
    return firebase.database().ref("/_flagReviewRequest/" + review.getUserID() + "-" + review.getVendorID()).remove();
  }

  //public activateVendor(newVendor: )


}
