import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavParams } from '@ionic/angular';
import { ServiceBaseComponent } from '../../../../app-components/shared-components/base-components';
import { Persistable } from '../../../../app-domain';
import { IVendor, IVendorData, VendorRepo } from '../../../../app-domain/vendor';
import { RemoteConfigService } from '../../../../app-services';

@Component({
  selector: 'vendor-mgmt-card',
  templateUrl: './vendor-mgmt-card.component.html'
})

export class VendorMgmtCardComponent extends ServiceBaseComponent implements OnInit {
  public vendor: IVendor;
  public initialReviewedFlag: boolean;

  //Remote Configs - Firebase
  //Vendor Operating Status
  public operatingStatuses: { [key: string]: any }[];
  //public statusObject: {[key: string]: any};

  constructor(
    private navParams: NavParams, private remoteConfigs: RemoteConfigService,
    modalCtrl: ModalController, private vendorRepo: VendorRepo,
    loadCtrl: LoadingController, alertCtrl: AlertController
  ) {
    super(modalCtrl, loadCtrl, alertCtrl)
  }

  async ngOnInit() {
    this.vendor = this.navParams.get('vendor');
    console.log(this.vendor);
    this.initialReviewedFlag = this.vendor.isReviewed();
    this.operatingStatuses = await this.remoteConfigs.getOperatingStatuses();
    console.log(this.operatingStatuses);
    this.enrichStatus();
  }


  enrichStatus() {
    this.operatingStatuses.push({
      value: 0,
      readable: "Requested",
      selectable: false,
      desc:"Requested Vendor"
    })
  }


  async activateVendor() {
    this.startLoader("Activating Vendor");
    try {
      const response = await this.vendorRepo.activateVendor(this.vendor as unknown as Persistable<IVendorData>);
      this.stopLoader();
      if (response.data.statusCode != 1) {
        await this.createAlert("Unable to activate vendor", response.data.msg);
      } else {
        await this.createAlert("Vendor Successfully Activated", "This vendor is now searchable on the user Trucks page.");
      }
      this.dismissModal(true)
    } catch (error) {
      this.stopLoader();
      await this.createAlert("Unable to Activate vendor", "This is kind of a problem, huh. Get your shit together Zach.");
    }
  }

  async inactivateVendor() {
    this.startLoader("Inactivating Vendor");
    try {
      const response = await this.vendorRepo.inactivateVendor(this.vendor as unknown as Persistable<IVendorData>);
      this.stopLoader();
      if (response.data.statusCode != 1) {
        await this.createAlert("Unable to inactivate vendor", response.data.msg);
      } else {
        await this.createAlert("Vendor Successfully Inactivated", "This vendor will now be hidden from searches on the user Trucks page.");
      }
      this.dismissModal(true)
    } catch (error) {
      this.stopLoader();
      await this.createAlert("Unable to inactivate vendor", "This is kind of a problem, huh. Get your shit together Zach.");
    }
  }

  async addVendor() {
    this.startLoader("Adding Vendor");
    try {
     
      const response = await this.vendorRepo.add(this.vendor as unknown as Persistable<IVendorData>);
      this.stopLoader();
      if (response.data.statusCode != 1) {
        await this.createAlert("Unable to add vendor", response.data.msg);
      } else {
        await this.createAlert("Vendor Successfully Added", "The user will now have access to this vendor account. The vendor account is pending review and will be activated once the user completes the necessary actions to complete their profile.");
      }
      this.dismissModal(true)
    } catch (error) {
      this.stopLoader();
      await this.createAlert("Unable to add vendor", "This is kind of a problem, huh. Get your shit together Zach.");
    }
  }
}
