
export enum NotificationTopic{
    NEW_VENDOR = "new_vendor_added",
    DAILY_EVENTS = "daily_events",
    ADDED_EVENT = "added_event",
    OPEN_TODAY = "open_today",
    HOTSPOT = "verified_event_creation",
    REMINDER = "weekly_calendar_reminder",
    PROXIMITY = "proximity",
    NEW_CUSTOMER_REVIEW = "new_customer_review"
}

export interface Notification{
    /***FCM Contract */
    
    /**
     * The notification title.
     *
     * @since 1.0.0
     */
    title?: string;
    /**
     * The notification subtitle.
     *
     * @since 1.0.0
     */
    subtitle?: string;
    /**
     * The main text payload for the notification.
     *
     * @since 1.0.0
     */
    body?: string;
    /**
     * The notification identifier.
     *
     * @since 1.0.0
     */
    id: string;
    /**
     * The notification tag.
     *
     * Only available on Android (from push notifications).
     *
     * @since 4.0.0
     */
    tag?: string;
    /**
     * The number to display for the app icon badge.
     *
     * @since 1.0.0
     */
    badge?: number;
    /**
     * It's not being returned.
     *
     * @deprecated will be removed in next major version.
     * @since 1.0.0
     */
    notification?: any;
    /**
     * Any additional data that was included in the
     * push notification payload.
     *
     * @since 1.0.0
     */
    data: any;
    /**
     * The action to be performed on the user opening the notification.
     *
     * Only available on Android.
     *
     * @since 1.0.0
     */
    click_action?: string;
    /**
     * Deep link from the notification.
     *
     * Only available on Android.
     *
     * @since 1.0.0
     */
    link?: string;
    /**
     * Set the group identifier for notification grouping.
     *
     * Only available on Android. Works like `threadIdentifier` on iOS.
     *
     * @since 1.0.0
     */
    group?: string;
    /**
     * Designate this notification as the summary for an associated `group`.
     *
     * Only available on Android.
     *
     * @since 1.0.0
     */
    groupSummary?: boolean;


}