import {Injectable } from '@angular/core';

@Injectable()
export abstract class InAppBrowserService{

	constructor(
	){

	}

	public abstract open(url: string, target?: string): Promise<void>	
}
 