import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

import { Image, Model } from "../index";
import {IVendorData, IVendor, Cuisine, IEasyEats, IICal, IGoogle, IFacebook} from "./index";
import { Persistable } from "../../app-domain/index";
import { IReviewList, IReview } from "../review/index";
import { IMenu } from '../menu/index';
import { IParentCalendar } from "../scheduling/index";
import { Preference } from '../preference/index';
import { PreferenceCollection } from "../preference/preference-collection"
import { AppModule } from 'src/app/app.module';
import { SubscriptionManagerService } from 'src/app/app-services/subscription-manager.service';

export class Vendor implements Persistable<IVendorData>, IVendor{
	private vendorID: string;
	private vendorData: IVendorData;
	private reviewList: IReviewList;
	private menu: IMenu;
  	private calendar: IParentCalendar;
  	private preferenceCollection: PreferenceCollection; 
	private reference: firebase.database.Reference;

	constructor(vendorID: string, vendorData: IVendorData){
		this.vendorID = vendorID;
		this.vendorData = vendorData;
	}


	public getID(): string{
		return this.vendorID;
	}

	public getData(): IVendorData{
		return this.vendorData;
	}

	public getVendorID(): string{
		return this.vendorID;
	}

	public setVendorID(vendorID: string): IVendor{
		this.vendorID = vendorID;
		return this;
	}

	public setVendorData(vendorData: IVendorData): void{
		this.vendorData = vendorData;
	}

	public getDetail(detail: string): any{
		if (this.vendorData[detail]){
			return this.vendorData[detail];
		}
	}


	public getAdmin(): string[]{
		return this.vendorData.admin;
	}

	public getAdminID(): string{
		return Object.keys(this.vendorData.admin)[0];
	}

	public getAffiliation(): string[]{
		return this.vendorData.affiliation;
	}

	//Images
	public getAvatarImgUrl(){
		return this.vendorData.avatarImg.imageUrl;
	}

	public getAvatarImgSrc(){
		return this.vendorData.avatarImg.imageSrc;
	}

	public getBackgroundImgUrl(){
		return this.vendorData.backgroundImg.imageUrl;
	}

	public getBackgroundImgSrc(){
		return this.vendorData.backgroundImg.imageSrc;
	}

	public setAvatarImgUrl(url: string): void{
		this.vendorData.avatarImg.imageUrl = url;
	}

	public setBackgroundImgUrl(url: string): void{
		this.vendorData.backgroundImg.imageUrl = url;
	}

	public setBackgroundImg(img: Image): void{
		this.vendorData.backgroundImg = img;
	}

	public setAvatarImg(img: Image): void{
		this.vendorData.avatarImg = img;
	}


	//Created Date
	public getCreatedDate(): number{
		return this.vendorData.createdDate;
	}


	//Description
	public getDescription(): string{
		return this.vendorData.desc;
	}

	public setDescription(desc: string): void{
		this.vendorData.desc = desc;
	}

	//EasyEats
	public hasEasyEats(){
		return this.vendorData.easyEats != undefined  && Object.keys(this.vendorData.easyEats).length > 0 ? true: false;
	}
	public getEasyEatsID(){
		return this.vendorData.easyEats.id;
	}

	public resetEasyEats(): void{
		this.vendorData.easyEats  = <IEasyEats>{};
	}


	//Email
	public hasEmail(){
		return this.vendorData.email ? true: false;
	}
	public getEmail(){
		return this.vendorData.email;
	}

	public setEmail(email: string): void{
		this.vendorData.email = email;
	}


	//Facebook
	public hasFacebook(){
		return this.vendorData.facebook ? true: false;
	}

	public getFacebookName(){
		if (this.vendorData.facebook) {return this.vendorData.facebook.pageName;}
	}

	public getFacebookID(){
		if (this.vendorData.facebook) {return this.vendorData.facebook.pageId;}
	}

	public resetFacebook(): void{
		this.vendorData.facebook = <IFacebook>{};
	}


	//Cuisine
	public getCuisine(){
		return this.vendorData.cuisine;
	}

	public setCuisine(cuisine: Cuisine[]): void{
		this.vendorData.cuisine = cuisine;
	}

	//Google
	public resetGoogle(): void{
		this.vendorData.google = <IGoogle>{};
	}

	public hasGoogleCalendar(): boolean{
		if (this.vendorData.google && this.vendorData.google.name){
			return true;
		}else{
			return false;
		}
	}

	public getGoogleCalName(): string{
		if (this.vendorData.google){
			return this.vendorData.google.name;
		}
	}


	//ICal
	public getICalURL(): string{
		if (this.vendorData.iCal) {return this.vendorData.iCal.url;}
		return;
	}

	public hasICal(): boolean{
		if (this.vendorData.iCal && this.vendorData.iCal.url){
			return true;
		}else{
			return false;
		}
	}

	public setICal(url: string): void{
		if (!this.vendorData.iCal) {this.vendorData.iCal = <IICal>{};}
		this.vendorData.iCal.url = url;
	}

	//Instagram
	public hasInstagram(){
		return this.vendorData.instagram  ? true: false;
	}

	public getInstagramHandle(){
		return this.vendorData.instagram;
	}

	public setInstagramHandle(handle: string): void{
		this.vendorData.instagram = handle;
	}

	//Location
	public getState(): string{
		return this.vendorData.loc;
	}

	//Name
	public getName(){
		return this.vendorData.name;
	}

	//Phone
	public hasPhone(){
		return this.vendorData.phone ? true: false;
	}
	public getPhoneNumber(){
		return this.vendorData.phone;
	}

	//Rating
  public getRating() {
    if (this.reviewList) {
      return parseFloat(this.reviewList.getAverageRating());
    } else {
      return this.vendorData.rating;
    }
	}

	//Reviewed
	public isReviewed(): boolean{
		return this.vendorData.reviewed ? true : false;
	}

	//Status statusFlag
	public getStatusFlag(): number{
		return this.vendorData.statusFlag;
	}

	public setStatusFlag(status: number): void{
		this.vendorData.statusFlag = status;
  }

  public getStatusMeaning(): string {
    switch (this.vendorData.statusFlag) {
      case 0: return "Requested";
      case 1: return "In Review";
      case 2: return "Active";
      case 3: return "Not Operating";
      case 5: return "Inactive";
    }
  }

	//StreatCred
	public getStreatCred(): number{
		if (this.vendorData.streatCred2 && this.vendorData.streatCred2.streatCred){
			return this.vendorData.streatCred2.streatCred;
		}else{
			return 0;
		}
	}

	public getStreatCredPercentile(): string{
		if (this.vendorData.streatCred2 && this.vendorData.streatCred2.streatCredPercentile){
			var sub: string;
			switch(this.vendorData.streatCred2.streatCredPercentile.toString().substring(1,2)){
				case "1": sub = "st"; break;
				case "2": sub = "nd"; break;
				case "3": sub = "rd"; break;
				default: sub = "th"; break;
			}
			return "" + this.vendorData.streatCred2.streatCredPercentile + sub + "%";
		}else{
			return "0";
		}
	}

	//Twitter
	public hasTwitter(){
		return this.vendorData.twitter ? true: false;
	}

	public getTwitterHandle(){
		return this.vendorData.twitter;
	}

	public setTwitterHandle(handle: string): void{
		this.vendorData.twitter = handle;
	}


	//Website
	public hasWebsite(){
		return this.vendorData.url ? true: false;
	}
	public getWebsiteURL(){
		return this.vendorData.url;
	}

	public setWebsiteURL(url: string): void{
		this.vendorData.url = url;
	}



	//Reviews
	public getReviewList(): IReviewList{
		return this.reviewList;
	}

	public setReviewList(reviewList: IReviewList): void{
		this.reviewList = reviewList;
	}

	public getFavoriteCount(): number{
		return this.reviewList.getFavoriteCount();
	}

	public getReviewCount(): number{
		return this.reviewList.getReviewCount();
  }

  public getRatingCount(): number {
    return this.reviewList.getRatingCount();
  }

	//Calendar
	public setCalendar(calendar: IParentCalendar): void{
		if (this.calendar) this.calendar.destroy()
		this.calendar = calendar;
	}

	public getCalendar(): IParentCalendar{
		return this.calendar;
		
	}


	//Menu
	public setMenu(menu: IMenu){
		this.menu = menu;
	}

	public getMenu(): IMenu{
		return this.menu;
	}


  //Preferences
	public setPrefs(prefs: PreferenceCollection) {
		this.preferenceCollection = prefs;;
	}

	public getPrefCollection(): PreferenceCollection{
		return this.preferenceCollection;
	}

	public getAllPrefs(): Preference[]{
		return this.preferenceCollection.getAllPrefs();
	}

	public getPrefByKeys(key: string, subKey?: string): Preference{
		return this.preferenceCollection.getPrefByKeys(key, subKey);
	}

	public setPref(pref: Preference): void{
		this.preferenceCollection.setPref(pref);
	}

	//Firebase subscription
	public subscribe(db: firebase.database.Reference): void{
    	this.unsubscribe();
		AppModule.injector.get(SubscriptionManagerService).addSubcription(this);
		this.reference = db;
		db.on("value", (snapshot: firebase.database.DataSnapshot) => {
			this.vendorData = snapshot.val();
		});
	}

	public unsubscribe(): void {
		if (this.reference) this.reference.off();
	}


	/***Private***/
	/*private async _setVendorData(): Promise<void>{
		this.vendorData = await <Promise<IVendorData>>this.restAPIHandler.get("/vendors/" + this.vendorID);
	}
*/
}
