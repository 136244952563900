import {Injectable } from '@angular/core';

/***Firebase***/
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';


import {Cuisine, IVendor, VendorCuisineOptions} from "../vendor/index";


//How Cuisine Data is Persisted
//Key is name of the cuisine
export interface CuisineData {
	[key: string]: any;
}


@Injectable()
export class CuisineService{
	private cache: VendorCuisineOptions;

	constructor(){

	}

  public async getCuisineOptions(forceRefresh?: boolean): Promise<VendorCuisineOptions>{
    if (this.cache != undefined && !forceRefresh) {
      console.log("Cache Hit");
			return this.cache;
		}else{
			const ref: firebase.database.Reference = firebase.database().ref("/cuisine/");
      const snapshot: firebase.database.DataSnapshot = await ref.once('value');
			const cuisineData: CuisineData = snapshot.val();
			const cuisineOptions: VendorCuisineOptions = this.transformCuisine(cuisineData);
			this.cache = cuisineOptions;
			return cuisineOptions;
		}

		//return cuisineData.map((cuisine: CuisineData) => {  this.transformCuisine(cuisine);})
	}

	private transformCuisine(cuisineData: CuisineData): VendorCuisineOptions{
		return new VendorCuisineOptions(Object.keys(cuisineData).map((c) => new Cuisine(cuisineData[c].family,c, cuisineData[c].info)));
  }

  public deleteCuisineOption(cuisine: Cuisine): Promise<any> {
    return firebase.database().ref("/cuisine/" + cuisine.getName()).remove();
  }

  public updateCuisineOption(cuisine: Cuisine): Promise<any> {
   // console.log({ family: cuisine.getFamily(), info: cuisine.getInfo() });
    return firebase.database().ref("/cuisine/" + cuisine.getName()).set({ family: cuisine.getFamily(), info: cuisine.getInfo() ? cuisine.getInfo:null })
  }

  public async getCuisinesFromVendors(vendors: IVendor[]): Promise<Cuisine[]> {
    const cuisineOptions = await this.getCuisineOptions();
    const cuisines: Array<Cuisine> = [];
    vendors.forEach((v) => {
      if (v.getCuisine()) {
        v.getCuisine().forEach((c) => {

          const cuisine = cuisines.find((_cuisine) => c === _cuisine.getValue())

          if (cuisine) {
            cuisine.setCount(cuisine.getCount() + 1);
          } else {
            const cuisineObject = cuisineOptions.getCuisine(c);
            if (cuisineObject) {
              cuisineObject.setCount(1);
              cuisines.push(cuisineObject);
            }
          }
        })
      }
    })
    return cuisines;
  }
}
