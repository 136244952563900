import {Injectable } from '@angular/core';
/***Firebase***/
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';

import { IMenuItem, IMenuItemData, MenuItem} from "./index";
import { IRepository, Persistable } from "../../app-domain/index";
import { MenuService } from "../../app-services/index";



@Injectable()
export class MenuItemRepo implements IRepository<IMenuItem, IMenuItemData>{

	constructor(private menuService: MenuService){}


	public async getByID(id: string, id2: string): Promise<IMenuItem>{
		const ref: firebase.database.Reference = firebase.database().ref("/menus/" + id + "/" + id2);
		const snapshot: firebase.database.DataSnapshot = await ref.once("value");
		const menuItemData: IMenuItemData= snapshot.val();
		return new MenuItem(menuItemData,id2);
	}


	public async getByIDAndSubscribe(id: string, id2: string): Promise<IMenuItem>{
		const ref: firebase.database.Reference = firebase.database().ref("/menus/" + id + "/" + id2);
		const snapshot: firebase.database.DataSnapshot = await ref.once('value');
		const menuItemData: IMenuItemData = snapshot.val();
		const menuItem: IMenuItem = new MenuItem(menuItemData, id2);
		menuItem.subscribe(ref);
		return menuItem;
	}


	public update(data: Persistable<IMenuItemData>, miscData: {[key: string]: any}): Promise<any>{
		if (!miscData.vendorID) {throw new Error("No VendorID Provided");}
		return this.menuService.updateMenuItem(data, miscData);
	}

	public delete(data: Persistable<IMenuItemData>, miscData: {[key: string]: any}): Promise<any>{
		if (!miscData.vendorID) {throw new Error("No VendorID Provided");}
		return this.menuService.deleteMenuItem(data, miscData);
	}

	public add(data: Persistable<IMenuItemData>, miscData: {[key: string]: any}): Promise<any>{
		if (!miscData.vendorID) {throw new Error("No VendorID Provided");}
		return this.menuService.addMenuItem(data, miscData);
	}




}
