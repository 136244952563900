import {Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
/***Models***/
import { IVendorData } from 'src/app/app-domain/vendor';
/***Interfaces */
import { Persistable } from "../../../app-domain/index";
import { RemotePayload, RemoteResponse } from "../../../app-interfaces/index";
/***Services */
import { RemoteVendorService } from './vendor.service.abstract';
import { AlertController, LoadingController } from '@ionic/angular';




@Injectable()
export class FirebaseVendorService extends RemoteVendorService{

	constructor(){
		super();
		
	}


	public updateVendor(data: Persistable<IVendorData>, dataOptions?: {[key: string]: any}): Promise<RemoteResponse>{
		const payload: RemotePayload<IVendorData> = this.createPayload<IVendorData>(data, dataOptions);
		console.log(payload)
    const updateVendor = firebase.functions().httpsCallable("service_vendor_updateVendor");
    return updateVendor(payload);
	}

	public addVendor(data: Persistable<IVendorData>, dataOptions?: {[key: string]: any}):Promise<RemoteResponse>{
		const payload: RemotePayload<IVendorData> = this.createPayload<IVendorData>(data, dataOptions);
		console.log(payload)
		const addVendor = firebase.functions().httpsCallable("service_vendor_addVendor");
    return addVendor(payload);
	}

	public deleteVendor(data: Persistable<IVendorData>, dataOptions?: {[key: string]: any}): Promise<RemoteResponse>{
		const payload: RemotePayload<IVendorData> = this.createPayload<IVendorData>(data, dataOptions);
		console.log(payload)
		const updateUser = firebase.functions().httpsCallable("service_vendor_deleteVendor");
		return updateUser(payload);
  }

  public activateVendor(data: Persistable<IVendorData>, dataOptions?: { [key: string]: any }): Promise<RemoteResponse> {
    const payload: RemotePayload<IVendorData> = this.createPayload<IVendorData>(data, dataOptions);
    console.log(payload)
    const activate = firebase.functions().httpsCallable("service_vendor_activateVendor");
    return activate(payload);
  }

  public inactivateVendor(data: Persistable<IVendorData>, dataOptions?: { [key: string]: any }): Promise<RemoteResponse> {
    const payload: RemotePayload<IVendorData> = this.createPayload<IVendorData>(data, dataOptions);
    console.log(payload)
    const inactivate = firebase.functions().httpsCallable("service_vendor_inactivateVendor");
    return inactivate(payload);
  }
	
	private createPayload<T>(persistable: Persistable<T>, dataOptions: {[key: string]: any}): RemotePayload<T>{
		const payload: RemotePayload<T> = <RemotePayload<T>>{};
		payload.id = persistable.getID();
		payload.data = persistable.getData();
		if (dataOptions && Object.keys(dataOptions).length > 0) Object.keys(dataOptions).forEach((option: string) => {payload[option] = dataOptions[option];});
		return payload;
	}

}

