import {Injectable } from '@angular/core';

@Injectable()
export abstract class PhotoViewerService{

	constructor(
	){

	}

	public abstract show(url: string, title?: string): Promise<void>	

	public abstract exit():void

}
 