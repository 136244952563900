import {Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Capacitor } from "@capacitor/core";


export enum View {
	USER = 1,
	VENDOR,
	VENUE
}

export enum App{
	WEB = 1,
	IOS,
	ANDROID
}

@Injectable()
export class ViewManagerService{
	private view: View = View.USER;

	constructor(private router: Router){

	}


	public currentView(): View{
		if (this.router.url.search('/vendor/') === 0){
			return View.VENDOR;
		}else if(this.router.url.search('/venue/') === 0){
			return View.VENUE;
		}else if(this.router.url.search('/user/') === 0){
			return View.USER;
		}
	}

	public isUserView(): boolean{
		if (this.router.url.search('/user/') === 0) {return true;}
		return false;
	}

	public isVendorView(): boolean{
		if (this.router.url.search('/vendor/') === 0) {return true;}
		return false;
	}

	public getPlatform(): App{
		switch(Capacitor.getPlatform()){
			case "web": 
				return App.WEB;
			case "ios": 
				return App.IOS;
			case "android":
				return App.ANDROID; 
		}
	}

	public isNative(): boolean{
		return Capacitor.isNativePlatform();
	}

}
