/***Models***/
import {IMenuItemData, IMenuTypeData } from "../../../app-domain/menu/index";
/***Interfaces */
import { Persistable } from "../../../app-domain/index";


export abstract class MenuService{
    constructor(){

    }

    public abstract addMenuItem(menuItem: Persistable<IMenuItemData>, dataOptions?: {[key: string]: any}): Promise<any>
	public abstract updateMenuItem(menuItem: Persistable<IMenuItemData>, dataOptions?: {[key: string]: any}): Promise<any>
	public abstract deleteMenuItem(menuItem: Persistable<IMenuItemData>, dataOptions?: {[key: string]: any}): Promise<any>
	public abstract toggleMenuItem(menuItem: Persistable<IMenuItemData>, dataOptions?: {[key: string]: any}): Promise<any>
	public abstract toggleSubMenu(menu: Persistable<{[key: string]: IMenuItemData}>, dataOptions?: {[key: string]: any}): Promise<any>
	public abstract reorderSubMenu(menu: Persistable<{[key: string]: IMenuItemData}>, dataOptions?: {[key: string]: any}): Promise<any>
	public abstract deleteSubMenu(menu: Persistable<{[key: string]: IMenuItemData}>, dataOptions?: {[key: string]: any}): Promise<any>
	public abstract deleteMenuType(menuType: Persistable<IMenuTypeData>, dataOptions?: {[key: string]: any}): Promise<any>
	public abstract updateMenuType(menuType: Persistable<IMenuTypeData>, dataOptions?: {[key: string]: any}): Promise<any>
	public abstract addMenuType(menuType: Persistable<IMenuTypeData>, dataOptions?: {[key: string]: any}): Promise<any>
  public abstract reorderMenuTypes(menuType: Persistable<IMenuTypeData>, dataOptions?: { [key: string]: any }): Promise<any>
}
