import { Directive, ElementRef, Input, OnInit} from '@angular/core';
import { UserService, UserRole } from "../app-domain/user/index";

@Directive({
	selector: '[componentAccess]',
})
export class ComponentAccessDirective implements OnInit{
	@Input()  componentAccess: UserRole[];


	constructor(private userService: UserService, private el: ElementRef) {

	}


	ngOnInit(){
		if (!this.componentAccess.includes(this.userService.getRole())){
			this.el.nativeElement.style.display = "none";
		}
	}

}
