import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

import { ISubMenu, IMenuTypeData, IMenuItem, IMenuItemData, MenuItem, IMenu } from "./index";
import { Model, Persistable, QuerySubscription } from "../../app-domain/index";
import { AppModule } from 'src/app/app.module';
import { VendorService } from '../vendor';
import { SubscriptionManagerService } from 'src/app/app-services/subscription-manager.service';

export class SubMenu implements ISubMenu, Persistable<IMenuTypeData>{
	private menuTypeData: IMenuTypeData = <IMenuTypeData>{};
	private menuTypeID: string;
	private menuItems: IMenuItem[] = [];
	private allAvailableInd: boolean;
	private menu: IMenu;
	private menuTypeReference: firebase.database.Reference;
	private subMenuQueryRef: firebase.database.Query;

	constructor(menuTypeID?: string, menuTypeData?: IMenuTypeData){
		this.menuTypeID = menuTypeID;
		this.menuTypeData = menuTypeData;
	}

	public getID(): string{
		return this.getMenuTypeID();
	}

	public getData(o?: number): IMenuTypeData{
		return this.getMenuTypeData();
	}

	public setMenu(menu: IMenu): void{
		this.menu = menu;
	}

	public getMenuTypeID(): string{
		return this.menuTypeID;
	}

	public getMenuTypeData(): IMenuTypeData{
		return this.menuTypeData;
	}

	public getName(): string{
		return this.menuTypeData.name;
	}

	public getOrder(): number{
		return this.menuTypeData.order;
	}


	public getDescription(): string{
		return this.menuTypeData.description;
	}

	public subscribe(db: firebase.database.Reference): void{
		if (this.menuTypeReference) this.menuTypeReference.off();
		AppModule.injector.get(SubscriptionManagerService).addSubcription(this);
		this.menuTypeReference = db;
		db.on("value",(snapshot: firebase.database.DataSnapshot) => {
			//console.log("Menu Type Update");
			if (snapshot.val() != undefined){
				if (this.getName() != snapshot.val().name){
					this.resubscribeToQuery(snapshot.val().name);
				}
				this.menuTypeData = snapshot.val();
				//if (b_name != a_name) this.menu.reconstitute();
				//if (this.menu) this.menu.reconciliateMenu();
			}
		});
	}

	

	public subscribeToQuery(query: firebase.database.Query): void{
    	if (this.subMenuQueryRef) this.subMenuQueryRef.off();
		AppModule.injector.get(SubscriptionManagerService).addSubcription(this);
		this.subMenuQueryRef = query;
		query.on('value', async (snapshot) => {
			//console.log("Sub Menu Update");
			this.resetMenuItems();
			//console.log(snapshot.val())
			
			if (snapshot.val()){
				Object.keys(snapshot.val()).forEach((menuItemID: string) => {
					const ref: firebase.database.Reference = firebase.database().ref("/menus/" + this.menu.getMenuID() + '/' + menuItemID);
					const menuItem: IMenuItem = new MenuItem(snapshot.val()[menuItemID],menuItemID);
					menuItem.subscribe(ref);
					this.addMenuItem(menuItem);
				});
			}
			
			//if (this.menu) this.menu.reconciliateMenu();
		});
	}

	private resubscribeToQuery(newMenuType: string): void{
		//console.log("Resub")
		//console.log(newMenuType);
		this.subMenuQueryRef.off();
		//console.log(this.menu.getMenuID());
		const ref = firebase.database().ref("menus/" + this.menu.getMenuID())
		const query: firebase.database.Query = ref.orderByChild('menuType').equalTo(newMenuType);
		this.subscribeToQuery(query);
	}

	public getAllMenuItems(): IMenuItem[]{
		return this.menuItems.sort(this.order);
	}

	public getAvailableMenuItems(): IMenuItem[]{
		return this.menuItems.filter((menuItem) => {
			return menuItem.isActive();
		}).sort(this.order)
	}

	public addMenuItems(menuItems: IMenuItem[]): void{
		this.menuItems = this.menuItems.concat(menuItems);
	}

	private addMenuItem(menuItem: IMenuItem): void{
		//console.log(menuItem);
		this.menuItems.push(menuItem);
	}

	public resetMenuItems(): void{
		this.menuItems.forEach(menuItem => menuItem.destroy());
		this.menuItems = [];
	}

	public removeMenuItem(menuItem: IMenuItem): void{
		this.menuItems = this.menuItems.filter((_menuItem: IMenuItem) => _menuItem.getItemID() != menuItem.getItemID());
	}

	//confirm this is efficient
	public allMenuItemsAvailable(): boolean{
		if (this.getName() === "Main Menu"){

			this.menuItems.reduce((allAvail,menuItem,idx) => allAvail && menuItem.getMenuData() && menuItem.isActive(),true);
		}

		return this.menuItems.reduce((allAvail,menuItem,idx) => allAvail && menuItem.getMenuData() && menuItem.isActive(),true);
	}

	private order(a,b): any{
		if (a.getOrder() > b.getOrder()) {return 1;}
		if (a.getOrder() < b.getOrder()) {return -1;}
		return 0;
	}

	public hasMenuItems(): boolean{
		return this.getAllMenuItems().length > 0;
	}

	public hasActiveMenuitems(): boolean{
		return this.hasMenuItems() && this.menuItems.filter((menuItem) => {return menuItem.isActive()}).length > 0;
	}

	public destroy(){
		this.resetMenuItems();
		this.unsubscribe();
	}

	public unsubscribe(): void {
		if (this.menuTypeReference) this.menuTypeReference.off();
		if (this.subMenuQueryRef) this.subMenuQueryRef.off();
	}

}
