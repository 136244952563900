import { Component } from '@angular/core';

import { NavParams, ModalController, ToastController } from "@ionic/angular";
import { EventRepo, IChildCalendar, IEvent, IHotSpotData } from "../../../../../app-domain/scheduling/index";
import { VendorService, IVendor } from "../../../../../app-domain/vendor/index";


/***Services***/
import { SchedulingService } from "../../../../../app-services/index";

/* Interfaces */
/**Data */
import usStates from "../../../../../../assets/data/us-states";

export interface VerifiedEventUpdate {
  status: "add" | "remove",
  event: IEvent
}


@Component({
	templateUrl: 'verified-event.page.html',
	styleUrls: ['verified-event.page.scss']
})
export class VerifiedEventPage {
  public events: IChildCalendar;
  public vendor: IVendor;
  public state: string;
  public usStates = usStates;
  public updatedEvent: VerifiedEventUpdate;

  constructor(private vendorService: VendorService, private navParams: NavParams, private modalCtrl: ModalController,
    private eventRepo: EventRepo, private schedulingService: SchedulingService, private toastCtrl: ToastController
				) {
	
	}

  async ngOnInit() {
    this.vendor = this.vendorService.getVendorFocus();
    this.state = this.vendor.getState();
    this.getUpcomingHotSpotsBy(this.state);
	}

  public async getUpcomingHotSpotsBy(state: string) {
    this.events = await this.eventRepo.getUpcomingHotSpotsByState(state);
    console.log(this.events);
  }

  public onChange(state: string) {
    return this.getUpcomingHotSpotsBy(state);
  }

  public onCancel() {
    this.modalCtrl.dismiss(this.updatedEvent ? this.updatedEvent : undefined);
  }

  public addVendorToHotspot(checked, event: IEvent) {
   
    if (checked === true) {
      this.updatedEvent = {
        event: event,
        status: 'add'
      }
      this.schedulingService.addVendorToHotSpot(event.getEventID(), { event: event.getEventData(), hotspot: event.getHotSpotData() }, this.vendor.getVendorID());
      this.sendToastMessage("You are now attending " + event.getHotSpotData().name);
    } else {
      this.updatedEvent = {
        event: event,
        status: 'remove'
      }
      this.schedulingService.removeVendorFromHotSpot(event.getEventID(), { event: event.getEventData(), hotspot: event.getHotSpotData() }, this.vendor.getVendorID());
      this.sendToastMessage("You are no longer attending " + event.getHotSpotData().name);
    }
  }

  async sendToastMessage(msg: string){
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      color: 'dark'
    })

    toast.present();
  }
}
