import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import {Injectable } from '@angular/core';
import { RemoteStorageService, RemoteStorageTaskResponse, RemoteStorageTaskState } from './index';
import { Image } from 'src/app/app-domain';
import { DataURL } from 'src/app/app-interfaces';



@Injectable()
export class FirebaseStorageService extends RemoteStorageService{

	constructor(
	
	){
		super();
	}

    async uploadImage(path: string, image: DataURL): Promise<RemoteStorageTaskResponse>{
        const uploadResponse: RemoteStorageTaskResponse = {
            state: RemoteStorageTaskState.SUCCESS,
            message: "",
            url: ""
        };
      
      try {
        const taskSnapshot = await firebase.storage().ref(path).putString(image.contentString, image.contentEncode, { contentType: image.contentType, cacheControl: "public, max-age=4000" })
            if (taskSnapshot.state === firebase.storage.TaskState.SUCCESS){
                uploadResponse.url = await firebase.storage().ref(path).getDownloadURL();
                uploadResponse.message = "Image Upload Successful";
            }else{
                uploadResponse.message = "Image Upload Unsuccessful";
                uploadResponse.state = RemoteStorageTaskState.FAILURE
            }
        }catch(error){
            uploadResponse.message = error.message;
            uploadResponse.state = RemoteStorageTaskState.FAILURE
        }
        
        return uploadResponse;
    }

    
	
	async deleteImage(image: Image): Promise<RemoteStorageTaskResponse>{
        const uploadResponse: RemoteStorageTaskResponse = {
            state: RemoteStorageTaskState.SUCCESS,
            message: "",
            url: image.imageUrl
        };

		try{
            await firebase.storage().ref(image.imageSrc).delete();
            uploadResponse.message = "Image Deletion Successful";
        }catch(error){
            uploadResponse.message = error.message;
            uploadResponse.state = RemoteStorageTaskState.FAILURE;
        }
        return uploadResponse;
	}

    async uploadAndReplaceImage(newImagePath: string, newImage: DataURL, oldImage?: Image): Promise<RemoteStorageTaskResponse>{
        const uploadResponse = await this.uploadImage(newImagePath, newImage);
        console.log()
        if (uploadResponse.state === RemoteStorageTaskState.FAILURE){
           return uploadResponse;
        }
        
        if (oldImage){
            const deleteResponse = await this.deleteImage(oldImage);
            if (deleteResponse.state === RemoteStorageTaskState.SUCCESS){
                return {
                    state: RemoteStorageTaskState.SUCCESS,
                    message: "Image has been successfully updated",
                    url: uploadResponse.url
                }
            }else{
                return {
                    state: RemoteStorageTaskState.SUCCESS,
                    message: "Unable to delete existing image, logging should be added here in future",
                    url: uploadResponse.url
                }
            }
            
        }else{
            return uploadResponse;
        }
    }



   
}
